import React, { CSSProperties, useEffect, useMemo, useState } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiFile } from 'ant/components/ui/file';
import styles from 'ant/components/ui/file-list/FileList.scss';
import { UiList } from 'ant/components/ui/list';
import { File } from 'ant/components/widgets/File';
import { declension, filesDeclension } from 'ant/plugins/declension';
import { FileStorageObjectModel } from 'ant/types/models/file.model';

interface Props {
  maxCollapsedCount?: number;
  compact?: boolean;
  fileStorageObjects: FileStorageObjectModel[];
}

export const FileListCollapsed: React.FC<Props> = (props) => {
  const { maxCollapsedCount = 3, fileStorageObjects, compact } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    setIsCollapsed(fileStorageObjects.length > maxCollapsedCount);
  }, [fileStorageObjects]);

  const restFileCount = fileStorageObjects.length - maxCollapsedCount;
  const moreButtonLabel = `еще ${restFileCount} ${declension(restFileCount, filesDeclension)}`;

  const showedItems = useMemo(
    () => fileStorageObjects.filter((_, index) => (isCollapsed ? index < maxCollapsedCount : true)),
    [fileStorageObjects, isCollapsed, maxCollapsedCount],
  );

  const baseStyles: CSSProperties = { marginBottom: 8 };

  return (
    <>
      <UiList
        dataSource={showedItems}
        renderItem={(file) =>
          compact ? (
            <UiFile.Compact style={baseStyles} url={file.file} label={file.name} />
          ) : (
            <File
              style={baseStyles}
              fileName={file.name}
              file={{ ...file, filesCount: 0, foldersCount: 0 }}
            />
          )
        }
      />
      {isCollapsed && (
        <UiButton
          type="link"
          label={moreButtonLabel}
          onClick={() => setIsCollapsed(false)}
          className={styles.fileList__buttonExpand}
        />
      )}
    </>
  );
};
