import React, { FC, CSSProperties } from 'react';

import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { createArrayMock } from 'ant/plugins/create-mocks';

const MOCKS_MONTH_COUNT = 12;
const POSITION_STYLE: CSSProperties = { width: '100%', display: 'flex' };

export const GanttCompactLoading: FC = () => {
  return (
    <>
      <UiRow style={{ ...POSITION_STYLE, alignItems: 'center', justifyContent: 'center' }}>
        {createArrayMock(MOCKS_MONTH_COUNT, (_, key) => (
          <UiCol key={key} span={2} style={{ textAlign: 'center' }}>
            <UiSkeleton loading width={70} height={16} />
          </UiCol>
        ))}
      </UiRow>
      <UiRow style={{ ...POSITION_STYLE, alignContent: 'flex-start' }}>
        {[1, 4, 10].map((offset) => (
          <UiCol key={offset} span={12} offset={offset}>
            <UiSkeleton loading width="100%" height={20} />
          </UiCol>
        ))}
      </UiRow>
    </>
  );
};
