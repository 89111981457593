import classnames from 'classnames';
import React, { FC, ReactNode, HTMLAttributes } from 'react';

import { LayoutContent } from '../content';
import { LayoutLoading } from '../loading';
import { LayoutNoAccess } from '../no-access';
import { LayoutNotFound } from '../not-found';
import { LayoutSidebar } from '../sidebar';
import styles from './Layout.scss';

export interface LayoutProps extends HTMLAttributes<HTMLDivElement> {
  header?: ReactNode;
  navbar?: ReactNode;
  columns?: ReactNode[];
}

export type LayoutComponents = {
  Sidebar: typeof LayoutSidebar;
  Content: typeof LayoutContent;
  NotFound: typeof LayoutNotFound;
  NoAccess: typeof LayoutNoAccess;
  Loading: typeof LayoutLoading;
};

export const Layout: FC<LayoutProps> & LayoutComponents = (props) => {
  const { header, navbar, children, className, style } = props;

  return (
    <div className={classnames(className, styles.layout)} style={style}>
      <>{header}</>
      <>{navbar}</>
      <main
        className={classnames(styles.layout__main, {
          [styles.layout__main_topHeader]: Boolean(header),
          [styles.layout__main_leftNav]: Boolean(navbar),
        })}
      >
        {children}
      </main>
    </div>
  );
};

Layout.Sidebar = LayoutSidebar;
Layout.NotFound = LayoutNotFound;
Layout.NoAccess = LayoutNoAccess;
Layout.Content = LayoutContent;
Layout.Loading = LayoutLoading;
