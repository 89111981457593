import { DraggerProps } from 'antd/lib/upload';
import React, { FC } from 'react';

import { UiUpload, UiUploadProps } from 'ant/components/ui/upload';

export type UiUploadDraggerProps = UiUploadProps & Pick<DraggerProps, 'height'>;

export const UiUploadDragger: FC<UiUploadDraggerProps> = (props) => {
  const { style, height, ...restProps } = props;

  return <UiUpload {...restProps} type="drag" style={{ ...style, height }} />;
};
