import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React, { useMemo } from 'react';

import { UiTypography } from 'ant/components/ui/typography';

import styles from './UiEmptyFeedMessage.scss';

export type UiEmptyMessageType = {
  header?: React.ReactNode;
  description?: React.ReactNode;
};

export type UiEmptyFeedMessageProps = {
  emptyMessage?: UiEmptyMessageType;
  className?: string;
};

const DEFAULT_HEADER = 'Здесь пока ничего нет';

const UiEmptyFeedMessage: React.FC<UiEmptyFeedMessageProps> = (props) => {
  const { emptyMessage, className } = props;

  const emptyMessageHeader = useMemo(
    () => (isEmpty(emptyMessage) ? DEFAULT_HEADER : emptyMessage?.header),
    [emptyMessage],
  );

  return (
    <article className={classNames(styles.emptyFeedText, className)}>
      {emptyMessageHeader && (
        <UiTypography.Title
          type="secondary"
          level={3}
          className={classNames(styles.emptyFeedText__typography, styles.emptyFeedText__header)}
        >
          {emptyMessageHeader}
        </UiTypography.Title>
      )}
      {emptyMessage?.description && (
        <UiTypography.Text type="secondary" className={styles.emptyFeedText__typography}>
          {emptyMessage.description}
        </UiTypography.Text>
      )}
    </article>
  );
};

export { UiEmptyFeedMessage };
