import React, { FC, useCallback, useRef, useState } from 'react';
import { SketchPicker, ColorState, ColorChangeHandler } from 'react-color';

import { UiCol } from 'ant/components/ui/grid';
import { UiSpace } from 'ant/components/ui/space';
import { UiTooltip } from 'ant/components/ui/tooltip';
import { UiTypography } from 'ant/components/ui/typography/UiTypography';
import { useOnClickOutside } from 'ant/helpers/hooks/use-on-click-outside';
import { getColorBasedOnBackgroundColor } from 'ant/plugins/utils/get-color-based-on-background-color';
import { colorNameDictionary, themeStorage } from 'ant/theme/store';
import { ColorTheme } from 'ant/theme/typings';

import styles from './ColorPicker.scss';
import { ColorPickerComponentsPreview } from './components-preview/ComponentsPreview';

enum ColorSourceTypes {
  Hex = 'hex',
  Rgb = 'rgb',
  Hsl = 'hsl',
  Hsv = 'hsv',
}

type Props = {
  color: ColorTheme;
};

export const ColorPicker: FC<Props> = (props) => {
  const { color: selectedColorProps } = props;
  const { color: selectedColor, name, description } = selectedColorProps;
  const { setColorThemeEvent } = themeStorage;

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

  const getColorResult = ({ source, hex, rgb }: ColorState): string => {
    let colorValue = hex;

    if (source === ColorSourceTypes.Rgb) {
      colorValue = `rgba(${[rgb.r, rgb.g, rgb.b, rgb.a].join(',')})`;
    }

    return colorValue;
  };

  const onChangeComplete = useCallback<ColorChangeHandler<ColorState>>(
    (colorResult) => setColorThemeEvent({ name, color: getColorResult(colorResult) }),
    [name],
  );

  const onClickOpenPickColor = () => setIsColorPickerOpen(true);
  const onClickOutsideOverlay = () => setIsColorPickerOpen(false);

  const sketchPickerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(sketchPickerRef, onClickOutsideOverlay);

  return (
    <>
      <UiCol span={8} style={{ marginBottom: 16 }}>
        <span ref={sketchPickerRef}>
          <UiSpace size={20}>
            <UiTypography.Title type="secondary" style={{ fontWeight: 600, width: 188 }} level={4}>
              {colorNameDictionary[name]}
            </UiTypography.Title>
            <UiTooltip title={description}>
              <button
                value={selectedColor}
                name={name}
                type="button"
                onClick={onClickOpenPickColor}
                style={{ background: selectedColor, color: getColorBasedOnBackgroundColor(selectedColor) }}
                className={styles.colorPicker__palette}
              >
                {selectedColor.startsWith(ColorSourceTypes.Rgb) ? selectedColor : selectedColor.toUpperCase()}
              </button>
            </UiTooltip>
          </UiSpace>

          {isColorPickerOpen && (
            <SketchPicker
              color={selectedColor}
              onChangeComplete={onChangeComplete}
              className={styles.colorPicker__picker}
            />
          )}
        </span>
      </UiCol>
      <UiCol span={12} offset={2} style={{ marginBottom: 16 }}>
        <ColorPickerComponentsPreview colorName={name} />
      </UiCol>
    </>
  );
};
