import { DropDownProps as AntDropDownProps } from 'antd/es/dropdown';
import classNames from 'classnames';
import React, { FC, KeyboardEventHandler, useCallback, useMemo } from 'react';

import MeatballSvg from 'ant/components/svg/meatball.svg';
import { UiButton, UiButtonProps } from 'ant/components/ui/button';
import { UiDropdown } from 'ant/components/ui/dropdown';
import { UiIcon } from 'ant/components/ui/icon';
import { ItemType, UiMenuClickEventHandler } from 'ant/components/ui/menu';

import styles from './ActionsDropdown.scss';

export type ActionsDropdownItem = {
  label: string;
  onClick?: (params?: unknown) => void;
  icon?: SvgrComponent;
  disabled?: boolean;
};

interface Props extends Omit<AntDropDownProps, 'overlay'> {
  icon?: SvgrComponent;
  items: ActionsDropdownItem[];
  recordId?: string | number;
  disabled?: boolean;
  className?: string;
  itemClassName?: string;
  menuClassName?: string;
  dropdownButtonProps?: UiButtonProps;
}

export const ActionsDropdown: FC<Props> = (props) => {
  const {
    icon = MeatballSvg,
    items,
    recordId,
    disabled = false,
    className,
    itemClassName,
    menuClassName,
    dropdownButtonProps,
    ...rest
  } = props;

  const menuItems = useMemo<ItemType[]>(() => {
    return items.map((item, index) => {
      const onItemClick: UiMenuClickEventHandler = ({ domEvent }) => {
        domEvent.preventDefault();
        domEvent.stopPropagation();

        if (item.onClick) {
          item.onClick(recordId);
        }
      };

      const onKeyPress: KeyboardEventHandler = ({ key }) => {
        if (item.onClick && key === 'Enter') {
          item.onClick(recordId);
        }
      };

      return {
        icon: item.icon && (
          <UiIcon
            width={20}
            height={20}
            component={item.icon}
            className={styles.actionsDropdown__iconButton}
          />
        ),
        className: classNames(styles.actionsDropdown__menuItem, itemClassName),
        key: index,
        label: item.label,
        disabled: item.disabled,
        onClick: onItemClick,
        onKeyPress,
      };
    });
  }, [items]);

  const onButtonClick = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
    <UiDropdown
      trigger={['hover']}
      disabled={disabled}
      placement="bottomRight"
      menu={{ items: menuItems, className: classNames(styles.actionsDropdown__menu, menuClassName) }}
      {...rest}
    >
      <UiButton
        className={className}
        type="link-secondary"
        disabledFocus
        icon={<UiIcon width={20} height={20} component={icon} />}
        onClick={onButtonClick}
        {...dropdownButtonProps}
      />
    </UiDropdown>
  );
};
