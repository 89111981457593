import React, { FC, ReactNode, useMemo } from 'react';

import DownloadSvg from 'ant/components/svg/download.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiDivider } from 'ant/components/ui/divider';
import { UiFileDirection } from 'ant/components/ui/file';
import { UiIcon } from 'ant/components/ui/icon';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { FileProps } from 'ant/components/widgets/File';
import { declension, filesDeclension } from 'ant/plugins/declension';
import { getFormattedDate } from 'ant/plugins/utils/get-formatted-date';
import { getFormattedFileSize } from 'ant/plugins/utils/get-formatted-file-size';
import { FileStorageEntryType, FileStorageListEntryModel } from 'ant/types/models/file.model';

import { FileListItemColumn } from './column/FileListItemColumn';
import { FileListItemRow } from './row/FileListItemRow';

export interface FileListItemProps extends Pick<FileProps, 'direction' | 'actions'> {
  file: FileStorageListEntryModel;
}

export const FileListItem: FC<FileListItemProps> = (props) => {
  const { direction = UiFileDirection.Horizontal, file, actions = [], ...restProps } = props;
  const { size: fileSize, createdAt, name, file: fileUrl } = file;
  const isFolder = file.type === FileStorageEntryType.Folder;

  const title = useMemo(() => {
    return (
      <UiTypography.Text strong>
        <UiTruncateMarkup truncate lines={1}>
          {name}
        </UiTruncateMarkup>
      </UiTypography.Text>
    );
  }, [name, fileUrl]);

  const subtitle = useMemo<ReactNode>(() => {
    return (
      <UiTypography.Text type="secondary">
        {isFolder && `${file.filesCount} ${declension(file.foldersCount, filesDeclension)}`}
        {!isFolder && (
          <>
            {getFormattedFileSize(fileSize)}
            <UiDivider.Dot type="secondary" />
            {createdAt ? getFormattedDate(createdAt, 'dd.MM.yyyy') : ''}
          </>
        )}
      </UiTypography.Text>
    );
  }, [fileSize, file, createdAt]);

  const fileActions = useMemo<ReactNode[]>(() => {
    return [
      <UiButton
        key="download"
        target="_blank"
        href={fileUrl}
        disabledFocus
        type="link-secondary"
        icon={<UiIcon component={DownloadSvg} width={20} height={20} />}
        onClick={(e) => e.stopPropagation()}
      />,
      ...actions,
    ];
  }, [fileUrl, actions]);

  const FileListDirectionItem =
    direction === UiFileDirection.Horizontal ? FileListItemRow : FileListItemColumn;

  return (
    <FileListDirectionItem
      {...restProps}
      title={title}
      subtitle={subtitle}
      fileName={name}
      actions={fileActions}
      file={file}
      isFolder={isFolder}
    />
  );
};
