import React, { FC } from 'react';

import { UiRow, UiRowProps } from '../grid';
import { UiDescriptionsItem } from './item/UiDescriptionsItem';

export type UiDescriptionsComposition = {
  Item: typeof UiDescriptionsItem;
};

export type UiDescriptionsProps = UiRowProps;

// TODO: B2BCORE-4060 Дизайн. Контент с label привести к одному виду
export const UiDescriptions: FC<UiDescriptionsProps> & UiDescriptionsComposition = (props) => (
  <UiRow {...props} />
);

UiDescriptions.Item = UiDescriptionsItem;
