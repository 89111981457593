import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { DisplayOptions } from 'ant/components/widgets/Gantt/GanttCompact';
import { GanttFullTasksItem } from 'ant/components/widgets/Gantt/full/tasks/item/GanttFullTasksItem';
import { GetTasksStorage } from 'ant/store/tasks';
import { ProjectsStageModel } from 'ant/types/models/projects';

interface Props {
  stage: ProjectsStageModel;
  referenceTime: string;
  daySize: DisplayOptions['daySize'];
  storages: GetTasksStorage;
}

export const GanttFullTasks: FC<Props> = (props) => {
  const { storages, ...rest } = props;
  const { data: tasksData } = useStore(storages.storage.store);

  return (
    <>
      {tasksData.map((task) => (
        <GanttFullTasksItem key={task.id} task={task} storages={storages} {...rest} />
      ))}
    </>
  );
};
