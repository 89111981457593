import React, { FC, PropsWithChildren } from 'react';

import { UiCheckbox, UiCheckboxValueType } from 'ant/components/ui/checkbox';
import { UiFileDirection } from 'ant/components/ui/file';
import { UiList, UiListProps } from 'ant/components/ui/list';
import {
  FileStorageListItem,
  FileStorageListItemProps,
} from 'ant/components/widgets/FileStorageList/item/FileStorageListItem';
import { PaginatedList, PaginatedListProps } from 'ant/components/widgets/PaginatedList';
import { useLocalStorage } from 'ant/helpers/hooks/use-local-storage';
import { GetFileStorageListStorage } from 'ant/store/filestorage';
import { HistoryActionType } from 'ant/types/history';
import { FileStorageListEntryModel } from 'ant/types/models/file.model';

export type FileStorageListItemSelect = {
  selectedItemKeys: UiCheckboxValueType[];
  onChange: (newSelectedItemKeys: UiCheckboxValueType[]) => void;
};

export type FileStorageListProps = Pick<PaginatedListProps, 'storage' | 'pageSize' | 'query'> &
  Omit<FileStorageListItemProps, 'item' | 'file'>;

export const FileStorageList: FC<PropsWithChildren<FileStorageListProps>> = (props) => {
  const { storage, pageSize, query, fileSelect, ...itemProps } = props;
  const [fileListManagerDirection] = useLocalStorage<UiFileDirection>(
    'fileListManagerDirection',
    UiFileDirection.Horizontal,
  );

  const listGrid: Record<UiFileDirection, UiListProps<FileStorageListEntryModel>['grid']> = {
    [UiFileDirection.Vertical]: { column: 2, xl: 3, xxl: 5, gutter: 32 },
    [UiFileDirection.Horizontal]: undefined,
  };

  return (
    <PaginatedList<GetFileStorageListStorage['storage']>
      storage={storage}
      pageSize={pageSize}
      query={query}
      historyAction={HistoryActionType.Replace}
      size="small"
    >
      {({ data, isLoading }) => (
        <UiCheckbox.Group
          value={fileSelect?.selectedItemKeys}
          onChange={(selectedRowsKeys) => fileSelect?.onChange?.(selectedRowsKeys)}
          style={{ width: '100%' }}
        >
          <UiList
            split={false}
            loading={isLoading}
            grid={listGrid[fileListManagerDirection]}
            style={{
              padding:
                fileListManagerDirection === UiFileDirection.Vertical ? '0 24px 16px 24px' : '0 0 16px 0',
            }}
            dataSource={data}
            renderItem={(file) => {
              return (
                <FileStorageListItem
                  {...itemProps}
                  file={file}
                  fileSelect={fileSelect}
                  direction={fileListManagerDirection}
                />
              );
            }}
          />
        </UiCheckbox.Group>
      )}
    </PaginatedList>
  );
};
