import isEmpty from 'lodash/isEmpty';
import { marked } from 'marked';

import { EditorValue } from 'ant/components/widgets/Editor';
import { retrieveTextFromHtml } from 'ant/plugins/retrieve-text-from-html';
import { ArticleEditorBlockData, ArticleEditorOutputData, EntityContentData } from 'ant/types/editor';
import { EntityTextDefinition, EntityContentType } from 'ant/types/models/entity-text';
import { PostTextExtendedModel, PostTypes } from 'ant/types/models/post';

/**
 * Guard возвращает true, если тип данных соответствует deprecated-редактору editorJS
 *
 * @param {EntityContentData} body Данные для проверки.
 */
export const isEditorJSDeprecatedFormat = (body: EntityContentData): body is ArticleEditorOutputData =>
  body && 'blocks' in body;

export const isEditorVersion = (version: EntityContentType, body: EntityContentData): body is EditorValue =>
  body && 'version' in body && body.version === version;

/** @example [Антон Сергеев](@/user/54b2c8ab-da12-443f-9de6-0c80b32e0d40) => Антон Сергеев, для всех вхождений */
export const parseMentionsToPlainText = (sourceText?: string) => {
  if (!sourceText) return '';

  const mentionToReplace = /\[.*?]\((@\/user.*?)\)/g; // [Антон Сергеев](@/user/54b2c8ab-da12-443f-9de6-0c80b32e0d40)
  const mentionNameSelector = /(?<=\[).+?(?=])/; // выбирает текст в скобках -> [Антон Сергеев] => Антон Сергеев

  return sourceText.replaceAll(mentionToReplace, (mention: string) => {
    const mentionName = mention.match(mentionNameSelector)?.[0] ?? '';

    return mention.replace(mention, mentionName);
  });
};

export const parseEntityText = (entityText: string): EntityTextDefinition => {
  try {
    const parsedValue = JSON.parse(entityText);

    return {
      textType: parsedValue.textType || EntityContentType.CKEditor5,
      textValue: typeof parsedValue === 'number' ? parsedValue.toString() : parsedValue.textValue,
    };
  } catch {
    return {
      textType: EntityContentType.CKEditor5,
      textValue: entityText,
    };
  }
};

export const stringifyEntityText = (textType: EntityContentType, textValue: string) =>
  JSON.stringify({
    textType,
    textValue,
  });

export const retrieveTextFromMarkdown = (mdOrHtml: string) => {
  const { textValue, textType } = parseEntityText(mdOrHtml);
  const description = textType === EntityContentType.Markdown ? marked(textValue) : textValue;

  return retrieveTextFromHtml(description);
};

type ConverterFunction = (block: ArticleEditorBlockData) => string;

const convertArticleHeader = (block: ArticleEditorBlockData) => {
  const { data } = block;
  const { level, text } = data;

  return `<h${level}>${text}</h${level}>`;
};

const convertArticleImage = (block: ArticleEditorBlockData) => {
  const { file, caption } = block.data;

  const { url } = file;
  const imageCaption = caption ? `<figcaption>${caption}</figcaption>` : '';

  return `<figure class="image"><img src="${url}" />${imageCaption}</figure>`;
};

const convertArticleVideo = (block: ArticleEditorBlockData) => {
  const { file } = block.data;

  const { url } = file;

  return `<figure class="video"><video src="${url}" controls=""></video></figure>`;
};

const convertArticleAttaches = (block: ArticleEditorBlockData) => {
  const { file } = block.data;
  const { url, name } = file;

  return `<a href="${url}" target="_blank">${name}</a>`;
};

const convertArticleParagraph = (block: ArticleEditorBlockData) => {
  const { text } = block.data;

  return `<p>${text}</p>`;
};

const convertArticleFileBlock = (converterFn: ConverterFunction) => (block: ArticleEditorBlockData) => {
  const { file } = block.data;

  return file ? converterFn(block) : '';
};

/** @deprecated плагины старого редактора */
enum ArticleEditorBlockTypes {
  Paragraph = 'paragraph',
  Header = 'header',
  Image = 'image',
  Attaches = 'attaches',
  Video = 'video',
}

const blockConverters = {
  [ArticleEditorBlockTypes.Header]: convertArticleHeader,
  [ArticleEditorBlockTypes.Paragraph]: convertArticleParagraph,
  [ArticleEditorBlockTypes.Image]: convertArticleFileBlock(convertArticleImage),
  [ArticleEditorBlockTypes.Video]: convertArticleFileBlock(convertArticleVideo),
  [ArticleEditorBlockTypes.Attaches]: convertArticleFileBlock(convertArticleAttaches),
};

export const convertEditorJSDeprecatedToCKEditorFormat = (oldData: ArticleEditorOutputData): EditorValue => {
  const outputData = (oldData?.blocks || [])
    .map((block) => blockConverters[<ArticleEditorBlockTypes>block.type](block))
    .join('');

  return {
    data: outputData,
    version: EntityContentType.CKEditor5,
  };
};

const isEditorHasBody = (body: EntityContentData) => body && !isEmpty(body);

export const getPlainTextFromHtml = (html: string) => {
  return html.replace(/(<([^>]+)>)/gi, '');
};

export const getActualEditorFormat = (editorValue: EntityContentData) => {
  if (!isEditorHasBody(editorValue)) {
    return { data: '', version: EntityContentType.CKEditor5 };
  }

  return isEditorJSDeprecatedFormat(editorValue)
    ? convertEditorJSDeprecatedToCKEditorFormat(editorValue)
    : editorValue;
};

/**
 * Функция парсит и конвертирует входящие данные текстового поста в html
 * Возможные варианты постов:
 * Deprecated: Посты с разметкой HTML (post.text = { post.textType = 'html', post.textValue = '<p>example</p>'})
 * Deprecated: Посты с разметкой Markdown (post.text = { post.textType = 'md', post.textValue = 'Hello *World*!'})
 * Deprecated: Посты с разметкой editorjs (post.body = { blocks = [...], version = '2.2.22.....'})
 * Посты с разметкой ckeditor5 (post.body = { data = '<p>example</p>', version = 'CKEditor5'})
 *
 * @param {PostTextExtendedModel} post: пост который нужно распарсить
 *
 * @return {string} возвращает строку в формате HTML или Markdown.
 */
export const parseTextPostContent = (post: PostTextExtendedModel) => {
  if (post?.body && isEditorHasBody(post.body)) {
    return isEditorJSDeprecatedFormat(post.body)
      ? convertEditorJSDeprecatedToCKEditorFormat(post.body).data
      : post.body.data;
  }

  let result;

  if (post.type === PostTypes.Entry && post.cutText) {
    result = parseEntityText(post.cutText).textValue;
  } else {
    result = 'text' in post && post.text ? parseEntityText(post.text).textValue : '';
  }

  return marked(result);
};
