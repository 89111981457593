import classNames from 'classnames';
import React, { CSSProperties, FC, PropsWithChildren } from 'react';

import styles from './UiCardHeader.scss';
import { UiCardHeaderBack, UiCardHeaderBackProps } from './back/UiCardHeaderBack';
import { UiCardHeaderTitle, UiCardHeaderTitleProps } from './title/UiCardHeaderTitle';

type CardHeaderBackComponent = React.FC<UiCardHeaderBackProps>;
type CardHeaderTitleComponent = React.FC<UiCardHeaderTitleProps>;

type HeaderComposition = {
  Back: CardHeaderBackComponent;
  Title: CardHeaderTitleComponent;
};

export interface UiCardHeaderProps {
  className?: string;
  style?: CSSProperties;
  emptyPadding?: boolean;
  hasBottomBorder?: boolean;
}

export type UiCardHeaderComponent = FC<PropsWithChildren<UiCardHeaderProps>> & HeaderComposition;

export const UiCardHeader: UiCardHeaderComponent = (props) => {
  const { children, className, emptyPadding = false, hasBottomBorder = false, style } = props;

  return (
    <header
      style={style}
      className={classNames(className, styles.uiCardHeader, {
        [styles.uiCardHeader_emptyPadding]: emptyPadding,
        [styles.uiCardHeader_bottomBorder]: hasBottomBorder,
      })}
    >
      {children}
    </header>
  );
};

UiCardHeader.Back = UiCardHeaderBack;
UiCardHeader.Title = UiCardHeaderTitle;
