import classNames from 'classnames';
import React, { CSSProperties, FC } from 'react';

import { UiAvatarSize } from 'ant/components/ui/avatar';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiUserInfoProps } from 'ant/components/ui/user';

import styles from '../UiUserInfo.scss';

export type UiUserInfoSkeletonProps = {
  className?: UiUserInfoProps['className'];
  size?: UiAvatarSize;
  style?: CSSProperties;
};

const UiUserInfoSkeleton: FC<UiUserInfoSkeletonProps> = (props) => {
  const { style, className, size = UiAvatarSize.S } = props;

  const isSmall = size <= UiAvatarSize.XS;
  const skeletonHeight = size <= UiAvatarSize.XS ? 12 : 15;

  return (
    <span
      style={style}
      className={classNames(styles.uiUserInfo, { [styles.uiUserInfo_small]: isSmall }, className)}
    >
      <UiSkeleton loading circle width={size} height={size} />
      <span className={styles.uiUserInfo__info}>
        <UiSkeleton loading width={200} height={skeletonHeight} style={{ marginBottom: 2 }} />
        <UiSkeleton loading width={130} height={skeletonHeight} />
      </span>
    </span>
  );
};

export { UiUserInfoSkeleton };
