import { UiOptionData } from 'ant/components/ui/select';
import { generateBaseSelectOptions } from 'ant/plugins/utils/options-helper';
import { Dictionaries } from 'ant/store/dictionaries/dictionaries';
import { TagId } from 'ant/types/models/base/base-tag';

export const generateTagFieldOptions = (tags: Dictionaries.Dict[] = []): UiOptionData[] =>
  generateBaseSelectOptions<Dictionaries.Dict>(tags, {
    valuePath: 'id',
    labelPath: 'name',
  });

export const generateTagApiOptions = (tags: UiOptionData[] = []): TagId[] => tags.map(({ value }) => value);
