import { UTCDate } from 'ant/types/models/issuer-date.model';

import { ListMeta } from './api';

export interface BaseAttributes {
  name: string;
}

export type RecordResponse<Attributes = BaseAttributes> = {
  attributes: Attributes;
  created: string;
  deletedAt: string | null;
  dictionary: string;
  id: string;
  isDeleted: boolean;
  isEnabled: boolean;
};

export type FetchRecordsParams = {
  withDeleted?: boolean;
  withDisabled?: boolean;
  pageNumber?: boolean;
  pageSize?: boolean;
};

export type FetchRecordsResponse<Attributes = BaseAttributes> = {
  objects: RecordResponse<Attributes>[];
  meta: ListMeta;
};

export type DictsResponse<Scheme = Record<string, unknown>> = {
  id: string;
  name: string;
  scheme: Scheme;
  description: string;
  defaultKeyFields?: string[];
  hasUniqueField?: boolean;
  updatedAt?: UTCDate;
};

export interface ContactLinkAttributes extends BaseAttributes {
  icon: string;
  recordId: string;
  prefix: string;
  placeholder: string;
}

export type DictBaseRecord = {
  id: string;
  name: string;
};

export enum ContactDictKinds {
  Messenger = 'messenger',
  ExternalLink = 'external_link',
}

export type FetchDictsListStorageParams = {
  items: RecordResponse[];
};

export type DictsListStorageParams = {
  ids: string[];
};
