import React, { FC, PropsWithChildren, ReactNode, useMemo } from 'react';

import DownloadSvg from 'ant/components/svg/download.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiDivider } from 'ant/components/ui/divider';
import { UiFileDirection, UiFileProps } from 'ant/components/ui/file';
import { UiColProps, UiRowProps } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { declension, filesDeclension } from 'ant/plugins/declension';
import { getFormattedDate } from 'ant/plugins/utils/get-formatted-date';
import { getFormattedFileSize } from 'ant/plugins/utils/get-formatted-file-size';
import { FileStorageEntryType, FileStorageListEntry } from 'ant/types/models/file.model';

import { FileActions } from './FileActions/FileActions';
import { FileColumn } from './FileColumn/FileColumn';
import { FileRow } from './FileRow/FileRow';

type FileComposition = {
  Actions: typeof FileActions;
};

export interface FileProps
  extends Omit<UiFileProps, 'title' | 'subtitle'>,
    Pick<UiRowProps, 'style' | 'justify'>,
    Pick<UiColProps, 'flex'> {
  title?: ReactNode;
  subtitle?: ReactNode;
  actions?: ReactNode[];
  file: FileStorageListEntry;
  stretch?: boolean;
}

export type FileComponent = FC<PropsWithChildren<FileProps>> & FileComposition;

export const File: FileComponent = (props) => {
  const {
    actions: actionsProp = [],
    file,
    subtitle: subtitleProp,
    title: titleProp,
    direction = UiFileDirection.Horizontal,
    ...restProps
  } = props;
  const { size: fileSize, createdAt, name } = file;
  const isFolder = file.type === FileStorageEntryType.Folder;
  const isVertical = direction === UiFileDirection.Vertical;
  const fileUrl = file.file;

  const title = useMemo(() => {
    if (titleProp) {
      return titleProp;
    }

    const titleContent = (
      <UiTruncateMarkup truncate lines={1}>
        {name}
      </UiTruncateMarkup>
    );

    if (fileUrl && !isVertical) {
      return (
        <UiTypography.Link href={fileUrl} target="_blank">
          {titleContent}
        </UiTypography.Link>
      );
    }

    return <UiTypography.Text strong>{titleContent}</UiTypography.Text>;
  }, [name, titleProp, fileUrl, isVertical]);

  const subtitle = useMemo<ReactNode>(() => {
    if (subtitleProp) {
      return subtitleProp;
    }

    const fileSubtitle = (
      <>
        {getFormattedFileSize(fileSize)}
        <UiDivider.Dot type="secondary" />
        {createdAt ? getFormattedDate(createdAt, 'dd.MM.yyyy') : ''}
      </>
    );

    const folderSubtitle = isFolder && `${file.filesCount} ${declension(file.foldersCount, filesDeclension)}`;

    return <UiTypography.Text type="secondary">{isFolder ? folderSubtitle : fileSubtitle}</UiTypography.Text>;
  }, [fileSize, file, createdAt, subtitleProp]);

  const actions = useMemo<ReactNode[]>(() => {
    if (fileUrl) {
      return [
        <UiButton
          key="download"
          target="_blank"
          href={fileUrl}
          disabledFocus
          type="link-secondary"
          icon={<UiIcon component={DownloadSvg} width={20} height={20} />}
          onClick={(e) => e.stopPropagation()}
        />,
        ...actionsProp,
      ];
    }

    return actionsProp;
  }, [fileUrl, actionsProp]);

  const FileComponent = isVertical ? FileColumn : FileRow;

  return (
    <FileComponent
      isFolder={isFolder}
      title={title}
      subtitle={subtitle}
      file={file}
      actions={actions}
      {...restProps}
    />
  );
};

File.Actions = FileActions;
