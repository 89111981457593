import { set, startOfHour, addHours } from 'date-fns';

export const getNearestHourInterval = (date: Date) => {
  const getSelectedDateWithCurrentTime = (selectedDate: Date) => {
    const currentDate = new Date();

    return set(selectedDate, {
      hours: currentDate.getHours(),
      minutes: currentDate.getMinutes(),
    });
  };

  const sinceDate = addHours(startOfHour(getSelectedDateWithCurrentTime(date)), 1);
  const tillDate = addHours(sinceDate, 1);

  return [sinceDate, tillDate];
};
