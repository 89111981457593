import { EditorValue } from 'ant/components/widgets/Editor';
import { Dictionaries } from 'ant/store/dictionaries/dictionaries';
import { BaseTag } from 'ant/types/models/base/base-tag';
import { BlogFullModel, PermissionsEnum } from 'ant/types/models/blog.model';
import { FileStorageEntryUrl } from 'ant/types/models/file.model';
import { GamificationItemTypes } from 'ant/types/models/gamification.model';
import { CreateDateModel, PublishDateModel } from 'ant/types/models/issuer-date.model';
import { KeycloakId, KeycloakUser } from 'ant/types/models/keycloak-user';
import { NewsArticle } from 'ant/types/models/news.model';
import { SkillType } from 'ant/types/models/profile.model';
import { ReactableModel } from 'ant/types/models/reactions.model';
import { PostSearchModel } from 'ant/types/models/search.model';
import { FullNameModel, Gender, UserModel } from 'ant/types/models/user.model';

export enum PostTypes {
  Entry = 'entry',
  Micropost = 'micropost',
  Simple = 'simple',
  Stories = 'stories',
  GamificationEntry = 'gamificationentry',
  ProfileEntry = 'profileentry',
}

export enum PostStatuses {
  FlagArchive = 0,
  FlagPublished = 1,
  FlagNew = 2,
  FlagDelete = 3,
  AwaitingApprove = 4,
  ApproveInProgress = 5,
  Decline = 6,
}

export enum PostSettingsVisibilityType {
  All = 'all',
  Followers = 'followers',
}

export type PostSettings = {
  isReacted: boolean;
  isComments: boolean;
  visibility: PostSettingsVisibilityType;
};

interface Owner {
  id: KeycloakId;
  gender: Gender;
  avatar: string;
  fullName: FullNameModel;
  keycloakUser: Pick<KeycloakUser, 'id' | 'keycloakId'>;
}

export type PostId = number;

interface PostCommonFields<Tags extends BaseTag = Dictionaries.Dict>
  extends CreateDateModel,
    PublishDateModel {
  id: PostId;
  type: PostTypes;
  permissions: PermissionsEnum[];
  authorId: string;
  author: UserModel;
  commentsCount: number;
  owner: Owner;
  settings?: PostSettings;
  tags: Tags[];
  flag: PostStatuses;
}

export interface PostOrdinaryEntryBase extends ReactableModel, PostCommonFields {
  blogFeedback: boolean;
  blogsFull: BlogFullModel[];
  blogs: BlogFullModel[] | BlogFullModel;

  /**
   * Эта строка - JSON, в котором два поля - тип контента (textType) и сам контент (textValue).
   * Для новых постов в типе будет признак того, каким способом показывать контент (HTML, markdown и т.п.),
   * и какой редактор открывать при редактировании
   * Если в поле невалидный JSON, счиатем, что это строка. Для обратной совместимости
   */
  cutText: string;
  draft: boolean;
  favorite: boolean;
  image?: FileStorageEntryUrl /** @deprecated use fileStorageImageUrl instead */;
  isAdvert: boolean;
  isPinned: boolean;
  title?: string;
  viewsCount: number;
  body?: EditorValue;
}

export interface MicropostModel extends ReactableModel, PostCommonFields {
  createdAt: string;
  updatedAt: string;

  /**
   * Эта строка - JSON, в котором два поля - тип контента (textType) и сам контент (textValue).
   * Для новых постов в типе будет признак того, каким способом показывать контент (HTML, markdown и т.п.),
   * и какой редактор открывать при редактировании
   * Если в поле невалидный JSON, счиатем, что это HTML. Для обратной совместимости
   */
  text: string;
  favorite: boolean;
  type: PostTypes.Micropost;
  title: string;
  body?: EditorValue;
}

export interface PostBlogModel extends PostOrdinaryEntryBase {
  type: PostTypes.Entry;
}

export interface PostNewsModel
  extends ReactableModel,
    PostCommonFields,
    Omit<NewsArticle, 'tags' | 'settings'> {
  type: PostTypes.Simple | PostTypes.Stories;
  image: FileStorageEntryUrl;
}

export interface PostGamificationModel extends PostOrdinaryEntryBase {
  type: PostTypes.GamificationEntry;
  entityType: GamificationItemTypes;
  entityId: number;
}

interface ProfileExtraInfo {
  id: string;
  name: string;
  entityId: number;
}

export interface PostProfileEntryModel extends ReactableModel, PostCommonFields {
  createdAt: string;
  updatedAt: string;
  text: string;
  entityType: SkillType;
  extraInfo: ProfileExtraInfo;
  type: PostTypes.ProfileEntry;
}

export type PostBasicModel = PostBlogModel | MicropostModel;
export type PostTextModel = PostBasicModel | PostNewsModel;
export type PostTextExtendedModel = PostTextModel | PostSearchModel;
export type PostModel = PostTextModel | PostProfileEntryModel | PostGamificationModel;
