import { Avatar, Badge } from 'antd';
import classNames from 'classnames';
import React from 'react';

import AvatarUserSvg from 'ant/components/svg/avatar-user.svg';
import { UiAvatarProps } from 'ant/components/ui/avatar';

import styles from './UiAvatar.scss';

const ccn = classNames.bind(styles);

export const UiAvatar: React.FC<UiAvatarProps> = (props) => {
  const {
    icon,
    size: propSize,
    className,
    withBadge = false,
    shape = 'circle',
    disabled,
    src,
    ...avatarProps
  } = props;

  const size = withBadge ? 64 : propSize;
  const Icon = icon || <AvatarUserSvg height={size} width={size} />;

  const classNameStyles = {
    [styles.uiAvatar__circle]: shape === 'circle',
    [styles.uiAvatar__square]: shape === 'square',
    [styles.uiAvatar__disabled]: disabled,
  };

  const AvatarComponent = (
    <Avatar
      style={{ minWidth: size, minHeight: size }}
      {...avatarProps}
      {...(!disabled && { src })}
      shape={shape}
      size={size}
      icon={Icon}
      className={ccn(styles.uiAvatar, classNameStyles, className)}
    />
  );

  return withBadge ? (
    <Badge dot offset={[-10, 10]}>
      {AvatarComponent}
    </Badge>
  ) : (
    AvatarComponent
  );
};
