// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._432b1459_file-list__button-expand{height:20px;margin:6px 0}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/file-list/FileList.scss"],"names":[],"mappings":"AAEE,oCACE,WAAA,CACA,YAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.file-list {\n  &__button-expand {\n    height: 20px;\n    margin: 6px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file-list__button-expand": "_432b1459_file-list__button-expand",
	"fileList__buttonExpand": "_432b1459_file-list__button-expand"
};
export default ___CSS_LOADER_EXPORT___;
