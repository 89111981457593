import React, { FC, PropsWithChildren, CSSProperties } from 'react';

import ContactWorkPhoneSvg from 'ant/components/svg/case.svg';
import ContactMailSvg from 'ant/components/svg/contact-mail.svg';
import ContactPhoneSvg from 'ant/components/svg/contact-phone.svg';
import ContactLocationSvg from 'ant/components/svg/location.svg';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiIconProps } from 'ant/components/ui/icon';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { ClipboardButton } from 'ant/components/widgets/ClipboardButton';
import { PhoneNames } from 'ant/types/models/user.model';

import { ContactsLinkIcon } from './icon/ContactsLinkIcon';

export const ContactsLinkIcons: Record<PhoneNames, SvgrComponent> = {
  [PhoneNames.MobilePhone]: ContactPhoneSvg,
  [PhoneNames.PersonalEmail]: ContactMailSvg,
  [PhoneNames.WorkEmail]: ContactMailSvg,
  [PhoneNames.WorkPhone]: ContactWorkPhoneSvg,
  [PhoneNames.ContactLocation]: ContactLocationSvg,
};

export type ContactLinkProps = {
  value: string;
  label: string;
  href?: string;
  icon?: UiIconProps['component'];
};

type ContactsLinkComposition = {
  Icon: typeof ContactsLinkIcon;
};

const labelStyle: CSSProperties = { marginLeft: 8 };

export const ContactsLink: FC<PropsWithChildren<ContactLinkProps>> & ContactsLinkComposition = (props) => {
  const { value, icon, href, label, children: image } = props;
  const truncatedLabel = <UiTruncateMarkup truncate>{label}</UiTruncateMarkup>;

  return (
    <UiRow wrap={false} align="middle" gutter={8}>
      <UiCol flex="auto">
        <UiRow wrap={false}>
          {icon ? <ContactsLinkIcon component={icon} /> : image}
          {href && (
            <UiTypography.Link href={href} style={labelStyle}>
              {truncatedLabel}
            </UiTypography.Link>
          )}
          {!href && (
            <UiTypography.Text strong style={labelStyle}>
              {truncatedLabel}
            </UiTypography.Text>
          )}
        </UiRow>
      </UiCol>
      <UiCol>
        <ClipboardButton value={value} />
      </UiCol>
    </UiRow>
  );
};

ContactsLink.Icon = ContactsLinkIcon;
