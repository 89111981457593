import { BlogId, BlogSlugId } from 'ant/types/models/blog.model';
import { FileStorageContextServiceTypes, FileStorageListEntryModel } from 'ant/types/models/file.model';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import { ProjectId } from 'ant/types/models/projects';

type FileStorageRouteParams = { id: BlogId | BlogSlugId | ProjectId | KeycloakId };

export const getFileStorageRouteParams = (
  entry: FileStorageListEntryModel,
): FileStorageRouteParams | null => {
  if (entry?.context?.service === FileStorageContextServiceTypes.Blogs) {
    return { id: entry.context.blogSlug || entry.context.blogId };
  }

  if (entry?.context?.service === FileStorageContextServiceTypes.Projects) {
    return { id: entry.context.projectId };
  }

  if (entry?.context?.service === FileStorageContextServiceTypes.Profile) {
    return { id: entry.context.userId };
  }

  return null;
};
