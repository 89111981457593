const getGroupsUrl = (url: string) => `/api/groups/${url}`;

export class GroupsEndpoints {
  static organizationMembersAvailable = () => getGroupsUrl('organization/members/available/');

  static membersGroups = () => getGroupsUrl('members/groups/');

  static membersUserIdGroups = (userId: string) => getGroupsUrl(`members/${userId}/groups/`);

  static search = () => getGroupsUrl('search/');

  static department = () => getGroupsUrl('department/');
}
