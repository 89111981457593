import { differenceInMinutes, isAfter, addMinutes } from 'date-fns';
import { Event } from 'effector';
import { useSpring } from 'react-spring';
import { useGesture } from 'react-use-gesture';

import { getFormattedDateForApi } from 'ant/plugins/utils/get-formatted-date-for-api';
import { roundToGrid } from 'ant/plugins/utils/round-to-grid';
import { CalendarFreeSlot } from 'ant/types/models/calendar';

type UseTimeSectionGestureParams = {
  slotSince?: string;
  slotTill?: string;
  minDate: Date;
  maxDate: Date;
  halfHourColumnWidth: number;
  onUpdateEvent: Event<CalendarFreeSlot | null>;
};

const BORDER_WIDTH_OFFSET = 1;
const HALF_HOUR_MINUTES = 30;

const useTimeSectionGesture = (params: UseTimeSectionGestureParams) => {
  const { slotSince, slotTill, minDate, maxDate, halfHourColumnWidth, onUpdateEvent } = params;

  const slotSinceDate = slotSince ? new Date(slotSince) : new Date();
  const slotTillDate = slotTill ? new Date(slotTill) : slotSinceDate;
  const displayedSelectedSlotTillDate = isAfter(slotTillDate, maxDate) ? maxDate : slotTillDate;
  const displayedSelectedSlotSinceDate = isAfter(minDate, slotSinceDate) ? minDate : slotSinceDate;

  const selectedSlotDuration = differenceInMinutes(
    displayedSelectedSlotTillDate,
    displayedSelectedSlotSinceDate,
  );
  const selectedSlotStartShift = Math.max(0, differenceInMinutes(slotSinceDate, minDate));
  const allDuration = differenceInMinutes(maxDate, minDate);
  const maxShiftDuration = allDuration - selectedSlotDuration;

  const pxToMinutes = (px: number) => (px * HALF_HOUR_MINUTES) / halfHourColumnWidth;
  const minutesToPx = (minutes: number) => Math.floor((minutes * halfHourColumnWidth) / HALF_HOUR_MINUTES);

  const initialWidth = minutesToPx(selectedSlotDuration) + BORDER_WIDTH_OFFSET;
  const shift = minutesToPx(selectedSlotStartShift);
  const maxShift = minutesToPx(maxShiftDuration) - BORDER_WIDTH_OFFSET;
  const maxRight = minutesToPx(allDuration) - BORDER_WIDTH_OFFSET;

  const quarterHourColumnWidth = halfHourColumnWidth / 2;

  const snapToGrid = (x: number) => roundToGrid(x, quarterHourColumnWidth);

  const [{ x, width }, setSelection] = useSpring<{ x: number; width: number }>(
    () => ({
      x: shift,
      width: initialWidth,
    }),
    [shift, initialWidth],
  );

  const bindMiddleSectionGesture = useGesture(
    {
      onDrag: ({ movement: [ox] }) => {
        setSelection.start({
          x: snapToGrid(ox),
        });
      },
      onDragEnd: ({ movement: [ox] }) => {
        const oxMinutes = pxToMinutes(snapToGrid(ox));

        const newDateSince = addMinutes(minDate, oxMinutes);
        const newDateTill = addMinutes(minDate, oxMinutes + selectedSlotDuration);

        onUpdateEvent({
          since: getFormattedDateForApi(newDateSince),
          till: getFormattedDateForApi(newDateTill),
        });
      },
    },
    {
      drag: {
        bounds: { left: 0, right: maxShift, top: 0, bottom: 50 },
        initial: [x.get(), 0],
      },
    },
  );

  const bindLeftBorderGesture = useGesture(
    {
      onDrag: ({ movement: [ox] }) => {
        setSelection.start({
          x: snapToGrid(ox),
          width: snapToGrid(shift + initialWidth - ox - 2 * BORDER_WIDTH_OFFSET) + BORDER_WIDTH_OFFSET,
        });
      },
      onDragEnd: ({ movement: [ox] }) => {
        const sinceMinutes = pxToMinutes(snapToGrid(ox));
        const newDateSince = addMinutes(minDate, sinceMinutes);

        onUpdateEvent({
          since: getFormattedDateForApi(newDateSince),
          till: getFormattedDateForApi(slotTillDate),
        });
      },
    },
    {
      drag: {
        bounds: { left: 0, right: shift + initialWidth - quarterHourColumnWidth, top: 0, bottom: 50 },
        initial: [x.get(), 0],
      },
    },
  );

  const bindRightBorderGesture = useGesture(
    {
      onDrag: ({ movement: [ox] }) => {
        setSelection.start({
          width: snapToGrid(ox - x.get()) + BORDER_WIDTH_OFFSET,
        });
      },
      onDragEnd: ({ movement: [ox] }) => {
        const tillMinutes = pxToMinutes(snapToGrid(ox));
        const newDateTill = addMinutes(minDate, tillMinutes);

        onUpdateEvent({
          since: getFormattedDateForApi(slotSinceDate),
          till: getFormattedDateForApi(newDateTill),
        });
      },
    },
    {
      drag: {
        bounds: { left: shift + quarterHourColumnWidth, right: maxRight, top: 0, bottom: 50 },
        initial: [x.get() + width.get(), 0],
      },
    },
  );

  return { x, width, bindMiddleSectionGesture, bindLeftBorderGesture, bindRightBorderGesture };
};

export { useTimeSectionGesture };
