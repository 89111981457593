// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8d3f2e9d_ui-divider_column{flex-direction:column}._8d3f2e9d_ui-divider_overwrite-base-padding{width:auto;margin-left:-24px;margin-right:-24px}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/divider/UiDivider.scss"],"names":[],"mappings":"AAEE,6BACE,qBAAA,CAGF,6CACE,UAAA,CACA,iBAAA,CACA,kBAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.ui-divider {\n  &_column {\n    flex-direction: column;\n  }\n\n  &_overwrite-base-padding {\n    width: auto;\n    margin-left: -$baseSpacing;\n    margin-right: -$baseSpacing;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-divider_column": "_8d3f2e9d_ui-divider_column",
	"uiDivider_column": "_8d3f2e9d_ui-divider_column",
	"ui-divider_overwrite-base-padding": "_8d3f2e9d_ui-divider_overwrite-base-padding",
	"uiDivider_overwriteBasePadding": "_8d3f2e9d_ui-divider_overwrite-base-padding"
};
export default ___CSS_LOADER_EXPORT___;
