import { api } from 'ant/plugins/api';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { ReportsEndpoints } from 'ant/store/reports/endpoints';
import { RolesReportParams } from 'ant/types/reports';
import {
  GetSummaryAnalyticsReportParams,
  SummaryAnalyticsReportType,
} from 'ant/types/summary-analytics-report';

const getReportEndpoint = (params: GetSummaryAnalyticsReportParams) => {
  const { report, ...queryParams } = params;
  const isMetricsReports = [
    SummaryAnalyticsReportType.Dau,
    SummaryAnalyticsReportType.Mau,
    SummaryAnalyticsReportType.Sticky,
  ].includes(report);

  return buildEndpointWithQueryParams(
    isMetricsReports ? ReportsEndpoints.metricsReports(report) : ReportsEndpoints.blogCountsReports(report),
    queryParams,
  );
};

export const getReport = (params: GetSummaryAnalyticsReportParams) => {
  return api.get<Blob>({
    url: getReportEndpoint(params),
    responseType: 'blob',
  });
};

export const getRolesReport = (params: RolesReportParams) => {
  return api.get<Blob>({
    url: buildEndpointWithQueryParams(ReportsEndpoints.rolesReport(), params),
    responseType: 'blob',
  });
};
