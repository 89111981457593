import classNames from 'classnames';
import chunk from 'lodash/chunk';
import React, { ReactNode, Key, Children, FC, HTMLAttributes, useMemo } from 'react';

import { generateRandomString } from 'ant/plugins/utils/generate-random-string';

import styles from './LayoutSidebar.scss';

const DEFAULT_COLUMN_COUNT = 1;

type ReactChildCollection = { child: ReactNode[]; key: Key };
export interface LayoutSidebarProps extends HTMLAttributes<HTMLDivElement> {
  maxColumn?: number;
  flex?: boolean;
  shiftTop?: boolean;
}

export const LayoutSidebar: FC<LayoutSidebarProps> = (props) => {
  const { children, maxColumn = DEFAULT_COLUMN_COUNT, flex = true, shiftTop = true } = props;

  const childrenColumns = useMemo<ReactChildCollection[]>(() => {
    const childrenArray = Children.toArray(children);

    if (maxColumn > DEFAULT_COLUMN_COUNT) {
      const chunkCount = Math.ceil(childrenArray.length / maxColumn);

      return chunk(childrenArray, chunkCount).map((child) => ({ child, key: generateRandomString(4) }));
    }

    return [{ child: childrenArray, key: generateRandomString(4) }];
  }, [children, maxColumn]);

  return (
    <div
      className={classNames(styles.layoutSidebar, {
        [styles.layoutSidebar_flex]: flex,
        [styles.layoutSidebar_shiftTop]: shiftTop,
      })}
    >
      {childrenColumns.map(({ child, key }) => {
        return (
          <div key={key} className={styles.layoutSidebar__column}>
            {child}
          </div>
        );
      })}
    </div>
  );
};
