import classNames from 'classnames';
import React, { KeyboardEvent } from 'react';

import { reactionsIcons } from 'ant/components/widgets/Reactions/constants';

import styles from './ReactionsIcon.scss';

type Props = {
  id: string;
  iconName: string;
  onReact: (reactionId: string) => void;
  size?: number;
  className?: string;
};

export const ReactionsIcon: React.FC<Props> = (props) => {
  const { id, iconName, onReact, size = 20, className } = props;

  const onIconKeyPress = ({ key }: KeyboardEvent, reactionId: string) => {
    if (key === 'Enter') {
      onReact(reactionId);
    }
  };

  const Icon = reactionsIcons.find((icon) => icon.name === iconName)?.icon;

  return (
    <div
      tabIndex={-1}
      className={classNames(styles.reactionsIcon, className)}
      onClick={() => onReact(id)}
      role="button"
      onKeyPress={(event) => onIconKeyPress(event, id)}
    >
      <Icon width={size} height={size} />
    </div>
  );
};
