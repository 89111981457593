import React, { FC } from 'react';

export type UiMarkProps = {
  text: string;
  value: string;
  multiple?: boolean;
};

export const UiMark: FC<UiMarkProps> = (props) => {
  const { text, value, multiple = false } = props;

  if (!text || !value) {
    return <>{text}</>;
  }

  const matchSearchIndex = text.toLowerCase().indexOf(value.toLowerCase());

  if (matchSearchIndex >= 0) {
    return (
      <>
        {text.substring(0, matchSearchIndex)}
        <mark>{text.substring(matchSearchIndex, matchSearchIndex + value.length)}</mark>
        {multiple ? (
          <UiMark text={text.substring(matchSearchIndex + value.length)} value={value} multiple={multiple} />
        ) : (
          text.substring(matchSearchIndex + value.length)
        )}
      </>
    );
  }

  return <>{text}</>;
};
