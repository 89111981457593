export const numberThousandSeparate = (value: string | number = '', separator = ' '): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const numberReplaceSeparator = (value: number | string, reg = '.', separator = ','): string => {
  return value.toString().replace(reg, separator);
};

/** @example 5000 => 5K */
export const numberAbbreviator = (value: number) => {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });

  return formatter.format(value);
};
