import React, { FC, CSSProperties } from 'react';

import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { useElementSize } from 'ant/helpers/hooks/use-element-size';
import { GetProjectStagesParams, GetProjectStagesStorage } from 'ant/store/tasks';

import { GanttCompactContent } from './compact/content/GanttCompactContent';
import { GanttContentHeader } from './compact/header/GanttContentHeader';
import { getDaySizeInYear } from './helpers/gantt-date-helper';

export interface DisplayOptions {
  year: NonNullable<GetProjectStagesParams['year']>;
  daySize: number;
  isSmall: boolean;
}

export enum GanttIntervalType {
  Year,
  Years,
  Months,
  Weeks,
}

const ROW_MARGINS = 12 * 2;
const SMALL_HEADER_WIDTH = 1100;

export const BASE_NAVIGATION_WIDTH = 220;
export const GANTT_PREVIEW_CONTAINER: CSSProperties = {
  width: `calc(100% - ${BASE_NAVIGATION_WIDTH - ROW_MARGINS}px)`,
};

interface GanttCompactProps {
  year: DisplayOptions['year'];
  storage: GetProjectStagesStorage['storage'];
}

export const GanttCompact: FC<GanttCompactProps> = (props) => {
  const { storage, year } = props;
  const { data: projectStagesData, loading: isProjectStagesLoading } = useAbstractStorage(storage);

  const { ref, width } = useElementSize([isProjectStagesLoading, projectStagesData]);
  const daySize = getDaySizeInYear(year, width);
  const isSmall = Boolean(width <= SMALL_HEADER_WIDTH);

  const displayOptions: DisplayOptions = {
    year,
    daySize,
    isSmall,
  } as const;

  return (
    <div ref={ref} style={{ overflow: 'hidden', width: '100%' }}>
      <GanttContentHeader displayOptions={displayOptions} />
      <GanttCompactContent displayOptions={displayOptions} stages={projectStagesData} />
    </div>
  );
};
