import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { useInView, IntersectionOptions } from 'react-intersection-observer';

export type UseInViewOptions = Pick<IntersectionOptions, 'onChange' | 'threshold' | 'root' | 'rootMargin'>;
export interface UiInfiniteScrollProps extends UseInViewOptions {
  loader?: ReactNode;
  hasMore?: boolean;
}

export const UiInfiniteScroll: FC<PropsWithChildren<UiInfiniteScrollProps>> = (props) => {
  const { children, onChange, loader, hasMore, threshold = 0, root, rootMargin = '100%' } = props;
  const { ref } = useInView({ onChange, threshold, root, rootMargin });

  return (
    <>
      {children}
      {hasMore && <div ref={ref}>{loader}</div>}
    </>
  );
};
