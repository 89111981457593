import classNames from 'classnames';
import { format } from 'date-fns';
import { Store } from 'effector';
import { useStore } from 'effector-react';
import React, { useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import DownloadSvg from 'ant/components/svg/download.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiFile } from 'ant/components/ui/file';
import { isFileToDisplay, isFolderToDisplay } from 'ant/components/ui/file-list/guards';
import { FileOrFolderAction, FileOrFolderToDisplay, FileToDisplay } from 'ant/components/ui/file-list/types';
import { UiIcon } from 'ant/components/ui/icon';
import { UiProgress } from 'ant/components/ui/progress';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { ActionsDropdown } from 'ant/components/widgets/ActionsDropdown';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getFormattedFileSize } from 'ant/plugins/utils/get-formatted-file-size';
import { getTruncateStringEnding } from 'ant/plugins/utils/get-truncate-string-ending';

import styles from './FileListItem.scss';

export type FileListItemProps = {
  item: FileOrFolderToDisplay;
  uploadProgressMapStore: Store<Record<string, number>>;
  isReadOnly?: boolean;
  hideDownloadButton?: boolean;
  className?: string;
};

const DownloadLink = ({ to, ...rest }: LinkProps) =>
  to ? <Link {...rest} to={to} download target="_blank" /> : <>{rest.children}</>;

const getMetaInfo = (item: FileOrFolderToDisplay) => {
  if (isFileToDisplay(item)) {
    const { date, size } = item;
    const dateString = date ? format(new Date(date), 'dd.MM.yyyy') : '';
    const sizeString = getFormattedFileSize(size);

    return [dateString, sizeString].filter(Boolean).join(' • ');
  }

  return `Файлы: ${item.filesCount}`;
};

const checkIsClickable = (item: FileOrFolderToDisplay) => {
  if (isFolderToDisplay(item)) {
    return Boolean(item.onClick);
  }

  if (isFileToDisplay(item)) {
    return Boolean(item.url);
  }

  return false;
};

/** @deprecated: Используй ant/components/widgets/File */
const FileListItem: React.FC<FileListItemProps> = ({
  item,
  uploadProgressMapStore,
  isReadOnly,
  hideDownloadButton = false,
  className,
}) => {
  const { name = '', actions = [] } = item;
  const meta = getMetaInfo(item);
  const mappedActions = useMemo(
    () =>
      (actions as FileOrFolderAction<FileOrFolderToDisplay>[]).map((action) => ({
        label: action.label,
        onClick: () => {
          action.onClick(item);
        },
      })),
    [item],
  );
  const nameTruncatedEnding = getTruncateStringEnding(name);
  const uploadProgressStore = useMemo(
    () => uploadProgressMapStore.map((map) => map[(item as FileToDisplay).id]),
    [(item as FileToDisplay).id],
  );
  const uploadProgressPercent = useStore(uploadProgressStore);
  const isUploading = uploadProgressPercent !== undefined;

  const onClick = () => {
    if (isReadOnly) {
      return;
    }

    if (isFolderToDisplay(item) && item.onClick) {
      item.onClick(item);
    }
  };

  const isClickable = checkIsClickable(item);
  const isFolder = isFolderToDisplay(item);

  const onKeyPress: React.KeyboardEventHandler = ({ key }) => {
    if (key === 'Enter') {
      onClick();
    }
  };

  const downloadUrl = isFileToDisplay(item) && item.url && !isReadOnly ? item.url : '';

  const clickableButtonProps = (clName: string, isOnlyClass = false) => {
    return isOnlyClass
      ? {
          className: clName,
        }
      : {
          role: 'button',
          onKeyPress,
          tabIndex: -1,
          onClick,
          className: classNames(clName, {
            [styles.fileListItem_clickable]: isClickable && !isReadOnly,
          }),
        };
  };

  return (
    <div className={classNames(styles.fileListItem, className)}>
      <div {...clickableButtonProps(styles.fileListItem__icon)}>
        <DownloadLink to={downloadUrl}>
          {isUploading ? (
            <UiProgress type="circle" percent={uploadProgressPercent} width={40} />
          ) : (
            <UiFile.Icon fileName={item.name} isFolder={isFolder} />
          )}
        </DownloadLink>
      </div>
      <div {...clickableButtonProps(styles.fileListItem__main, !isFolderToDisplay(item))}>
        <div {...clickableButtonProps(styles.fileListItem__name, isFolderToDisplay(item))}>
          <DownloadLink to={downloadUrl}>
            <UiTruncateMarkup truncate lines={1} ellipsis={`...${nameTruncatedEnding}`}>
              {name}
            </UiTruncateMarkup>
          </DownloadLink>
        </div>
        <div className={styles.fileListItem__meta}>{meta}</div>
      </div>
      {!isUploading && (
        // eslint-disable-next-line
        <div className={styles.fileListItem__actions} onClick={(event) => event.stopPropagation()}>
          {item?.author && (
            <UiTypography.Text className={styles.fileListItem__author}>
              <UiTruncateMarkup truncate>{getFullNameWithoutPatronymic(item?.author)}</UiTruncateMarkup>
            </UiTypography.Text>
          )}
          {downloadUrl && !hideDownloadButton && (
            <DownloadLink to={downloadUrl}>
              <UiButton
                disabledFocus
                type="link"
                className={styles.fileListItem__downloadBtn}
                icon={<UiIcon component={DownloadSvg} width={20} height={20} />}
              />
            </DownloadLink>
          )}
          {mappedActions.length > 0 && <ActionsDropdown items={mappedActions} />}
        </div>
      )}
    </div>
  );
};

export { FileListItem };
