import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import { BadgeColors, UiBadge } from 'ant/components/ui/badge';

import styles from './UiTableActionsPanel.scss';

type UiTableActionsPanelProps = {
  count: number;
  className?: string;
};

const UiTableActionsPanel: FC<PropsWithChildren<UiTableActionsPanelProps>> = ({
  count,
  children,
  className,
}) => {
  return (
    <span className={classNames(styles.uiTableActionsPanel, className)}>
      <UiBadge color={BadgeColors.Brand} count={count} />
      {children}
    </span>
  );
};

export { UiTableActionsPanel };
