import { createEvent, Event } from 'effector';

import { NotificationTypes } from './events';
import { NotificationContent } from './types';

type NotificationEventsMap = { [T in NotificationTypes]: Event<NotificationContent<T>> };

const buildEvent = <T extends NotificationTypes>(): Event<NotificationContent<T>> =>
  createEvent<NotificationContent<T>>();

const notificationEvents: NotificationEventsMap = Object.values(NotificationTypes).reduce(
  (res, curr) =>
    Object.assign(res, {
      [curr]: buildEvent<typeof curr>(),
    }),
  {} as NotificationEventsMap,
);

export const getNotificationEvent = <T extends NotificationTypes>(eventName: T) =>
  // FIXME: как избежать unknown?
  notificationEvents[eventName] as unknown as Event<NotificationContent<T>>;
