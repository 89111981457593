import QRCode from 'qrcode.react';
import React from 'react';

import { themePalette } from 'ant/theme';

export type UiQRCodeProps = typeof QRCode;

const UiQRCode: UiQRCodeProps = (props) => (
  <QRCode bgColor={themePalette.white} fgColor={themePalette.black} {...props} />
);

export { UiQRCode };
