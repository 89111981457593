import React, { useMemo, useState } from 'react';

import { UiOptionData } from 'ant/components/ui/auto-complete';
import { UiAvatar, UiAvatarSize } from 'ant/components/ui/avatar';
import {
  SearchOptionComplete,
  SearchOptionCompleteType,
  SearchOptionItem,
} from 'ant/components/widgets/SearchOptionComplete';
import { useAbstractStorage, UseAbstractStorageParams } from 'ant/helpers/hooks/use-abstract-storage';
import { getFullName } from 'ant/plugins/name-formatters';
import { getProfileStorage } from 'ant/store/profile';
import { useProfileStorage } from 'ant/store/profile/hooks';
import { AutoCompleteListStorage } from 'ant/types/auto-complete-list';

type SearchOptionItemOnlyId = Pick<SearchOptionItem, 'id'>;
export type SearchUserFieldOptionItem = SearchOptionItem | SearchOptionItemOnlyId;

interface SearchUserFieldBaseParam extends Record<string, unknown> {
  query?: string;
}

interface SearchUserFieldProps<SP>
  extends Omit<SearchOptionCompleteType, 'value'>,
    UseAbstractStorageParams<UiOptionData[], UiOptionData[], SP> {
  value?: SearchUserFieldOptionItem;
  storage: AutoCompleteListStorage;
}

export const SearchUserField = <SP extends SearchUserFieldBaseParam>(props: SearchUserFieldProps<SP>) => {
  const {
    value,
    storage,
    autoFetchParams,
    autoFetchAndRefetch = true,
    resetStoreOnUnmount = true,
    cancelPendingRequestOnUnmount = true,
    ...restProps
  } = props;
  const [searchQuery, setSearchQuery] = useState('');

  const { data: options, fetchFx: fetchList } = useAbstractStorage(storage, {
    autoFetchAndRefetch,
    resetStoreOnUnmount,
    cancelPendingRequestOnUnmount,
    autoFetchParams,
  });

  const { storage: profileStorage } = useMemo(getProfileStorage, []);
  const { data: profileData } = useAbstractStorage(profileStorage, {
    autoFetchAndRefetch: Boolean(value && !('name' in value)),
    autoFetchParams: { userId: value?.id || '' },
  });

  const updatedValue = useMemo<SearchOptionItem | undefined>(() => {
    if (!value) {
      return value;
    }

    if ('name' in value) {
      return value;
    }

    return {
      ...value,
      name: profileData ? getFullName(profileData.fullName) : '',
      avatar: profileData?.smallAvatar,
    };
  }, [value, profileData]);

  const { data: profile } = useProfileStorage({ id: value?.id });

  const onUpdate = (newSearchQuery: string) => fetchList({ query: newSearchQuery, ...autoFetchParams });

  return (
    <SearchOptionComplete
      placeholder="Выбрать"
      size="large"
      prefix={<UiAvatar size={UiAvatarSize.XS} src={profile?.smallAvatar} />}
      options={options}
      onSearch={setSearchQuery}
      searchQuery={searchQuery}
      updateOptionListHandler={onUpdate}
      value={updatedValue}
      {...restProps}
    />
  );
};
