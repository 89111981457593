import React from 'react';
import { Navigation, Mousewheel, Lazy, Pagination } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

import './UiSwiper.scss';
import 'swiper/css';
import 'swiper/css/lazy';
import 'swiper/css/navigation';

export interface UiSwiperCustomProps {
  className?: string;
  slideClassName?: string;
  children?: React.ReactNode;
}

export type UiSwiperProps = React.ComponentProps<typeof Swiper> & UiSwiperCustomProps;

export const UiSwiper: React.FC<UiSwiperProps> = (props) => {
  const { className, slideClassName, children, ...params } = props;

  const swiperParams: SwiperProps = {
    lazy: true,
    simulateTouch: false,
    mousewheel: true,
    navigation: true,
    pagination: { enabled: false, clickable: true },
    scrollbar: { draggable: true },
    modules: [Navigation, Mousewheel, Lazy, Pagination],
    ...params,
  };

  return (
    <Swiper {...swiperParams} className={className}>
      {React.Children.map(
        children,
        (child) => child && <SwiperSlide className={slideClassName}>{child}</SwiperSlide>,
      )}
    </Swiper>
  );
};
