import { useStore } from 'effector-react';
import React, { FC, PropsWithChildren, useMemo } from 'react';

import LinkSvg from 'ant/components/svg/link.svg';
import { UiCard, UiCardProps } from 'ant/components/ui/card';
import { UiEmpty } from 'ant/components/ui/empty';
import { UiImage } from 'ant/components/ui/image';
import { UiList } from 'ant/components/ui/list';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiSpace } from 'ant/components/ui/space';
import { SocialLink } from 'ant/components/widgets/SocialLink';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { regexpPatterns } from 'ant/helpers/patterns';
import { formatPhoneNumber, PhoneMask, RegExpPattern } from 'ant/plugins/phone-number';
import { useSettingsConfig } from 'ant/providers/settings-config';
import { getContactsDictsStorage } from 'ant/store/contacts';
import { getProfileContactsStorage } from 'ant/store/profile';
import { ExternalLinkContact } from 'ant/store/profile/api';
import { DictMatchTypes } from 'ant/types/api';
import { ContactDictKinds } from 'ant/types/dictionary';
import { ContactLink } from 'ant/types/models/contact-links';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import { PhoneNames, UserModelId } from 'ant/types/models/user.model';

import { ContactsLink, ContactsLinkIcons } from './contact-link/ContactsLink';

export type EmployeeContactsProps = {
  userId: UserModelId<KeycloakId>;
  style?: UiCardProps['style'];
};

const GUTTER = 8;
const formatPhoneNumberRu = (value?: string) =>
  formatPhoneNumber(value, RegExpPattern.InternationalRu, PhoneMask.InternationalRu);

export const EmployeeContacts: FC<PropsWithChildren<EmployeeContactsProps>> = (props) => {
  const { children, style, userId } = props;
  const { config } = useSettingsConfig();

  const profileContactsStorage = useMemo(() => getProfileContactsStorage({ userId }), [userId]);
  const {
    messengerDictsStorage,
    messengerDictsOptionsStore,
    externalLinkDictsStorage,
    externalLinkDictsOptionsStore,
  } = useMemo(getContactsDictsStorage, []);

  useAbstractStorage(messengerDictsStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { kind: { type: DictMatchTypes.Exact, value: ContactDictKinds.Messenger } },
  });

  useAbstractStorage(externalLinkDictsStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { kind: { type: DictMatchTypes.Exact, value: ContactDictKinds.ExternalLink } },
  });

  const {
    data: contactsData,
    loading: contactsLoading,
    error: contactsError,
  } = useAbstractStorage(profileContactsStorage.storage, {
    autoFetchAndRefetch: Boolean(userId),
  });

  const messengerDictOptions = useStore(messengerDictsOptionsStore);
  const messengerIconByRecordId = useMemo(
    () => new Map(messengerDictOptions.map(({ value: recordId, icon }) => [recordId, icon])),
    [messengerDictOptions],
  );

  const externalLinkDictsOptions = useStore(externalLinkDictsOptionsStore);
  const externalLinkIconByRecordId = useMemo(
    () => new Map(externalLinkDictsOptions.map(({ recordId, icon, prefix }) => [recordId, { icon, prefix }])),
    [externalLinkDictsOptions],
  );

  const {
    workPhone,
    mobilePhone,
    workEmail,
    workPhoneExtension,
    personalEmail,
    otherLinks = [],
    externalLinks = [],
  } = contactsData || {};
  const socialLinks = useMemo<Array<ExternalLinkContact | ContactLink>>(
    () => [...externalLinks, ...otherLinks],
    [externalLinks, otherLinks],
  );

  const hasContacts = workPhone || mobilePhone || workEmail || personalEmail;
  const hasOtherContacts = contactsData?.otherContacts && contactsData.otherContacts.length > 0;
  const hasSocialLinks = socialLinks.length > 0;

  const contactsList = [
    {
      value: workPhone,
      href: `tel:${workPhone}`,
      label: formatPhoneNumberRu(workPhone),
      icon: ContactsLinkIcons[PhoneNames.WorkPhone],
    },
    ...(config.layouts?.profileWorkPhoneExtension?.value
      ? [
          {
            value: workPhoneExtension,
            label: workPhoneExtension,
            icon: ContactsLinkIcons[PhoneNames.WorkPhone],
          },
        ]
      : []),
    {
      value: mobilePhone,
      href: `tel:${mobilePhone}`,
      label: formatPhoneNumberRu(mobilePhone),
      icon: ContactsLinkIcons[PhoneNames.MobilePhone],
    },
    {
      value: workEmail,
      label: workEmail,
      href: `mailto:${workEmail}`,
      icon: ContactsLinkIcons[PhoneNames.WorkEmail],
    },
    {
      value: personalEmail,
      label: personalEmail,
      href: `mailto:${personalEmail}`,
      icon: ContactsLinkIcons[PhoneNames.PersonalEmail],
    },
  ];

  return (
    <UiCard size="default" emptyPadding style={style}>
      <UiCard.Header style={{ paddingBottom: 0 }}>
        <UiCard.Header.Title>Контакты</UiCard.Header.Title>
      </UiCard.Header>
      <UiCard.Content basePadding>
        {contactsError && (
          <UiEmpty.Feed emptyMessage={{ header: 'Ошибка', description: 'Невозможно загрузить контакты' }} />
        )}

        {!hasContacts && !hasOtherContacts && !hasSocialLinks && !contactsLoading && !contactsError && (
          <UiEmpty.Feed emptyMessage={{ header: 'Контакты не заполнены' }} />
        )}

        {contactsLoading && (
          <UiSkeleton count={5} loading height={20} width="100%" style={{ marginBottom: GUTTER }} />
        )}

        {hasContacts && (
          <UiList
            split={false}
            grid={{ column: 1 }}
            dataSource={contactsList}
            renderItem={({ value, label, ...item }) =>
              value &&
              label && (
                <UiList.Item style={{ paddingTop: GUTTER, paddingBottom: GUTTER, margin: 0 }}>
                  <ContactsLink value={value} label={label} {...item} />
                </UiList.Item>
              )
            }
          />
        )}

        {hasOtherContacts && (
          <UiList
            split={false}
            grid={{ column: 1 }}
            dataSource={contactsData.otherContacts}
            renderItem={({ value, recordId }) =>
              value && (
                <UiList.Item style={{ paddingTop: GUTTER, paddingBottom: GUTTER, margin: 0 }}>
                  <ContactsLink
                    value={value}
                    icon={undefined}
                    label={formatPhoneNumberRu(value)}
                    href={regexpPatterns.phoneNumber.test(value) ? `tel:${value}` : undefined}
                  >
                    <UiImage
                      width={20}
                      height={20}
                      src={messengerIconByRecordId.get(recordId)}
                      wrapperStyle={{ background: 'transparent' }}
                      placeholder={<ContactsLink.Icon component={LinkSvg} />}
                    />
                  </ContactsLink>
                </UiList.Item>
              )
            }
          />
        )}

        {hasSocialLinks && (
          <UiSpace align="start" size={GUTTER} style={{ padding: '12px 0 0' }}>
            <ContactsLink.Icon component={LinkSvg} height={32} />
            <UiList
              grid={{ gutter: GUTTER }}
              itemLayout="horizontal"
              dataSource={socialLinks}
              renderItem={({ value, name, recordId }) => {
                const { icon, prefix } = externalLinkIconByRecordId.get(recordId) || {};

                return (
                  value && (
                    <UiList.Item style={{ marginBottom: GUTTER }}>
                      <SocialLink value={value} name={name} iconUrl={icon} prefixUrl={prefix} />
                    </UiList.Item>
                  )
                );
              }}
            />
          </UiSpace>
        )}
      </UiCard.Content>

      {Boolean(hasContacts || hasOtherContacts || hasSocialLinks) && children}
    </UiCard>
  );
};
