import { AxiosError } from 'axios';
import { Effect } from 'effector';
import React, { useCallback, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
  getExcludedOptions,
  UiAutoComplete,
  UiAutoCompleteProps,
  UiOptionData,
} from 'ant/components/ui/auto-complete';
import { generateAutoCompleteOptions } from 'ant/plugins/utils/options-helper';
import { CreateDictionaryRecord, generateCreateDictRecordParams } from 'ant/store/dictionaries/api';
import { RecordResponse } from 'ant/types/dictionary';

export interface CreatableSearchSelectProps extends Omit<UiAutoCompleteProps, 'onSearch' | 'options'> {
  options: UiOptionData[];
  excludeOptions?: UiOptionData[];
  onSearch: (value: string) => void;
  onCreateEffect: Effect<CreateDictionaryRecord, RecordResponse, AxiosError>;
  generateOnCreateEffectParams?: <T = CreateDictionaryRecord>(value: string) => T;
}

const CREATE_ID = 'CREATE_ID';
const createDropdownOption = (value: string) => ({
  value,
  label: `Добавить «${value}»`,
  key: CREATE_ID,
});

const CreatableSearchSelect: React.FC<CreatableSearchSelectProps> = (props) => {
  const {
    value,
    options,
    excludeOptions = [],
    onSearch,
    onSelect,
    onCreateEffect,
    generateOnCreateEffectParams = generateCreateDictRecordParams,
    ...otherProps
  } = props;

  const optionsValueSet = useMemo(() => new Set(options.map((option) => option.value)), [options]);
  const excludedOptions = useMemo(
    () => getExcludedOptions(options, excludeOptions),
    [options, excludeOptions],
  );

  const normalizedOptions = useMemo(() => {
    if (!value?.trim()) {
      return [];
    }

    return optionsValueSet.has(value) ? excludedOptions : [createDropdownOption(value), ...excludedOptions];
  }, [value, excludedOptions]);

  const searchHandler = useDebouncedCallback((searchTextRaw: string) => onSearch(searchTextRaw.trim()), 400);

  const selectHandler = useCallback((textValue: string, option: UiOptionData) => {
    const { key } = option;

    if (String(key).startsWith(CREATE_ID)) {
      onCreateEffect?.(generateOnCreateEffectParams(textValue)).then((record) => {
        const createdOption = generateAutoCompleteOptions([record])[0];

        onSelect?.(textValue, createdOption);
      });
    } else {
      onSelect?.(textValue, option);
    }
  }, []);

  return (
    <UiAutoComplete
      {...otherProps}
      defaultValue={value}
      options={normalizedOptions}
      onSearch={searchHandler}
      onSelect={selectHandler}
    />
  );
};

export { CreatableSearchSelect };
