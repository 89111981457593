import { format, eachDayOfInterval, subMinutes } from 'date-fns';

import { CalendarEventDates } from 'ant/types/models/calendar';

export const INITIAL_DATE_FORMAT_MASK = 'yyyy.MM.dd';

type getReducedEventsByDateParams<ObjectType> = {
  objects: ObjectType[];
  sinceKey?: keyof ObjectType;
  tillKey?: keyof ObjectType;
  formatMask?: string;
};

const getReducedEventsByDateCallback = <ObjectType extends CalendarEventDates>(
  params: Omit<getReducedEventsByDateParams<ObjectType>, 'objects'>,
) => {
  const { sinceKey = 'since', tillKey = 'till', formatMask = INITIAL_DATE_FORMAT_MASK } = params;

  return (acc: Record<string, ObjectType[]>, event: ObjectType): Record<string, ObjectType[]> => {
    const sinceDate = event[sinceKey] as unknown as string;
    const tillDate = event[tillKey] as unknown as string;
    const endExceptMidnight = subMinutes(new Date(tillDate), 1);

    const eventDates = eachDayOfInterval({ start: new Date(sinceDate), end: endExceptMidnight });

    eventDates.forEach((date) => {
      const accKey = format(date, formatMask);

      if (!Array.isArray(acc[accKey])) {
        acc[accKey] = [];
      }

      acc[accKey].push(event);
    });

    return acc;
  };
};

export const getReducedEventsByDate = <ObjectType extends CalendarEventDates>({
  objects,
  ...restParams
}: getReducedEventsByDateParams<ObjectType>) => {
  return objects.reduce<Record<string, ObjectType[]>>(getReducedEventsByDateCallback(restParams), {});
};
