import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { UiAvatar, UiAvatarSize } from 'ant/components/ui/avatar';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiSpace } from 'ant/components/ui/space';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { DisplayOptions } from 'ant/components/widgets/Gantt/GanttCompact';
import { getOffsetInDays } from 'ant/components/widgets/Gantt/helpers/gantt-date-helper';
import { GanttRangeTooltip } from 'ant/components/widgets/Gantt/range-tooltip/GanttRangeTooltip';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { closeGlobalModal, openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { GetTasksStorage } from 'ant/store/tasks';
import { ProjectsStageModel, ProjectsTasksModel } from 'ant/types/models/projects';

import styles from './GanttFullTasksItem.scss';

const MIN_TITLE_WIDTH = 400;

interface Props {
  task: ProjectsTasksModel;
  referenceTime: string;
  daySize: DisplayOptions['daySize'];
  stage: ProjectsStageModel;
  storages: GetTasksStorage;
}

export const GanttFullTasksItem: FC<Props> = (props) => {
  const { referenceTime, task, daySize, stage, storages } = props;
  const { color } = stage;
  const { workStart, workEnd, assigneeProfile } = task;
  const intervalWidth = getOffsetInDays(workStart, workEnd) * daySize;
  const startPosition = getOffsetInDays(referenceTime, workStart) * daySize;

  const onOpenTaskPreview = () => {
    openGlobalModal(GlobalModalNames.TaskPreview, {
      taskId: task.id,
      onSuccess: storages.storage.refetchWithLastParams,
      onClose: () => closeGlobalModal(GlobalModalNames.TaskPreview),
    });
  };

  return (
    <UiRow
      wrap={false}
      gutter={[16, 0]}
      style={{
        marginLeft: startPosition,
      }}
    >
      <GanttRangeTooltip timeStart={workStart} timeEnd={workEnd} compact>
        <UiCol
          className={styles.ganttFullTasksItem__timeline}
          style={{ backgroundColor: color, minWidth: intervalWidth }}
        />
      </GanttRangeTooltip>
      <UiCol style={{ minWidth: MIN_TITLE_WIDTH }}>
        <UiSpace size={4} style={{ lineHeight: 0, paddingTop: 4 }} wrap={false}>
          <Link to={getRoutePath(RouteNames.Profile, { id: assigneeProfile.id })}>
            <UiAvatar size={UiAvatarSize.XS} src={assigneeProfile.smallAvatar} />
          </Link>
          <UiSpace direction="vertical" size={2} style={{ lineHeight: 0 }} wrap={false}>
            <UiTypography.Link
              type="secondary"
              onClick={onOpenTaskPreview}
              className={styles.ganttFullTasksItem__key}
            >
              {task.key}
            </UiTypography.Link>
            <UiTypography.Footnote>
              <UiTruncateMarkup truncate tooltipProps={{ title: task.title }}>
                {task.title}
              </UiTruncateMarkup>
            </UiTypography.Footnote>
          </UiSpace>
        </UiSpace>
      </UiCol>
    </UiRow>
  );
};
