import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import ClearSvg from 'ant/components/svg/clear.svg';
import { UiAvatar } from 'ant/components/ui/avatar';
import { UiButton, UiButtonProps } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { checkFileMimeType } from 'ant/plugins/utils/check-file-mime-type';
import { FileUploadAccepts } from 'ant/types/models/file-upload-accepts';

import styles from './UiUploader.scss';

// TODO относится только к картинкам, поэтому не должно лежать в универсальном компоненте
const RECOMMENDED_SIZE = 280;
const RECOMMENDED_SIZE_STRING = `Рекомендуемый размер не менее ${RECOMMENDED_SIZE}x${RECOMMENDED_SIZE} пикс.`;

export type UiUploaderProps = {
  label?: string;
  previewUrl?: string;
  onFileChange?: (file: File | null) => void;
  /** null - изображения нет, undefined - смотреть в previewUrl */
  value?: File | null;
  isAvatarView?: boolean;
  withFileSizeText?: boolean;
  fileSizeText?: string;
  uploadButtonProps?: UiButtonProps;
  hideButtonIfImageExists?: boolean;
  uploadedImageClass?: string;
  children?: React.ReactNode;
  mimeType?: FileUploadAccepts;
};

const DEFAULT_MIME_TYPE = FileUploadAccepts.ImagePngJpg;

export const UiUploader: React.FC<UiUploaderProps> = ({
  label,
  previewUrl,
  onFileChange,
  value,
  isAvatarView = false,
  withFileSizeText = true,
  fileSizeText,
  uploadButtonProps,
  hideButtonIfImageExists = false,
  uploadedImageClass,
  children,
  mimeType = DEFAULT_MIME_TYPE,
}) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(value || null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  // TODO Есть предположение, что это не работет в IE11
  const imageUrl = useMemo(() => (file ? URL.createObjectURL(file) : previewUrl), [file, previewUrl]);

  const showImage = imageUrl && value !== null;

  const handleNewImageBtnClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleFileInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);

    if (target.files && target.files[0] && checkFileMimeType(mimeType, target.files[0].type)) {
      setFile(target.files[0]);

      if (onFileChange && target.files[0]) {
        onFileChange(target.files[0]);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    setFile(value || null);
  }, [value]);

  const onClear = () => {
    setFile(null);

    if (inputFileRef.current) {
      inputFileRef.current.value = '';
    }

    if (onFileChange) {
      onFileChange(null);
    }
  };

  return (
    <div className={styles.uiUploader}>
      <div className={styles.uiUploader__main}>
        {showImage && isAvatarView && (
          <UiAvatar src={imageUrl} className={styles.uiUploader__avatar} size={40} />
        )}
        {hideButtonIfImageExists && showImage ? null : (
          <div className={styles.uiUploader__column}>
            <UiButton
              type="link"
              className={styles.uiUploader__addFile}
              loading={loading}
              onClick={handleNewImageBtnClick}
              {...uploadButtonProps}
            >
              {label || children}
            </UiButton>
            <input
              type="file"
              accept={mimeType}
              ref={inputFileRef}
              onChange={handleFileInputChange}
              style={{ display: 'none' }}
            />
            {withFileSizeText && (
              <span className={styles.uiUploader__footnote}>{fileSizeText || RECOMMENDED_SIZE_STRING}</span>
            )}
          </div>
        )}
      </div>
      {showImage && !isAvatarView && (
        <div
          className={classNames(styles.uiUploader__image, uploadedImageClass)}
          style={{
            backgroundImage: `url("${imageUrl}")`,
          }}
        >
          <UiButton
            type="link-secondary"
            className={styles.uiUploader__imageDelete}
            onClick={onClear}
            icon={<UiIcon width={20} height={20} component={ClearSvg} />}
          />
        </div>
      )}
    </div>
  );
};
