import classNames from 'classnames';
import React, { FC, ReactNode, CSSProperties } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import UserBlockedAvatarPlaceholderSvg from 'ant/components/svg/user-blocked-avatar-placeholder.svg';
import { UiAvatar, UiAvatarProps } from 'ant/components/ui/avatar';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { UiUserInfoSkeleton } from 'ant/components/ui/user/info/skeleton/UiUserInfoSkeleton';

import styles from './UiUserInfo.scss';

export enum UiUserInfoSize {
  Small = 'small',
  Middle = 'middle',
  Large = 'large',
}

export type UiUserInfoProps = {
  to: LinkProps['to'];
  title: string;
  subTitle?: string | React.ReactNode | null;
  isActive?: boolean;
  avatar: UiAvatarProps;
  onClick?: () => void;
  className?: string;
  size?: UiUserInfoSize;
  extra?: ReactNode;
  shift?: CSSProperties['marginRight'];
};

interface UiUserInfoComposition extends FC<UiUserInfoProps> {
  Skeleton: typeof UiUserInfoSkeleton;
}

export const UiUserInfo: UiUserInfoComposition = (props) => {
  const {
    to,
    className,
    title,
    subTitle,
    onClick,
    avatar,
    isActive,
    size = UiUserInfoSize.Middle,
    extra,
    shift = 8,
  } = props;

  const avatarProps: UiAvatarProps =
    isActive === false
      ? { ...avatar, src: '', icon: <UserBlockedAvatarPlaceholderSvg />, disabled: true }
      : avatar;

  return (
    <span
      className={classNames(
        styles.uiUserInfo,
        {
          [styles.uiUserInfo_small]: size === UiUserInfoSize.Small,
          [styles.uiUserInfo_large]: size === UiUserInfoSize.Large,
        },
        className,
      )}
    >
      <Link to={to} onClick={onClick}>
        <UiAvatar {...avatarProps} />
      </Link>
      <div className={styles.uiUserInfo__info}>
        <div className={styles.uiUserInfo__info_top}>
          <Link style={{ marginRight: shift }} to={to} onClick={onClick}>
            <UiTruncateMarkup className={styles.uiUserInfo__infoName} truncate tooltipProps={{ title }}>
              {title}
            </UiTruncateMarkup>
          </Link>
          {extra}
        </div>
        {subTitle && <UiTypography.Text type="secondary">{subTitle}</UiTypography.Text>}
      </div>
    </span>
  );
};

UiUserInfo.Skeleton = UiUserInfoSkeleton;
