import { Plugin } from '@ckeditor/ckeditor5-core';

import { VideoStyleEditing } from './videostyle/videostyleediting';
import { VideoStyleUI } from './videostyle/videostyleui';

export class VideoStyle extends Plugin {
  public static get requires() {
    return [VideoStyleEditing, VideoStyleUI] as const;
  }

  public static get pluginName() {
    return 'VideoStyle' as const;
  }
}
