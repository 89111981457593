import { type Editor } from '@ckeditor/ckeditor5-core';
import { StickyPanelView } from '@ckeditor/ckeditor5-ui';
import { FileLoader, FileRepository } from '@ckeditor/ckeditor5-upload';

import { ProgressBarView } from '../view/ProgressBarView';

export type FileUploadId = string;
export type FileUploadProgressElement = {
  name: string;
  uploadId: FileUploadId;
};

class FileUploadProgress {
  static createUiBar(editor: Editor, fileElement: FileUploadProgressElement) {
    const fileRepository = editor.plugins.get(FileRepository);
    const loader = fileRepository.loaders.get(fileElement.uploadId);

    if (loader) {
      FileUploadProgress.showUiBar(editor, loader, fileElement);
    }
  }

  static removeUiBar(progressBar: ProgressBarView, toolbar: StickyPanelView) {
    if (toolbar.element && progressBar.element) {
      toolbar.element.removeChild(progressBar.element);
    }

    progressBar.destroy();
  }

  static showUiBar(editor: Editor, loader: FileLoader, fileElement: FileUploadProgressElement) {
    const progressBar = new ProgressBarView(editor.locale, fileElement);
    // @ts-expect-error: TODO: исправить ошибки типизации
    const toolbar = editor.ui.view.stickyPanel;

    progressBar.render();

    toolbar.element.append(progressBar.element);

    progressBar.on('cancel', () => {
      try {
        loader.abort();
      } catch {
        console.warn('Загрузка файла уже отменена');
      }

      FileUploadProgress.removeUiBar(progressBar, toolbar);
    });

    loader.on('change:uploadedPercent', (_, __, value) => {
      // @ts-expect-error: TODO: исправить ошибки типизации
      progressBar.set('customWidth', value);
    });

    loader.on('change:uploadResponse', (_, __, value) => {
      if (value) {
        FileUploadProgress.removeUiBar(progressBar, toolbar);
      }
    });
  }
}

export { FileUploadProgress };
