import { EntityId } from 'ant/types/blogs';

const optional = (id?: EntityId) => (id ? `${id}/` : '');

/** @deprecated: Используй ant/endpoints/**
 * TODO: перенести эти ручки в ant/endpoints/**.ts */
export class Endpoints {
  // TODO: Убрать в https://jira.vk.team/browse/B2BCORE-6186, эта ручка больше не нужна в сервисе лент
  static gamificationEntry = (id?: EntityId) => `/blogs/api/blog/gamification_entry/${optional(id)}`;

  static fileStorageAttachments = (id?: EntityId) => `/filestorage/api/attachments/${optional(id)}`;

  /** @deprecated */
  static groupsRoot = () => `/api/groups/root/`;

  static dicts = (name?: EntityId) => `/dicts/${optional(name)}`;

  static dictsRecords = (name: EntityId) => `/dicts/${name}/records/`;

  /** @deprecated: new -> ant/store/dictionaries/endpoints */
  static dictsRecordsNew = (name: EntityId) => `/dicts/${name}/records/new/`;

  static notificationsList = () => '/api/notifications/feed/';

  static notificationConfirm = (id: EntityId) => `/api/notifications/feed/${id}/mark_confirm/`;

  static notificationRead = (id: EntityId) => `/api/notifications/feed/${id}/mark_read/`;

  static notificationsMarkReadAll = () => '/api/notifications/feed/mark_read_all/';

  static polls = () => '/api/polls/polls/';

  static pollById = (id: EntityId) => `/api/polls/polls/${id}/`;

  static vote = (id: EntityId) => `/api/polls/polls/${id}/vote/`;

  static exportPollToXLSX = (id: EntityId) => `/api/polls/polls/${id}/export/xlsx/`;

  static exportPollAnswerToXLSX = (questionId: EntityId, optionId: EntityId) =>
    `/api/polls/polls/question/${questionId}/option/${optionId}/export/xlsx/`;

  // todo принять решение как быть с этой ручкой, т.к. её сейчас нет в продукте
  static config = () => `/krakend/api/config/fsk`;

  static searchNews = () => `/api/news/feed/`;

  static fileUpload = () => `/api/filestorage/v2/file/upload/`;
}
