import React from 'react';

import 'react-image-crop/dist/ReactCrop.css';

import styles from './CropImageArea.scss';
import { CropImageAreaFigure, CropImageAreaFigureProps } from './CropImageAreaFigure/CropImageAreaFigure';
import { CropImageAreaTitle } from './CropImageAreaTitle/CropImageAreaTitle';
import { CropImageAreaWrapper } from './CropImageAreaWrapper/CropImageAreaWrapper';

type CropImageAreaComposition = {
  Figure: typeof CropImageAreaFigure;
  Wrapper: typeof CropImageAreaWrapper;
  Title: typeof CropImageAreaTitle;
};

export interface CropImageAreaProps extends Omit<CropImageAreaFigureProps, 'imageFile'> {
  imageFile?: CropImageAreaFigureProps['imageFile'];
}

const CropImageArea: React.FC<CropImageAreaProps> & CropImageAreaComposition = (props) => {
  const { imageFile, figureSettings } = props;

  return (
    <div className={styles.reactCropArea}>
      {imageFile && (
        <CropImageAreaWrapper>
          {figureSettings.title && <CropImageAreaTitle title={figureSettings.title} />}
          <CropImageAreaFigure {...props} imageFile={imageFile} />
        </CropImageAreaWrapper>
      )}
    </div>
  );
};

CropImageArea.Figure = CropImageAreaFigure;
CropImageArea.Wrapper = CropImageAreaWrapper;
CropImageArea.Title = CropImageAreaTitle;

export { CropImageArea };
