import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import UserBlockedAvatarPlaceholderSvg from 'ant/components/svg/user-blocked-avatar-placeholder.svg';
import { UiAvatar, UiAvatarSize } from 'ant/components/ui/avatar';
import { UiTypography, UiTypographyTextType } from 'ant/components/ui/typography';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { UserModel } from 'ant/types/models/user.model';

import { UiTruncateMarkup } from '../truncate-markup';
import styles from './UiUserLinkCell.scss';

type Props = {
  user: UserModel;
  size?: number;
  getFullName?: (user: UserModel) => string;
  strong?: boolean;
  type?: UiTypographyTextType;
  lines?: number;
  nameStyles?: CSSProperties;
};

/** TODO: необходимо объединить с UiLinkListItem и перенести в перенести в ui/table */
export const UiUserLinkCell: React.FC<Props> = (props) => {
  const {
    user,
    size = UiAvatarSize.XS,
    getFullName = getFullNameWithoutPatronymic,
    strong = false,
    type,
    lines,
    nameStyles,
  } = props;

  const name = getFullName(user);
  const isBlocked = user.isActive === false || user.keycloakUser?.isActive === false;
  const avatarProps = isBlocked
    ? { size, disabled: true, icon: <UserBlockedAvatarPlaceholderSvg /> }
    : { src: user.avatar, size };

  return (
    <Link to={getRoutePath(RouteNames.Profile, { id: user.id })} className={styles.userLinkCell}>
      <UiAvatar {...avatarProps} />
      <UiTypography.Text style={nameStyles} type={type} className={styles.userLinkCell__name} strong={strong}>
        <UiTruncateMarkup lines={lines} truncate={Boolean(lines)}>
          {name}
        </UiTruncateMarkup>
      </UiTypography.Text>
    </Link>
  );
};
