import React, { FC } from 'react';

import { UiMarkdown } from 'ant/components/ui/markdown';

import styles from './EditorViewer.scss';

type EditorViewerProps = {
  html: string;
};

export const EditorViewer: FC<EditorViewerProps> = (props) => {
  const { html } = props;

  return (
    <UiMarkdown htmlMarkup className={styles.editorViewer}>
      {html}
    </UiMarkdown>
  );
};
