import React, { FC, useState, PropsWithChildren, useEffect } from 'react';

import ArrowCollapseSvg from 'ant/components/svg/arrow-collapse.svg';
import ArrowExpandSvg from 'ant/components/svg/arrow-expand.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { useElementSize } from 'ant/helpers/hooks/use-element-size';

import styles from './UiCollapseContent.scss';

type Props = {
  height?: number;
  isExpandable?: boolean;
};

export const UiCollapseContent: FC<PropsWithChildren<Props>> = (props) => {
  const { children, height = 200, isExpandable = true } = props;

  const [isCollapsible, setIsCollapsible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState(height);
  const { ref, height: totalHeight } = useElementSize();

  useEffect(() => {
    setMaxHeight((currentValue) => Math.max(currentValue, totalHeight));
    setIsCollapsible(totalHeight > height * 2);
  }, [totalHeight]);

  if (!isCollapsible) {
    return <div ref={ref}>{children}</div>;
  }

  return (
    <>
      <div
        ref={ref}
        className={styles.uiCollapseContent}
        style={{ maxHeight: isExpanded ? maxHeight : height }}
      >
        {children}
        {!isExpanded && <div className={styles.uiCollapseContent__overlay} />}
      </div>
      {isExpandable && (
        <UiButton
          block
          size="large"
          type="secondary"
          icon={<UiIcon component={isExpanded ? ArrowCollapseSvg : ArrowExpandSvg} width={20} height={20} />}
          label={isExpanded ? 'Свернуть' : 'Показать полностью'}
          className={styles.uiCollapseContent__btn}
          onClick={() => setIsExpanded((currentValue) => !currentValue)}
        />
      )}
    </>
  );
};
