import { addMinutes } from 'date-fns';

import { roundToGrid } from './round-to-grid';

type GetDatesByIntervalParams = {
  sinceDate: Date;
  start: number;
  duration: number;
  pxInHour: number;
  stepMinutes?: number;
};

export const getDateRangeByInterval = ({
  sinceDate,
  start,
  duration,
  pxInHour,
  stepMinutes = 15,
}: GetDatesByIntervalParams) => {
  const minutesStart = Math.round(start / (pxInHour / 60));
  const minutesDuration = Math.round(duration / (pxInHour / 60));
  const since = addMinutes(sinceDate, roundToGrid(minutesStart, stepMinutes));
  const till = addMinutes(since, roundToGrid(minutesDuration, stepMinutes));

  return { since, till };
};
