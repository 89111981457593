import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { downloadFile } from 'ant/plugins/utils/download-file';
import { getSearchProfileAdvancedDownload } from 'ant/store/advanced-search/api';
import { SearchParams } from 'ant/types/models/search.model';

export const downloadSearchProfileAdvancedReportEffect = createEffect<SearchParams, void, AxiosError>(
  (params) =>
    getSearchProfileAdvancedDownload(params).then(({ data }) => {
      downloadFile(data, 'profile_search_results.xlsx');
    }),
);
