import { useStore } from 'effector-react';
import React, { useMemo, useState, FC, PropsWithChildren } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiList } from 'ant/components/ui/list';
import { message } from 'ant/components/ui/message';
import { UiModal } from 'ant/components/ui/modals';
import { UiRender, UiRenderType } from 'ant/components/ui/render';
import { UiSpinner } from 'ant/components/ui/spinner';
import { UiTypography } from 'ant/components/ui/typography';
import { isUiUploadOriginFile } from 'ant/components/ui/upload';
import {
  CropImageArea,
  FigureType,
  CropImageParams,
  CropImageAreaFigureProps,
} from 'ant/components/widgets/CropImageArea';
import { UploadDraggerArea, UploadDraggerAreaProps } from 'ant/components/widgets/UploadDraggerArea';
import { BaseFileSizeUploadParams, checkFileSizeUploadEffect } from 'ant/helpers/validation/file-size-helper';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { getImageDimensions } from 'ant/plugins/get-image-dimensions';
import { checkFileMimeType } from 'ant/plugins/utils/check-file-mime-type';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import { readFileToStringPromise } from 'ant/plugins/utils/read-file-to-string-promise';
import { uploadFileStorageAttachmentEffectFactory, uploadImageCropEffect } from 'ant/store/filestorage';
import { FileStorageApiVersions } from 'ant/store/filestorage/api';
import { FileUploadAccepts } from 'ant/types/models/file-upload-accepts';
import { FileToUploadModel, PreloadedFileModel } from 'ant/types/models/file.model';

type DescriptionProps = {
  rulesTitle: string;
  rulesList: string[];
  cropTitle: string;
};

type ImageUploadCropValue = {
  id: PreloadedFileModel['storageObject'];
  file: PreloadedFileModel['fileUrl'];
};

export type ImageUploadPreviewValue = {
  uploadImage?: ImageUploadCropValue;
  previewImage?: FileToUploadModel;
};

export interface ImageUploadCropProps
  extends Partial<Pick<CropImageAreaFigureProps, 'aspect' | 'minWidth' | 'minHeight'>> {
  title: string;
  value?: ImageUploadPreviewValue;
  onChange: (image: ImageUploadCropValue) => void;
  onClose: () => void;
  figureType?: FigureType;
  descriptionProps?: DescriptionProps;
  maxSize?: BaseFileSizeUploadParams['maxSize'];
  uploadAreaProps?: Partial<UploadDraggerAreaProps>;
}

type UploadAttachmentFactoryParams = Pick<ImageUploadCropProps, 'minWidth' | 'minHeight' | 'maxSize'>;

const MAX_SIZE_DEFAULT = 10;
const MIN_WIDTH_DEFAULT = 764;
const MIN_HEIGHT_DEFAULT = 432;
const uploadAttachmentEffect = uploadFileStorageAttachmentEffectFactory<PreloadedFileModel>(
  FileStorageApiVersions.v3,
);

export const uploadAttachmentEffectFactory: (
  params: UploadAttachmentFactoryParams,
) => (
  params: Parameters<Required<Pick<UploadDraggerAreaProps, 'onUploadAttachment'>>['onUploadAttachment']>[0],
) => Promise<Required<Pick<ImageUploadCropProps, 'value'>>['value']> =
  ({ minWidth = MIN_WIDTH_DEFAULT, minHeight = MIN_HEIGHT_DEFAULT, maxSize = MAX_SIZE_DEFAULT }) =>
  async (params) => {
    const { file } = params;

    if (!isUiUploadOriginFile(file)) {
      throw new Error('Неверный формат загрузки файла');
    }

    const [, previewImage] = await Promise.all([
      checkFileSizeUploadEffect({ file, maxSize }),
      readFileToStringPromise(file, true),
    ]);

    const { width, height } = await getImageDimensions(previewImage.data);

    if (width < minWidth || height < minHeight) {
      throw new Error(`Минимальный размер фото ${minWidth} × ${minHeight} пикселей.`);
    }

    const { storageObject, fileUrl } = await uploadAttachmentEffect({ file });

    return { previewImage, uploadImage: { id: storageObject, file: fileUrl } };
  };

export const ImageUploadCrop: FC<PropsWithChildren<ImageUploadCropProps>> = (props) => {
  const {
    aspect = 1,
    title,
    value,
    onChange,
    onClose,
    children,
    maxSize = MAX_SIZE_DEFAULT,
    minWidth = MIN_WIDTH_DEFAULT,
    minHeight = MIN_HEIGHT_DEFAULT,
    descriptionProps,
    figureType = FigureType.Rectangle,
    uploadAreaProps,
  } = props;

  const description = useMemo(() => {
    if (!descriptionProps) {
      return {
        rulesTitle: 'Загрузите изображение и убедитесь, что оно соответствует правилам:',
        rulesList: [
          `• минимальный размер изображения ${minWidth} × ${minHeight} пикселей;`,
          `• максимальный вес файла ${maxSize} мегабайт.`,
        ],
        cropTitle: 'Область изображения',
      };
    }

    return descriptionProps;
  }, [descriptionProps, minHeight, minWidth, maxSize]);

  const { rulesTitle, rulesList, cropTitle } = description;
  const [uploadImage, setUploadImage] = useState<ImageUploadCropValue | undefined>(value?.uploadImage);
  const [previewImage, setPreviewImage] = useState<FileToUploadModel | undefined>(value?.previewImage);
  const [settings, setSettings] = useState<CropImageParams>();

  const fileType = previewImage?.rawFile?.type;
  const isCropImageDisabled = useMemo(
    () => Boolean(fileType && checkFileMimeType(FileUploadAccepts.ImageSvg, fileType)),
    [fileType],
  );
  const figureSettings = useMemo(
    () => ({ aspect, setSettings, title: cropTitle }),
    [aspect, setSettings, cropTitle],
  );

  const onUploadAttachment: UploadDraggerAreaProps['onUploadAttachment'] = async (params) => {
    try {
      const uploadAttachment = uploadAttachmentEffectFactory({ maxSize, minWidth, minHeight });
      const uploadedAttachment = await uploadAttachment(params);

      setUploadImage(uploadedAttachment.uploadImage);
      setPreviewImage(uploadedAttachment.previewImage);
    } catch (e) {
      message.error(String(e));
    }
  };

  const onSaveSuccess = (newValue: ImageUploadCropValue) => {
    onChange(newValue);
    onClose();
  };

  const onSave = () => {
    if (!isCropImageDisabled && uploadImage?.id && settings) {
      uploadImageCropEffect({ fileStorageEntryId: uploadImage.id, settings })
        .then(({ id, file }) => onSaveSuccess({ id, file }))
        .catch((err) =>
          message.error(getErrorResponseMessage(err, 'Ошибка при сохранении области изображения')),
        );
    } else if (isCropImageDisabled && uploadImage) {
      onSaveSuccess(uploadImage);
    } else {
      onClose();
    }
  };

  const isUploadAttachmentPending = useStore(uploadAttachmentEffect.pending);
  const isUploadImageCropPending = useStore(uploadImageCropEffect.pending);

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle(title)} />
      </UiModal.Header>

      <UiModal.Content basePadding>
        <UiSpinner spinning={isUploadAttachmentPending}>
          {rulesTitle && <UiTypography.Title level={4}>{rulesTitle}</UiTypography.Title>}
          {rulesList.length > 0 && (
            <UiList
              split={false}
              dataSource={rulesList}
              renderItem={(rule) => (
                <UiList.Item noStyle>
                  <UiTypography.Paragraph>{rule}</UiTypography.Paragraph>
                </UiList.Item>
              )}
            />
          )}

          {!value && (
            <UploadDraggerArea
              multiple={false}
              style={{ marginTop: 24 }}
              loading={isUploadAttachmentPending}
              accept={FileUploadAccepts.ImagePngJpg}
              onUploadAttachment={onUploadAttachment}
              {...uploadAreaProps}
            />
          )}

          {previewImage && figureSettings && !isUploadAttachmentPending && (
            <CropImageArea.Wrapper>
              {figureSettings.title && <CropImageArea.Title title={figureSettings.title} />}
              <CropImageArea.Figure
                minWidth={minWidth}
                minHeight={minHeight}
                imageFile={previewImage}
                figureType={figureType}
                figureSettings={figureSettings}
                disabled={isCropImageDisabled}
              />
            </CropImageArea.Wrapper>
          )}

          <UiRender type={UiRenderType.DisplayNone} visible={!previewImage}>
            {children}
          </UiRender>
        </UiSpinner>
      </UiModal.Content>

      <UiModal.Footer>
        <UiModal.Footer.Buttons>
          <UiButton
            size="large"
            type="primary"
            label="Сохранить"
            onClick={onSave}
            loading={isUploadImageCropPending}
          />
          <UiButton size="large" type="tertiary" label="Отмена" onClick={onClose} />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </>
  );
};
