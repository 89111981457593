import React, { FC, ReactNode } from 'react';

import { UiCol } from 'ant/components/ui/grid';
import { UiList } from 'ant/components/ui/list';

type Props = {
  actions: ReactNode[];
};

export const FileActions: FC<Props> = (props) => {
  const { actions } = props;

  return (
    <UiList grid={{ gutter: 16 }} dataSource={actions} renderItem={(action) => <UiCol>{action}</UiCol>} />
  );
};
