import { Space, SpaceProps } from 'antd';
import { NoCompactStyle } from 'antd/es/space/Compact';
import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './UiSpace.scss';

export type { SpaceCompactProps as UiSpaceCompactProps } from 'antd/es/space/Compact';
export {
  useCompactItemContext,
  SpaceCompactItemContext as UiSpaceCompactItemContext,
} from 'antd/es/space/Compact'; /** @note Импорт должен быть именно из antd/es, в antd/lib нет _инициализации_ контекста для antd */

export interface UiSpaceComposition {
  Compact: typeof Space.Compact;
  NoCompactStyle: typeof NoCompactStyle;
}

export interface UiSpaceProps extends SpaceProps {
  flex?: boolean;
  full?: boolean;
}

export const UiSpace: FC<UiSpaceProps> & UiSpaceComposition = (props) => {
  const { flex, full, className, ...restProps } = props;

  return (
    <Space
      className={classNames({ [styles.uiSpace_flex]: flex, [styles.uiSpace_full]: full }, className)}
      {...restProps}
    />
  );
};

UiSpace.Compact = Space.Compact;
UiSpace.NoCompactStyle = NoCompactStyle;
