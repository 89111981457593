// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5e91250a_back-top{bottom:20px;right:36px}", "",{"version":3,"sources":["webpack://./../ant/src/components/widgets/ScrollToTopButton/ScrollToTopButton.scss"],"names":[],"mappings":"AACA,oBACE,WAAA,CACA,UAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.back-top {\n  bottom: 20px;\n  right: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"back-top": "_5e91250a_back-top",
	"backTop": "_5e91250a_back-top"
};
export default ___CSS_LOADER_EXPORT___;
