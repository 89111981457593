import { Plugin } from '@ckeditor/ckeditor5-core';

import { VideoResizeButtons } from './videoresize/videoresizebuttons';
import { VideoResizeEditing } from './videoresize/videoresizeediting';
import { VideoResizeHandles } from './videoresize/videoresizehandles';

import './theme/videoresize.scss';

export class VideoResize extends Plugin {
  public static get requires() {
    return [VideoResizeEditing, VideoResizeHandles, VideoResizeButtons] as const;
  }

  public static get pluginName() {
    return 'VideoResize' as const;
  }
}
