import React, { FC } from 'react';

import { UiIcon, UiIconLabelProps } from 'ant/components/ui/icon';
import { UiSpace } from 'ant/components/ui/space';

export type SurveyQuestionInfoItem = Omit<UiIconLabelProps, 'className'>;

export type SurveyQuestionInfoProps = {
  questionInfo: SurveyQuestionInfoItem[];
  direction?: Directions;
};

export enum Directions {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

const SurveyQuestionInfo: FC<SurveyQuestionInfoProps> = ({
  questionInfo,
  direction = Directions.Horizontal,
}) => {
  return (
    <UiSpace size="large" direction={direction}>
      {questionInfo.map((info) => (
        <UiIcon.Label {...info} strong />
      ))}
    </UiSpace>
  );
};

export { SurveyQuestionInfo };
