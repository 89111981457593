import { Plugin } from '@ckeditor/ckeditor5-core';
import { ButtonView } from '@ckeditor/ckeditor5-ui';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { UiIcon } from 'ant/components/ui/icon';

class ExtraButtonsUiPlugin extends Plugin {
  init() {
    const { editor } = this;
    const buttons = editor.config.get('extraButtons.items');

    buttons?.forEach((button) => {
      editor.ui.componentFactory.add(button.id, (locale) => {
        const view = new ButtonView(locale);
        const { label, icon, onClick } = button;

        view.set({
          label,
          tooltip: label,
          icon: icon ? renderToStaticMarkup(<UiIcon component={icon} width={20} height={20} />) : undefined,
        });

        view.on('execute', () => {
          onClick();
        });

        return view;
      });
    });
  }

  public static get pluginName() {
    return 'ExtraButtonsUi' as const;
  }
}

export { ExtraButtonsUiPlugin };
