import React, {
  useRef,
  useMemo,
  useCallback,
  useEffect,
  useState,
  createContext,
  PropsWithChildren,
  FC,
} from 'react';

import { SettingsConfigValue } from '../types';

type SetSettingsConfigHandler = (state: SettingsConfigValue) => void;
type SettingsConfigContextValueType = {
  config: SettingsConfigValue;
  setConfig: SetSettingsConfigHandler;
};

export const SettingsConfigContext = createContext<SettingsConfigContextValueType | null>(null);

type SettingsConfigProviderProps = {
  config: SettingsConfigContextValueType['config'];
  onChange?: SetSettingsConfigHandler;
};

export const SettingsConfigProvider: FC<PropsWithChildren<SettingsConfigProviderProps>> = (props) => {
  const { children, config, onChange } = props;
  const [state, setState] = useState<SettingsConfigContextValueType['config']>(config);

  useEffect(() => {
    setState(config);
  }, [config]);

  const onChangeRef = useRef(onChange);

  onChangeRef.current = onChange;

  const setConfig = useCallback((newConfig: SettingsConfigValue) => {
    setState(newConfig);
    onChangeRef?.current?.(newConfig);
  }, []);

  const value: SettingsConfigContextValueType = useMemo(() => {
    return { config: state, setConfig };
  }, [state, setConfig]);

  return <SettingsConfigContext.Provider value={value}>{children}</SettingsConfigContext.Provider>;
};
