// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bd90f700_ui-image-showcase{width:100%}.bd90f700_ui-image-showcase__slick-item{margin-top:2px}.bd90f700_ui-image-showcase .slick-track{margin-bottom:2px;height:240px;overflow:hidden}.bd90f700_ui-image-showcase .slick-dots{flex-wrap:wrap;justify-content:start;position:relative;margin:0 -2px -2px -2px}.bd90f700_ui-image-showcase .slick-dots>*{width:calc(33.3% - 4px);margin:2px;cursor:pointer}.bd90f700_ui-image-showcase .slick-dots .slick-active{opacity:.4}.bd90f700_ui-image-showcase .slick-dots-bottom{bottom:0}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/image-showcase/UiImageShowcase.scss"],"names":[],"mappings":"AACA,4BAGE,UAAA,CAEA,wCACE,cALI,CASJ,yCACE,iBAVE,CAWF,YAVU,CAWV,eAAA,CAGF,wCACE,cAAA,CACA,qBAAA,CACA,iBAAA,CACA,uBAAA,CAEA,0CACE,uBAAA,CACA,UAvBA,CAwBA,cAAA,CAGF,sDACE,UAAA,CAIJ,+CACE,QAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.ui-image-showcase {\n  $gap: 2px;\n  $trackHeight: 240px;\n  width: 100%;\n\n  &__slick-item {\n    margin-top: $gap;\n  }\n\n  :global {\n    .slick-track {\n      margin-bottom: $gap;\n      height: $trackHeight;\n      overflow: hidden;\n    }\n\n    .slick-dots {\n      flex-wrap: wrap;\n      justify-content: start;\n      position: relative;\n      margin: 0 (-$gap) (-$gap) (-$gap);\n\n      > * {\n        width: calc(33.3% - 4px);\n        margin: $gap;\n        cursor: pointer;\n      }\n\n      .slick-active {\n        opacity: 0.4;\n      }\n    }\n\n    .slick-dots-bottom {\n      bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-image-showcase": "bd90f700_ui-image-showcase",
	"uiImageShowcase": "bd90f700_ui-image-showcase",
	"ui-image-showcase__slick-item": "bd90f700_ui-image-showcase__slick-item",
	"uiImageShowcase__slickItem": "bd90f700_ui-image-showcase__slick-item"
};
export default ___CSS_LOADER_EXPORT___;
