import React, { FC, PropsWithChildren, useContext } from 'react';

import {
  getSizeGutterBySizeFullName,
  SizeGutterPostfix,
  UiConfigProviderSizeContext,
  UiConfigProviderSizeType,
} from 'ant/components/ui/config-provider';
import { UiCol } from 'ant/components/ui/grid';
import { AVATAR_OFFSET, AVATAR_SIZE } from 'ant/components/widgets/Notification';

import { NotificationBodyFooter } from './footer/NotificationBodyFooter';

export type NotificationBodyPropsType = {
  isSpacing?: boolean;
  size?: UiConfigProviderSizeType | number;
};

export type NotificationBodyProps = PropsWithChildren<NotificationBodyPropsType>;

export type NotificationBodyComposition = {
  Footer: typeof NotificationBodyFooter;
};

export type NotificationBodyComponent = FC<NotificationBodyProps> & NotificationBodyComposition;

type SizeNameMap<T> = Record<SizeGutterPostfix, T>;

const gutterSizes: SizeNameMap<number> = { sm: 4, md: 8, lg: 12 };

const NotificationBody: NotificationBodyComponent = (props) => {
  const { children, isSpacing = true, size: propsSize } = props;

  const providerSize = useContext(UiConfigProviderSizeContext);
  const sizeName = propsSize && typeof propsSize !== 'number' ? propsSize : providerSize;
  const sizeGutterName = getSizeGutterBySizeFullName(sizeName || 'middle');
  const spaceSize = propsSize && typeof propsSize === 'number' ? propsSize : gutterSizes[sizeGutterName];

  return (
    <UiCol
      style={{
        paddingTop: spaceSize,
        marginLeft: isSpacing ? AVATAR_SIZE + AVATAR_OFFSET : 0,
      }}
    >
      {children}
    </UiCol>
  );
};

NotificationBody.Footer = NotificationBodyFooter;

export { NotificationBody };
