import classNames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  DependencyList,
  FC,
  ReactNode,
  HTMLAttributes,
} from 'react';

import BurgerIcon from 'ant/components/svg/burger.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiRow } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { useOnClickOutside } from 'ant/helpers/hooks/use-on-click-outside';

import styles from './Navbar.scss';
import { NavbarContainer } from './container/NavbarContainer';

export interface NavbarProps extends HTMLAttributes<HTMLElement> {
  collapseIcon?: ReactNode;
  collapseDeps?: DependencyList;
}

type NavbarComponents = {
  Container: typeof NavbarContainer;
};

export const Navbar: FC<NavbarProps> & NavbarComponents = (props) => {
  const {
    children,
    className,
    collapseDeps = [],
    collapseIcon = <UiIcon component={BurgerIcon} width={20} height={20} />,
    ...restProps
  } = props;
  const navigationRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const collapseHandler = useCallback(() => {
    if (isExpanded) {
      setIsExpanded(false);
    }
  }, [isExpanded]);

  useOnClickOutside(navigationRef, collapseHandler);

  useEffect(collapseHandler, collapseDeps);

  const onExpandHandler = () => {
    setIsExpanded(true);
  };

  return (
    <NavbarContainer
      ref={navigationRef}
      {...restProps}
      className={classNames(className, { [styles.navbar_expanded]: isExpanded })}
    >
      <div className={styles.navbar__expandedBackground}>{children}</div>
      <UiRow justify="center" className={styles.navbar__collapsedBtn}>
        <UiButton type="link" icon={collapseIcon} onClick={onExpandHandler} />
      </UiRow>
    </NavbarContainer>
  );
};

Navbar.Container = NavbarContainer;
