import { Pagination as AntPagination } from 'antd';
import React, { FC } from 'react';

import { UiPaginationConfig } from 'ant/components/ui/pagination';
import { UiPaginationTotal } from 'ant/components/ui/pagination/UiPaginationTotal';

export type { PaginationProps as UiPaginationProps } from 'antd';

export interface UiPaginationComposition extends FC<UiPaginationConfig> {
  Total: typeof UiPaginationTotal;
}

const UiPagination: UiPaginationComposition = (props) => <AntPagination {...props} />;

UiPagination.Total = UiPaginationTotal;

export { UiPagination };
