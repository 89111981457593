import classNames from 'classnames';
import { isWeekend, format, isSameDay } from 'date-fns';
import ru from 'date-fns/locale/ru';
import React from 'react';

import RectangleSvg from 'ant/components/svg/rectangle.svg';
import { UiIcon } from 'ant/components/ui/icon';
import { UiTypography } from 'ant/components/ui/typography';

import styles from './CalendarHeading.scss';

type CalendarSidebarType = {
  isSidebar?: boolean;
  sidebarTitle?: string;
  columnDate?: undefined;
};
type CalendarHeadingType = {
  isSidebar?: boolean;
  sidebarTitle?: string;
  isDayView?: boolean;
  columnDate: Date;
};

export type CalendarHeadingPropsComponent = React.FC<CalendarSidebarType | CalendarHeadingType>;

const CalendarHeading: CalendarHeadingPropsComponent = (props) => {
  const { isSidebar } = props;

  if (isSidebar) {
    const { sidebarTitle } = props as CalendarSidebarType;

    return (
      <div className={classNames(styles.calendarHeading, styles.calendarHeading_center)}>
        <UiTypography.Text className={styles.calendarHeading__weekCount}>{sidebarTitle}</UiTypography.Text>
      </div>
    );
  }

  const { columnDate, isDayView } = props as CalendarHeadingType;

  const isDayOff = !isDayView && isWeekend(columnDate);
  const isActive = isSameDay(Date.now(), columnDate);
  const weekNameShort = format(columnDate, 'EEEEEE', { locale: ru });
  const weekNameFull = format(columnDate, 'EEEE', { locale: ru });
  const dayAndMonth = format(columnDate, 'd MMMM', { locale: ru });
  const dateOfMonth = format(columnDate, 'd', { locale: ru });
  const title = isDayView ? dayAndMonth : dateOfMonth;
  const subtitle = isDayView ? weekNameFull : weekNameShort;

  return (
    <div
      className={classNames(styles.calendarHeading, {
        [styles.calendarHeading_dayOff]: isDayOff,
        [styles.calendarHeading_active]: isActive && !isDayView,
        [styles.calendarHeading_left]: isDayView,
      })}
    >
      <UiTypography.Text className={styles.calendarHeading__subtitle}>{subtitle}</UiTypography.Text>
      <UiTypography.Title level={1} style={{ margin: 0 }} className={styles.calendarHeading__title}>
        {title}
      </UiTypography.Title>
      {isActive && (
        <UiIcon
          component={RectangleSvg}
          height={8}
          width={24}
          className={classNames(styles.calendarHeading__rectangle, {
            [styles.calendarHeading__rectangle_left]: isDayView,
          })}
        />
      )}
    </div>
  );
};

export { CalendarHeading };
