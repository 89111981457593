import { AttachmentEntityId } from 'ant/types/models/attachment.model';
import { EventModel } from 'ant/types/models/event.model';
import { FileModel, FileStorageFileModel } from 'ant/types/models/file.model';
import { PollModel } from 'ant/types/models/poll';

export type PostFormAttachmentIdTemp = string;
export type PostFormAttachmentId = number | PostFormAttachmentIdTemp;
export type PostAttachedEntity = PollModel | FileModel | EventModel | FileStorageFileModel;

/** При добавлении нового типа, не забыть про attachmentGroupsPriority, иначе атачменты отображаться не будут */
export enum PostAttachmentTypes {
  Poll = 'poll',
  File = 'file',
  Image = 'image',
  Event = 'event',
}

export type PostAttachmentModel<T extends PostAttachedEntity = PostAttachedEntity> = {
  id: PostFormAttachmentId;
  objectId?: number;
  /** Техническое поле, им помечаем еще не прикрепленную сущность */
  isNotYetAttached?: boolean;
  attachmentId?: AttachmentEntityId;
  contentType?: number | string;
  attachmentType: PostAttachmentTypes;
  attachedEntity: T;
};
