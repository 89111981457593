const getPagesUrl = (url: string) => `/pages/${url}`;

class PagesEndpoints {
  static layoutWidgetList = () => getPagesUrl('widgets/list/');

  static widgetDataNew = () => getPagesUrl('widget_data/new/');

  static widgetsTypes = () => getPagesUrl('widgets/types/');

  static widgetData = (id: number) => getPagesUrl(`widgets/${id}/`);

  static colors = () => getPagesUrl(`colors/`);

  static image = () => getPagesUrl(`v3/image/`);
}

export { PagesEndpoints };
