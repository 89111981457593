import { Switch } from 'antd';
import { SwitchProps } from 'antd/es/switch';
import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './UiSwitch.scss';
import { UiSwitchGroup } from './group/UiSwitchGroup';

export interface UiSwitchProps extends SwitchProps {
  label?: string;
}

export interface UiSwitchComponent extends FC<UiSwitchProps> {
  Group: typeof UiSwitchGroup;
}

const UiSwitch: UiSwitchComponent = ({ label, className, disabled, ...props }) => (
  <div className={className}>
    <Switch disabled={disabled} {...props} />
    <span className={classNames(styles.switch__label, { [styles.switch__label_disabled]: disabled })}>
      {label}
    </span>
  </div>
);

UiSwitch.Group = UiSwitchGroup;

export { UiSwitch };
