import { useStore } from 'effector-react';
import isEmpty from 'lodash/isEmpty';
import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';

import 'antd/dist/antd.variable.less';
import 'ant/assets/scss/styles/fonts-includes.css';
import 'ant/assets/scss/styles/index.scss';
import 'ant/assets/scss/ant-rewriting/index.scss';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { Layout } from 'ant/components/layout/layout';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { useLocalStorage } from 'ant/helpers/hooks/use-local-storage';
import antTheme from 'ant/theme';
import { headerLogoImageStorage } from 'ant/theme/header-logo-image/store';
import { ThemeProvider, ThemeVariables, ThemeOptions, ThemeProviderProps } from 'ant/theme/provider';
import { themeStorage } from 'ant/theme/store';

const THEME_VARIABLES = 'themeVariables';
const initialTheme = {
  name: process.env.CUSTOMER || 'b2bcore',
  varsPrefix: ['--', process.env.CUSTOMER, process.env.CUSTOMER && '-', THEME_VARIABLES].join(''),
  variables: antTheme[THEME_VARIABLES] as ThemeVariables,
};

export const ThemeService: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const [userTheme, setUserTheme] = useLocalStorage<ThemeOptions>(
    initialTheme.varsPrefix,
    {} as ThemeOptions,
  );

  const { loading: isThemeLoading, error: themeError } = useAbstractStorage(themeStorage.storage, {
    autoFetchAndRefetch: true,
  });

  useAbstractStorage(headerLogoImageStorage.storage, {
    autoFetchAndRefetch: true,
  });

  const { colors, isUserColorsFilled } = useStore(themeStorage.themeState);

  const isUserThemeExist = !isEmpty(userTheme);
  const isThemeError = isEmpty(themeError);
  const isShowRouter = isUserThemeExist || isThemeError;

  const theme = useMemo<ThemeOptions>(() => {
    const themeDefault = !userTheme?.varsPrefix ? initialTheme : userTheme;
    const variables = { ...themeDefault.variables };

    if (isUserColorsFilled) {
      colors.forEach(({ name, color }) => {
        variables[name] = color;
      });
    }

    return { ...themeDefault, variables };
  }, [userTheme, colors, isUserColorsFilled]);

  const onChangeTheme = useCallback<ThemeProviderProps['onChange']>(
    ({ variables, ...rest }) => {
      setUserTheme({
        ...rest,
        variables: { ...userTheme?.variables, ...variables },
      });
    },
    [userTheme],
  );

  useEffect(() => {
    if (isUserColorsFilled) {
      setUserTheme(theme);
    }
  }, [isUserColorsFilled]);

  return (
    <ThemeProvider theme={theme} onChange={onChangeTheme}>
      {isShowRouter && children}
      {!isUserThemeExist && <Layout.Loading spinning={isThemeLoading} />}
    </ThemeProvider>
  );
};
