import { isSameDay } from 'date-fns';
import range from 'lodash/range';

import { UiFormInstance } from 'ant/components/ui/form';

const HOURS_IN_DAY = 24;
const MINUTES_IN_HOUR = 60;

enum CurrentInput {
  Since = 'since',
  Till = 'till',
}
type GetPickerDisabledTimeParams = {
  form: UiFormInstance;
  sinceTimeFieldPath?: string;
  tillTimeFieldPath?: string;
  sinceDateFieldPath?: string;
  tillDateFieldPath?: string;
  minutesStep?: number;
  isOneDay?: boolean;
};

const getPickerDisabledTime = (params: GetPickerDisabledTimeParams) => {
  const {
    form,
    sinceTimeFieldPath = 'sinceTime',
    tillTimeFieldPath = 'tillTime',
    sinceDateFieldPath = 'sinceDate',
    tillDateFieldPath = 'tillDate',
    minutesStep = 5,
    isOneDay = false,
  } = params;

  const getFormTime = () => {
    const sinceTime: Date | undefined = form.getFieldValue(sinceTimeFieldPath);
    const tillTime: Date | undefined = form.getFieldValue(tillTimeFieldPath);
    const sinceDate: Date | undefined = form.getFieldValue(sinceDateFieldPath);
    const tillDate: Date | undefined = form.getFieldValue(tillDateFieldPath);
    const isSameDate = isOneDay || (sinceDate && tillDate && isSameDay(sinceDate, tillDate));

    const sinceHour = sinceTime?.getHours();
    const sinceMinutes = sinceTime?.getMinutes();
    const tillHour = tillTime?.getHours();
    const tillMinutes = tillTime?.getMinutes();

    return { sinceHour, sinceMinutes, tillHour, tillMinutes, isSameDate };
  };

  const getDisabledHours = (currentInput: CurrentInput) => (): number[] => {
    const { sinceHour, sinceMinutes, tillHour, tillMinutes, isSameDate } = getFormTime();

    if (isSameDate) {
      if (currentInput === CurrentInput.Since && tillHour !== undefined) {
        const isZeroMinutesSelected = tillMinutes === 0;
        const compensationHour = isZeroMinutesSelected ? 0 : 1;

        return range(tillHour + compensationHour, HOURS_IN_DAY);
      }

      if (currentInput === CurrentInput.Till && sinceHour !== undefined && sinceMinutes !== undefined) {
        const isLastMinutesSelected = sinceMinutes >= MINUTES_IN_HOUR - minutesStep;
        const disabledSinceHour = isLastMinutesSelected ? sinceHour + 1 : sinceHour;

        return range(disabledSinceHour);
      }
    }

    return [];
  };

  const getDisabledMinutes =
    (currentInput: CurrentInput) =>
    (selectedHour = 0): number[] => {
      const { sinceHour, sinceMinutes, tillHour, tillMinutes, isSameDate } = getFormTime();
      const isSameSinceAndSelectedHour = sinceHour === selectedHour;
      const isSameTillAndSelectedHour = tillHour === selectedHour || (!sinceHour && !tillHour);

      if (isSameDate) {
        if (currentInput === CurrentInput.Since && tillMinutes !== undefined && isSameTillAndSelectedHour) {
          return range(tillMinutes, MINUTES_IN_HOUR, minutesStep);
        }

        if (currentInput === CurrentInput.Till) {
          const defaultSinceMinute = !selectedHour ? 1 : 0;
          const disabledTillMinutes =
            sinceMinutes !== undefined && isSameSinceAndSelectedHour
              ? sinceMinutes + minutesStep
              : defaultSinceMinute;

          return range(0, disabledTillMinutes, minutesStep);
        }
      }

      return [];
    };

  const disabledSinceTime = () => {
    return {
      disabledHours: getDisabledHours(CurrentInput.Since),
      disabledMinutes: getDisabledMinutes(CurrentInput.Since),
    };
  };

  const disabledTillTime = () => {
    return {
      disabledHours: getDisabledHours(CurrentInput.Till),
      disabledMinutes: getDisabledMinutes(CurrentInput.Till),
    };
  };

  return { disabledSinceTime, disabledTillTime };
};

export { getPickerDisabledTime };
