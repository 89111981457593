import React, { FC, PropsWithChildren } from 'react';

import { UiRow, UiCol, UiRowProps } from 'ant/components/ui/grid';

import { PostBodyAttachments } from './attachments/PostBodyAttachments';
import { PostBodyForm } from './form/PostBodyForm';
import { PostBodyMarkup } from './markup/PostBodyMarkup';
import { PostBodyReactions } from './reactions/PostBodyReactions';

type PostBodyComposition = {
  Markup: typeof PostBodyMarkup;
  Form: typeof PostBodyForm;
  Attachments: typeof PostBodyAttachments;
  Reactions: typeof PostBodyReactions;
};

interface Props extends UiRowProps {
  onClick?: () => void;
}

export type PostBodyComponent = FC<PropsWithChildren<Props>> & PostBodyComposition;

export const PostBody: PostBodyComponent = (props) => {
  const { children, ...restProps } = props;

  return (
    <UiRow {...restProps}>
      <UiCol span={24}>{children}</UiCol>
    </UiRow>
  );
};

PostBody.Markup = PostBodyMarkup;
PostBody.Form = PostBodyForm;
PostBody.Attachments = PostBodyAttachments;
PostBody.Reactions = PostBodyReactions;
