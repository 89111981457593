import { AxiosError } from 'axios';

import { flattenObject } from 'ant/plugins/utils/flatten-object';

export const getFirstErrorResponseMessage = (
  e: AxiosError<Record<string, any>>,
  defaultError: string,
): string => {
  const [, value] = e.response?.data ? Object.entries(flattenObject(e.response.data))[0] : [];

  return typeof value === 'string' ? value : defaultError;
};
