import { getDictsStorage } from 'ant/store/dictionaries';
import { Dictionaries } from 'ant/store/dictionaries/dictionaries';
import { DictMatchTypes } from 'ant/types/api';
import { FetchSelectEntityParams } from 'ant/types/creatable-multiselect';
import { RecordResponse } from 'ant/types/dictionary';

export const tagsDictsStorage = getDictsStorage<RecordResponse, FetchSelectEntityParams>({
  dictionaryName: Dictionaries.Names.Tags,
  dataBuilder: ({ value }) => ({ name: { type: DictMatchTypes.Icontains, value } }),
  getEndpointParams: () => ({ ordering: 'name' }),
});
