import { useStore } from 'effector-react';
import React, { FC, useState, useMemo } from 'react';

import ArrowExpandSvg from 'ant/components/svg/arrow-expand.svg';
import { UiBadge } from 'ant/components/ui/badge';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSpace } from 'ant/components/ui/space';
import { UiSpinner } from 'ant/components/ui/spinner';
import { DisplayOptions } from 'ant/components/widgets/Gantt/GanttCompact';
import { GanttFullTasks } from 'ant/components/widgets/Gantt/full/tasks/GanttFullTasks';
import {
  getOffsetInDays,
  getReferenceTimeMonthsOfInterval,
  getReferenceTimeWeeksOfInterval,
} from 'ant/components/widgets/Gantt/helpers/gantt-date-helper';
import { GanttRangeTooltip } from 'ant/components/widgets/Gantt/range-tooltip/GanttRangeTooltip';
import { ganttFilterStore, GanttViewType } from 'ant/components/widgets/Gantt/store';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { getTasksStorage } from 'ant/store/tasks';
import { themePalette } from 'ant/theme';
import { BaseTimeInterval } from 'ant/types/models/base/base-time-date';
import { ProjectsStageModel } from 'ant/types/models/projects';

import styles from './GanttFullStage.scss';

interface GanttFullStageProps extends ProjectsStageModel {
  daySize: DisplayOptions['daySize'];
  stagesInterval: Partial<BaseTimeInterval>;
}

export const GanttFullStage: FC<GanttFullStageProps> = (props) => {
  const { daySize, stagesInterval, ...stage } = props;
  const { title, color, timeStart, timeEnd } = stage;
  const { timeStart: stagesTimeStart, timeEnd: stagesTimeEnd } = stagesInterval;
  const [isExpanded, setIsExpanded] = useState(false);

  const { viewType, ...filterParams } = useStore(ganttFilterStore.store);

  const tasksStorages = useMemo(getTasksStorage, [stage.id]);
  const { data: tasksData, loading: isTasksLoading } = useAbstractStorage(tasksStorages.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { stageId: stage.id, ...filterParams },
    cancelPendingRequestOnUnmount: true,
  });

  const isActive = (type: GanttViewType) => type === viewType;
  const isTasksExist = tasksData.length > 0;
  const referenceTime = isActive(GanttViewType.Year)
    ? getReferenceTimeMonthsOfInterval(stagesTimeStart, stagesTimeEnd)
    : getReferenceTimeWeeksOfInterval(stagesTimeStart);
  const intervalWidth = getOffsetInDays(timeStart, timeEnd) * daySize;
  const startPosition = getOffsetInDays(referenceTime, timeStart) * daySize;

  return (
    <>
      <UiRow
        gutter={[0, 8]}
        onClick={() => setIsExpanded((prev) => !prev)}
        style={{
          width: intervalWidth,
          marginLeft: startPosition,
          ...(isTasksExist && { cursor: 'pointer' }),
        }}
      >
        <UiCol span={24}>
          <UiSpinner spinning={isTasksLoading}>
            <UiSpace>
              <UiBadge text={title} count={tasksData.length} reverse showZero />
              {isTasksExist && (
                <UiIcon
                  style={{
                    display: 'flex',
                    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                    color: themePalette.colorIcon,
                  }}
                  component={() => <ArrowExpandSvg />}
                />
              )}
            </UiSpace>
          </UiSpinner>
        </UiCol>
        <GanttRangeTooltip timeStart={timeStart} timeEnd={timeEnd} compact>
          <UiCol
            span={24}
            className={styles.ganttFullStage__timeline}
            style={{
              backgroundColor: color,
            }}
          />
        </GanttRangeTooltip>
      </UiRow>
      {isTasksExist && isExpanded && (
        <GanttFullTasks
          daySize={daySize}
          referenceTime={referenceTime}
          stage={stage}
          storages={tasksStorages}
        />
      )}
    </>
  );
};
