import { CSSProperties } from 'react';

import { UiModalTypes } from './typings';

export const modalOffset = 40;
export const modalHeaderHeight = 68;

const stickedHeight = `calc(100vh - ${modalOffset * 2}px)`;

export const modalWidths: Record<UiModalTypes, string> = {
  [UiModalTypes.Auto]: 'auto',
  [UiModalTypes.Small]: '460px',
  [UiModalTypes.Medium]: '596px',
  [UiModalTypes.Large]: '860px',
  [UiModalTypes.LargeAlternative]: '1024px',
  [UiModalTypes.XL]: `calc(100vw - ${modalOffset * 2}px)`,
  [UiModalTypes.FullScreen]: '100vw',
  [UiModalTypes.Fit]: 'min-content',
};

export const modalHeights: Record<UiModalTypes, string> = {
  [UiModalTypes.Auto]: 'auto',
  [UiModalTypes.Small]: 'auto',
  [UiModalTypes.Medium]: 'auto',
  [UiModalTypes.Large]: stickedHeight,
  [UiModalTypes.LargeAlternative]: stickedHeight,
  [UiModalTypes.XL]: stickedHeight,
  [UiModalTypes.FullScreen]: '100vh',
  [UiModalTypes.Fit]: 'min-content',
};

export const getUiModalBodyStyle = (type: UiModalTypes): CSSProperties => ({
  width: modalWidths[type],
  height: modalHeights[type],
  maxHeight: type === UiModalTypes.FullScreen ? undefined : stickedHeight,
});
