import { CommentsEndpoints } from 'ant/endpoints/comments';
import { api } from 'ant/plugins/api';
import { stringifyEntityText } from 'ant/plugins/utils/markup-content';
import { CommentContentTypes, CommentId, CommentObjectId } from 'ant/types/models/comment.model';
import { EntityContentType } from 'ant/types/models/entity-text';
import { Reaction, ReactionId } from 'ant/types/models/reactions.model';

export type BaseCommentParams = {
  text: string;
  textType?: EntityContentType;
  contentType: CommentContentTypes;
  objectId: CommentObjectId;
};

export interface CreateCommentParams extends BaseCommentParams {
  parentId: CommentId | null;
}
export interface UpdateCommentParams extends BaseCommentParams {
  commentId: CommentId;
}
export type DeleteCommentParams = Pick<UpdateCommentParams, 'commentId'>;
export type AddReactionToCommentParams = { commentId: CommentId; reactionId: ReactionId };
export type RemoveReactionFromCommentParams = Pick<AddReactionToCommentParams, 'commentId'>;

export const createComment = <T>({
  parentId,
  text,
  textType = EntityContentType.Markdown,
  objectId,
  contentType,
}: CreateCommentParams) =>
  api.post<T>({
    url: CommentsEndpoints.commentSave(),
    data: {
      parentId,
      text: stringifyEntityText(textType, text),
      objectId,
      contentType,
    },
  });

export const updateComment = <T>({
  commentId,
  text,
  textType = EntityContentType.Markdown,
  contentType,
  objectId,
}: UpdateCommentParams) =>
  api.post<T>({
    url: CommentsEndpoints.commentSave(),
    data: {
      id: commentId,
      text: stringifyEntityText(textType, text),
      objectId,
      contentType,
    },
  });

export const deleteComment = ({ commentId }: DeleteCommentParams) =>
  api.delete<void>({
    url: CommentsEndpoints.comment(commentId),
  });

export const addReactionToComment = ({ commentId, reactionId }: AddReactionToCommentParams) =>
  api.post<Reaction>({
    url: CommentsEndpoints.reactionSave(),
    data: {
      emojiUuid: reactionId,
      commentId,
    },
  });

export const removeReactionFromComment = ({ commentId }: RemoveReactionFromCommentParams) =>
  api.delete<void>({
    url: CommentsEndpoints.reaction(commentId),
  });
