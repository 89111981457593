// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._06e97116_ui-collapse-content{position:relative;overflow:hidden;transition:max-height .3s}._06e97116_ui-collapse-content__overlay{position:absolute;width:100%;height:72px;bottom:0;background:linear-gradient(transparent, var(--default-themeVariables-colorBgPrimary))}._06e97116_ui-collapse-content__btn{margin-top:24px}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/collapse/content/UiCollapseContent.scss","webpack://./../ant/src/assets/scss/variables/content.scss"],"names":[],"mappings":"AACA,+BACE,iBAAA,CACA,eAAA,CACA,yBAAA,CAEA,wCACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,QAAA,CACA,qFAAA,CAGF,oCACE,eCdU","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.ui-collapse-content {\n  position: relative;\n  overflow: hidden;\n  transition: max-height 0.3s;\n\n  &__overlay {\n    position: absolute;\n    width: 100%;\n    height: 72px;\n    bottom: 0;\n    background: linear-gradient(transparent, $colorBgPrimary);\n  }\n\n  &__btn {\n    margin-top: $baseSpacing;\n  }\n}\n","$borderRadiusBase: get('themeSizes.borderRadiusBase');\n$baseSpacing: 24px;\n$spacerHeight: 32px;\n$widgetMargin: get('themeOffsets.widgetMargin');\n\n$borderRadius: 8px; // TODO: вынести в тему\n$borderRadiusHalf: 4px; // TODO: вынести в тему\n$borderRadiusQuarter: 2px; // TODO: вынести в тему\n$blockquoteBorderRadius: get('themeLongRead.blockquoteBorderRadius');\n\n$modalOffset: 72px;\n$spacerBorder: 1px solid $colorStroke;\n$dashedBorder: 1px dashed $colorStrokeDark;\n\n$spaceBase: 4px;\n\n$space2XS: $spaceBase;\n$spaceXS: $spaceBase * 2;\n$spaceS: $spaceBase * 3;\n$spaceM: $spaceBase * 4;\n$spaceL: $spaceBase * 5;\n$spaceXL: $spaceBase * 6;\n$space2XL: $spaceBase * 8;\n$space3XL: $spaceBase * 9;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-collapse-content": "_06e97116_ui-collapse-content",
	"uiCollapseContent": "_06e97116_ui-collapse-content",
	"ui-collapse-content__overlay": "_06e97116_ui-collapse-content__overlay",
	"uiCollapseContent__overlay": "_06e97116_ui-collapse-content__overlay",
	"ui-collapse-content__btn": "_06e97116_ui-collapse-content__btn",
	"uiCollapseContent__btn": "_06e97116_ui-collapse-content__btn"
};
export default ___CSS_LOADER_EXPORT___;
