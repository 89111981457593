import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import styles from './UiContainer.scss';

type UiContainerDirection = 'horizontal' | 'vertical';

export interface UiContainerProps {
  wide?: boolean;
  className?: string;
  direction?: UiContainerDirection;
}

export enum UiContainerSidebarPosition {
  Left = 'left',
  Right = 'right',
}

export type UiContentSidebarProps = {
  position?: UiContainerSidebarPosition;
};

const UiContent: FC<PropsWithChildren<UiContainerProps>> = (props) => {
  const { children, wide, direction, className } = props;

  return (
    <div className={styles.uiContainer__middle}>
      <div
        className={classNames(styles.uiContainer__middleContent, className, {
          [styles.uiContainer__middleContent_wide]: wide,
          [`${styles.uiContainer__middleContent}_${direction}`]: direction,
        })}
      >
        {children}
      </div>
    </div>
  );
};

const UiSidebar: FC<PropsWithChildren<UiContentSidebarProps>> = (props) => {
  const { children, position: currentPosition = UiContainerSidebarPosition.Right } = props;
  const isCurrentPosition = (position: UiContainerSidebarPosition) => currentPosition === position;

  return (
    <div
      className={classNames(styles.uiContainer__sidebar, {
        [styles.uiContainer__sidebar_right]: isCurrentPosition(UiContainerSidebarPosition.Right),
        [styles.uiContainer__sidebar_left]: isCurrentPosition(UiContainerSidebarPosition.Left),
      })}
    >
      {children}
    </div>
  );
};

const UiSidebarItem: FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.uiContainer__sidebarItem}>{children}</div>
);

type UiContainerComposition = {
  UiContent: FC<PropsWithChildren<UiContainerProps>>;
  UiSidebar: FC<PropsWithChildren<UiContentSidebarProps>>;
  UiSidebarItem: FC<PropsWithChildren>;
};

const UiContainer: FC<PropsWithChildren<UiContainerProps>> & UiContainerComposition = ({ children }) => {
  return <div className={styles.uiContainer}>{children}</div>;
};

UiContainer.UiContent = UiContent;
UiContainer.UiSidebar = UiSidebar;
UiContainer.UiSidebarItem = UiSidebarItem;

export { UiContainer };
