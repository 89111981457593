export type CropImageParams = {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
};

export type PositionParams = {
  aspect: number;
  imgWidth: number;
  imgHeight: number;
};

export interface FigureSettings {
  title?: string;
  aspect: number;
  setSettings: (value: CropImageParams) => void;
}

export enum FigureType {
  Circle = 'circle',
  Rectangle = 'rectangle',
}
