import React, { useState, FC } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiCheckbox, UiCheckboxGroupProps, UiCheckboxValueType } from 'ant/components/ui/checkbox';
import { UiIcon, UiIconProps } from 'ant/components/ui/icon';
import { UiPopover, UiPopoverProps } from 'ant/components/ui/popover';

import styles from './UiCheckboxMenu.scss';

export interface UiCheckboxMenuProps extends UiPopoverProps {
  value?: UiCheckboxValueType[];
  items: UiCheckboxGroupProps['options'];
  icon: UiIconProps['component'];
  onChange?: UiCheckboxGroupProps['onChange'];
}

const UiCheckboxMenu: FC<UiCheckboxMenuProps> = (props) => {
  const { icon, items, value = [], onChange, ...popoverProps } = props;
  const [selectedKeys, setSelectedKeys] = useState<UiCheckboxValueType[]>(value);

  const onChangeChecked: UiCheckboxGroupProps['onChange'] = (keys) => {
    onChange?.(keys);
    setSelectedKeys(keys);
  };

  const CheckboxContent = (
    <UiCheckbox.Group
      options={items}
      value={selectedKeys}
      onChange={onChangeChecked}
      className={styles.uiCheckboxMenu__group}
    />
  );

  return (
    <UiPopover
      trigger={['click']}
      content={CheckboxContent}
      overlayClassName={styles.uiCheckboxMenu}
      {...popoverProps}
    >
      <UiButton
        disabledFocus
        type="link-secondary"
        icon={<UiIcon width={20} height={20} component={icon} />}
      />
    </UiPopover>
  );
};

export { UiCheckboxMenu };
