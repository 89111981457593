import { AxiosError, AxiosResponse } from 'axios';
import { Effect } from 'effector';

import { BlogsEndpoints } from 'ant/endpoints/blogs';
import { AbstractStorage } from 'ant/helpers/storage/abstract-storage-factory';
import { api } from 'ant/plugins/api';
import { UserIdParams } from 'ant/store/profile';
import { PaginationParams } from 'ant/types/api';
import { BlogsSettingsModel, BlogTypes } from 'ant/types/blogs';
import { EntityContentData } from 'ant/types/editor';
import {
  BlogId,
  BlogModel,
  BlogRegistrationId,
  BlogRequestId,
  PermissionsEnum,
  Role,
  SubscribeToBlogRequestModel,
} from 'ant/types/models/blog.model';
import { KeycloakId } from 'ant/types/models/keycloak-user';

export type MemberInviteId = number;

export type BlogStorageParticipantsParams = { roles?: string };

export type RemoveMemberParams = UserIdParams & BlogStorageParams;
export type GetParticipantsParams = GetParticipantsFilters & BlogStorageParticipantsParams & PaginationParams;

type SwitchBlogNotificationsApiParams = SwitchBlogNotificationsParams & BlogStorageParams;
export type SwitchBlogNotificationsParams = { notify: boolean; type: BlogTypes };

export type BlogStorageParams = { blogId: BlogId };
export type UpdateBlogDescriptionParams = { expandedDescription: EntityContentData };

export type SubscribeToBlogParams = { follow: boolean; type: BlogTypes };
export type SubscribeToBlogRequestBaseParams = {
  requestId?: BlogRequestId;
  follow: boolean;
};
export type SubscribeToBlogApiParams = SubscribeToBlogParams & BlogStorageParams;
export type SubscribeToBlogRequestParams = SubscribeToBlogRequestBaseParams & BlogStorageParams;

export type BlogDataStorage = { id: number; permissionsV2?: BlogModel['permissionsV2'] };
export interface BlogStorage<T extends BlogDataStorage> {
  storage: AbstractStorage<T, T, T | null, void>;
  updateDescriptionEffect: Effect<UpdateBlogDescriptionParams, unknown, AxiosError>;
  subscribeToBlogEffect: Effect<SubscribeToBlogApiParams, unknown, AxiosError>;
  updateBlogPermissionsEffect: Effect<void, AxiosResponse<BlogResponse>, AxiosError>;
  switchBlogNotificationEffect: Effect<SwitchBlogNotificationsParams, { notify: boolean }, AxiosError>;
  subscribeToBlogRequestEffect: Effect<
    SubscribeToBlogRequestParams,
    SubscribeToBlogRequestModel | void,
    AxiosError
  >;
}

export type BlogResponse = {
  permissions: PermissionsEnum[];
};

export type GetParticipantsFilters = {
  searchString?: string;
};

export type RemoveMemberFromBlogModel = {
  id: MemberInviteId;
  keycloakId: KeycloakId;
  role: Role;
};

export const blogEndpointsMap = {
  [BlogTypes.BlogsView]: BlogsEndpoints.blogId,
  [BlogTypes.BlogsViewSlug]: BlogsEndpoints.blogSlug,
  [BlogTypes.BlogsList]: BlogsEndpoints.list,
  [BlogTypes.BlogSubscribe]: BlogsEndpoints.blogSubscriptionsMe,
  [BlogTypes.BlogSubscribeRequest]: BlogsEndpoints.blogSubscribeRequest,
};

export const exportBlogRegistrationsToXlsx = (blogId: BlogId, registrationId: BlogRegistrationId) => {
  return api.get<Blob>({
    url: BlogsEndpoints.blogRegistrationExportXlsx(blogId, registrationId),
    responseType: 'blob',
  });
};

export const acceptBlogInvite = ({ blogId }: BlogStorageParams) => {
  return api.post({ url: BlogsEndpoints.blogAcceptInvite(blogId) });
};

export const rejectBlogInvite = ({ blogId }: BlogStorageParams) => {
  return api.post({ url: BlogsEndpoints.blogRejectInvite(blogId) });
};

export const setBlogsSettingsMain = (data: BlogsSettingsModel) => {
  return api.post<BlogsSettingsModel>({ url: BlogsEndpoints.settingsMain(), data });
};

export const removeMember = ({ blogId, userId }: RemoveMemberParams) => {
  return api.delete<RemoveMemberFromBlogModel>({
    url: BlogsEndpoints.blogSubscriptions(userId, blogId),
  });
};

export const getBlogParticipantsEndpoint = (blogId: BlogId) => BlogsEndpoints.blogParticipants(blogId);

export const getBlogInfo = <T = unknown>({ blogId }: BlogStorageParams): Promise<AxiosResponse<T>> => {
  return api.get({ url: blogEndpointsMap[BlogTypes.BlogsView](blogId) });
};

export const subscribeToBlog = ({ blogId, follow, type }: SubscribeToBlogApiParams) => {
  const method = follow ? 'put' : 'delete';

  return api[method]({ url: blogEndpointsMap[type](blogId as never) });
};

export const subscribeToBlogRequest = ({ blogId, follow, requestId }: SubscribeToBlogRequestParams) => {
  const getUrl = blogEndpointsMap[BlogTypes.BlogSubscribeRequest];

  if (follow) {
    return api.post<SubscribeToBlogRequestModel>({ url: getUrl(blogId) });
  }

  return api.delete<void>({ url: getUrl(blogId, requestId) });
};

function delay(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export const switchBlogNotification = ({ blogId, notify, type }: SwitchBlogNotificationsApiParams) =>
  delay(1200).then(() => ({ blogId, notify, type }));

type UpdateBlogDescriptionApiParams = UpdateBlogDescriptionParams & BlogStorageParams;

export const updateBlogDescription = ({ blogId, expandedDescription }: UpdateBlogDescriptionApiParams) =>
  api.patch({
    url: BlogsEndpoints.blogId(blogId),
    data: { expandedDescription },
  });
