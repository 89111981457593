import {
  FileStorageBlogContextModel,
  FileStorageContextServiceTypes,
  FileStorageListEntryContext,
} from 'ant/types/models/file.model';
import {
  PostAttachedEntity,
  PostAttachmentModel,
  PostAttachmentTypes,
} from 'ant/types/models/post-attachment';

export const isAttachment = <T extends PostAttachedEntity>(
  entity: PostAttachmentModel<PostAttachedEntity>,
  type: PostAttachmentTypes,
): entity is PostAttachmentModel<T> => entity.attachmentType === type;

export const isFileStorageBlogContext = (
  context?: FileStorageListEntryContext,
): context is FileStorageBlogContextModel => {
  return context?.service === FileStorageContextServiceTypes.Blogs;
};
