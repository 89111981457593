// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._217dff0f_survey-divider[class]{margin:20px 0}._217dff0f_survey-divider[class] .ant-divider-inner-text[class]{padding:0;width:28px}._217dff0f_survey-divider[class] .ant-divider-inner-text[class]>button{width:28px;height:28px;padding:0}", "",{"version":3,"sources":["webpack://./../ant/src/components/widgets/Survey/survey-divider/SurveyDivider.scss"],"names":[],"mappings":"AACA,iCAIE,aAAA,CAGE,gEACE,SAAA,CACA,UARS,CAUT,uEACE,UAXO,CAYP,WAZO,CAaP,SAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.survey-divider[class] {\n  $buttonSize: 28px;\n  $baseMargin: 20px;\n\n  margin: $baseMargin 0;\n\n  :global {\n    .ant-divider-inner-text[class] {\n      padding: 0;\n      width: $buttonSize;\n\n      > button {\n        width: $buttonSize;\n        height: $buttonSize;\n        padding: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"survey-divider": "_217dff0f_survey-divider",
	"surveyDivider": "_217dff0f_survey-divider"
};
export default ___CSS_LOADER_EXPORT___;
