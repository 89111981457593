import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { UserAgreementEndpoints } from 'ant/store/user-agreements/endpoints';
import { DictPaginatedObjects } from 'ant/types/api';
import { UserAgreementModel, UserAgreementOpenApiModel } from 'ant/types/models/user-agreements.model';
import { AcceptUserAgreement, CreateNewUserAgreement } from 'ant/types/user-agreements';

import { acceptUserAgreementsRequest, newUserAgreementsRequest } from './api';

export const getUserAgreementsStorage = () => {
  const storage = abstractStorageFactory<DictPaginatedObjects<UserAgreementModel>, UserAgreementModel[], []>({
    endpointBuilder: () => buildEndpointWithQueryParams(UserAgreementEndpoints.termsOfUse()),
    dataMapper: (data) => data.objects,
    defaultValue: [],
  });

  return { storage };
};

export const getAllUserAgreementsStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginatedObjects<UserAgreementOpenApiModel>,
    UserAgreementOpenApiModel[],
    []
  >({
    endpointBuilder: () => buildEndpointWithQueryParams(UserAgreementEndpoints.termsOfUseOpenApi()),
    dataMapper: (data) => data.objects,
    defaultValue: [],
  });

  return { storage };
};

export const createNewUserAgreementEffect = createEffect<CreateNewUserAgreement, unknown, AxiosError>(
  (newUserAgreement: CreateNewUserAgreement) => newUserAgreementsRequest(newUserAgreement),
);

export const acceptUserAgreementEffect = createEffect<AcceptUserAgreement, unknown, AxiosError>(
  (acceptUserAgreement: AcceptUserAgreement) => acceptUserAgreementsRequest(acceptUserAgreement),
);
