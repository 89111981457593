import React, { FC, MouseEvent } from 'react';
import { useLinkClickHandler, useHref, LinkProps } from 'react-router-dom';

import { UiTypography, UiTypographyLinkProps } from 'ant/components/ui/typography';

type Props = UiTypographyLinkProps & LinkProps;

export const RouterLink: FC<Props> = (props) => {
  const { onClick, replace = false, state, target, to, ...restProps } = props;

  const href = useHref(to);
  const onLinkClick = useLinkClickHandler(to, { target, replace, state });

  const onInternalClick = (event: MouseEvent<HTMLAnchorElement>) => {
    onClick?.(event);

    if (!event.defaultPrevented) {
      onLinkClick(event);
    }
  };

  return <UiTypography.Link {...restProps} onClick={onInternalClick} href={href} />;
};
