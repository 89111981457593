export type AbstractShiftPlacement = {
  shift?: number;
  placement?: Placement;
};

export enum Placement {
  BottomCenter = 'bottomCenter',
  BottomLeft = 'bottomLeft',
  BottomRight = 'bottomRight',
  TopCenter = 'topCenter',
  TopLeft = 'topLeft',
  TopRight = 'topRight',
  Center = 'center',
  CenterLeft = 'centerLeft',
  CenterRight = 'centerRight',
}
