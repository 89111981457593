import { Plugin } from '@ckeditor/ckeditor5-core';
import { Widget } from '@ckeditor/ckeditor5-widget';

import { VideoBlockEditing } from './video/videoblockediting';

import './theme/video.scss';

export class Video extends Plugin {
  public static get requires() {
    return [VideoBlockEditing, Widget] as const;
  }

  public static get pluginName() {
    return 'Video' as const;
  }
}
