import classNames from 'classnames';
import React, { FC } from 'react';

import { UiInput, UiInputProps, UiInputRef } from 'ant/components/ui/input';

import {
  UI_POLL_BUTTON_TYPE_DEFAULT,
  UiPollButton,
  UiPollButtonProps,
  UiPollButtonTypesProps,
} from '../UiPollButton';
import styles from './UiPollButtonInput.scss';

export interface UiPollButtonInputProps extends Omit<UiInputProps, 'type'> {
  type?: UiPollButtonTypesProps['type'];
  inputRef?: React.Ref<UiInputRef>;
  buttonProps?: UiPollButtonProps;
}

const DEFAULT_BUTTON_PROPS = {
  type: UI_POLL_BUTTON_TYPE_DEFAULT,
};

const UiPollButtonInput: FC<UiPollButtonInputProps> = ({
  placeholder,
  inputRef,
  className,
  buttonProps,
  ...props
}) => (
  <UiPollButton className={styles.uiPollButtonInput} {...DEFAULT_BUTTON_PROPS} {...buttonProps}>
    <UiInput
      {...props}
      ref={inputRef}
      bordered={false}
      placeholder={placeholder || 'Введите вариант ответа'}
      className={classNames(styles.uiPollButtonInput__input, className)}
    />
  </UiPollButton>
);

export { UiPollButtonInput };
