// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4a465ec9_ui-modal-footer{display:flex;padding:24px}._4a465ec9_ui-modal-footer_top-border{border-top:1px solid var(--default-themeVariables-colorStroke)}._4a465ec9_ui-modal-footer__buttons{display:flex;flex-wrap:nowrap;align-items:center}._4a465ec9_ui-modal-footer__buttons>:not(:last-child){margin-right:16px}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/modals/modal/footer/UiModalFooter.scss","webpack://./../ant/src/assets/scss/variables/content.scss"],"names":[],"mappings":"AAGA,2BACE,YAAA,CACA,YCJY,CDMZ,sCACE,8DCGW,CDCf,oCACE,YAAA,CACA,gBAAA,CACA,kBAAA,CAEA,sDACE,iBAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n$ui-modal-footer__buttons: 'ui-modal-footer__buttons';\n\n.ui-modal-footer {\n  display: flex;\n  padding: $baseSpacing;\n\n  &_top-border {\n    border-top: $spacerBorder;\n  }\n}\n\n.#{$ui-modal-footer__buttons} {\n  display: flex;\n  flex-wrap: nowrap;\n  align-items: center;\n\n  & > :not(:last-child) {\n    margin-right: 16px;\n  }\n}\n","$borderRadiusBase: get('themeSizes.borderRadiusBase');\n$baseSpacing: 24px;\n$spacerHeight: 32px;\n$widgetMargin: get('themeOffsets.widgetMargin');\n\n$borderRadius: 8px; // TODO: вынести в тему\n$borderRadiusHalf: 4px; // TODO: вынести в тему\n$borderRadiusQuarter: 2px; // TODO: вынести в тему\n$blockquoteBorderRadius: get('themeLongRead.blockquoteBorderRadius');\n\n$modalOffset: 72px;\n$spacerBorder: 1px solid $colorStroke;\n$dashedBorder: 1px dashed $colorStrokeDark;\n\n$spaceBase: 4px;\n\n$space2XS: $spaceBase;\n$spaceXS: $spaceBase * 2;\n$spaceS: $spaceBase * 3;\n$spaceM: $spaceBase * 4;\n$spaceL: $spaceBase * 5;\n$spaceXL: $spaceBase * 6;\n$space2XL: $spaceBase * 8;\n$space3XL: $spaceBase * 9;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-modal-footer": "_4a465ec9_ui-modal-footer",
	"uiModalFooter": "_4a465ec9_ui-modal-footer",
	"ui-modal-footer_top-border": "_4a465ec9_ui-modal-footer_top-border",
	"uiModalFooter_topBorder": "_4a465ec9_ui-modal-footer_top-border",
	"ui-modal-footer__buttons": "_4a465ec9_ui-modal-footer__buttons",
	"uiModalFooter__buttons": "_4a465ec9_ui-modal-footer__buttons"
};
export default ___CSS_LOADER_EXPORT___;
