import { api } from 'ant/plugins/api';
import { Endpoints } from 'ant/plugins/endpoints';
import { NotificationId } from 'ant/types/models/notification.model';

export const markAllNotificationsRead = () => {
  return api.post({
    url: Endpoints.notificationsMarkReadAll(),
  });
};

export const markNotificationRead = (id: NotificationId) => {
  return api.post<void>({
    url: Endpoints.notificationRead(id),
  });
};
