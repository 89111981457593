import { useContext } from 'react';

import { CurrentProfileContext } from '../provider/CurrentProfileProvider';

export const useCurrentProfile = () => {
  const profile = useContext(CurrentProfileContext);

  if (!profile) {
    throw new Error('Can not resolve CurrentProfileContext into useCurrentProfile hook');
  }

  return profile;
};
