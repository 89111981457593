import React, { FC } from 'react';

import ImgPlaceholderSvg from 'ant/components/svg/img-placeholder.svg';
import { UiAvatarProps, UiAvatar } from 'ant/components/ui/avatar';
import { UiIcon } from 'ant/components/ui/icon';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';

import styles from './UiTableCellAvatar.scss';

interface Props extends UiAvatarProps {
  name: string;
}

export const UiTableCellAvatar: FC<Props> = ({ name, size, ...props }) => {
  return (
    <div className={styles.uiTableCellAvatar}>
      <UiAvatar
        size={size}
        className={styles.uiTableCellAvatar__avatar}
        icon={<UiIcon component={ImgPlaceholderSvg} height={size} width={size} />}
        {...props}
      />
      <UiTruncateMarkup truncate>{name}</UiTruncateMarkup>
    </div>
  );
};
