import classNames from 'classnames';
import noop from 'lodash/noop';
import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';

import UploadSvg from 'ant/components/svg/upload.svg';
import { UiIcon, UiIconLabelProps } from 'ant/components/ui/icon';
import { UiTypographyTextTypes } from 'ant/components/ui/typography';
import { themePalette } from 'ant/theme';

import { UiButton } from '../../button';
import { UiOverlayProps } from '../UiOverlay';
import styles from '../UiOverlay.scss';

export interface UiOverlayButtonProps
  extends Partial<Pick<UiIconLabelProps, 'label' | 'component'>>,
    Pick<UiOverlayProps, 'trigger' | 'isVisible'>,
    HTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  overlayClassName?: string;
}

export const UiOverlayButton: FC<PropsWithChildren<UiOverlayButtonProps>> = (props) => {
  const {
    style,
    label = 'Загрузить',
    component = UploadSvg,
    children,
    onClick = noop,
    trigger,
    className,
    overlayClassName,
    isVisible = true,
  } = props;

  return (
    <UiButton.Decorator
      className={classNames(styles.uiOverlay, styles.uiOverlayButton, className, {
        [`${styles.uiOverlay}_${trigger}`]: trigger,
      })}
      onClick={onClick}
      style={style}
    >
      {children}
      <span
        className={classNames(styles.uiOverlay__overlay, styles.uiOverlay__overlayButton, overlayClassName, {
          [styles.uiOverlay__overlay_visible]: isVisible,
        })}
      >
        <UiIcon.Label
          color={themePalette.colorTextInvert}
          type={UiTypographyTextTypes.Invert}
          component={component}
          label={label}
          strong
        />
      </span>
    </UiButton.Decorator>
  );
};
