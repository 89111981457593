export function BinaryHeap(scoreFunction) {
  this.content = [];
  this.scoreFunction = scoreFunction;
}

BinaryHeap.prototype = {
  push(element) {
    // Добавить новый элемент в конец массива
    this.content.push(element);
    // Поднять его вверх
    this.bubbleUp(this.content.length - 1);
  },

  pop() {
    // Храним первый элемент, чтобы вернуть его позже
    const result = this.content[0];
    // Берем элемент в конце массива
    const end = this.content.pop();
    // Если остались элементы, поместить его вначале и дать опуститься
    if (this.content.length > 0) {
      this.content[0] = end;
      this.sinkDown(0);
    }
    return result;
  },

  remove(node) {
    const { length } = this.content;
    // Находим элемент в массиве, чтобы его удалить
    // it.
    for (let i = 0; i < length; i++) {
      if (this.content[i] === node) {
        // Когда он найден, повторяем логику в 'pop', чтобы заполнить пустое пространство
        const end = this.content.pop();
        if (i !== length - 1) {
          this.content[i] = end;
          if (this.scoreFunction(end) < this.scoreFunction(node)) this.bubbleUp(i);
          else this.sinkDown(i);
        }
        return;
      }
    }
    throw new Error(`Узел ${node} не найден`);
  },

  size() {
    return this.content.length;
  },

  bubbleUp(argN) {
    let n = argN;
    // Элемент, который должен быть сдвинут
    const element = this.content[n];
    // Не можем двигаться дальше вверх, если находимся на 0
    while (n > 0) {
      // Вычислить индекс родительского элемента
      const parentN = Math.floor((n + 1) / 2) - 1;
      const parent = this.content[parentN];
      // Поменять местами элементы, если родитель большего значения
      if (this.scoreFunction(element) < this.scoreFunction(parent)) {
        this.content[parentN] = element;
        this.content[n] = parent;
        // Обноввить 'n', чтобы продолжить на новой позиции
        n = parentN;
      }
      // Нашли родителя у которого значение меньше, следовательно не нужно двигаться дальше
      else {
        break;
      }
    }
  },

  sinkDown(argN) {
    let n = argN;

    const { length } = this.content;
    const element = this.content[n];
    const elementScore = this.scoreFunction(element);

    // Выходим из цикла на 36 строчке вниз
    // eslint-disable-next-line no-constant-condition
    while (true) {
      // Вычисляем индексы детей
      const child2N = (n + 1) * 2;
      const child1N = child2N - 1;
      // Нужно для хранения новой позиции элемента
      // Если any
      let swap = null;
      // Если существует первый ребенок внутри массива;
      let child1Score = null;

      if (child1N < length) {
        // Вычислить его score
        const child1 = this.content[child1N];
        child1Score = this.scoreFunction(child1);

        // Если score меньше чем у нашего ребенка, меняем их местами
        if (child1Score < elementScore) {
          swap = child1N;
        }
      }
      // Делаем ту же самую проверку для другого ребёнка
      if (child2N < length) {
        const child2 = this.content[child2N];
        const child2Score = this.scoreFunction(child2);
        if (child2Score < (swap == null ? elementScore : child1Score)) {
          swap = child2N;
        }
      }

      if (swap != null) {
        this.content[n] = this.content[swap];
        this.content[swap] = element;
        n = swap;
      }

      // Выход из цикла, если swap == null
      else {
        break;
      }
    }
  },
};

/* eslint-enable */
