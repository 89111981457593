export const downloadFile = (file: Blob, name: string) => {
  const a = document.createElement('a');

  document.body.appendChild(a);

  const blobURL = URL.createObjectURL(file);

  a.href = blobURL;
  a.download = name;
  a.click();
  URL.revokeObjectURL(blobURL);
  a.remove();
};
