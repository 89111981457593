import React, { useEffect, FC } from 'react';
import useClipboard from 'react-use-clipboard';

import ShareSvg from 'ant/components/svg/share.svg';
import { UiButton, UiButtonProps } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { message } from 'ant/components/ui/message';

interface ShareButtonProps extends Omit<UiButtonProps, 'type' | 'onClick' | 'icon' | 'href'> {
  value?: string;
}

export const ShareButton: FC<ShareButtonProps> = (props) => {
  const {
    style = { marginLeft: 'auto', height: 'auto' },
    value = window.location.href,
    ...buttonProps
  } = props;
  const [isCopied, setCopied] = useClipboard(value, { successDuration: 300 });

  useEffect(() => {
    if (isCopied) {
      message.success('Ссылка скопирована');
    }
  }, [isCopied]);

  return (
    <UiButton
      type="link-secondary"
      onClick={setCopied}
      icon={<UiIcon component={ShareSvg} width={20} height={20} />}
      style={style}
      {...buttonProps}
    />
  );
};
