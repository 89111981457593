// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._82c69ca8_gantt-content-header{width:100%;height:36px}._82c69ca8_gantt-content-header__list{display:flex;align-items:center;height:inherit}._82c69ca8_gantt-content-header__list-item{display:flex;align-items:center;justify-content:center;height:inherit;border-left:1px solid var(--default-themeVariables-colorStroke)}", "",{"version":3,"sources":["webpack://./../ant/src/components/widgets/Gantt/compact/header/GanttContentHeader.scss","webpack://./../ant/src/assets/scss/variables/content.scss"],"names":[],"mappings":"AACA,gCACE,UAAA,CACA,WCoBS,CDlBT,sCACE,YAAA,CACA,kBAAA,CACA,cAAA,CAEA,2CACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,+DCJS","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.gantt-content-header {\n  width: 100%;\n  height: $space3XL;\n\n  &__list {\n    display: flex;\n    align-items: center;\n    height: inherit;\n\n    &-item {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      height: inherit;\n      border-left: $spacerBorder;\n    }\n  }\n}\n","$borderRadiusBase: get('themeSizes.borderRadiusBase');\n$baseSpacing: 24px;\n$spacerHeight: 32px;\n$widgetMargin: get('themeOffsets.widgetMargin');\n\n$borderRadius: 8px; // TODO: вынести в тему\n$borderRadiusHalf: 4px; // TODO: вынести в тему\n$borderRadiusQuarter: 2px; // TODO: вынести в тему\n$blockquoteBorderRadius: get('themeLongRead.blockquoteBorderRadius');\n\n$modalOffset: 72px;\n$spacerBorder: 1px solid $colorStroke;\n$dashedBorder: 1px dashed $colorStrokeDark;\n\n$spaceBase: 4px;\n\n$space2XS: $spaceBase;\n$spaceXS: $spaceBase * 2;\n$spaceS: $spaceBase * 3;\n$spaceM: $spaceBase * 4;\n$spaceL: $spaceBase * 5;\n$spaceXL: $spaceBase * 6;\n$space2XL: $spaceBase * 8;\n$space3XL: $spaceBase * 9;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gantt-content-header": "_82c69ca8_gantt-content-header",
	"ganttContentHeader": "_82c69ca8_gantt-content-header",
	"gantt-content-header__list": "_82c69ca8_gantt-content-header__list",
	"ganttContentHeader__list": "_82c69ca8_gantt-content-header__list",
	"gantt-content-header__list-item": "_82c69ca8_gantt-content-header__list-item",
	"ganttContentHeader__listItem": "_82c69ca8_gantt-content-header__list-item"
};
export default ___CSS_LOADER_EXPORT___;
