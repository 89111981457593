import { api } from 'ant/plugins/api';
import { PagesEndpoints } from 'ant/store/pages/endpoints';
import { ColorTheme } from 'ant/theme/typings';

export type SaveThemeColorsParams = {
  colors: ColorTheme[];
};

export const saveThemeColors = <T>({ colors = [] }: SaveThemeColorsParams) =>
  api.post<T>({
    url: PagesEndpoints.colors(),
    data: colors,
  });
