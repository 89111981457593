import classNames from 'classnames';
import React, { PropsWithChildren, FC } from 'react';

import styles from './UiToolbar.scss';

declare const PositionTypes: ['left', 'right'];
export declare type PositionType = typeof PositionTypes[number];

export enum UiToolbarPlacement {
  Left = 'left',
  Right = 'right',
}

type LeftContent = {
  leftContent: React.ReactNode;
};

type Position = {
  placement: PositionType;
};

type ToolbarProps = {
  className?: string;
  emptyMargin?: boolean;
} & (LeftContent | Position);

export const UiToolbar: FC<PropsWithChildren<ToolbarProps>> = (props) => {
  const { children, className, emptyMargin, ...restProps } = props;
  const emptyMarginClassName = emptyMargin ? styles.toolbar__emptyMargin : undefined;

  if ('placement' in restProps) {
    const isActive = (key: string) => restProps.placement === key;

    return (
      <div
        className={classNames(
          styles.toolbar,
          { [styles.toolbar_left]: isActive(UiToolbarPlacement.Left) },
          emptyMarginClassName,
          className,
        )}
      >
        {children}
      </div>
    );
  }

  if ('leftContent' in restProps) {
    return (
      <div className={classNames(styles.toolbar, emptyMarginClassName, className)}>
        <div className={styles.toolbar_left}>{restProps.leftContent}</div>
        {children}
      </div>
    );
  }

  return <div className={classNames(styles.toolbar, emptyMarginClassName, className)}>{children}</div>;
};
