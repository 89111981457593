import { useContext } from 'react';

import { SettingsConfigContext } from '../provider/SettingsConfigProvider';

export const useSettingsConfig = () => {
  const context = useContext(SettingsConfigContext);

  if (!context) {
    throw new Error('Can not resolve SettingsConfigContext into useSettingsConfig hook');
  }

  return context;
};
