import { Dictionaries } from 'ant/store/dictionaries/dictionaries';
import { EntityContentData } from 'ant/types/editor';
import { BaseTag } from 'ant/types/models/base/base-tag';
import { FileStorageEntryUrl } from 'ant/types/models/file.model';
import { PostSettings, PostTypes } from 'ant/types/models/post';
import { ReactableModel } from 'ant/types/models/reactions.model';
import { UserModel } from 'ant/types/models/user.model';

export enum NewsStatus {
  Published = 'published',
  Planned = 'planned',
  Draft = 'draft',
  Pinned = 'pinned',
}

export type NewsCategory = {
  id: number;
  name: string;
  extraInfo: { color?: string };
};

export type NewsId = number;
export type CategoryId = number;
export type NewsCategoriesDist = Record<number, NewsCategory>;
export type NewsType = PostTypes.Simple | PostTypes.Stories;

export interface NewsCategoryParams extends Omit<NewsCategory, 'id'> {
  id?: NewsId;
}

type NewsArticleSettings = Pick<PostSettings, 'isComments' | 'isReacted'>;

export interface NewsArticle<Tags extends BaseTag = Dictionaries.Dict> extends ReactableModel {
  id: NewsId;
  title: string;
  cover: FileStorageEntryUrl;
  type?: NewsType;
  categoryId: CategoryId;
  publishedAt: string;
  updatedAt?: string;
  createdAt: string;
  author: UserModel;
  commentsCount: number;
  important: boolean;
  pinned: boolean;
  timelinePinned: boolean;
  tags: Tags[];
  body: EntityContentData;
  draft: boolean;
  authorId: string;
  status: NewsStatus;
  viewsCount: number;
  settings: NewsArticleSettings;
}
