import { CSSProperties, useMemo } from 'react';

import { Placement } from 'ant/types/placement';

type UsePlacementShiftType = {
  placementCls: string | null;
  placementStyles: CSSProperties | null;
};

export const usePlacementShift = (placement?: Placement, shift?: number): UsePlacementShiftType => {
  const innerShift = shift || 0;

  const mapPlacementShift = useMemo<Record<Placement, CSSProperties>>(() => {
    return {
      [Placement.BottomCenter]: {
        left: '50%',
        transform: 'translate(-50%, 0)',
        bottom: innerShift,
      },
      [Placement.BottomLeft]: {
        left: innerShift,
        bottom: innerShift,
      },
      [Placement.BottomRight]: {
        right: innerShift,
        bottom: innerShift,
      },
      [Placement.TopCenter]: {
        left: '50%',
        transform: 'translate(-50%, 0)',
        top: innerShift,
      },
      [Placement.TopLeft]: {
        top: innerShift,
        left: innerShift,
      },
      [Placement.TopRight]: {
        top: innerShift,
        right: innerShift,
      },
      [Placement.CenterLeft]: {
        top: '50%',
        transform: 'translate(0, -50%)',
        left: innerShift,
      },
      [Placement.CenterRight]: {
        top: '50%',
        transform: 'translate(0, -50%)',
        right: innerShift,
      },
      [Placement.Center]: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    };
  }, [innerShift]);

  return {
    placementCls: placement ? 'ph-placement' : null,
    placementStyles: placement ? mapPlacementShift[placement] : null,
  };
};
