import { stringify } from 'query-string';
import React, { Fragment, FC, PropsWithChildren } from 'react';
import { useHref } from 'react-router-dom';

import { UiRow, UiCol } from 'ant/components/ui/grid';
import { UiSpace } from 'ant/components/ui/space';
import { ReactionHandler, Reactions } from 'ant/components/widgets/Reactions';
import { ShareButton } from 'ant/components/widgets/ShareButton';
import { isSomePostBlogsNegativeKey } from 'ant/plugins/get-post-blogs';
import { getHrefByPathname } from 'ant/plugins/router';
import { isNewsPost } from 'ant/types/guards/post';
import { PostModel } from 'ant/types/models/post';

type Props = {
  post: PostModel;
  onReaction?: ReactionHandler;
};

export const PostBodyReactions: FC<Props> = (props) => {
  const { post, onReaction } = props;
  const { commentsCount, reactions } = post;
  const viewsCount = isNewsPost(post) ? post.viewsCount : 0;

  const routerHref = useHref({
    search: stringify({
      postId: post.id,
      postType: post.type,
    }),
  });
  const shareHref = getHrefByPathname(routerHref);

  const isReactedDisabledPostSettings = post?.settings && !post.settings.isReacted;
  const isReactedDisabledPostBlogs = isSomePostBlogsNegativeKey(post, 'isReacted');
  const isCommentsDisabledPostSettings = post?.settings && !post.settings.isComments;
  const isCommentsDisabledPostBlogs = isSomePostBlogsNegativeKey(post, 'isComments');
  const isCommentsDisabled = isCommentsDisabledPostSettings || isCommentsDisabledPostBlogs;
  const isReactionsDisabled = isReactedDisabledPostSettings || isReactedDisabledPostBlogs;

  const isReactions = reactions && !isReactionsDisabled;

  const ReactionWrapper: FC<PropsWithChildren> = (prop) => {
    if (isReactions) {
      return <Reactions reactions={reactions} {...(post.author.isActive && { onReaction })} {...prop} />;
    }

    return <Fragment {...prop} />;
  };

  return (
    <UiRow justify="space-between" align="middle" wrap={false}>
      <UiCol>
        <ReactionWrapper>
          <UiSpace size={16} flex>
            {!isCommentsDisabled && <Reactions.Comments count={commentsCount} />}
            <ShareButton value={shareHref} />
          </UiSpace>
        </ReactionWrapper>
      </UiCol>
      {Boolean(viewsCount) && (
        <UiCol>
          <Reactions.Views count={viewsCount} />
        </UiCol>
      )}
    </UiRow>
  );
};
