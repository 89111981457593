import { useStore } from 'effector-react';
import omit from 'lodash/omit';
import { parse } from 'query-string';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useMount } from 'ant/helpers/hooks/use-mount';
import { useQueryParams } from 'ant/helpers/hooks/use-query-params';
import { getStringifyIdentity } from 'ant/plugins/get-stringify-identity';
import { globalModalsStore, openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames, GlobalModalsStorePayloads } from 'ant/store/global-modals/modal-types';

type LongreadModalName =
  | GlobalModalNames.PostLongread
  | GlobalModalNames.NewsLongread
  | GlobalModalNames.EventsLongread
  | GlobalModalNames.SurveysLongread
  | GlobalModalNames.TimelineLongread;

type LongreadModalQueryParams = NonNullable<GlobalModalsStorePayloads[LongreadModalName]['queryParams']>;
type LongreadModalQueryParamsKeys = Array<keyof LongreadModalQueryParams>;
type LongreadModalPayload = GlobalModalsStorePayloads[LongreadModalName]['payload'];

/**
 * Открывает Longread если пользователь перешел по прямой ссылке на основе параметров в search,
 * @param longreadModalName: GlobalModalNames.Longread || GlobalModalNames.EventsLongread || GlobalModalNames.SurveysLongread
 * @param params
 * @param payload
 */
export const useLongreadModalParams = (
  longreadModalName: LongreadModalName,
  params: LongreadModalQueryParamsKeys,
  payload?: Partial<LongreadModalPayload>,
) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { queryParams } = useQueryParams<LongreadModalQueryParams>();

  const globalModals = useStore(globalModalsStore);
  const modalState = globalModals[longreadModalName];
  const isModalVisible = modalState?.isVisible;

  useMount(() => {
    const modalParams = {} as LongreadModalQueryParams;

    Object.entries(queryParams).forEach(([key, val]) => {
      if (params.includes(key)) {
        modalParams[key] = val;
      }
    });

    if (modalParams && Object.keys(modalParams).length > 0) {
      openGlobalModal(longreadModalName, { ...payload, ...modalParams }, modalParams);
    }
  });

  useEffect(() => {
    if (modalState?.queryParams) {
      const parsedSearch = parse(search);
      const searchParams = isModalVisible
        ? { ...parsedSearch, ...modalState.queryParams }
        : omit(parsedSearch, Object.keys(modalState.queryParams));

      navigate({ pathname, search: getStringifyIdentity(searchParams) }, { replace: !isModalVisible });
    }
  }, [isModalVisible]);
};
