import { FC } from 'react';

import { SurveyAnswer } from 'ant/components/widgets/Survey/survey-answer/SurveyAnswer';
import { SurveyQuestionChart } from 'ant/components/widgets/Survey/survey-question-chart/SurveyQuestionChart';
import { SurveyQuestionTable } from 'ant/components/widgets/Survey/survey-question-table/SurveyQuestionTable';

import {
  SurveyDivider,
  SurveyDividerProps as SurveyDividerPropsImport,
} from './survey-divider/SurveyDivider';
import { SurveyQuestionInfo } from './survey-question-info/SurveyQuestionInfo';
import { SurveyQuestionTableCollapse } from './survey-question-table-collapse/SurveyQuestionTableCollapse';
import {
  SurveyQuestion,
  SurveyQuestionProps as SurveyQuestionPropsImport,
} from './survey-question/SurveyQuestion';
import {
  SurveyQuestionEdit,
  SurveyEditProps as SurveyEditPropsImport,
} from './survey-question/edit/SurveyQuestionEdit';

export type SurveyQuestionProps = SurveyQuestionPropsImport;
export type SurveyEditProps = SurveyEditPropsImport;
export type SurveyDividerProps = SurveyDividerPropsImport;

export * from './survey-answer/constants';
export * from './survey-answer/types';

export interface SurveyInterface extends FC {
  Answer: typeof SurveyAnswer;
  Divider: typeof SurveyDivider;
  Question: typeof SurveyQuestion;
  QuestionEdit: typeof SurveyQuestionEdit;
  QuestionChart: typeof SurveyQuestionChart;
  QuestionInfo: typeof SurveyQuestionInfo;
  QuestionTableCollapse: typeof SurveyQuestionTableCollapse;
  QuestionTable: typeof SurveyQuestionTable;
}

export const Survey: SurveyInterface = () => null;

Survey.Answer = SurveyAnswer;
Survey.Divider = SurveyDivider;
Survey.Question = SurveyQuestion;
Survey.QuestionEdit = SurveyQuestionEdit;
Survey.QuestionChart = SurveyQuestionChart;
Survey.QuestionInfo = SurveyQuestionInfo;
Survey.QuestionTableCollapse = SurveyQuestionTableCollapse;
Survey.QuestionTable = SurveyQuestionTable;
