import classNames from 'classnames';
import React from 'react';

import AddSvg from 'ant/components/svg/add.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiDivider } from 'ant/components/ui/divider';
import { UiIcon } from 'ant/components/ui/icon';

import styles from './SurveyDivider.scss';

export type SurveyDividerProps = {
  onClick: () => void;
  icon?: SvgrComponent;
  className?: string;
};

const SurveyDivider: React.FC<SurveyDividerProps> = (props) => {
  const { onClick, icon = AddSvg, className } = props;

  return (
    <UiDivider className={classNames(styles.surveyDivider, className)}>
      <UiButton
        round
        type="primary"
        onClick={onClick}
        icon={<UiIcon component={icon} width={20} height={20} />}
      />
    </UiDivider>
  );
};

export { SurveyDivider };
