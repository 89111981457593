import { Menu as AntMenu } from 'antd';
import { MenuProps } from 'antd/es/menu';
import {
  MenuInfo,
  SelectInfo,
  MenuClickEventHandler,
  SelectEventHandler,
  MenuItemType as RcMenuItemType,
  MenuDividerType as RcMenuDividerType,
  SubMenuType as RcSubMenuType,
  MenuItemGroupType as RcMenuItemGroupType,
} from 'rc-menu/lib/interface';
import React, { useMemo } from 'react';

import { UiTruncateMarkup, UiTruncateMarkupProps } from 'ant/components/ui/truncate-markup';

import { UiMenuCollapsible } from './collapsible/UiMenuCollapsible';

interface MenuItemType extends RcMenuItemType {
  danger?: boolean;
  icon?: React.ReactNode;
  title?: string;
}
interface SubMenuType extends Omit<RcSubMenuType, 'children'> {
  icon?: React.ReactNode;
  theme?: 'dark' | 'light';
  children: ItemType[];
}
interface MenuItemGroupType extends Omit<RcMenuItemGroupType, 'children'> {
  children?: ItemType[];
  key?: React.Key;
}
interface MenuDividerType extends RcMenuDividerType {
  dashed?: boolean;
}
export declare type ItemType = MenuItemType | SubMenuType | MenuItemGroupType | MenuDividerType | null;
export type UiMenuInfo = MenuInfo;
export type UiSelectInfo = SelectInfo;

export interface UiMenuItemType extends MenuItemType {
  extraIcon?: React.ReactNode;
  truncateProps?: UiTruncateMarkupProps;
}
export type UiSubMenuType = SubMenuType;
export type UiMenuItemGroupType = MenuItemGroupType;
export type UiMenuDividerType = MenuDividerType;
export type UiItemType = UiMenuItemType | UiSubMenuType | UiMenuDividerType | UiMenuItemGroupType | null;

export interface UiMenuProps extends Omit<MenuProps, 'items'> {
  items?: UiItemType[];
  strong?: boolean;
  isStickyBadge?: boolean;
}
export type UiMenuClickEventHandler = MenuClickEventHandler;
export type UiMenuSelectEventHandler = SelectEventHandler;

type UiMenuComposition = {
  Divider: typeof AntMenu.Divider;
  Item: typeof AntMenu.Item;
  SubMenu: typeof AntMenu.SubMenu;
  ItemGroup: typeof AntMenu.ItemGroup;
  Collapsible: typeof UiMenuCollapsible;
};

export const UiMenu: React.FC<UiMenuProps> & UiMenuComposition = (props) => {
  const { items = [], strong, isStickyBadge, ...menuProps } = props;
  const isMenuItemType = (menuItem: Exclude<UiItemType, null>): menuItem is UiMenuItemType =>
    'extraIcon' in menuItem;

  const menuItems: ItemType[] = useMemo(() => {
    return items?.map((item) => {
      if (item && isMenuItemType(item)) {
        const { label, truncateProps = { truncate: false }, extraIcon, ...itemProps } = item;

        return {
          ...itemProps,
          label: (
            <>
              <UiTruncateMarkup {...truncateProps}>
                {strong && <strong>{label}</strong>}
                {!strong && <>{label}</>}
              </UiTruncateMarkup>
              {extraIcon && (
                <span style={isStickyBadge ? { marginLeft: 8 } : { marginLeft: 'auto' }}>{extraIcon}</span>
              )}
            </>
          ),
        };
      }

      return item;
    });
  }, [items]);

  return <AntMenu {...menuProps} items={items?.length ? menuItems : undefined} />;
};

UiMenu.Divider = AntMenu.Divider;
UiMenu.Item = AntMenu.Item;
UiMenu.SubMenu = AntMenu.SubMenu;
UiMenu.ItemGroup = AntMenu.ItemGroup;
UiMenu.Collapsible = UiMenuCollapsible;
