import React, { useCallback } from 'react';

import { UiFormItemProps } from 'ant/components/ui/form';
import { UiOptionData } from 'ant/components/ui/select';
import { CreatableMultiSelect } from 'ant/components/widgets/CreatableMultiSelect';
import { MAX_LENGTH_INPUT_50 } from 'ant/helpers/validation';
import { tagsDictsStorage } from 'ant/store/tags';

const TagsField: React.FC<UiFormItemProps<UiOptionData[]>> = (props) => {
  const { value = [], onChange } = props;
  const onTagsChange = useCallback((tags: UiOptionData[]) => onChange?.(tags), [onChange]);

  return (
    <CreatableMultiSelect
      value={value}
      caseInsensitive
      maxTagItems={6}
      maxLength={MAX_LENGTH_INPUT_50}
      onChange={onTagsChange}
      optionsStorage={tagsDictsStorage.storage}
      createOptionEffect={tagsDictsStorage.createNewDictsRecordEffect}
    />
  );
};

export { TagsField };
