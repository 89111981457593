import { Command, type Editor } from '@ckeditor/ckeditor5-core';

import type { VideoStyleOptionDefinition } from '../videoconfig';
import type { VideoUtils } from '../videoutils';

export class VideoStyleCommand extends Command {
  private defaultStyles: Record<string, string | false>;
  private styles: Map<string, VideoStyleOptionDefinition>;

  constructor(editor: Editor, styles: Array<VideoStyleOptionDefinition>) {
    super(editor);

    this.defaultStyles = {
      videoBlock: false,
    };

    this.styles = new Map(
      styles.map((style) => {
        if (style.isDefault) {
          for (const modelElementName of style.modelElements) {
            this.defaultStyles[modelElementName] = style.name;
          }
        }

        return [style.name, style];
      }),
    );
  }

  public override refresh(): void {
    const { editor } = this;
    const videoUtils: VideoUtils = editor.plugins.get('VideoUtils');
    const element = videoUtils.getClosestSelectedVideoElement(this.editor.model.document.selection)!;

    this.isEnabled = !!element;

    if (!this.isEnabled) {
      this.value = false;
    } else if (element.hasAttribute('videoStyle')) {
      this.value = element.getAttribute('videoStyle');
    } else {
      this.value = this.defaultStyles[element.name];
    }
  }

  public override execute(options: { value?: string } = {}): void {
    const { editor } = this;
    const { model } = editor;
    const videoUtils: VideoUtils = editor.plugins.get('VideoUtils');

    model.change((writer) => {
      const requestedStyle = options.value;
      const videoElement = videoUtils.getClosestSelectedVideoElement(model.document.selection)!;

      if (!requestedStyle || this.styles.get(requestedStyle)!.isDefault) {
        writer.removeAttribute('videoStyle', videoElement);
      } else {
        writer.setAttribute('videoStyle', requestedStyle, videoElement);
      }
    });
  }
}
