import { ProfileEndpoints } from 'ant/endpoints/profile';
import { api } from 'ant/plugins/api';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { SearchParams } from 'ant/types/models/search.model';

export const getSearchProfileAdvancedDownload = (params: SearchParams) => {
  const { searchString, ...restParams } = params;

  return api.get<Blob>({
    url: buildEndpointWithQueryParams(ProfileEndpoints.v3AdvancedSearchDownload(), {
      query: searchString,
      skipEmptyName: true,
      isActive: true,
      ...restParams,
    }),
    responseType: 'blob',
  });
};
