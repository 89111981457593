import { SummaryAnalyticsReportType } from 'ant/types/summary-analytics-report';

const getReportsUrl = (url: string) => `/api/reports/${url}`;

export class ReportsEndpoints {
  static dashboardAnalytic = () => getReportsUrl('dashboard_analytic/');

  static metricsReports = (report: SummaryAnalyticsReportType) => getReportsUrl(`metrics_reports/${report}/`);

  static blogCountsReports = (report: SummaryAnalyticsReportType) =>
    getReportsUrl(`blogs_count_reports/${report}/`);

  static rolesReport = () => getReportsUrl(`roles_reports/admins/`);
}
