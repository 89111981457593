import React, { CSSProperties, FC, KeyboardEventHandler } from 'react';

import { UiImage } from 'ant/components/ui/image';
import { UiSpace } from 'ant/components/ui/space';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { getFormattedDate } from 'ant/plugins/utils/get-formatted-date';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { NewsArticle } from 'ant/types/models/news.model';
import { PostTypes } from 'ant/types/models/post';

import styles from './NewsListItem.scss';

type Props = {
  newsArticle: NewsArticle;
};

const borderRadius: CSSProperties = { borderRadius: 2 };

export const NewsListItem: FC<Props> = (props) => {
  const { newsArticle } = props;
  const { cover, title, publishedAt, id, type } = newsArticle;

  const openLongread = () => {
    const newsLongreadParams = { newsId: id, newsType: type || PostTypes.Simple };

    openGlobalModal(GlobalModalNames.NewsLongread, newsLongreadParams, newsLongreadParams);
  };

  const onHTMLKeyPress: KeyboardEventHandler = ({ key }) => {
    if (key === 'Enter') {
      openLongread();
    }
  };

  return (
    <div
      className={styles.newsListItem}
      onClick={openLongread}
      tabIndex={-1}
      role="link"
      onKeyPress={onHTMLKeyPress}
    >
      <UiSpace align="start" style={{ marginRight: 16 }}>
        <UiImage src={cover} width={90} style={borderRadius} wrapperStyle={borderRadius} />
      </UiSpace>

      <div className={styles.newsListItem__body}>
        <UiTypography.Text className={styles.newsListItem__title}>
          <UiTruncateMarkup truncate lines={3}>
            {title}
          </UiTruncateMarkup>
        </UiTypography.Text>

        <UiTypography.Text className={styles.newsListItem__date}>
          {getFormattedDate(publishedAt, 'dd MMMM в H:mm')}
        </UiTypography.Text>
      </div>
    </div>
  );
};
