import { Select as AntSelect, SelectProps, AutoCompleteProps } from 'antd';
import { SelectValue, LabeledValue, RefSelectProps } from 'antd/es/select';
import classNames from 'classnames';
import { BaseSelectPrivateProps, BaseSelectRef } from 'rc-select/es/BaseSelect';
import { BaseOptionType, DefaultOptionType } from 'rc-select/es/Select';
import React from 'react';

import DropDownSvg from 'ant/components/svg/drop-down.svg';

import styles from './UiSelect.scss';

export type UiBaseSelectRef = BaseSelectRef;
export type UiRefSelectProps = RefSelectProps;
export type UiOptionItem = { value: string; label: string };
export type UiSelectBaseOptionType = BaseOptionType;
export interface UiSelectDefaultOption extends Omit<DefaultOptionType, 'value'> {
  value: string;
}
export type UiOptionData = UiSelectDefaultOption;
export type UiLabeledValue = LabeledValue;
export type UiSelectValue = SelectValue;

// @ts-expect-error TODO: Pick<BaseSelectPrivateProps, 'maxLength'> -- хак который работает, на самом деле правильнее работать через getInputElement, но в antd этого пропса нет
export type UiSelectMaxLengthProps = Pick<BaseSelectPrivateProps, 'maxLength'>;
export interface UiSelectProps<
  VT,
  OT extends UiSelectBaseOptionType | UiSelectDefaultOption = UiSelectDefaultOption,
> extends SelectProps<VT, OT> {
  type?: 'text';
}

export const getPopupContainerParentElement: AutoCompleteProps['getPopupContainer'] = (trigger) =>
  trigger.parentElement;

const InternalUiSelect = <
  OptionType extends UiSelectBaseOptionType | UiSelectDefaultOption = UiSelectDefaultOption,
>(
  props: UiSelectProps<OptionType>,
  ref: React.Ref<BaseSelectRef>,
) => {
  const { type, suffixIcon, className, popupClassName, mode, ...rest } = props;
  const SuffixIcon = suffixIcon !== undefined ? suffixIcon : <DropDownSvg />;
  const isTextType = type === 'text';
  const isMultipleMode = mode === 'multiple';

  const classNamesStyles = classNames(className, {
    [styles.uiSelect__text]: isTextType,
    [styles.multiSelect]: isMultipleMode,
  });
  const popupClassNameStyles = classNames(popupClassName, {
    [styles.uiSelect__textOptions]: isTextType,
    [styles.multiSelect__dropdown]: isMultipleMode,
  });

  return (
    <AntSelect
      {...rest}
      ref={ref}
      mode={mode}
      suffixIcon={SuffixIcon}
      className={classNamesStyles}
      popupClassName={popupClassNameStyles}
    />
  );
};

export const UiSelect = React.forwardRef(InternalUiSelect) as unknown as (<
  ValueType = any,
  OptionType extends UiSelectBaseOptionType | UiSelectDefaultOption = UiSelectDefaultOption,
>(
  props: React.PropsWithChildren<UiSelectProps<ValueType, OptionType>> & {
    ref?: React.Ref<UiBaseSelectRef>;
  },
) => React.ReactElement) & {
  Option: typeof AntSelect.Option;
  OptGroup: typeof AntSelect.OptGroup;
};

UiSelect.Option = AntSelect.Option;
UiSelect.OptGroup = AntSelect.OptGroup;
