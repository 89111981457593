import React, { FC, PropsWithChildren } from 'react';

import { SurveyQuestionInfoProps } from 'ant/components/widgets/Survey/survey-question-info/SurveyQuestionInfo';

import { Survey } from '../Survey';
import { SurveyQuestion, SurveyQuestionProps } from '../survey-question/SurveyQuestion';

export type SurveyQuestionChartProps = SurveyQuestionProps & SurveyQuestionInfoProps;

const SurveyQuestionChart: FC<PropsWithChildren<SurveyQuestionChartProps>> = (props) => {
  const { title, questionInfo, className, children, ...rest } = props;

  return (
    <SurveyQuestion className={className} title={title} {...rest}>
      <Survey.QuestionInfo questionInfo={questionInfo} />
      {children}
    </SurveyQuestion>
  );
};

export { SurveyQuestionChart };
