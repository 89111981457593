import { AxiosError } from 'axios';
import { createEffect } from 'effector';
import mapValues from 'lodash/mapValues';

import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { defaultConfigLayout } from 'ant/providers/settings-config/defaultValue';
import { updateSettingsLayouts } from 'ant/store/settings/api';
import { SettingsAuthModel, SettingsConfigLayoutModel } from 'ant/types/models/settings.model';

import { SettingsEndpoints } from './endpoints';

const getSettingsLayoutsConfigStorage = () => {
  const storage = abstractStorageFactory<
    SettingsConfigLayoutModel,
    SettingsConfigLayoutModel,
    SettingsConfigLayoutModel
  >({
    endpointBuilder: SettingsEndpoints.layouts,
    defaultValue: defaultConfigLayout,
    dataMapper: (data) => {
      return mapValues(defaultConfigLayout, (config, key) => {
        return { ...config, value: data[key] ? data[key].value : config.value };
      });
    },
    cancelPendingRequestOnFetch: true,
  });

  const updateSettingsLayoutsEffect = createEffect<SettingsConfigLayoutModel, unknown, AxiosError>(
    updateSettingsLayouts,
  );

  return { storage, updateSettingsLayoutsEffect };
};

export const getSettingsAuthConfigStorage = () => {
  const storage = abstractStorageFactory<SettingsAuthModel, SettingsAuthModel, null>({
    endpointBuilder: SettingsEndpoints.auth,
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

/** @deprecated Не используй! Тебе пригодится только хук: useSettingsConfig */
export const settingsLayoutsConfigStorage = getSettingsLayoutsConfigStorage();
export const settingsAuthConfigStorage = getSettingsAuthConfigStorage();
