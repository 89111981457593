import { AttachmentTypes } from 'ant/types/models/attachment.model';
import { TagId } from 'ant/types/models/base/base-tag';
import { FileStorageEntryId, FileStorageListEntryModel } from 'ant/types/models/file.model';
import { UpdateIssuerDateModel } from 'ant/types/models/issuer-date.model';
import { KeycloakId } from 'ant/types/models/keycloak-user';

export enum IdeasTabTypes {
  All = 'all',
  Author = 'author',
  Drafts = 'drafts',
  Review = 'review',
  Sponsoring = 'sponsoring',
  Working = 'working',
}

export type IdeasTabCountsModel = Record<IdeasTabTypes, number>;

export enum IdeaStatusTypes {
  Draft = 'draft',
  Pending = 'pending',
  Review = 'review',
  Declined = 'declined',
  Progress = 'progress',
  Cancelled = 'cancelled',
  Completed = 'completed',
}

export enum IdeaCurrentVoteTypes {
  Up = '+',
  Down = '-',
}

export type IdeaVotesModel = {
  agree: number;
  disagree: number;
  currentVote: IdeaCurrentVoteTypes | null;
};

export type IdeaModelId = string;
export type IdeaCategoryId = string;

export type IdeaCategoryModel = {
  id: IdeaCategoryId;
  name: string;
  isEnabled: boolean;
};

export type IdeaAttachmentModel = {
  id: FileStorageEntryId;
  attachmentType: AttachmentTypes;
  fileStorageObject: FileStorageListEntryModel;
};

export interface IdeaModel extends UpdateIssuerDateModel<KeycloakId> {
  id: IdeaModelId;
  status: IdeaStatusTypes;
  statusDescription: string | null;
  name: string;
  category: IdeaCategoryId;
  description: string;
  tags: TagId[];
  attachments: IdeaAttachmentModel[];
  author: KeycloakId;
  reviewer: KeycloakId | null;
  reviewers: KeycloakId[];
  responsible: KeycloakId | null;
  sponsor: KeycloakId | null | boolean;
  sponsorComment: string | null;
  createdAt: string;
  countComments?: number;
  votes: IdeaVotesModel;
  group: string | null;
}

export type IdeaUploadAttachmentModel = {
  attachmentType: AttachmentTypes;
  fileStorageObjectId: FileStorageEntryId;
};

type IdeaCreateBaseModel = {
  name: string;
  description: string;
  category: IdeaCategoryId;
  attachments?: IdeaUploadAttachmentModel[];
};

export interface IdeaCreateModel extends IdeaCreateBaseModel {
  status: IdeaStatusTypes;
}

export interface IdeaUpdateModel extends IdeaCreateBaseModel {
  id: IdeaModelId;
}
