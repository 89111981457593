import { TitleProps as AntTitleProps } from 'antd/es/typography/Title';
import classNames from 'classnames';
import React from 'react';

import { UiTypography } from 'ant/components/ui/typography';

import styles from './UiWidgetHeader.scss';

interface UiWidgetHeaderProps extends Pick<AntTitleProps, 'level'> {
  icon?: React.ReactNode;
  title: string;
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

/** @deprecated используй UiCard.Header и UiCard.Header.Title */
export const UiWidgetHeader: React.FC<UiWidgetHeaderProps> = (props) => {
  const { level = 3, title, icon, children, className, style } = props;

  return (
    <span style={style} className={classNames(styles.uiWidgetHeader, className)}>
      {icon}
      <UiTypography.Title level={level} style={{ marginBottom: 0 }}>
        {title}
      </UiTypography.Title>

      {children}
    </span>
  );
};
