import type {
  DowncastDispatcher,
  Element,
  UpcastDispatcher,
  UpcastElementEvent,
  DowncastAttributeEvent,
} from '@ckeditor/ckeditor5-engine';
import { first, type GetCallback } from '@ckeditor/ckeditor5-utils';

import type { VideoUtils } from '../videoutils';

export function upcastVideoFigure(videoUtils: VideoUtils): (dispatcher: UpcastDispatcher) => void {
  const converter: GetCallback<UpcastElementEvent> = (_, data, conversionApi) => {
    if (!conversionApi.consumable.test(data.viewItem, { name: true, classes: 'video' })) {
      return;
    }

    const viewVideo = videoUtils.findViewVideoElement(data.viewItem);

    if (!viewVideo || !conversionApi.consumable.test(viewVideo, { name: true })) {
      return;
    }

    conversionApi.consumable.consume(data.viewItem, { name: true, classes: 'video' });

    const conversionResult = conversionApi.convertItem(viewVideo, data.modelCursor);
    const modelVideo = first(conversionResult.modelRange!.getItems()) as Element;

    if (!modelVideo) {
      conversionApi.consumable.revert(data.viewItem, { name: true, classes: 'video' });

      return;
    }

    conversionApi.convertChildren(data.viewItem, modelVideo);
    conversionApi.updateConversionResult(modelVideo, data);
  };

  return (dispatcher) => {
    dispatcher.on<UpcastElementEvent>('element:figure', converter);
  };
}

export function downcastVideoAttribute(
  videoUtils: VideoUtils,
  attributeKey: string,
): (dispatcher: DowncastDispatcher) => void {
  const converter: GetCallback<DowncastAttributeEvent<Element>> = (evt, data, conversionApi) => {
    if (!conversionApi.consumable.consume(data.item, evt.name)) {
      return;
    }

    const viewWriter = conversionApi.writer;
    const element = conversionApi.mapper.toViewElement(data.item)!;
    const video = videoUtils.findViewVideoElement(element)!;

    viewWriter.setAttribute(data.attributeKey, data.attributeNewValue || '', video);
  };

  return (dispatcher) => {
    dispatcher.on<DowncastAttributeEvent<Element>>(`attribute:${attributeKey}:videoBlock`, converter);
  };
}
