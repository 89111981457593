import React, { FC, VideoHTMLAttributes } from 'react';

export type UiVideoProps = VideoHTMLAttributes<HTMLVideoElement>;

export const UiVideo: FC<UiVideoProps> = (props) => {
  return (
    <video {...props}>
      <track kind="captions" />
    </video>
  );
};
