import React, { FC, PropsWithChildren } from 'react';

import { UiPopover, UiPopoverProps } from 'ant/components/ui/popover/UiPopover';
import { ReactionsAttributes } from 'ant/components/widgets/Reactions';
import { ReactionsIcon } from 'ant/components/widgets/Reactions/icon/ReactionsIcon';
import { RecordResponse } from 'ant/types/dictionary';

import styles from './ReactionsPopover.scss';

interface Props extends Omit<UiPopoverProps, 'content' | 'overlayClassName'> {
  reactionsData: RecordResponse<ReactionsAttributes>[];
  onReact: (reactionId: string) => void;
}

const POPOVER_ICON_SIZE = 28;

const ReactionsPopover: FC<PropsWithChildren<Props>> = (props) => {
  const { reactionsData, onReact, ...popoverProps } = props;

  const popoverContent = (
    <div className={styles.reactionsPopover__content}>
      {reactionsData.map(({ attributes: { iconName }, id }) => (
        <ReactionsIcon
          key={id}
          id={id}
          iconName={iconName}
          onReact={onReact}
          size={POPOVER_ICON_SIZE}
          className={styles.reactionsPopover__icon}
        />
      ))}
    </div>
  );

  return <UiPopover {...popoverProps} overlayClassName={styles.reactionsPopover} content={popoverContent} />;
};

export { ReactionsPopover };
