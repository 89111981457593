import { Plugin, type Editor } from '@ckeditor/ckeditor5-core';
import type {
  Element,
  ViewElement,
  DocumentSelection,
  ViewDocumentSelection,
  Selection,
  ViewSelection,
  DocumentFragment,
  DowncastWriter,
  Model,
  Position,
} from '@ckeditor/ckeditor5-engine';
import { findOptimalInsertionRange, isWidget, toWidget } from '@ckeditor/ckeditor5-widget';

function isNotInsideVideo(selection: DocumentSelection): boolean {
  return [...selection.focus!.getAncestors()].every((ancestor) => !ancestor.is('element', 'videoBlock'));
}

function getInsertVideoParent(
  selection: Selection | DocumentSelection,
  model: Model,
): Element | DocumentFragment {
  const insertionRange = findOptimalInsertionRange(selection, model);
  const { parent } = insertionRange.start;

  if (parent.isEmpty && !parent.is('element', '$root')) {
    return parent.parent!;
  }

  return parent;
}

function isVideoAllowedInParent(editor: Editor, selection: Selection | DocumentSelection): boolean {
  const parent = getInsertVideoParent(selection, editor.model);

  return editor.model.schema.checkChild(parent as Element, 'videoBlock');
}

export class VideoUtils extends Plugin {
  public static get pluginName() {
    return 'VideoUtils' as const;
  }

  // eslint-disable-next-line class-methods-use-this
  public isVideoView(element?: ViewElement | null): boolean {
    return !!element && element.is('element', 'video');
  }

  // eslint-disable-next-line class-methods-use-this
  public isBlockVideoView(element?: ViewElement | null): boolean {
    return !!element && element.is('element', 'figure') && element.hasClass('video');
  }

  public insertVideo(
    attributes: Record<string, unknown> = {},
    selectable: Selection | Position | null = null,
  ): Element | null {
    const { editor } = this;
    const { model } = editor;
    const { selection } = model.document;

    // eslint-disable-next-line no-param-reassign
    attributes = {
      ...Object.fromEntries(selection.getAttributes()),
      ...attributes,
    };

    for (const attributeName in attributes) {
      if (!model.schema.checkAttribute('videoBlock', attributeName)) {
        // eslint-disable-next-line no-param-reassign
        delete attributes[attributeName];
      }
    }

    return model.change((writer) => {
      const videoElement = writer.createElement('videoBlock', attributes);

      model.insertObject(videoElement, selectable, null, {
        setSelection: 'on',
        // eslint-disable-next-line eqeqeq
        findOptimalPosition: !selectable ? 'auto' : undefined,
      });

      if (videoElement.parent) {
        return videoElement;
      }

      return null;
    });
  }

  public getClosestSelectedVideoWidget(selection: ViewSelection | ViewDocumentSelection): ViewElement | null {
    const selectionPosition = selection.getFirstPosition();

    if (!selectionPosition) {
      return null;
    }

    const viewElement = selection.getSelectedElement();

    if (viewElement && this.isVideoWidget(viewElement)) {
      return viewElement;
    }

    let { parent } = selectionPosition;

    while (parent) {
      if (parent.is('element') && this.isVideoWidget(parent)) {
        return parent;
      }

      // @ts-expect-error assignable error
      parent = parent.parent;
    }

    return null;
  }

  public getClosestSelectedVideoElement(selection: Selection | DocumentSelection): Element | null {
    const selectedElement = selection.getSelectedElement();

    return this.isVideo(selectedElement)
      ? selectedElement
      : selection.getFirstPosition()!.findAncestor('videoBlock');
  }

  public isVideoAllowed(): boolean {
    const { model } = this.editor;
    const { selection } = model.document;

    return isVideoAllowedInParent(this.editor, selection) && isNotInsideVideo(selection);
  }

  // eslint-disable-next-line class-methods-use-this
  public toVideoWidget(viewElement: ViewElement, writer: DowncastWriter): ViewElement {
    writer.setCustomProperty('video', true, viewElement);

    return toWidget(viewElement, writer);
  }

  // eslint-disable-next-line class-methods-use-this
  protected isVideoWidget(viewElement: ViewElement): boolean {
    return !!viewElement.getCustomProperty('video') && isWidget(viewElement);
  }

  // eslint-disable-next-line class-methods-use-this
  public isVideo(modelElement?: Element | null): boolean {
    return !!modelElement && modelElement.is('element', 'videoBlock');
  }

  public findViewVideoElement(figureView: ViewElement): ViewElement | undefined {
    if (this.isVideoView(figureView)) {
      return figureView;
    }

    try {
      return figureView.getChild(0) as ViewElement;
    } catch (_) {
      const editingView = this.editor.editing.view;

      for (const props of editingView.createRangeIn(figureView)) {
        const { item } = props;

        if (this.isVideoView(item as ViewElement)) {
          return item as ViewElement;
        }
      }

      return undefined;
    }
  }
}
