import { KeycloakId } from 'ant/types/models/keycloak-user';
import { ServiceRolesNames } from 'ant/types/roles';

const getUrl = (url: string) => `/api/roles/${url}`;

class RolesEndpoints {
  static userPermissionsAll = (userId: KeycloakId) => getUrl(`${userId}/permissions/all/`);
  static serviceNamePermissions = (serviceName: ServiceRolesNames) => getUrl(`${serviceName}/permissions/`);
}

export { RolesEndpoints };
