import { FileId } from 'ant/store/filestorage/api';
import { EventId } from 'ant/types/models/event.model';
import { FileStorageEntryId } from 'ant/types/models/file.model';
import { PollId } from 'ant/types/models/poll';

export enum AttachmentTypes {
  File = 'file',
  Image = 'image',
  Video = 'video',
  Embed = 'embed',
}

export type AttachmentEntryId = number;
export type AttachmentEntityId = PollId | FileId | EventId | FileStorageEntryId;

export type AttachmentDetail = {
  name: string;
  attachmentFileUrl: string;
  size: number;
};
