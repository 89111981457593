import React, { useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { UiTable } from 'ant/components/ui/table';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { AbstractStorage } from 'ant/helpers/storage/abstract-storage-factory';
import { DictPaginated } from 'ant/types/api';

import { UiTableColumnFilterItem, UiTableColumnFilterProps } from '../UiTableColumnFilter';

interface Props<T extends Record<string, unknown>>
  extends Pick<UiTableColumnFilterProps, 'filterDropdownProps'> {
  storage: AbstractStorage<DictPaginated<string>, string[], string[], T>;
  params: T;
  onChange: (values: string[]) => void;
}

export const UiTableColumnFilterPaths = <T extends Record<string, unknown>>(props: Props<T>) => {
  const { storage, params, ...restProps } = props;

  const [query, setQuery] = useState('');

  const { data, loading, error } = useAbstractStorage(storage, {
    autoFetchAndRefetch: true,
    // TODO https://gitlab.digital-insider.ru/di/main-frontend/-/issues/197
    autoFetchParams: { ...params, pageSize: 12, query },
    cancelPendingRequestOnUnmount: true,
  });

  const onSearch = useDebouncedCallback(setQuery, 400);

  const options = useMemo<UiTableColumnFilterItem[]>(() => {
    return data.map((path) => ({
      value: path,
      label: (
        <UiTruncateMarkup truncate tooltipProps={{ title: path }}>
          {path}
        </UiTruncateMarkup>
      ),
    }));
  }, [data]);

  return (
    <UiTable.ColumnFilter
      loading={loading}
      options={options}
      error={Boolean(error)}
      onSearch={onSearch}
      {...restProps}
    />
  );
};
