import { AxiosError } from 'axios';
import { createEffect, createEvent } from 'effector';

import { UiOptionData } from 'ant/components/ui/select';
import { SurveyEndpoints } from 'ant/endpoints/survey';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import {
  buildEndpointWithQueryParams,
  EndpointQueryParamsBaseType,
} from 'ant/plugins/utils/endpoint-builder';
import { generateSelectOptions } from 'ant/plugins/utils/options-helper';
import { DictPaginated, PaginationParams } from 'ant/types/api';
import {
  SurveyGroupListModel,
  SurveyListModel,
  GroupsMailingListItemModel,
  SurveyModel,
  SurveyDetailedModel,
  GroupsMailingListModel,
  SurveyQuestionId,
  SurveyQuestion,
  SurveySendingParams,
  SurveySendingModel,
  GroupsMailingSendingParams,
  SurveySendingStatus,
  SurveySendingGroupDetailModel,
  SendingSurveyId,
  SendingSurveyInstanceModel,
  SurveySendingMemberModel,
  GroupsMailingUpdateParams,
  SurveySendingUpdateParams,
  GroupsMailingGroupMemberModel,
  SurveyGroupId,
  SurveyQuestionStatistic,
  SurveySendingDetailModel,
  SurveyId,
  SurveyChoiceParams,
  SurveyChoiceDetailsModel,
  GroupsMailingId,
  SurveyGroupMailingCloneModel,
  SurveyQuestionStatisticCommonList,
  SurveyQuestionStatisticCommonTypes,
  SurveyStatisticKeys,
} from 'ant/types/models/survey';
import { UserAuthorModel } from 'ant/types/models/user.model';

import {
  deleteSurvey,
  cloneSurvey,
  saveSurvey,
  getSurvey,
  surveySending,
  deleteGroupMailing,
  DefaultGroupMailingParams,
  DefaultSurveyParams,
  DeleteMemberParams,
  createGroupMailing,
  getGroupMailing,
  updateGroupMailing,
  DefaultSendingSurveyParams,
  surveySendingUpdate,
  surveySendingCancel,
  deleteMemberGroupMailing,
  cloneGroupMailing,
} from './api';

export interface CloneSaveSurveyQuestionParams extends Omit<SurveyQuestion, 'id'> {
  id?: SurveyQuestionId;
}

export interface SaveSurveyParams extends Omit<SurveyDetailedModel, 'questions'> {
  questions?: CloneSaveSurveyQuestionParams[];
}

export interface SurveyListParams extends PaginationParams {
  isMine: boolean;
  isDraft: boolean;
}

export interface GroupsMailingListParams extends PaginationParams {
  isMine: boolean;
}

export interface GroupMailingParams extends EndpointQueryParamsBaseType {
  groupMailingId: GroupsMailingId;
  query?: string;
}

export const getSurveyListStorage = () => {
  const storage = abstractStorageFactory<
    SurveyListModel,
    SurveyModel[],
    SurveyModel[],
    Partial<SurveyListParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(SurveyEndpoints.surveyList(), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) => data.items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal, page: meta.pageNumber }),
  });
  const deleteSurveyEvent = createEvent<DefaultSurveyParams>();

  const { store } = storage;

  store.on(deleteSurveyEvent, (state, { surveyId }) => ({
    ...state,
    data: state.data.filter((survey) => survey.id !== surveyId),
  }));

  const deleteSurveyEffect = createEffect<DefaultSurveyParams, unknown, AxiosError>((params) =>
    deleteSurvey(params).then(() => deleteSurveyEvent(params)),
  );

  return { storage, deleteSurveyEffect };
};

export type GetSurveyListStorage = ReturnType<typeof getSurveyListStorage>;

export const cloneSurveyEffect = createEffect<DefaultSurveyParams, SurveyModel, AxiosError>((params) =>
  cloneSurvey<SurveyModel>(params).then((response) => response.data),
);

export const saveSurveyEffect = createEffect<SaveSurveyParams, SurveyModel, AxiosError>((params) =>
  saveSurvey<SurveyModel>(params).then((response) => response.data),
);

export const getSurveyEffect = createEffect<DefaultSurveyParams, SurveyDetailedModel, AxiosError>((params) =>
  getSurvey<SurveyDetailedModel>(params).then((response) => response.data),
);

export const surveySendingEffect = createEffect<SurveySendingParams, SurveySendingModel, AxiosError>(
  (params) => surveySending<SurveySendingModel>(params).then((response) => response.data),
);

export const surveySendingUpdateEffect = createEffect<
  SurveySendingUpdateParams,
  SurveySendingModel,
  AxiosError
>((params) => surveySendingUpdate<SurveySendingModel>(params).then((response) => response.data));

export const surveySendingCancelEffect = createEffect<SendingSurveyId, unknown, AxiosError>((id) =>
  surveySendingCancel<SurveySendingModel>(id).then((response) => response.data),
);

export const getSurveyStorage = () => {
  const storage = abstractStorageFactory<SurveyDetailedModel, SurveyDetailedModel, null, DefaultSurveyParams>(
    {
      endpointBuilder: (params) => SurveyEndpoints.survey(params.surveyId),
      defaultValue: null,
      cancelPendingRequestOnFetch: true,
    },
  );

  return { storage };
};

interface SurveySendingDetailParams extends Partial<PaginationParams> {
  status: SurveySendingStatus;
  isMine: boolean;
}

export const getSurveySendingDetailStorage = (id: SurveyId) => {
  const storage = abstractStorageFactory<
    DictPaginated<SurveySendingDetailModel>,
    SurveySendingDetailModel[],
    SurveySendingDetailModel[],
    Partial<SurveySendingDetailParams>
  >({
    endpointBuilder: (params) =>
      buildEndpointWithQueryParams(SurveyEndpoints.surveySurveyIdSending(id), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal, page: meta.pageNumber }),
  });

  return { storage };
};

export type GetSurveySendingDetailStorage = ReturnType<typeof getSurveySendingDetailStorage>;

export interface SurveyGroupListParams {
  isMine: boolean;
}

export const getSurveyGroupListOptionsStorage = () => {
  const storage = abstractStorageFactory<
    SurveyGroupListModel,
    UiOptionData[],
    UiOptionData[],
    Partial<SurveyGroupListParams>
  >({
    endpointBuilder: (params) =>
      buildEndpointWithQueryParams(SurveyEndpoints.groupList(), { isMine: false, ...params }),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) =>
      generateSelectOptions(data.items, {
        labelPath: 'title',
        valuePath: 'id',
      }),
  });

  return { storage };
};

export type GetSurveyGroupListOptionsStorage = ReturnType<typeof getSurveyGroupListOptionsStorage>;

export const getGroupsMailingListStorage = () => {
  const storage = abstractStorageFactory<
    GroupsMailingListModel,
    GroupsMailingListItemModel[],
    GroupsMailingListItemModel[],
    Partial<GroupsMailingListParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(SurveyEndpoints.groupList(), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal, page: meta.pageNumber }),
  });

  const deleteGroupMailingEvent = createEvent<DefaultGroupMailingParams>();

  const { store } = storage;

  store.on(deleteGroupMailingEvent, (state, { groupMailingId }) => {
    const { data } = state;
    const filteredData: GroupsMailingListItemModel[] = data.filter(({ id }) => id !== groupMailingId);

    return { ...state, data: filteredData };
  });

  const deleteGroupMailingEffect = createEffect<DefaultGroupMailingParams, unknown, AxiosError>((params) =>
    deleteGroupMailing(params).then(() => deleteGroupMailingEvent(params)),
  );

  return { storage, deleteGroupMailingEffect };
};

export const createGroupMailingEffect = createEffect<GroupsMailingSendingParams, unknown, AxiosError>(
  (params) => createGroupMailing(params),
);

export const updateGroupMailingEffect = createEffect<GroupsMailingUpdateParams, unknown, AxiosError>(
  (params) => updateGroupMailing(params),
);

export const cloneGroupMailingEffect = createEffect<
  GroupsMailingId,
  SurveyGroupMailingCloneModel,
  AxiosError
>((params) => cloneGroupMailing<SurveyGroupMailingCloneModel>(params).then((response) => response.data));

export type GetGroupsMailingListStorage = ReturnType<typeof getGroupsMailingListStorage>;

export type SurveySendingInstanceParams = { sendingSurveyId: SendingSurveyId };

export const getSurveySendingInstanceStorage = () => {
  const storage = abstractStorageFactory<
    SendingSurveyInstanceModel,
    SendingSurveyInstanceModel,
    null,
    SurveySendingInstanceParams
  >({
    endpointBuilder: (params) => SurveyEndpoints.surveyInstanceSendingSurveyId(params.sendingSurveyId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getSurveySendingInstanceMemberStorage = (id: SendingSurveyId) => {
  const storage = abstractStorageFactory<
    DictPaginated<SurveySendingMemberModel>,
    SurveySendingMemberModel[],
    SurveySendingMemberModel[],
    Partial<PaginationParams>
  >({
    endpointBuilder: (params) =>
      buildEndpointWithQueryParams(SurveyEndpoints.surveyInstanceSendingSurveyIdMembers(id), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) => data.items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal, page: meta.pageNumber }),
  });

  return { storage };
};

interface SurveySendingGroupParams extends Partial<PaginationParams>, EndpointQueryParamsBaseType {
  id: SurveyGroupId;
}

interface SurveySendingDetailParams extends Partial<PaginationParams> {
  status: SurveySendingStatus;
  isMine: boolean;
}

export type SurveySendingGroupDetailParams = SurveySendingGroupParams | Partial<SurveySendingDetailParams>;

const isSendingDetailGroup = <T extends SurveySendingGroupDetailParams>(
  params: SurveySendingGroupDetailParams,
): params is T => {
  return Boolean('id' in params && params.id);
};

export const getSurveySendingGroupDetailStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<SurveySendingGroupDetailModel>,
    SurveySendingGroupDetailModel[],
    SurveySendingGroupDetailModel[],
    SurveySendingGroupDetailParams
  >({
    endpointBuilder: (params) =>
      isSendingDetailGroup<SurveySendingGroupParams>(params)
        ? SurveyEndpoints.surveySendingGroupGroupId(params.id)
        : buildEndpointWithQueryParams(SurveyEndpoints.surveySendingList(), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) => data.items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal, page: meta.pageNumber }),
  });

  return { storage };
};

export type GetSurveySendingGroupDetailStorage = ReturnType<typeof getSurveySendingGroupDetailStorage>;
export const surveySendingGroupDetailStorage = getSurveySendingGroupDetailStorage();

export const getSurveyGroupMember = () => {
  const storage = abstractStorageFactory<
    DictPaginated<UserAuthorModel>,
    UserAuthorModel[],
    UserAuthorModel[],
    GroupMailingParams
  >({
    endpointBuilder: ({ groupMailingId, ...params }) =>
      buildEndpointWithQueryParams(SurveyEndpoints.groupMailingIdMembers(groupMailingId), params),

    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) => data.items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal, page: meta.pageNumber }),
  });

  return { storage };
};

export type GetSurveyGroupMemberStorage = ReturnType<typeof getSurveyGroupMember>;

export type GroupsMailingParams = {
  id: SurveyGroupId;
};

export const getGroupsMailingStorage = () => {
  const storage = abstractStorageFactory<
    GroupsMailingListItemModel,
    GroupsMailingListItemModel,
    null,
    GroupsMailingParams
  >({
    endpointBuilder: ({ id }) => buildEndpointWithQueryParams(SurveyEndpoints.groupGroupId(id)),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export type GetGroupsMailingStorage = ReturnType<typeof getGroupsMailingStorage>;

export interface GroupsMailingMemberParams extends Partial<PaginationParams>, EndpointQueryParamsBaseType {
  id: SurveyGroupId;
}

export const getGroupsMailingMembersStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<GroupsMailingGroupMemberModel>,
    GroupsMailingGroupMemberModel[],
    GroupsMailingGroupMemberModel[],
    GroupsMailingMemberParams
  >({
    endpointBuilder: ({ id, ...params }) =>
      buildEndpointWithQueryParams(SurveyEndpoints.groupGroupIdMembers(id), { ...params }),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) => data.items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal, page: meta.pageNumber }),
  });

  return { storage };
};

export type GetSurveyGroupMember = ReturnType<typeof getSurveyGroupMember>;

export const getSurveyGroupEffect = createEffect<
  DefaultGroupMailingParams,
  GroupsMailingListItemModel,
  AxiosError
>((params) =>
  getGroupMailing<GroupsMailingListItemModel>(params.groupMailingId).then((response) => response.data),
);

export type GetGroupsMailingMembersStorage = ReturnType<typeof getGroupsMailingMembersStorage>;

export const getSurveyStatisticStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<SurveyQuestionStatistic>,
    SurveyQuestionStatistic[],
    SurveyQuestionStatistic[],
    DefaultSendingSurveyParams
  >({
    endpointBuilder: (params) =>
      SurveyEndpoints.statisticsSurveyInstanceSendingSurveyId(params.sendingSurveyId),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items,
  });

  return { storage };
};

export const getSurveyStatisticCommonStorage = () => {
  const storage = abstractStorageFactory<
    SurveyQuestionStatisticCommonTypes,
    SurveyQuestionStatisticCommonList,
    SurveyQuestionStatisticCommonList,
    DefaultSurveyParams
  >({
    endpointBuilder: (params) => SurveyEndpoints.statisticsSurveySurveyIdCommon(params.surveyId),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: (statistic) =>
      Object.values(SurveyStatisticKeys).map((key) => ({
        label: key,
        value: statistic[key],
      })),
  });

  return { storage };
};

export const getSurveyChoiceStatisticStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<SurveyChoiceDetailsModel>,
    SurveyChoiceDetailsModel[],
    SurveyChoiceDetailsModel[],
    SurveyChoiceParams & Partial<PaginationParams>
  >({
    endpointBuilder: ({ sendingSurveyId, questionId, pageNumber, pageSize }) =>
      buildEndpointWithQueryParams(
        SurveyEndpoints.statisticsSurveyInstanceSendingSurveyIdQuestionQuestionIdComments(
          sendingSurveyId,
          questionId,
        ),
        { pageNumber, pageSize },
      ),
    dataBuilder: (data) => ({ positionChoice: data.positionChoice }),
    requestMethod: 'post',
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items,
  });

  return { storage };
};

export const deleteMemberGroupMailingEffect = createEffect<DeleteMemberParams, unknown, AxiosError>(
  ({ groupMailingId, memberId }) => deleteMemberGroupMailing(groupMailingId, memberId),
);
