import React from 'react';

import { UiSpace, UiSpaceProps } from 'ant/components/ui/space';

export type UiTableFooterProps = UiSpaceProps;

const UiTableFooter: React.FC<UiTableFooterProps> = ({ size, children }) => {
  return <UiSpace size={size}>{children}</UiSpace>;
};

export { UiTableFooter };
