import { Key } from 'react';

import { UiTreeNode } from 'ant/components/ui/tree';

export const getUpdatedTreeData = (list: UiTreeNode[], key: Key, children: UiTreeNode[]): UiTreeNode[] => {
  return list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }

    if (node.children) {
      return {
        ...node,
        children: getUpdatedTreeData(node.children, key, children),
      };
    }

    return node;
  });
};
