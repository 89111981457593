import { useState } from 'react';

interface UseStepsCounter {
  start?: number;
  total: number;
}

export const useStepsCounter = ({ start = 0, total }: UseStepsCounter) => {
  const [current, setCurrentStep] = useState(start);
  const isActive = (step: number) => step === current;

  return {
    next: () => setCurrentStep(current + 1),
    prev: () => setCurrentStep(current - 1),
    current,
    isFirst: current === 0,
    isFinished: current === total - 1,
    navigate: (num: number) => setCurrentStep(num),
    isActive,
  };
};
