import React, { useEffect } from 'react';
import useClipboard from 'react-use-clipboard';

import CopySvg from 'ant/components/svg/copy.svg';
import { UiButton, UiButtonProps } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { message } from 'ant/components/ui/message';

interface ClipboardButtonProps extends Omit<UiButtonProps, 'value' | 'onClick'> {
  value: string;
  successMessage?: string;
  successDuration?: number;
}

export const ClipboardButton: React.FC<ClipboardButtonProps> = React.memo(
  ({ value, successMessage = 'Скопировано', successDuration = 300, ...buttonProps }) => {
    const [isCopied, setCopied] = useClipboard(value, { successDuration });

    useEffect(() => {
      if (isCopied) {
        message.success(successMessage);
      }
    }, [isCopied]);

    return (
      <UiButton
        type="link-secondary"
        onClick={setCopied}
        icon={<UiIcon component={CopySvg} width={20} height={20} />}
        {...buttonProps}
      />
    );
  },
);
