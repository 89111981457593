import { BaseTimeInterval } from 'ant/types/models/base/base-time-date';
import { ProjectId } from 'ant/types/models/projects/projects.model';
import { ProjectsTasksModel } from 'ant/types/models/projects/tasks.model';

export type ProjectsStageId = number;

export enum ProjectsStagesPermissions {
  StageCreate = 'stage_create',
  StageEdit = 'stage_edit',
  StageView = 'stage_view',
}

export interface ProjectsStageModel extends BaseTimeInterval {
  id: ProjectsStageId;
  title: string;
  color: string;
  projectId: ProjectId;
  permissions: ProjectsStagesPermissions[];
  tasks: ProjectsTasksModel[];
  tasksCount: number;
}
