import { Breadcrumb } from 'antd';
import { BreadcrumbProps, Route } from 'antd/es/breadcrumb/Breadcrumb';
import classNames from 'classnames';
import React, { FC } from 'react';

import DropRightSvg from 'ant/components/svg/drop-right.svg';

import styles from './UiBreadcrumb.scss';
import { UiBreadcrumbItem } from './item/UiBreadcrumbItem';

export type UiRoute = Route;

export interface UiBreadcrumbProps extends BreadcrumbProps {
  breadcrumbRoutes: Route[];
  after?: React.ReactNode;
  before?: React.ReactNode;
  emptyMargin?: boolean;
  emptyPadding?: boolean;
  maxItemsAfterOverflow?: number;
}

export const UiBreadcrumb: FC<UiBreadcrumbProps> = (props) => {
  const {
    className,
    after,
    before,
    emptyMargin,
    emptyPadding,
    maxItemsAfterOverflow,
    breadcrumbRoutes,
    ...rest
  } = props;

  const items =
    maxItemsAfterOverflow && breadcrumbRoutes.length > maxItemsAfterOverflow + 1
      ? [
          <UiBreadcrumbItem key={breadcrumbRoutes[0].path} route={breadcrumbRoutes[0]} />,
          <React.Fragment key="overflow">...</React.Fragment>,
          ...breadcrumbRoutes
            .slice(-Number(maxItemsAfterOverflow))
            .map((route: Route) => <UiBreadcrumbItem key={route.path} route={route} />),
        ]
      : breadcrumbRoutes.map((route: Route) => <UiBreadcrumbItem key={route.path} route={route} />);

  return (
    <div
      className={classNames(styles.uiBreadcrumb__wrapper, {
        [styles.uiBreadcrumb__wrapper_shift]: !emptyMargin,
        [styles.uiBreadcrumb__wrapper_emptyPadding]: emptyPadding,
      })}
    >
      {before}
      <Breadcrumb {...rest} separator="" className={classNames(styles.uiBreadcrumb, className)}>
        {items.map((item) => (
          <React.Fragment key={item.key}>
            <Breadcrumb.Item>{item}</Breadcrumb.Item>
            <Breadcrumb.Separator>
              <DropRightSvg />
            </Breadcrumb.Separator>
          </React.Fragment>
        ))}
      </Breadcrumb>
      {after}
    </div>
  );
};
