import React from 'react';

import ClearSVG from 'ant/components/svg/clear.svg';
import { UiButton } from 'ant/components/ui/button/UiButton';
import { UiIcon } from 'ant/components/ui/icon';
import { UiImage, UiImageProps } from 'ant/components/ui/image';

import styles from './ImageActions.scss';

export interface ImageActionsProps extends UiImageProps {
  onClear?: React.MouseEventHandler<HTMLElement>;
  children?: React.ReactNode;
  wrapperClassName?: string;
}

const ImageActions: React.FC<ImageActionsProps> = ({ onClear, children, wrapperClassName, ...args }) => {
  const { width, height } = args;

  return (
    <div className={wrapperClassName} style={{ width, height, position: 'relative' }}>
      <UiImage {...args} />
      <span className={styles.imageActions}>
        {children}
        {onClear && (
          <UiButton
            size="small"
            type="action"
            onClick={onClear}
            icon={<UiIcon width={20} height={20} component={ClearSVG} />}
          />
        )}
      </span>
    </div>
  );
};

export { ImageActions };
