import { AxiosError } from 'axios';
import { createEffect, createEvent, createStore } from 'effector';

import { UiUploadFileStatusType } from 'ant/components/ui/upload';
import {
  BaseFileSizeUploadParams,
  FileSizeUnit,
  getFileSizeUploadError,
} from 'ant/helpers/validation/file-size-helper';
import { api } from 'ant/plugins/api';

type StoreFileId = string;
export type UploadFile<StoreFile> = {
  key: StoreFileId;
  status?: UiUploadFileStatusType;
  file: File;
  fileData: StoreFile;
  errorMessage?: string | null;
};

interface AbstractFilesUploadConfiguration<Response, StoreFile> {
  defaultValue: UploadFile<StoreFile>[];
  endpoint: string;
  sizeValidationConfig?: BaseFileSizeUploadParams;
  dataMapper?: (uploadedFile: Response, beforeUploadFile: StoreFile) => StoreFile;
}

interface AbstractFilesUploadParams<StoreFile> {
  filesToUpload: UploadFile<StoreFile>[];
  appendData?: boolean;
}

const DEFAULT_FILE_SIZE_VALIDATION: BaseFileSizeUploadParams = {
  errorSizeMessage: 'Превышен допустимый размер 2 Гб',
  maxSize: 2,
  unitSize: FileSizeUnit.GB,
};

export const abstractFilesUploadFactory = <Response, StoreFile>(
  params: AbstractFilesUploadConfiguration<Response, StoreFile>,
) => {
  const {
    endpoint,
    defaultValue,
    dataMapper = (value: unknown) => value as StoreFile,
    sizeValidationConfig = DEFAULT_FILE_SIZE_VALIDATION,
  } = params;
  const store = createStore<UploadFile<StoreFile>[]>(defaultValue);
  const uploadFilesEvent = createEvent<AbstractFilesUploadParams<StoreFile>>();
  const removeFilesEvent = createEvent<StoreFileId[]>();
  const refetchFileEvent = createEvent<StoreFileId>();
  const resetFilesEvent = createEvent();

  const validateSizes = (state: UploadFile<StoreFile>[]): UploadFile<StoreFile>[] => {
    return state.map((file) => {
      const errorMessage = getFileSizeUploadError({
        file: file.file,
        ...sizeValidationConfig,
      });

      const status = errorMessage ? UiUploadFileStatusType.Error : file.status;

      return { ...file, status, errorMessage };
    });
  };

  const isFileToUpload = ({ status }: UploadFile<StoreFile>) => {
    const fetchedStatuses = [
      UiUploadFileStatusType.Done,
      UiUploadFileStatusType.Error,
      UiUploadFileStatusType.Success,
    ];

    return fetchedStatuses.every((fetchedStatus) => fetchedStatus !== status);
  };

  const uploadFile = (file: File) => {
    const formData = new window.FormData();

    formData.append('file', file);

    return api.post<Response>({
      url: endpoint,
      data: formData,
    });
  };

  const uploadFileEffect = createEffect<UploadFile<StoreFile>, UploadFile<StoreFile>, AxiosError>(
    (storeData) => {
      if (storeData.status === UiUploadFileStatusType.Error) {
        return Promise.reject(storeData);
      }

      return uploadFile(storeData.file)
        .then(({ data }) => {
          const mappedResult = dataMapper(data, storeData.fileData);

          return {
            ...storeData,
            fileData: mappedResult,
          };
        })
        .catch(() => {
          return Promise.reject(storeData);
        });
    },
  );

  store
    .on(uploadFileEffect.done, (state, { result }) => {
      return state.map((file) =>
        file.key === result.key ? { ...result, status: UiUploadFileStatusType.Done } : file,
      );
    })
    .on(uploadFileEffect.fail, (state, { params: errorFile }) => {
      return state.map((file) =>
        file.key === errorFile.key ? { ...errorFile, status: UiUploadFileStatusType.Error } : file,
      );
    })
    .on(removeFilesEvent, (state, fileIdsToDelete) =>
      state.filter((file) => !fileIdsToDelete.includes(file.key)),
    )
    .on(refetchFileEvent, (state, fileIdToRefetch) => {
      const fileToRefetch = state.find((file) => file.key === fileIdToRefetch);

      if (fileToRefetch) {
        uploadFileEffect(fileToRefetch);
      }
    })
    .reset(resetFilesEvent)
    .on(uploadFilesEvent, (state, { filesToUpload, appendData = true }) => {
      const storeMap = new Map(state.map((file) => [file.key, file]));
      const newFiles = filesToUpload
        .filter((file) => !storeMap.has(file.key))
        .map((file) => ({ ...file, status: UiUploadFileStatusType.Uploading }));

      return appendData ? [...state, ...newFiles] : newFiles;
    })
    .watch(uploadFilesEvent, (state) => {
      validateSizes(state.filter(isFileToUpload)).forEach(uploadFileEffect);
    });

  return { store, uploadFilesEvent, removeFilesEvent, refetchFileEvent, resetFilesEvent, uploadFileEffect };
};

export type FilesUploadStore<StoreFile, Response> = ReturnType<
  typeof abstractFilesUploadFactory<StoreFile, Response>
>;
