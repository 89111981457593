import React, { FC } from 'react';

import CaseSvg from 'ant/components/svg/case.svg';
import OutStaffSvg from 'ant/components/svg/outstaff.svg';
import { UiIcon } from 'ant/components/ui/icon';
import { UiList } from 'ant/components/ui/list';
import { UiSpace } from 'ant/components/ui/space';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography, UiTypographyTextTypes } from 'ant/components/ui/typography';
import { themePalette } from 'ant/theme';
import { UserProfileModel } from 'ant/types/models/user.model';

import { colorTextInvertTooltipStyle } from '../../index';

export type UserProfileInfoBaseProps = {
  user: Pick<UserProfileModel, 'position' | 'timezone' | 'remoteEmployee' | 'memberOf'>;
};

export const UserProfileInfoBase: FC<UserProfileInfoBaseProps> = (props) => {
  const { user } = props;
  const { position, timezone, remoteEmployee, memberOf } = user;

  return (
    <UiSpace direction="vertical" align="start" size={8}>
      {position && <UiTypography.Footnote type="secondary">{position.name}</UiTypography.Footnote>}

      {timezone && (
        <UiTypography.Text underline strong style={colorTextInvertTooltipStyle}>
          {timezone}
        </UiTypography.Text>
      )}

      {remoteEmployee && (
        <UiIcon.Label
          strong
          width={20}
          height={20}
          component={OutStaffSvg}
          label="Внешний сотрудник"
          color={themePalette.colorNegative}
          type={UiTypographyTextTypes.Danger}
        />
      )}

      {memberOf && memberOf.length > 0 && (
        <UiSpace size={8} align="start">
          <UiIcon component={CaseSvg} width={20} height={20} color={themePalette.colorIcon} />
          <UiList
            dataSource={Array.isArray(memberOf) ? memberOf : [memberOf]}
            renderItem={(member) => (
              <UiList.Item style={{ padding: 0, marginBottom: 8 }}>
                <UiTypography.Link underline style={colorTextInvertTooltipStyle}>
                  <UiTruncateMarkup truncate>{member}</UiTruncateMarkup>
                </UiTypography.Link>
              </UiList.Item>
            )}
          />
        </UiSpace>
      )}
    </UiSpace>
  );
};
