import get from 'lodash/get';

function hashMapMaker<T, K extends string | number = string>(
  arr: Array<T>,
  key: keyof T,
  multiple: true,
): Record<K, T[]>;
function hashMapMaker<T, K extends string | number = string>(
  arr: Array<T>,
  key: keyof T,
  multiple?: false,
): Record<K, T>;
function hashMapMaker<T, K extends string | number = string>(
  arr: Array<T>,
  key: keyof T,
  multiple?: undefined,
): Record<K, T>;
function hashMapMaker<T, K extends string | number = string>(
  arr: Array<T>,
  key: keyof T,
  multiple?: boolean,
): Record<K, T> | Record<K, Array<T>> {
  if (multiple) {
    return arr.reduce<Record<K, Array<T>>>((acc, item) => {
      const keyValue = String(get(item, key)) as K;

      if (!acc[keyValue]) {
        acc[keyValue] = [];
      }

      acc[keyValue].push(item);

      return acc;
    }, {} as Record<K, T[]>);
  }

  return arr.reduce<Record<K, T>>((acc, item) => {
    const keyValue = String(get(item, key)) as K;

    acc[keyValue] = item;

    return acc;
  }, {} as Record<K, T>);
}

export { hashMapMaker };
