// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e0a59008_ui-space_flex{display:flex}.e0a59008_ui-space_flex .ant-space-item{display:inline-flex;flex:1}.e0a59008_ui-space_full{width:100%}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/space/UiSpace.scss"],"names":[],"mappings":"AAEE,wBACE,YAAA,CAEA,wCACE,mBAAA,CACA,MAAA,CAIJ,wBACE,UAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.ui-space {\n  &_flex {\n    display: flex;\n\n    :global(.ant-space-item) {\n      display: inline-flex;\n      flex: 1;\n    }\n  }\n\n  &_full {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-space_flex": "e0a59008_ui-space_flex",
	"uiSpace_flex": "e0a59008_ui-space_flex",
	"ui-space_full": "e0a59008_ui-space_full",
	"uiSpace_full": "e0a59008_ui-space_full"
};
export default ___CSS_LOADER_EXPORT___;
