import React, { useCallback, useMemo, FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { UserProfile } from 'ant/components/widgets/UserProfile';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { getProfileStorage } from 'ant/store/profile';
import { KeycloakId } from 'ant/types/models/keycloak-user';

import styles from './UiMarkdownMentionLink.scss';

export interface UiMarkdownMentionLinkProps extends LinkProps {
  userId: KeycloakId;
}

export const UiMarkdownMentionLink: FC<UiMarkdownMentionLinkProps> = (props) => {
  const { userId, children, to, ...linkProps } = props;

  const profileStorage = useMemo(getProfileStorage, [userId]);
  const { data: profileData } = useAbstractStorage(profileStorage.storage, {
    cancelPendingRequestOnUnmount: true,
  });

  const onMouseEnter = useCallback(() => {
    if (!profileData) {
      profileStorage.storage.fetchEffect({ userId });
    }
  }, [profileStorage, userId, profileData]);

  return (
    <UserProfile.Tooltip user={profileData}>
      <Link to={to} className={styles.uiMarkdownMentionLink} {...linkProps} onMouseEnter={onMouseEnter}>
        {children}
      </Link>
    </UserProfile.Tooltip>
  );
};
