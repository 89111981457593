import classNames from 'classnames';
import React, { FC, PropsWithChildren, HTMLAttributes } from 'react';

import styles from './UiCardFooter.scss';

export interface UiCardFooterProps extends Pick<HTMLAttributes<HTMLDivElement>, 'style' | 'className'> {
  emptyPadding?: boolean;
  hasTopBorder?: boolean;
}

export type UiCardFooterButtonsProps = PropsWithChildren &
  Pick<HTMLAttributes<HTMLDivElement>, 'style' | 'className'>;

type FooterComposition = {
  Buttons: FC<UiCardFooterButtonsProps>;
};

export const UiCardFooter: FC<PropsWithChildren<UiCardFooterProps>> & FooterComposition = (props) => {
  const { children, className, emptyPadding = false, hasTopBorder = false, style } = props;

  return (
    <footer
      style={style}
      className={classNames(className, styles.uiCardFooter, {
        [styles.uiCardFooter_emptyPadding]: emptyPadding,
        [styles.uiCardFooter_topBorder]: hasTopBorder,
      })}
    >
      {children}
    </footer>
  );
};

const UiCardFooterButtons: FC<PropsWithChildren<UiCardFooterButtonsProps>> = (props) => {
  const { children, className, style } = props;

  return (
    <div className={classNames(styles.uiCardFooter__buttons, className)} style={style}>
      {children}
    </div>
  );
};

UiCardFooter.Buttons = UiCardFooterButtons;
