import { EventsEndpoints } from 'ant/endpoints/events';
import { api } from 'ant/plugins/api';
import { Endpoints } from 'ant/plugins/endpoints';
import {
  CreateUpdateEventModel,
  CreateUpdateScheduleSlotModel,
  ConfirmEventParticipationModel,
  EventId,
  EventRequestDecideParams,
  EventGuestsCount,
  EventScheduleSlotId,
} from 'ant/types/models/event.model';
import { KeycloakId } from 'ant/types/models/keycloak-user';

export const createNewEvent = <T>(event: CreateUpdateEventModel) =>
  api.post<T>({
    url: EventsEndpoints.eventNew(),
    data: event,
  });

export const updateEvent = <T>(id: EventId, data: CreateUpdateEventModel) =>
  api.put<T>({
    url: EventsEndpoints.event(id),
    data,
  });

export const deleteEvent = (id: EventId) =>
  api.delete({
    url: EventsEndpoints.event(id),
  });

export const createScheduleSlot = <T>(scheduleSlot: CreateUpdateScheduleSlotModel) =>
  api.post<T>({
    url: EventsEndpoints.scheduleSlotNew(),
    data: scheduleSlot,
  });

export const updateScheduleSlot = <T>(id: EventId, data: CreateUpdateScheduleSlotModel) =>
  api.put<T>({
    url: EventsEndpoints.scheduleSlot(id),
    data,
  });

export const deleteScheduleSlot = (id: EventId) =>
  api.delete({
    url: EventsEndpoints.scheduleSlot(id),
  });

export const uploadImage = <T>(image: File) => {
  const formData = new window.FormData();

  formData.append('file', image);

  return api.post<T>({
    url: Endpoints.fileUpload(),
    data: formData,
  });
};

export const confirmEventParticipation = (id: EventId, data: ConfirmEventParticipationModel) =>
  api.post({
    url: EventsEndpoints.eventConfirmParticipation(id),
    data,
  });

export const declineEventParticipation = (id: EventId) =>
  api.post({
    url: EventsEndpoints.eventDeclineParticipation(id),
  });

export const confirmActivityParticipation = (id: EventId, data: ConfirmEventParticipationModel) =>
  api.post({
    url: EventsEndpoints.activityConfirmParticipation(id),
    data,
  });

export const declineActivityParticipation = (id: EventId) =>
  api.post({
    url: EventsEndpoints.activityDeclineParticipation(id),
  });

export const removeMemberFromEvent = ({ eventId, userId }: { eventId: EventId; userId: KeycloakId }) =>
  api.delete<void>({
    url: EventsEndpoints.eventMemberDelete(eventId, userId),
  });

export const copyEvent = <T>({ eventId }: { eventId: EventId }) =>
  api.post<T>({
    url: EventsEndpoints.eventCopy(eventId),
  });

export const eventParticipantsInfo = <T>({ eventId }: { eventId: EventId }) =>
  api.get<T>({
    url: EventsEndpoints.eventParticipantsInfo(eventId),
  });

export const inviteUserToEvent = <T>(eventId: EventId, data: { memberUid: KeycloakId }) =>
  api.post<T>({
    url: EventsEndpoints.eventInviteUsersV2(eventId),
    data,
  });

export const cancelInviteUserToEvent = <T>(eventId: EventId, data: { memberUid: KeycloakId }) =>
  api.delete<T>({
    url: EventsEndpoints.eventInviteUsersV2(eventId),
    data,
  });

export const eventRequestDecide = <T>(eventId: EventId, data: EventRequestDecideParams) =>
  api.patch<T>({
    url: EventsEndpoints.eventRequestDecide(eventId),
    data,
  });

export const updateEventGuestsCount = (eventId: EventId, data: EventGuestsCount) => {
  return api.patch<EventGuestsCount>({
    url: EventsEndpoints.eventGuest(eventId),
    data,
  });
};

export const updateScheduleGuestsCount = (eventId: EventScheduleSlotId, data: EventGuestsCount) => {
  return api.patch<EventGuestsCount>({
    url: EventsEndpoints.scheduleGuest(eventId),
    data,
  });
};
