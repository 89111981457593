import classNames from 'classnames';
import React, { ReactNode, CSSProperties, FC } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiTag, UiTagProps } from 'ant/components/ui/tag';
import { UiTooltipProps } from 'ant/components/ui/tooltip';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { useTheme } from 'ant/theme/provider';
import { OptionBase } from 'ant/types/models/option';

import styles from './UiTagGroup.scss';

export type UiTagGroupProps = {
  tags: OptionBase[];
  onEllipsisClick?: () => void;
  className?: string;
  tagProps?: UiTagProps;
  truncate?: boolean;
  tooltipProps?: UiTooltipProps;
  style?: CSSProperties;
};

export const UiTagGroup: FC<UiTagGroupProps> = (props) => {
  const { tags, onEllipsisClick, className, tagProps, truncate, tooltipProps, style } = props;
  const [{ variables: themeVariables }] = useTheme();

  const ellipsis = (node: ReactNode) => {
    // @ts-expect-error TODO: ждем пока react-truncate-markup обновит типы до react 18
    const renderedNodes = node?.props?.children;

    return (
      <UiButton type="tertiary" size="small" onClick={onEllipsisClick}>
        Еще {tags.length - renderedNodes.length}
      </UiButton>
    );
  };

  return (
    <div style={style} className={classNames(styles.uiTagGroup, className)}>
      {tags.length > 0 && (
        <UiTruncateMarkup
          ellipsis={ellipsis}
          lines={1}
          truncate={Boolean(truncate)}
          lineHeight={30}
          contentStyle={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', width: '100%' }}
          tooltipProps={tooltipProps}
        >
          {tags.map((tag) => (
            <UiTruncateMarkup.Atom key={tag.id}>
              <UiTag
                {...tagProps}
                title={tag.name}
                color={tagProps?.color ? tagProps?.color : themeVariables.colorBrandFaded}
                textColor={tagProps?.textColor ? tagProps?.textColor : themeVariables.colorTextPrimary}
              />
            </UiTruncateMarkup.Atom>
          ))}
        </UiTruncateMarkup>
      )}
    </div>
  );
};
