import { StringifiableRecord } from 'query-string';

import { BaseFieldParams, PaginationParams } from 'ant/types/api';
import { EntityContentData } from 'ant/types/editor';
import { CMSSectionId } from 'ant/types/models/cms.model';
import { OptionBase } from 'ant/types/models/option';
import { PostTypes } from 'ant/types/models/post';
import { ProfileBiographyEducModel, ProfileBiographyLanguagesModel } from 'ant/types/models/profile.model';
import { JobModel, UserPositionId, UserProfileModel } from 'ant/types/models/user.model';

export enum PagesSearchField {
  Name = 'name',
  Body = 'body',
  All = 'all',
}

export type PagesSearchParams = {
  searchField?: PagesSearchField;
  sectionId?: CMSSectionId;
};

export interface ProfileSearchParams
  extends Pick<UserProfileModel, 'townLiveRid' | 'metroStationRid' | 'birthDate'>,
    Pick<JobModel, 'dateEnd'>,
    Pick<ProfileBiographyLanguagesModel, 'levelRid'>,
    Pick<
      ProfileBiographyEducModel,
      'educationTypeId' | 'institutionId' | 'specializationId' | 'qualificationId'
    > {
  birthDayMonth: string;
  birthYear: string;
  hobbyRid: string[];
  languageId: ProfileBiographyLanguagesModel['languageId'][];
  skillRid: string[];
  competenceRid: string[];
  positionId: UserPositionId[];
  divisionRid: string;
  projectId: string[];
}

export type EventSearchParams = {
  isDraft?: boolean;
};

type EntitySearchParams = ProfileSearchParams & EventSearchParams & PagesSearchParams;

export interface SearchParams
  extends Partial<PaginationParams>,
    Pick<BaseFieldParams, 'query'>,
    Partial<EntitySearchParams>,
    StringifiableRecord {
  searchString?: string;
}

export enum SearchAllCategory {
  All = '_all',
}

export enum SearchSingleCategory {
  Post = 'entry',
  Profile = 'profile',
  Comment = 'comment',
  Blog = 'blog',
  Files = 'files',
  News = 'news',
  Event = 'event',
  Tag = 'tag',
  Pages = 'pages',
}

export type SearchCategoryType = SearchSingleCategory | SearchAllCategory;

export type OriginListTyping = {
  type: string;
  title: string;
  listTitle?: string | null;
};

export type SearchListItem = {
  id: string;
  title: string;
  subTitle: string;
  image?: string;
  email?: string;
  isActive?: boolean;
};

export interface CommentSearchListItem extends Omit<SearchListItem, 'id'> {
  id: number;
  text: string;
  entryTitle: string;
  entryText: string;
  entryId: number;
  authorId: string;
}

export interface PostSearchModel extends Omit<SearchListItem, 'id'> {
  id: number;
  cutText: string;
  text: string;
  postTitle: string;
  blogTitle: string;
  blogId: number;
  blogSlug: string | null;
  authorId?: string;
  tags: OptionBase[];
  type: PostTypes.Entry;
  body?: EntityContentData;
}

export interface AttachmentSearchListItem extends Omit<SearchListItem, 'subTitle' | 'id'> {
  id: number;
  url: string;
  date: string;
  size: number;
}
