import classNames from 'classnames';
import React from 'react';

import UserBlockedAvatarPlaceholderSvg from 'ant/components/svg/user-blocked-avatar-placeholder.svg';
import { UiAvatar } from 'ant/components/ui/avatar';
import { BaseDataType } from 'ant/types/models/option';
import { UserAvatarModel } from 'ant/types/models/user.model';

import { UiMultiSelectItemProps } from '../item/UiMultiSelectItem';
import styles from './UiMultiSelectContentAvatar.scss';

export interface ContentAvatarDataType extends BaseDataType, Partial<Pick<UserAvatarModel, 'avatar'>> {
  isRejected?: boolean;
  isActive?: boolean;
}

export type UiMultiSelectContentAvatarProps = UiMultiSelectItemProps<string, ContentAvatarDataType>;

export const UiMultiSelectContentAvatar = (props: UiMultiSelectContentAvatarProps) => {
  const { data, isTransparent } = props;
  const { avatar, selectedLabel, isRejected, isActive } = data;

  const avatarProps = isActive
    ? { src: avatar, size: 24 }
    : { size: 24, disabled: true, icon: <UserBlockedAvatarPlaceholderSvg /> };

  return (
    <>
      <UiAvatar {...avatarProps} className={styles.uiMultiSelectContentAvatar__avatar} />
      <span
        className={classNames(styles.uiMultiSelectContentAvatar__label, {
          [styles.uiMultiSelectContentAvatar__label_transparent]: isTransparent,
          [styles.uiMultiSelectContentAvatar__label_rejected]: isRejected,
        })}
      >
        {selectedLabel}
      </span>
    </>
  );
};
