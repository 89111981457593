// @ts-expect-error Нет типов
import petrovich from 'petrovich';

import { Gender, UserModel } from 'ant/types/models/user.model';

export enum PetrovichGenders {
  Male = 'male',
  Female = 'female',
  androgynous = 'androgynous',
}

const genderMap = new Map([
  [Gender.Male, PetrovichGenders.Male],
  [Gender.Female, PetrovichGenders.Female],
]);

export enum DeclensionCases {
  Nominative = 'nominative',
  Genitive = 'genitive',
  Dative = 'dative',
  Accusative = 'accusative',
  Instrumental = 'instrumental',
  Prepositional = 'prepositional',
}

interface GenderField {
  gender: PetrovichGenders | undefined;
}

interface FirstNameRequired extends GenderField {
  first: string;
  last?: string;
  middle?: string;
}

interface LastNameRequired extends GenderField {
  first?: string;
  last: string;
  middle?: string;
}

interface MiddleNameRequired extends GenderField {
  first?: string;
  last?: string;
  middle: string;
}

export type AtLeastOneName = FirstNameRequired | LastNameRequired | MiddleNameRequired;

export const declense = (
  person: AtLeastOneName,
  personCase: DeclensionCases = DeclensionCases.Dative,
): AtLeastOneName => {
  return petrovich(person, personCase);
};

export const declenseFirstName = (firstName: string, gender?: Gender): string => {
  const person = {
    first: firstName,
    gender: gender ? genderMap.get(gender) : PetrovichGenders.Male,
  };
  const declensed = declense(person);

  return declensed.first || '';
};

type FullNameOptions = Pick<UserModel, 'firstName' | 'lastName' | 'gender'>;

export const declenseFullName = (options: FullNameOptions, personCase = DeclensionCases.Dative) => {
  const { firstName, lastName, gender } = options;

  if (!firstName || !lastName) {
    return '';
  }

  const person = {
    first: firstName,
    last: lastName,
    gender: gender ? genderMap.get(gender) : PetrovichGenders.Male,
  };

  const declensed = declense(person, personCase);

  return `${declensed.first} ${declensed.last}`;
};
