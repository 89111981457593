import { Rate, RateProps } from 'antd';
import classNames from 'classnames';
import React from 'react';

import FavoriteSvg from 'ant/components/svg/favorite.svg';
import { UiIcon } from 'ant/components/ui/icon';

import defaultStyles from '../UiRate.scss';
import styles from './UiRateStar.scss';

const iconClassName = classNames(styles.uiRateStar__icon, defaultStyles.uiRate__icon);

const UiRateStar: React.FC<RateProps> = (props) => {
  return (
    <Rate
      className={styles.uiRateStar}
      character={<UiIcon width={16} height={16} className={iconClassName} component={FavoriteSvg} />}
      {...props}
    />
  );
};

export { UiRateStar };
