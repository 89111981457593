import { useMemo } from 'react';

import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { getProfileStorage } from 'ant/store/profile';
import { KeycloakId } from 'ant/types/models/keycloak-user';

/**
 * Используется для всех кроме currentUser.
 * Данные для currentUser брать из /main-frontend/packages/ant/src/plugins/currentProfileService.ts
 */
export const useProfileStorage = ({ id }: { id?: KeycloakId }) => {
  const { storage: singleStorage } = useMemo(getProfileStorage, []);

  const { data, loading: profilesLoading } = useAbstractStorage(singleStorage, {
    autoFetchAndRefetch: Boolean(id),
    autoFetchParams: { userId: id || '' },
  });

  const loading = !data || profilesLoading;

  return {
    data,
    loading,
  };
};
