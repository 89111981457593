import { Pie, PieConfig } from '@ant-design/charts';
import React, { useMemo } from 'react';

import { themePalette, themeSizes } from 'ant/theme';
import { useTheme } from 'ant/theme/provider';

type Props = Partial<PieConfig>;

const UiChartPie = (props: Props) => {
  const [{ variables: themeVariables }] = useTheme();
  const { data } = props;

  const total = useMemo(() => {
    return data?.reduce((acc, item) => acc + item.value, 0) || 1;
  }, [data]);

  // TODO:: При клике на один из вариантов ответа легенда улетает вправо (Donut) из за offset
  const defaultPieConfig: PieConfig = {
    tooltip: {
      domStyles: {
        'g2-tooltip': {
          backgroundColor: themeVariables.colorTextPrimary,
          fontSize: parseInt(themeSizes.heading4FontSize, 10),
        },
        'g2-tooltip-value': {
          fontWeight: 600,
          color: themeVariables.colorBgPrimary,
        },
        'g2-tooltip-list': {
          color: themeVariables.colorBgPrimary,
        },
        'g2-tooltip-marker': {
          display: 'none',
        },
        'g2-tooltip-title': {
          display: 'none',
        },
      },
      offset: 0,
      formatter: (value) => ({ value: `${((value.value / total) * 100).toFixed(1)}%`, name: value.type }),
    },
    data: [],
    color: [
      themePalette.colorAccidentBlue,
      themePalette.colorAccidentBlue2,
      themePalette.colorAccidentCoral,
      themePalette.colorAccidentCyan,
      themePalette.colorAccidentGold,
      themePalette.colorAccidentBlueDark,
      themePalette.colorAccidentLavender,
      themePalette.colorAccidentLime,
      themePalette.colorAccidentMagenta,
      themePalette.colorAccidentOrange,
      themePalette.colorAccidentPink,
      themePalette.colorAccidentRed,
      themePalette.colorAccidentSea,
      themePalette.colorAccidentTagSilver,
      themePalette.colorAccidentTurquoise,
    ],
    renderer: 'svg',
    width: 1200,
    statistic: {
      title: {
        customHtml: () => 'всего',
        style: {
          color: themeVariables.colorTextTertiary,
          fontSize: '13px',
        },
      },
      content: {
        style: {
          fontSize: '32px',
          fontWeight: 700,
        },
      },
    },
    legend: {
      offsetX: -300,
      text: {
        style: {
          fontSize: parseInt(themeSizes.heading4FontSize, 10),
          color: themeVariables.colorTextSecondary,
        },
      },
    },
    radius: 0.8,
    innerRadius: 0.75,
    padding: 'auto',
    angleField: 'value',
    colorField: 'type',
    label: {
      type: 'inner',
      autoRotate: false,
      style: {
        opacity: 0,
      },
    },
  };

  return <Pie {...defaultPieConfig} {...props} />;
};

export { UiChartPie };
