import React, { PropsWithChildren, FC } from 'react';

import { UiImage, UiImageProps } from 'ant/components/ui/image';

import styles from './UiActivityListItem.scss';
import { UiActivityListItemSkeleton } from './skeleton/UiActivityListItemSkeleton';

export enum UiActivityListItemSize {
  Small,
  Medium,
}

export type Props = {
  size: UiActivityListItemSize;
  srcImage: UiImageProps['src'];
};

export interface ActivityListItemProps extends FC<PropsWithChildren<Props>> {
  Skeleton: typeof UiActivityListItemSkeleton;
}

export const UiActivityListItemImageSize = {
  small: {
    width: 188,
    height: 112,
  },
  medium: {
    width: 250,
    height: 160,
  },
};

const UiActivityListItem: ActivityListItemProps = ({ size, srcImage, children }) => {
  return (
    <section className={styles.activityListItem}>
      <UiImage
        src={srcImage}
        placeholderSize={80}
        {...(size === UiActivityListItemSize.Small
          ? UiActivityListItemImageSize.small
          : UiActivityListItemImageSize.medium)}
      />
      <div className={styles.activityListItem__main}>{children}</div>
    </section>
  );
};

UiActivityListItem.Skeleton = UiActivityListItemSkeleton;

export { UiActivityListItem };
