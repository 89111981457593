import { Avatar } from 'antd';
import { GroupProps } from 'antd/lib/avatar/group';
import classNames from 'classnames';
import React, { Children, FC, PropsWithChildren } from 'react';

import { UiAvatar, UiAvatarSize } from 'ant/components/ui/avatar';
import { UiTypography } from 'ant/components/ui/typography';

import styles from './UiAvatarGroup.scss';

type UiAvatarAnySize = UiAvatarSize | number;

export interface UiAvatarGroupProps extends Omit<GroupProps, 'size'> {
  maxIcon?: React.ReactNode;
  onClickSuffix?: () => void;
  bordered?: boolean;
  size?: UiAvatarAnySize;
}

export const UiAvatarGroup: FC<PropsWithChildren<UiAvatarGroupProps>> = ({
  children,
  className,
  maxCount,
  maxIcon,
  onClickSuffix,
  bordered = true,
  size = UiAvatarSize.S,
  maxPopoverTrigger = 'click',
  maxPopoverPlacement = 'bottom',
  ...props
}) => {
  const childrenCount = Children.count(children);

  const AvatarContainer: FC<PropsWithChildren> = ({ children: node }) => (
    <Avatar.Group
      size={size}
      maxCount={maxIcon || onClickSuffix ? undefined : maxCount}
      maxPopoverPlacement={maxPopoverPlacement}
      maxPopoverTrigger={maxPopoverTrigger}
      className={classNames(
        styles.uiAvatarGroup,
        {
          [styles.uiAvatarGroup_bordered]: bordered,
        },
        className,
      )}
      {...props}
    >
      {node}
    </Avatar.Group>
  );

  if (maxCount && maxCount < childrenCount && (maxIcon || onClickSuffix)) {
    const childrenShow = Children.toArray(children).slice(0, maxCount);

    const suffix = maxIcon ? (
      <UiAvatar size={size} icon={maxIcon} className={styles.uiAvatarGroup_withIcon} />
    ) : (
      <Avatar size={size}>{`+${childrenCount - maxCount}`}</Avatar>
    );

    return (
      <AvatarContainer>
        {childrenShow}
        <UiTypography.Link onClick={onClickSuffix}>{suffix}</UiTypography.Link>
      </AvatarContainer>
    );
  }

  return <AvatarContainer>{children}</AvatarContainer>;
};
