import classNames from 'classnames';
import React, { FC, useMemo, PropsWithChildren } from 'react';

import { UiCheckbox, UiCheckboxGroupProps, UiCheckboxValueType } from 'ant/components/ui/checkbox';
import { UiPoll } from 'ant/components/ui/poll';
import { UiRadio, UiRadioChangeEvent, UiRadioGroupProps } from 'ant/components/ui/radio';

import {
  UiPollButtonTypes,
  UiPollButtonTypesProps,
  UI_POLL_BUTTON_TYPE_DEFAULT,
} from '../poll-button/UiPollButton';
import styles from './UiPollGroup.scss';

export type UiPollGroupGroupTypeProps = UiRadioGroupProps | UiCheckboxGroupProps;

export type UiPollGroupRadio = {
  value?: string;
  type?: UiPollButtonTypes.Single;
};
export type UiPollGroupCheckbox = {
  value?: string[];
  type: UiPollButtonTypes.Multiple;
};
export type UiPollGroupOption = {
  value?: string;
  label?: React.ReactNode;
};
export type UiPollGroupValue = (UiPollGroupCheckbox | UiPollGroupRadio)['value'];
export type UiPollGroupBaseProps = UiPollButtonTypesProps & {
  options?: UiPollGroupOption[];
  onChange?: (value: UiPollGroupValue) => void;
  className?: string;
};
type UiPollGroupTypeProps = UiPollGroupRadio | UiPollGroupCheckbox;
type ChangeHandlerValue = UiCheckboxValueType[] | UiRadioChangeEvent;
export type UiPollGroupProps = UiPollGroupTypeProps & UiPollGroupBaseProps & PropsWithChildren;

const UiPollGroup: FC<UiPollGroupProps> = ({ children, ...props }) => {
  const { type = UI_POLL_BUTTON_TYPE_DEFAULT, options, onChange, className } = props as UiPollGroupBaseProps;
  const { value: singleValue } = props as UiPollGroupRadio;
  const { value: multipleValue } = props as UiPollGroupCheckbox;

  const isType = (typeValue: UiPollButtonTypes) => type === typeValue;
  const changeHandler = (selectValue: ChangeHandlerValue) => {
    let newValue = selectValue;

    if (isType(UiPollButtonTypes.Single) && !Array.isArray(selectValue)) {
      newValue = (newValue as UiRadioChangeEvent)?.target?.value;
    }

    onChange?.(newValue as UiPollGroupValue);
  };

  const childrenOptions = useMemo(
    () =>
      children ||
      options?.map((option) => (
        <UiPoll.Button key={option.value} type={type} {...option}>
          {option.label}
        </UiPoll.Button>
      )),
    [],
  );

  const groupProps = {
    onChange: changeHandler,
    className: classNames(styles.uiPollGroup, className),
  };

  if (isType(UiPollButtonTypes.Single)) {
    return (
      <UiRadio.Group value={singleValue} {...groupProps}>
        {childrenOptions}
      </UiRadio.Group>
    );
  }

  if (isType(UiPollButtonTypes.Multiple)) {
    return (
      <UiCheckbox.Group value={multipleValue} {...groupProps}>
        {childrenOptions}
      </UiCheckbox.Group>
    );
  }

  return <>{childrenOptions}</>;
};

export { UiPollGroup };
