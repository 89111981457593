import { type Editor, Plugin } from '@ckeditor/ckeditor5-core';
import {
  Element,
  enablePlaceholder,
  type DocumentChangeEvent,
  type DiffItemAttribute,
} from '@ckeditor/ckeditor5-engine';
import { toWidgetEditable } from '@ckeditor/ckeditor5-widget';

import { VideoUtils } from '../videoutils';
import { ToggleVideoCaptionCommand } from './togglevideocaptioncommand';
import { VideoCaptionUtils } from './videocaptionutils';

export class VideoCaptionEditing extends Plugin {
  public static get requires() {
    return [VideoUtils, VideoCaptionUtils] as const;
  }

  public static get pluginName() {
    return 'VideoCaptionEditing' as const;
  }

  private savedCaptionsMap: WeakMap<Element, unknown>;

  constructor(editor: Editor) {
    super(editor);

    this.savedCaptionsMap = new WeakMap();
  }

  public init(): void {
    const { editor } = this;
    const { schema } = editor.model;

    if (!schema.isRegistered('caption')) {
      schema.register('caption', {
        allowIn: 'videoBlock',
        allowContentOf: '$block',
        isLimit: true,
      });
    } else {
      schema.extend('caption', {
        allowIn: 'videoBlock',
      });
    }

    editor.commands.add('toggleVideoCaption', new ToggleVideoCaptionCommand(this.editor));

    this.setupConversion();
    this.registerCaptionReconversion();
  }

  private setupConversion(): void {
    const { editor } = this;
    const { view } = editor.editing;
    const videoUtils: VideoUtils = editor.plugins.get('VideoUtils');
    const videoCaptionUtils: VideoCaptionUtils = editor.plugins.get('VideoCaptionUtils');

    editor.conversion.for('upcast').elementToElement({
      view: (element) => videoCaptionUtils.matchVideoCaptionViewElement(element),
      model: 'caption',
    });

    editor.conversion.for('dataDowncast').elementToElement({
      model: 'caption',
      view: (modelElement, { writer }) => {
        if (!videoUtils.isVideo(modelElement.parent as Element)) {
          return null;
        }

        return writer.createContainerElement('figcaption');
      },
    });

    editor.conversion.for('editingDowncast').elementToElement({
      model: 'caption',
      view: (modelElement, { writer }) => {
        if (!videoUtils.isVideo(modelElement.parent as Element)) {
          return null;
        }

        const figcaptionElement = writer.createEditableElement('figcaption');

        writer.setCustomProperty('videoCaption', true, figcaptionElement);

        figcaptionElement.placeholder = 'Подпись';
        enablePlaceholder({
          view,
          element: figcaptionElement,
          keepOnFocus: true,
        });

        const label = 'Подпись для видео';

        return toWidgetEditable(figcaptionElement, writer, { label });
      },
    });
  }

  public getSavedCaption(videoModelElement: Element): Element | null {
    const jsonObject = this.savedCaptionsMap.get(videoModelElement);

    return jsonObject ? Element.fromJSON(jsonObject) : null;
  }

  public saveCaption(videoModelElement: Element, caption: Element): void {
    this.savedCaptionsMap.set(videoModelElement, caption.toJSON());
  }

  private registerCaptionReconversion(): void {
    const { editor } = this;
    const { model } = editor;
    const videoUtils: VideoUtils = editor.plugins.get('VideoUtils');
    const videoCaptionUtils: VideoCaptionUtils = editor.plugins.get('VideoCaptionUtils');

    model.document.on<DocumentChangeEvent>('change:data', () => {
      const changes = model.document.differ.getChanges();

      for (const change of changes as Array<DiffItemAttribute>) {
        if (change.attributeKey !== 'alt') {
          // eslint-disable-next-line no-continue
          continue;
        }

        const video = change.range.start.nodeAfter as Element;

        if (videoUtils.isVideo(video)) {
          const caption = videoCaptionUtils.getCaptionFromVideoModelElement(video);

          if (!caption) {
            return;
          }

          editor.editing.reconvertItem(caption);
        }
      }
    });
  }
}
