import React, { FC, PropsWithChildren } from 'react';

import { UiCard, UiCardProps } from 'ant/components/ui/card';

import { PostBody } from './body/PostBody';
import { PostFooter } from './footer/PostFooter';
import { PostHeader } from './header/PostHeader';

type Props = UiCardProps;

export type PostComposition = {
  Body: typeof PostBody;
  Footer: typeof PostFooter;
  Header: typeof PostHeader;
};

export type PostComponent = FC<PropsWithChildren<Props>> & PostComposition;

export const Post: PostComponent = (props) => {
  const { children, emptyPadding = true, ...restProps } = props;

  return (
    <UiCard size="default" emptyPadding={emptyPadding} {...restProps}>
      {children}
    </UiCard>
  );
};

Post.Header = PostHeader;
Post.Body = PostBody;
Post.Footer = PostFooter;
