import classnames from 'classnames';
import React, { FC, ReactNode } from 'react';

import SunSvg from 'ant/components/svg/sun.svg';
import {
  UiEmptyFeedMessage,
  UiEmptyMessageType,
} from 'ant/components/ui/empty/feed/message/UiEmptyFeedMessage';
import { UiIcon } from 'ant/components/ui/icon';

import styles from './UiEmptyFeed.scss';

export type UiEmptyFeedProps = {
  emptyMessage?: UiEmptyMessageType;
  bordered?: boolean;
  className?: string;
  emptyPadding?: boolean;
  icon?: ReactNode;
};

type EmptyFeedComposition = {
  Message: typeof UiEmptyFeedMessage;
};

export const UiEmptyFeed: FC<UiEmptyFeedProps> & EmptyFeedComposition = (props) => {
  const { emptyMessage, bordered = false, className, emptyPadding = false, icon } = props;

  const Icon = icon || <UiIcon component={SunSvg} width={120} height={104} />;

  return (
    <div
      className={classnames(styles.emptyFeed, className, {
        [styles.emptyFeed_bordered]: bordered,
        [styles.emptyFeed_emptyPadding]: emptyPadding,
      })}
    >
      {Icon}
      <UiEmptyFeedMessage emptyMessage={emptyMessage} className={styles.emptyFeed_emptyPadding} />
    </div>
  );
};

UiEmptyFeed.Message = UiEmptyFeedMessage;
