// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5f05f413_ui-card-footer{display:flex;align-items:center;padding:24px}._5f05f413_ui-card-footer_empty-padding{padding:0}._5f05f413_ui-card-footer_top-border{border-top:1px solid var(--default-themeVariables-colorStroke)}._5f05f413_ui-card-footer__buttons{display:flex;flex-wrap:nowrap;align-items:center}._5f05f413_ui-card-footer__buttons>:not(:last-child){margin-right:16px}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/card/footer/UiCardFooter.scss","webpack://./../ant/src/assets/scss/variables/content.scss"],"names":[],"mappings":"AACA,0BACE,YAAA,CACA,kBAAA,CACA,YCHY,CDKZ,wCACE,SAAA,CAGF,qCACE,8DAAA,CAGF,mCACE,YAAA,CACA,gBAAA,CACA,kBAAA,CAEA,qDACE,iBAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.ui-card-footer {\n  display: flex;\n  align-items: center;\n  padding: $baseSpacing;\n\n  &_empty-padding {\n    padding: 0;\n  }\n\n  &_top-border {\n    border-top: $spacerBorder;\n  }\n\n  &__buttons {\n    display: flex;\n    flex-wrap: nowrap;\n    align-items: center;\n\n    & > :not(:last-child) {\n      margin-right: 16px;\n    }\n  }\n}\n","$borderRadiusBase: get('themeSizes.borderRadiusBase');\n$baseSpacing: 24px;\n$spacerHeight: 32px;\n$widgetMargin: get('themeOffsets.widgetMargin');\n\n$borderRadius: 8px; // TODO: вынести в тему\n$borderRadiusHalf: 4px; // TODO: вынести в тему\n$borderRadiusQuarter: 2px; // TODO: вынести в тему\n$blockquoteBorderRadius: get('themeLongRead.blockquoteBorderRadius');\n\n$modalOffset: 72px;\n$spacerBorder: 1px solid $colorStroke;\n$dashedBorder: 1px dashed $colorStrokeDark;\n\n$spaceBase: 4px;\n\n$space2XS: $spaceBase;\n$spaceXS: $spaceBase * 2;\n$spaceS: $spaceBase * 3;\n$spaceM: $spaceBase * 4;\n$spaceL: $spaceBase * 5;\n$spaceXL: $spaceBase * 6;\n$space2XL: $spaceBase * 8;\n$space3XL: $spaceBase * 9;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-card-footer": "_5f05f413_ui-card-footer",
	"uiCardFooter": "_5f05f413_ui-card-footer",
	"ui-card-footer_empty-padding": "_5f05f413_ui-card-footer_empty-padding",
	"uiCardFooter_emptyPadding": "_5f05f413_ui-card-footer_empty-padding",
	"ui-card-footer_top-border": "_5f05f413_ui-card-footer_top-border",
	"uiCardFooter_topBorder": "_5f05f413_ui-card-footer_top-border",
	"ui-card-footer__buttons": "_5f05f413_ui-card-footer__buttons",
	"uiCardFooter__buttons": "_5f05f413_ui-card-footer__buttons"
};
export default ___CSS_LOADER_EXPORT___;
