import { ListItemView } from '@ckeditor/ckeditor5-ui';

import type { MentionFeedItem } from '../mention-config';
import type { DomWrapperView } from './DomWrapperView';

class MentionListItemView extends ListItemView {
  public item!: MentionFeedItem;

  public marker!: string;

  public highlight(): void {
    const child = this.children.first as DomWrapperView;

    child.isOn = true;
  }

  public removeHighlight(): void {
    const child = this.children.first as DomWrapperView;

    child.isOn = false;
  }
}

export { MentionListItemView };
