import { EntityContentData } from 'ant/types/editor';
import {
  BlogId,
  BlogManager,
  BlogManagerUser,
  BlogMembersPermissionModel,
  BlogModel,
  BlogPublicTabs,
  BlogRequestId,
  BlogRequestStatus,
  BlogType,
  Role,
} from 'ant/types/models/blog.model';
import { FileStorageImage } from 'ant/types/models/file.model';
import { UserModel } from 'ant/types/models/user.model';

export enum BlogTabs {
  Posts = 'posts',
  Premoderation = 'premoderation',
}

export type EntityId = string | number;

export enum BlogTypes {
  BlogsView = 'blogs-view',
  BlogsViewSlug = 'blogs-view-slug',
  BlogsList = 'blogs-list',
  BlogSubscribe = 'blog-subscribe',
  BlogSubscribeRequest = 'blog-subscribe-request',
}

export enum BlogRestrictedType {
  Open = 'open',
  Restricted = 'restricted',
  Invisible = 'invisible',
}

export enum BlogModerationType {
  WithoutModeration = 0,
  Premoderation = 1,
}

export enum BlogIconType {
  Calendar = 'calendar',
  Edit = 'edit',
  Files = 'files',
  Faq = 'faq',
  Members = 'members',
  Picture = 'picture',
  Statistics = 'statistics',
  Success = 'success',
  UserChecked = 'userChecked',
  UserClear = 'userClear',
  UserFollowing = 'userFollowing',
  BallsMore = 'ballsMore',
  Reply = 'reply',
  Location = 'location',
  Time = 'time',
  Video = 'video',
  Event = 'event',
  Community = 'community',
  BlogIcon = 'blog',
}

export type BlogsListItem = {
  name: string;
  blogTypeCode: string;
  blogTypeName: string;
  createdAt: string;
  createdBy: string;
  description: string;
  fileStorageImageUrl?: string | null;
  id: number;
  isSubscribed: boolean;
  isRestricted: boolean;
  requestId: BlogRequestId | null;
  requestStatus: BlogRequestStatus | null;
  shortDescription?: string;
  slug: string;
  totalParticipants: number;
};

export type BlogsSettingsMicropostModel = { updatePeriodMinutes: number | null };
export type BlogsSettingsModel = { microposts: BlogsSettingsMicropostModel };

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Blog {
  export type Member = {
    keycloakId: string;
    division: string | null;
    role: Role;
  } & Omit<UserModel, 'keycloakUser' | 'position'>;

  export type Menu = Partial<Record<BlogIconType, number>>;

  export interface BlogManagerForApi extends Omit<BlogManager, 'user'> {
    userId: BlogManagerUser['id'];
  }

  export interface BlogEditForm extends Partial<BlogMembersPermissionModel>, FileStorageImage {
    name?: string;
    expandedDescription?: EntityContentData;
    shortDescription?: string;
    restrictedType: BlogRestrictedType;
    isPremoderation: boolean;
    slug?: string;
    image?: string;
    feedback?: boolean;
    categoryRid?: string;
    publicTabs: BlogPublicTabs[];
    managers: BlogModel['managers'];
  }

  export interface BlogCreatingForApi extends Partial<BlogMembersPermissionModel> {
    name: string;
    type?: BlogType;
    moderationType: BlogModerationType;
    expandedDescription: EntityContentData;
    shortDescription: string;
    slug?: string;
    linkToProjectObjectives?: string;
    activities?: {
      activity: number;
      functionalActivity: number;
    }[];
    orgStructures?: {
      organisation: number;
      department: number;
    }[];
    attachmentIds?: number[];
    imageById?: number | null;
    feedback?: boolean;
    categoryRid?: string;
    publicTabs: BlogPublicTabs[];
    managers: BlogManagerForApi[];
  }

  export interface BlogEditForApi extends BlogCreatingForApi {
    id?: BlogId;
  }
}
