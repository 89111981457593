import React, { FC } from 'react';

import ClearSvg from 'ant/components/svg/clear.svg';
import { UiOptionData } from 'ant/components/ui/auto-complete';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSelect, UiSelectProps } from 'ant/components/ui/select';

interface AutoCompleteListItemFieldProps extends UiSelectProps<UiOptionData> {
  onRemove: () => void;
  onSearchClear: () => void;
}

export const AutoCompleteListItemField: FC<AutoCompleteListItemFieldProps> = (props) => {
  const { onSearchClear, onRemove, onChange, ...restProps } = props;
  const onSelect = (value: UiOptionData, option: UiOptionData) => {
    onChange?.(value, option);
    onSearchClear();
  };

  const removeHandler = () => {
    onRemove();
    onSearchClear();
  };

  return (
    <>
      <UiSelect
        {...restProps}
        showSearch
        labelInValue
        allowClear={false}
        filterOption={false}
        optionLabelProp="label"
        onSelect={onSelect}
        onChange={onChange}
      />
      <UiButton
        size="middle"
        type="link-secondary"
        onClick={removeHandler}
        icon={<UiIcon component={ClearSvg} width={20} height={20} />}
      />
    </>
  );
};
