import { useLayoutEffect, useRef, useState } from 'react';

import { useWindowDimensions } from './use-window-dimensions';

export const useElementSize = <T extends HTMLElement = HTMLDivElement>(
  dependencies: ReadonlyArray<unknown> = [],
) => {
  const ref = useRef<T>(null);
  const { width: elementWidth, height: elementHeight } = useWindowDimensions({ throttleMs: 200 });

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    if (ref.current?.clientWidth) {
      setWidth(ref.current.clientWidth);
    }
  }, [elementWidth, ...dependencies]);

  useLayoutEffect(() => {
    if (ref.current?.clientHeight) {
      setHeight(ref.current.clientHeight);
    }
  }, [elementHeight, ...dependencies]);

  return { ref, width, height };
};
