// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._269e4812_ui-checkbox-menu{padding:8px}._269e4812_ui-checkbox-menu .ant-popover-arrow{display:none}._269e4812_ui-checkbox-menu__group{display:flex;flex-direction:column}._269e4812_ui-checkbox-menu__group .ant-checkbox-group-item{padding:8px 0}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/checkbox/menu/UiCheckboxMenu.scss","webpack://./../ant/src/assets/scss/variables/content.scss"],"names":[],"mappings":"AACA,4BACE,WCeQ,CDbR,+CACE,YAAA,CAGF,mCACE,YAAA,CACA,qBAAA,CAEA,4DACE,aAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.ui-checkbox-menu {\n  padding: $spaceXS;\n\n  :global(.ant-popover-arrow) {\n    display: none;\n  }\n\n  &__group {\n    display: flex;\n    flex-direction: column;\n\n    :global(.ant-checkbox-group-item) {\n      padding: $spaceXS 0;\n    }\n  }\n}\n","$borderRadiusBase: get('themeSizes.borderRadiusBase');\n$baseSpacing: 24px;\n$spacerHeight: 32px;\n$widgetMargin: get('themeOffsets.widgetMargin');\n\n$borderRadius: 8px; // TODO: вынести в тему\n$borderRadiusHalf: 4px; // TODO: вынести в тему\n$borderRadiusQuarter: 2px; // TODO: вынести в тему\n$blockquoteBorderRadius: get('themeLongRead.blockquoteBorderRadius');\n\n$modalOffset: 72px;\n$spacerBorder: 1px solid $colorStroke;\n$dashedBorder: 1px dashed $colorStrokeDark;\n\n$spaceBase: 4px;\n\n$space2XS: $spaceBase;\n$spaceXS: $spaceBase * 2;\n$spaceS: $spaceBase * 3;\n$spaceM: $spaceBase * 4;\n$spaceL: $spaceBase * 5;\n$spaceXL: $spaceBase * 6;\n$space2XL: $spaceBase * 8;\n$space3XL: $spaceBase * 9;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-checkbox-menu": "_269e4812_ui-checkbox-menu",
	"uiCheckboxMenu": "_269e4812_ui-checkbox-menu",
	"ui-checkbox-menu__group": "_269e4812_ui-checkbox-menu__group",
	"uiCheckboxMenu__group": "_269e4812_ui-checkbox-menu__group"
};
export default ___CSS_LOADER_EXPORT___;
