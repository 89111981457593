export const getFormattedFileSize = (size?: number): string => {
  if (!size) {
    return '';
  }

  const kb = 1024;
  const mb = kb * kb;
  const gb = mb * kb;

  if (size > gb) {
    return `${(size / gb).toFixed(2)} Гб`;
  }

  if (size > mb) {
    return `${(size / mb).toFixed(2)} Мб`;
  }

  if (size > kb) {
    return `${(size / kb).toFixed(2)} Кб`;
  }

  return `${size.toFixed(2)} Б`;
};
