import React from 'react';

import { UiTypography, UiTypographyTextProps } from 'ant/components/ui/typography';

export interface UiDividerDotProps extends UiTypographyTextProps {
  space?: number;
}

export const UiDividerDot: React.FC<UiDividerDotProps> = (props) => {
  const { space = 4, ...rest } = props;
  const spacing = {
    paddingRight: space,
    paddingLeft: space,
  };

  return (
    <UiTypography.Text style={spacing} {...rest}>
      •
    </UiTypography.Text>
  );
};
