import React, { useMemo } from 'react';

import FiltersSVG from 'ant/components/svg/filters.svg';
import { UiCard } from 'ant/components/ui/card';
import { UiCheckbox, UiCheckboxGroupProps, UiCheckboxValueType } from 'ant/components/ui/checkbox';
import { UiEmpty } from 'ant/components/ui/empty';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { createArrayMock } from 'ant/plugins/create-mocks';
import { getNewsFeedStorage, NewsFeedListParams } from 'ant/store/news';

import { NewsListItem } from './item/NewsListItem';
import { NewsListItemLoading } from './loading/NewsListItemLoading';

type NewsListProps = {
  title: React.ReactNode;
  children?: React.ReactNode;
  fetchParams?: Partial<NewsFeedListParams>;
  filter?: {
    value: UiCheckboxValueType[];
    items: UiCheckboxGroupProps['options'];
    onChange: (values: UiCheckboxValueType[]) => void;
  };
};

const MAX_NEWS_COUNTS = 6;
const MOCKS_NEWS_COUNTS = 4;
const newsDataMocks = createArrayMock(MOCKS_NEWS_COUNTS, (_, key) => <NewsListItemLoading key={key} />);

export const newsFeedStorage = getNewsFeedStorage({ shouldAppendData: false });

const NewsList: React.FC<NewsListProps> = ({ title, children, fetchParams, filter }) => {
  const { data: newsFeed, loading } = useAbstractStorage(newsFeedStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: {
      pageSize: MAX_NEWS_COUNTS,
      ...(fetchParams || {}),
    },
    cancelPendingRequestOnUnmount: true,
    resetStoreOnUnmount: true,
  });

  const isFeedEmpty = !loading && !newsFeed?.length;
  const newsFeedData = useMemo(
    () => newsFeed?.map((item) => <NewsListItem key={item.id} newsArticle={item} />),
    [newsFeed],
  );

  return (
    <UiCard emptyPadding>
      <UiCard.Header>
        <UiCard.Header.Title style={{ width: '100%' }}>{title}</UiCard.Header.Title>
        {filter && Boolean(filter.items?.length) && (
          <UiCheckbox.Menu {...filter} icon={FiltersSVG} placement="bottom" />
        )}
      </UiCard.Header>
      <UiCard.Content basePadding style={{ paddingTop: 0 }}>
        {loading && newsDataMocks}
        {!loading && newsFeedData}
        {isFeedEmpty && <UiEmpty.Feed emptyMessage={{ header: 'Нет новостей' }} />}
        {!loading && children}
      </UiCard.Content>
    </UiCard>
  );
};

export { NewsList };
