import { addHours, startOfDay } from 'date-fns';

const DEFAULT_DISPLAYED_SINCE_HOUR = 8;
const DEFAULT_DISPLAYED_TILL_HOUR = 24;

const getWorkingDaySinceTillDateTime = (
  date: Date,
  displayedSinceHour = DEFAULT_DISPLAYED_SINCE_HOUR,
  displayedTillHour = DEFAULT_DISPLAYED_TILL_HOUR,
) => {
  const displayedDurationHours = displayedTillHour - displayedSinceHour;
  const sinceDate = addHours(startOfDay(date), displayedSinceHour);
  const tillDate = addHours(sinceDate, displayedDurationHours);

  return { sinceDate, tillDate, displayedDurationHours };
};

export { getWorkingDaySinceTillDateTime };
