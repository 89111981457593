// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._37fffa52_survey-question-section{padding:16px;border:2px solid rgba(0,0,0,0)}._37fffa52_survey-question-section h3.ant-typography.ant-typography-secondary{margin:0 0 16px}._37fffa52_survey-question-section .ant-card-body{padding:0}", "",{"version":3,"sources":["webpack://./../ant/src/components/widgets/Survey/survey-question/section/SurveyQuestionSection.scss"],"names":[],"mappings":"AAKE,mCACE,YAHe,CAIf,8BALkB,CAQhB,8EACE,eAAA,CAGF,kDACE,SAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.survey-question-section {\n  $borderTransparent: 2px solid transparent;\n  $sectionPadding: 16px;\n\n  & {\n    padding: $sectionPadding;\n    border: $borderTransparent;\n\n    :global {\n      h3.ant-typography.ant-typography-secondary {\n        margin: 0 0 16px;\n      }\n\n      .ant-card-body {\n        padding: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"survey-question-section": "_37fffa52_survey-question-section",
	"surveyQuestionSection": "_37fffa52_survey-question-section"
};
export default ___CSS_LOADER_EXPORT___;
