import classNames from 'classnames';
import React from 'react';

import { UiCheckbox } from 'ant/components/ui/checkbox/UiCheckbox';
import { UiImage, UiImageProps } from 'ant/components/ui/image';
import { UiRadio } from 'ant/components/ui/radio';

import styles from './UiImageOption.scss';

export interface UiImageOptionProps extends UiImageProps {
  textClassName?: string;
  checked?: boolean;
  value?: number;
  onSelect?: () => void;
  type?: 'radio' | 'checkbox';
  text?: string;
}

const UiImageOption: React.FC<UiImageOptionProps> = ({
  src,
  wrapperClassName,
  checked,
  onSelect,
  type = 'checkbox',
  value,
  className,
  children,
  width = 100,
  height = 100,
}) => {
  const ChoiceComponent = type === 'radio' ? UiRadio : UiCheckbox;
  const choiceClassNames = type === 'radio' ? styles.uiImageOption__radio : styles.uiImageOption__checkbox;

  const propsWithValue = value ? { value } : {};

  return (
    <div style={{ height, width }} className={classNames(styles.uiImageOption, wrapperClassName)}>
      <ChoiceComponent className={choiceClassNames} checked={checked} {...propsWithValue} onChange={onSelect}>
        <div className={styles.uiImageOption__childrenWrapper}>{children}</div>
      </ChoiceComponent>
      <UiImage
        width={width}
        height={height}
        alt="cover"
        src={src}
        className={classNames(styles.uiImageOption__img, className)}
      />
    </div>
  );
};

export { UiImageOption };
