import { AxiosResponse, AxiosError } from 'axios';
import { createEffect, createEvent, createStore, sample } from 'effector';

import { BlogsEndpoints } from 'ant/endpoints/blogs';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { PostTypes } from 'ant/types/models/post';
import {
  PostAttachmentModel,
  PostAttachmentTypes,
  PostFormAttachmentId,
} from 'ant/types/models/post-attachment';

import {
  deleteAttachment,
  getAttachmentById,
  ReloadAttachmentByIdParams,
  ReplaceAttachmentByIdParams,
  deleteAttachmentPollById,
  DeleteAttachmentParams,
} from './api';

export type GetPostAttachmentsStorageParams = {
  postType: PostTypes;
  postId: number;
};

type DeleteAttachmentPromise = (params: DeleteAttachmentParams) => Promise<AxiosResponse<unknown>>;

const attachmentDeleteActions: Record<PostAttachmentTypes, DeleteAttachmentPromise> = {
  [PostAttachmentTypes.File]: deleteAttachment,
  [PostAttachmentTypes.Image]: deleteAttachment,
  [PostAttachmentTypes.Poll]: deleteAttachmentPollById,
  [PostAttachmentTypes.Event]: deleteAttachment,
};

export const getPostAttachmentsStorage = ({ postType, postId }: GetPostAttachmentsStorageParams) => {
  const storage = abstractStorageFactory<PostAttachmentModel[], PostAttachmentModel[], PostAttachmentModel[]>(
    {
      endpointBuilder: () => BlogsEndpoints.blogAttachments(postType, postId),
      defaultValue: [],
    },
  );

  const replaceAttachmentByIdEvent = createEvent<ReplaceAttachmentByIdParams>();

  storage.store.on(replaceAttachmentByIdEvent, (state, { attachmentId, attachment: newAttachment }) => {
    const foundAttachmentIndex = state.data.findIndex((attachment) => attachment.id === attachmentId);

    if (foundAttachmentIndex !== -1) {
      const newData = [...state.data];

      newData.splice(foundAttachmentIndex, 1, newAttachment);

      return {
        ...state,
        data: newData,
      };
    }

    return state;
  });

  const reloadAttachmentByIdEffect = createEffect<ReloadAttachmentByIdParams, void, AxiosError>(
    ({ attachmentId }) => {
      // TODO: GETSTATE ПОРОЖДАЕТ ТРУДНООТЛАЖИВАЕМЫЙ ИМПЕРАТИВНЫЙ КОД И СОСТОЯНИЯ ГОНКИ ДАННЫХ https://effector.dev/ru/docs/api/effector/store/
      const attachmentToReload = storage.store
        .getState()
        .data.find((attachment) => attachment.id === attachmentId);

      if (!attachmentToReload) {
        return Promise.resolve();
      }

      const { objectId, id } = attachmentToReload;

      return getAttachmentById({ contentType: postType, objectId, id }).then(({ data: attachment }) => {
        replaceAttachmentByIdEvent({ attachmentId, attachment });
      });
    },
  );

  const removedAttachmentsStore = createStore<PostAttachmentModel[]>([]); // Store для удаление postAttachments при сохранении редактирования поста
  const removeAttachmentByIdEvent = createEvent<{ attachmentId: PostFormAttachmentId }>();
  const clearRemovedAttachmentsEvent = createEvent();
  const saveRemovedAttachmentsEvent = createEvent();

  removedAttachmentsStore
    .on(removeAttachmentByIdEvent, (state, { attachmentId }) => {
      // TODO: GETSTATE ПОРОЖДАЕТ ТРУДНООТЛАЖИВАЕМЫЙ ИМПЕРАТИВНЫЙ КОД И СОСТОЯНИЯ ГОНКИ ДАННЫХ https://effector.dev/ru/docs/api/effector/store/
      const postAttachedData = storage.store.getState().data;
      const attachmentRemove = postAttachedData.find(
        (attachment) =>
          // TODO: это жесткий косяк, разные "конечные" модели в компонентах для работы с postAttachments!
          attachment.id === attachmentId || Number(attachment.attachmentId) === Number(attachmentId),
      );

      return attachmentRemove ? [...state, attachmentRemove] : state;
    })
    .reset(clearRemovedAttachmentsEvent);

  sample({
    clock: saveRemovedAttachmentsEvent,
    source: removedAttachmentsStore,
    fn: (removedAttachments): [DeleteAttachmentPromise, DeleteAttachmentParams][] => {
      return removedAttachments.map(({ attachmentType, id }) => [
        attachmentDeleteActions[attachmentType],
        { id, objectId: postId, contentType: postType } satisfies DeleteAttachmentParams,
      ]);
    },
    target: createEffect((effectsParams: [DeleteAttachmentPromise, DeleteAttachmentParams][]) => {
      return Promise.all(effectsParams.map(([deleteRequest, params]) => deleteRequest(params))).then(() =>
        clearRemovedAttachmentsEvent(),
      );
    }),
  });

  return {
    storage,
    reloadAttachmentByIdEffect,
    removeAttachmentByIdEvent,
    saveRemovedAttachmentsEvent,
    clearRemovedAttachmentsEvent,
  };
};

export type AttachmentsStorage = ReturnType<typeof getPostAttachmentsStorage>;
