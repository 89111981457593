export enum PhoneMask {
  InternationalRu = '$1 ($2) $3-$4-$5',
}

export const RegExpPattern = {
  InternationalRu: /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
};

export const formatPhoneNumber = (number = '', regexp: RegExp, mask = ''): string => {
  if (!number || !regexp || !mask) return '';

  try {
    return number.replace(regexp, mask);
  } catch (e) {
    return '';
  }
};
