import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import isSymbol from 'lodash/isSymbol';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import overEvery from 'lodash/overEvery';
import overSome from 'lodash/overSome';
import { stringify, StringifiableRecord, StringifyOptions } from 'query-string';

const isEmptyReal = overSome(overEvery([isString, isEmpty]), isUndefined, isNull, isNaN);

/**
 * Возвращает строку с уникальными ключами и значениями отличными от пустых
 * Например: "name=Ivan&userId=63d89233cff8"
 * @param object: StringifiableRecord -- ключи и значения
 * @param options?: StringifyOptions -- опции для stringify из query-string
 */
export const getStringifyIdentity = (object: StringifiableRecord, options?: StringifyOptions) =>
  stringify(omitBy(object, overSome(isSymbol, isEmptyReal, isObject)), options);
