import { useEffect } from 'react';

type UseScrollToElementParams = {
  elementId?: string;
  options?: ScrollIntoViewOptions;
  prefix?: string;
};

export type UseScrollToElementResult = {
  scrollElementId?: string;
};

const defaultScrollIntoViewOptions: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'nearest',
  inline: 'center',
};

export const useScrollToElementId = (params: UseScrollToElementParams): UseScrollToElementResult => {
  const { prefix = '', elementId, options = defaultScrollIntoViewOptions } = params;
  const scrollElementId = elementId && `${prefix}${elementId}`;

  useEffect(() => {
    if (scrollElementId) {
      const targetElement = document.getElementById(scrollElementId);

      targetElement?.scrollIntoView(options);
    }
  }, [scrollElementId]);

  return { scrollElementId };
};
