import { Command } from '@ckeditor/ckeditor5-core';
import type { Element, Writer } from '@ckeditor/ckeditor5-engine';

import { VideoBlockEditing } from '../video/videoblockediting';
import type { VideoUtils } from '../videoutils';
import type { VideoCaptionEditing } from './videocaptionediting';
import type { VideoCaptionUtils } from './videocaptionutils';

export class ToggleVideoCaptionCommand extends Command {
  public declare value: boolean;

  public override refresh(): void {
    const { editor } = this;
    const videoCaptionUtils: VideoCaptionUtils = editor.plugins.get('VideoCaptionUtils');
    const videoUtils: VideoUtils = editor.plugins.get('VideoUtils');

    if (!editor.plugins.has(VideoBlockEditing)) {
      this.isEnabled = false;
      this.value = false;

      return;
    }

    const { selection } = editor.model.document;
    const selectedElement = selection.getSelectedElement();

    if (!selectedElement) {
      const ancestorCaptionElement = videoCaptionUtils.getCaptionFromModelSelection(selection);

      this.isEnabled = !!ancestorCaptionElement;
      this.value = !!ancestorCaptionElement;

      return;
    }

    this.isEnabled = videoUtils.isVideo(selectedElement);

    if (!this.isEnabled) {
      this.value = false;
    } else {
      this.value = !!videoCaptionUtils.getCaptionFromVideoModelElement(selectedElement);
    }
  }

  public override execute(options: { focusCaptionOnShow?: boolean } = {}): void {
    const { focusCaptionOnShow } = options;

    this.editor.model.change((writer) => {
      if (this.value) {
        this.hideVideoCaption(writer);
      } else {
        this.showVideoCaption(writer, focusCaptionOnShow);
      }
    });
  }

  private showVideoCaption(writer: Writer, focusCaptionOnShow?: boolean): void {
    const { model } = this.editor;
    const { selection } = model.document;
    const videoCaptionEditing: VideoCaptionEditing = this.editor.plugins.get('VideoCaptionEditing');
    const selectedVideo = selection.getSelectedElement()!;
    const savedCaption = videoCaptionEditing.getSavedCaption(selectedVideo);
    const newCaptionElement = savedCaption || writer.createElement('caption');

    writer.append(newCaptionElement, selectedVideo);

    if (focusCaptionOnShow) {
      writer.setSelection(newCaptionElement, 'in');
    }
  }

  private hideVideoCaption(writer: Writer): void {
    const { editor } = this;
    const { selection } = editor.model.document;
    const videoCaptionEditing: VideoCaptionEditing = editor.plugins.get('VideoCaptionEditing');
    const videoCaptionUtils: VideoCaptionUtils = editor.plugins.get('VideoCaptionUtils');
    let selectedVideo = selection.getSelectedElement()!;
    let captionElement: Element;

    if (selectedVideo) {
      captionElement = videoCaptionUtils.getCaptionFromVideoModelElement(selectedVideo)!;
    } else {
      captionElement = videoCaptionUtils.getCaptionFromModelSelection(selection)!;
      selectedVideo = captionElement!.parent as Element;
    }

    videoCaptionEditing.saveCaption(selectedVideo, captionElement);

    writer.setSelection(selectedVideo, 'on');
    writer.remove(captionElement);
  }
}
