import { createEvent, createStore, Store, Event } from 'effector';

interface AbstractStoreFactoryReturnBase<T> {
  store: Store<T>;
  setStoreEvent: Event<T>;
  resetEvent: Event<void>;
}
interface AbstractStoreFactoryReturnUpdated<T> extends AbstractStoreFactoryReturnBase<T> {
  updateStoreEvent: Event<T>;
}

type AbstractStoreFactoryReturn<T> = T extends object
  ? AbstractStoreFactoryReturnUpdated<T>
  : AbstractStoreFactoryReturnBase<T>;

export const abstractStoreFactory = <T = unknown>(defaultState: T): AbstractStoreFactoryReturn<T> => {
  const store = createStore(defaultState);

  const setStoreEvent = createEvent<T>();
  const updateStoreEvent = createEvent<T>();
  const resetEvent = createEvent();

  store
    .on(setStoreEvent, (_, payload) => payload)
    .on(updateStoreEvent, (state, payload) => {
      return (Array.isArray(state) ? [...state, ...(payload as T[])] : { ...state, ...payload }) as T;
    })
    .reset(resetEvent);

  return { store, setStoreEvent, resetEvent, updateStoreEvent } as AbstractStoreFactoryReturn<T>;
};
