export enum SummaryAnalyticsReportType {
  Mau = 'mau',
  Dau = 'dau',
  Sticky = 'sticky',
  Top = 'top',
  Posts = 'posts',
  Authors = 'authors',
}

export type GetSummaryAnalyticsReportParams = {
  report: SummaryAnalyticsReportType;
  since?: string;
  till?: string;
  isXls?: boolean;
};
