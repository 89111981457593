import { KeycloakId } from 'ant/types/models/keycloak-user';
import { TimelineId } from 'ant/types/models/timelines.model';

export const timelinesUrls = {
  all: () => '/api/timelines/all/',
  blogs: () => '/api/timelines/blogs/',
  blogsSubscribed: () => '/api/timelines/blogs/subscribed/',
  records: (recordId: TimelineId) => `/api/timelines/records/${recordId}/`,
  my: () => '/api/timelines/my/',
  settingsAll: () => '/api/timelines/settings/all/',
  userId: (userId: KeycloakId) => `/api/timelines/user/${userId}/`,
  followingIsSubscribed: () => '/api/timelines/following/is_subscribed/',
  usersIdFollow: (userId: KeycloakId) => `/api/timelines/users/${userId}/follow/`,
  usersIdUnfollow: (userId: KeycloakId) => `/api/timelines/users/${userId}/unfollow/`,
  usersIdFollowers: (userId: KeycloakId) => `/api/timelines/users/${userId}/followers/`,
  usersIdFollowings: (userId: KeycloakId) => `/api/timelines/users/${userId}/followings/`,
  usersIdAllRelationsCounter: (userId: KeycloakId) => `/api/timelines/users/${userId}/all_relations/counter/`,
};
