import { hashMapMaker } from 'ant/plugins/utils/hash-map-maker';
import { PostAttachmentModel, PostAttachmentTypes } from 'ant/types/models/post-attachment';

const attachmentGroupsPriority = [
  PostAttachmentTypes.Poll,
  PostAttachmentTypes.Image,
  PostAttachmentTypes.File,
  PostAttachmentTypes.Event,
];

export type PostBodyAttachmentsGroup = {
  attachmentType: PostAttachmentTypes;
  attachments: PostAttachmentModel[];
};

export const groupAttachments = (attachments: PostAttachmentModel[]): PostBodyAttachmentsGroup[] => {
  const attachmentsByType = hashMapMaker(attachments, 'attachmentType', true);

  return attachmentGroupsPriority.map((attachmentType) => ({
    attachmentType,
    attachments: attachmentsByType[attachmentType] || [],
  }));
};
