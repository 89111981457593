import isBoolean from 'lodash/isBoolean';

import { isBlogPost } from 'ant/types/guards/post';
import { BlogFullModel } from 'ant/types/models/blog.model';
import { PostModel } from 'ant/types/models/post';

export const getPostBlogsMap = (post: PostModel): BlogFullModel[] => {
  if (!isBlogPost(post)) {
    return [];
  }

  if (post.blogsFull ?? Array.isArray(post.blogs)) {
    return Array.isArray(post.blogs) ? post.blogs : post.blogsFull;
  }

  if (post.blogs && !Array.isArray(post.blogs)) {
    return [post.blogs];
  }

  return [];
};

type BlogFullModelKey = keyof Pick<BlogFullModel, 'isComments' | 'isReacted'>;

export const isSomePostBlogsNegativeKey = (post: PostModel, key: BlogFullModelKey): boolean => {
  return getPostBlogsMap(post).some((blog) => isBoolean(blog[key]) && !blog[key]);
};
