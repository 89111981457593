import classnames from 'classnames';
import React, { FC } from 'react';

import { UiCheckboxOptionType } from 'ant/components/ui/checkbox';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSidebarType } from 'ant/components/ui/sidebar/menu/UiSidebarMenu';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';

import styles from './UiSidebarMenuItem.scss';

export type SidebarItem = UiCheckboxOptionType & {
  iconLeft?: SvgrComponent;
  iconRight?: SvgrComponent;
};

type UiSidebarMenuItemProps = {
  item: SidebarItem;
  type: UiSidebarType;
};

export type SidebarMenuItemComponent = FC<UiSidebarMenuItemProps>;

export const UiSidebarMenuItem: FC<UiSidebarMenuItemProps> = ({ item, type }) => {
  const { label, disabled = false, iconLeft, iconRight } = item;

  return (
    <div
      className={classnames(styles.uiSidebarMenuItem, {
        [styles.uiSidebarMenuItem_compact]: type === UiSidebarType.Compact,
        [styles.uiSidebarMenuItem_disabled]: disabled,
      })}
    >
      {iconLeft && (
        <UiIcon.Circle
          className={styles.uiSidebarMenuItem__leftIcon}
          type="primary"
          component={iconLeft}
          width={20}
          height={20}
        />
      )}

      <div className={styles.uiSidebarMenuItem__main}>
        <div className={styles.uiSidebarMenuItem__title}>
          <UiTruncateMarkup lines={2} truncate>
            {label}
          </UiTruncateMarkup>
          {iconRight && (
            <UiIcon
              className={styles.uiSidebarMenuItem__rightIcon}
              component={iconRight}
              width={20}
              height={20}
            />
          )}
        </div>
      </div>
    </div>
  );
};
