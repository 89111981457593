import classNames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

import styles from './UiEmptyFrame.scss';

export type UiEmptyFrameProps = HTMLAttributes<HTMLDivElement>;

export const UiEmptyFrame: FC<UiEmptyFrameProps> = (props) => {
  const { children, className, style } = props;

  return (
    <div style={style} className={classNames(styles.uiEmptyFrame, className)}>
      {children}
    </div>
  );
};
