import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { CommentsEndpoints } from 'ant/endpoints/comments';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import {
  addReactionToComment,
  AddReactionToCommentParams,
  createComment,
  CreateCommentParams,
  deleteComment,
  DeleteCommentParams,
  removeReactionFromComment,
  updateComment,
  UpdateCommentParams,
} from 'ant/store/comments/api';
import { DictPaginated } from 'ant/types/api';
import {
  CommentContentTypes,
  CommentDetailListModel,
  CommentObjectId,
  CommentSaveModel,
} from 'ant/types/models/comment.model';
import { Reaction } from 'ant/types/models/reactions.model';
import { SearchParams } from 'ant/types/models/search.model';

export const getCommentSearchStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<CommentDetailListModel>,
    CommentDetailListModel[],
    CommentDetailListModel[],
    SearchParams
  >({
    endpointBuilder: ({ pageNumber, pageSize }) => {
      return buildEndpointWithQueryParams(CommentsEndpoints.commentSearch(), { pageNumber, pageSize });
    },
    defaultValue: [],
    dataBuilder: ({ searchString }) => ({ searchString }),
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
    requestMethod: 'post',
  });

  return { storage };
};

export type GetCommentListStorageParams = { contentType: CommentContentTypes; objectId: CommentObjectId };
interface ReactOnCommentEffectParams extends AddReactionToCommentParams {
  isLike?: boolean;
}

export const getCommentListStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<CommentDetailListModel>,
    CommentDetailListModel[],
    CommentDetailListModel[],
    GetCommentListStorageParams
  >({
    endpointBuilder: ({ contentType, objectId }) => CommentsEndpoints.commentList(contentType, objectId),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  const { refetchWithLastParams } = storage;

  const createCommentEffect = createEffect<CreateCommentParams, CommentSaveModel, AxiosError>((params) =>
    createComment<CommentSaveModel>(params).then(({ data }) => data),
  );

  const updateCommentEffect = createEffect<UpdateCommentParams, void, AxiosError>((params) =>
    updateComment<CommentSaveModel>(params).then(refetchWithLastParams),
  );

  const deleteCommentEffect = createEffect<DeleteCommentParams, void, AxiosError>((params) =>
    deleteComment(params).then(refetchWithLastParams),
  );

  const reactOnCommentEffect = createEffect<ReactOnCommentEffectParams, Reaction | void, AxiosError>(
    ({ isLike, commentId, reactionId }) => {
      if (isLike) {
        return addReactionToComment({ commentId, reactionId }).then(({ data }) => data);
      }

      return removeReactionFromComment({ commentId }).then(({ data }) => data);
    },
  );

  return {
    storage,
    createCommentEffect,
    updateCommentEffect,
    deleteCommentEffect,
    reactOnCommentEffect,
  };
};

export type CommentListStorage = ReturnType<typeof getCommentListStorage>;
