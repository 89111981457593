import { regexpPatterns } from '../patterns';

/** @example [ Hello ] => [Hello] */
export const normalizeValueTrim = (value: string) => value?.trim();

/** @example [ Hello] => [Hello] */
export const normalizeValueTrimStart = (value: string) => value?.trimStart();

/** @example [+7905R] => [+7905] */
export const normalizeValuePhoneNumber = (value: string, prevValue: string) =>
  regexpPatterns.phoneNumber.test(value) ? value : prevValue;

/** @example [7905] => [+7905] */
export const normalizeValuePhoneNumberCode = (value: string) => (value ? `+${value}` : '');

/** @example [1234R] => [1234] */
export const normalizeValueTrimNumber = (value: string, prevValue: string) =>
  regexpPatterns.number.test(value) ? value?.trim() : prevValue;

/** @example [vk.com/hello] => [hello] */
export const normalizeUrlValueByPrefix = (prefix: string) => (value: string) => {
  const [, normalizedValue] = value.split(prefix);

  return normalizedValue?.trim() || value?.trim();
};

/** @example [hello/] => [hello] */
export const normalizeMessengerValue = (value: string, prevValue: string) => {
  const normalizedValue = regexpPatterns.plainText.test(value) ? value : prevValue;

  return normalizedValue?.trimStart() || value?.trimStart();
};
