import React, { useMemo } from 'react';

import { UiAvatar } from 'ant/components/ui/avatar';
import { UiSpinner } from 'ant/components/ui/spinner';
import {
  SearchOptionComplete,
  SearchOptionCompleteType,
  SearchOptionItem,
} from 'ant/components/widgets/SearchOptionComplete';
import { getFullName } from 'ant/plugins/name-formatters';
import { useProfileOptionsListStorage, useProfileStorage } from 'ant/store/profile/hooks';

export interface UserPickerProps extends Omit<SearchOptionCompleteType, 'value' | 'onChange'> {
  value?: string;
  onChange?: (value: string) => void;
}

export const ProfilePicker: React.FC<UserPickerProps> = (props) => {
  const { value, onChange, disabled, ...rest } = props;
  const { options, setQuerySearch, searchQuery, onUpdate } = useProfileOptionsListStorage();
  const { data: profile, loading } = useProfileStorage({ id: value });
  const isShowSpinner = value && loading && !disabled;

  const autoCompleteValue = useMemo(
    () =>
      profile
        ? {
            id: profile.id,
            name: getFullName(profile.fullName),
          }
        : undefined,
    [profile],
  );

  const onChangeHandler = (option?: SearchOptionItem) => {
    if (option) {
      onChange?.(option.id);
    }
  };

  if (isShowSpinner) {
    return <UiSpinner spinning />;
  }

  return (
    <SearchOptionComplete
      prefix={<UiAvatar size={24} src={profile?.smallAvatar} />}
      size="large"
      placeholder="Выбрать"
      options={options}
      onSearch={setQuerySearch}
      onChange={onChangeHandler}
      searchQuery={searchQuery}
      updateOptionListHandler={onUpdate}
      value={autoCompleteValue}
      disabled={disabled}
      {...rest}
    />
  );
};
