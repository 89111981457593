import { useStore } from 'effector-react';
import React, { FC } from 'react';

import ArrowBackSvg from 'ant/components/svg/arrow-back.svg';
import ArrowFrontSvg from 'ant/components/svg/arrow-front.svg';
import FullscreenSvg from 'ant/components/svg/fullscreen.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiCard } from 'ant/components/ui/card';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiIcon, UiIconProps } from 'ant/components/ui/icon';
import { UiSpinner } from 'ant/components/ui/spinner';
import { UiTypography } from 'ant/components/ui/typography';
import {
  GanttCompact,
  GANTT_PREVIEW_CONTAINER,
  BASE_NAVIGATION_WIDTH,
} from 'ant/components/widgets/Gantt/GanttCompact';
import { GetProjectStagesStorage } from 'ant/store/tasks';
import { themePalette } from 'ant/theme';

interface GanttPreviewProps {
  storages: GetProjectStagesStorage;
  onOpenFullSize: () => void;
}

export const GanttPreview: FC<GanttPreviewProps> = (props) => {
  const { storages, onOpenFullSize } = props;
  const { storage, paramsStore } = storages;
  const isProjectStagesLoading = useStore(storage.fetchEffect.pending);
  const year = storage.getLastRequestParams()?.year || 0;

  const onPlusYear = () => paramsStore.updateStoreEvent({ year: year + 1 });
  const onMinusYear = () => paramsStore.updateStoreEvent({ year: year - 1 });

  const btnIconProps: UiIconProps = {
    width: 20,
    height: 20,
    color: themePalette.colorIcon,
  };

  return (
    <UiSpinner spinning={isProjectStagesLoading}>
      <UiCard size="default" emptyPadding>
        <UiRow wrap={false}>
          <UiRow
            padding={[32, 20]}
            gutter={[24, 24]}
            style={{ width: BASE_NAVIGATION_WIDTH, alignContent: 'flex-start' }}
          >
            <UiCol span={24} style={{ textAlign: 'center' }}>
              <UiTypography.Text type="secondary" strong>
                Этапы проекта
              </UiTypography.Text>
            </UiCol>
            <UiCol span={24}>
              <UiRow justify="space-between">
                <UiButton
                  type="link-secondary"
                  icon={<UiIcon component={ArrowBackSvg} {...btnIconProps} />}
                  onClick={onMinusYear}
                  disabled={isProjectStagesLoading}
                />
                <UiTypography.Title level={3}>{year}</UiTypography.Title>
                <UiButton
                  type="link-secondary"
                  icon={<UiIcon component={ArrowFrontSvg} {...btnIconProps} />}
                  onClick={onPlusYear}
                  disabled={isProjectStagesLoading}
                />
              </UiRow>
            </UiCol>
            <UiCol span={24}>
              <UiButton
                label="На весь экран"
                type="tertiary"
                block
                size="small"
                icon={<UiIcon component={FullscreenSvg} {...btnIconProps} />}
                onClick={onOpenFullSize}
                disabled={isProjectStagesLoading}
              />
            </UiCol>
          </UiRow>
          <UiRow style={GANTT_PREVIEW_CONTAINER}>
            <GanttCompact year={year} storage={storage} />
          </UiRow>
        </UiRow>
      </UiCard>
    </UiSpinner>
  );
};
