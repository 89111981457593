import { UiChartBar } from './bar/UiChartBar';
import { UiChartColumn } from './column/UiChartColumn';
import { UiChartPercentBar } from './percent-bar/UiChartPercentBar';
import { UiChartPie } from './pie/UiChartPie';

export interface UiChartComposition {
  Pie: typeof UiChartPie;
  Bar: typeof UiChartBar;
  Column: typeof UiChartColumn;
  PercentBar: typeof UiChartPercentBar;
}

const UiChart: UiChartComposition = {
  // TODO:: Сделать чарт с калькулируемым оффсетом для Legend при разной ширине чарта
  Pie: UiChartPie,
  Bar: UiChartBar,
  Column: UiChartColumn,
  PercentBar: UiChartPercentBar,
};

export { UiChart };
