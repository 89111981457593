import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { PagesEndpoints } from 'ant/store/pages/endpoints';
import {
  deleteHeaderLogoImage,
  HeaderLogoImageData,
  HeaderLogoImageModel,
  updateHeaderLogoImage,
} from 'ant/theme/header-logo-image/store/api';

export const getHeaderLogoImageStorage = () => {
  const storage = abstractStorageFactory<HeaderLogoImageModel, HeaderLogoImageModel, HeaderLogoImageModel>({
    endpointBuilder: PagesEndpoints.image,
    defaultValue: { fileStorageObject: null },
    cancelPendingRequestOnFetch: true,
  });

  const changeHeaderLogoImageEffect = createEffect<HeaderLogoImageData, HeaderLogoImageModel, AxiosError>(
    (params) => {
      const effect = params.fileStorageObject ? updateHeaderLogoImage(params) : deleteHeaderLogoImage();

      return effect.then(({ data }) => data);
    },
  );

  storage.store.on(changeHeaderLogoImageEffect.done, (state, { result }) => ({
    ...state,
    data: result,
  }));

  return { storage, changeHeaderLogoImageEffect };
};

export const headerLogoImageStorage = getHeaderLogoImageStorage();
