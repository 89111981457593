import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import styles from './UiModalFooter.scss';

const ccn = classNames.bind(styles);

export interface UiModalFooterProps {
  className?: string;
  hasTopBorder?: boolean;
}

export interface UiModalFooterButtonsProps {
  className?: string;
  style?: React.CSSProperties;
}

type FooterComposition = {
  Buttons: FC<PropsWithChildren<UiModalFooterButtonsProps>>;
};

export type ModalFooterComponent = FC<PropsWithChildren<UiModalFooterProps>> & FooterComposition;

export const UiModalFooter: ModalFooterComponent = (props) => {
  const { children, className, hasTopBorder = true } = props;

  const modalFooterClassName = ccn(
    styles.uiModalFooter,
    {
      [styles.uiModalFooter_topBorder]: hasTopBorder,
    },
    className,
  );

  return <div className={modalFooterClassName}>{children}</div>;
};

const UiModalFooterButtons: FC<PropsWithChildren<UiModalFooterButtonsProps>> = (props) => {
  const { children, className, style } = props;

  return (
    <div className={classNames(styles.uiModalFooter__buttons, className)} style={style}>
      {children}
    </div>
  );
};

UiModalFooter.Buttons = UiModalFooterButtons;
