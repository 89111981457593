import get from 'lodash/get';
import map from 'lodash/map';
import toString from 'lodash/toString';

import { UiMentionSuggestionItem } from 'ant/components/ui/mentions';
import { UiOptionData, UiOptionItem } from 'ant/components/ui/select';
import { createArrayMock } from 'ant/plugins/create-mocks';
import { OptionModel } from 'ant/types/models/option';

/**
 * Базовая схема
 *
 * @param valuePath - путь до значения через точку
 * @param labelPath - путь до label через точку
 */
export type OptionsScheme = { valuePath: string; labelPath: string };

// TODO выпилить и передавать явно всегда
export const DEFAULT_OPTION_SCHEME = {
  valuePath: 'id',
  labelPath: 'attributes.name',
};

/**
 * Функция генерации Options для UiSelect инпута
 * @param arr - Массив элеметов
 * @param scheme - Схема
 */
export const generateSelectOptions = <T extends object>(
  arr: T[],
  scheme: OptionsScheme = DEFAULT_OPTION_SCHEME,
): UiOptionItem[] =>
  map(arr, (item) => ({ value: get(item, scheme.valuePath), label: get(item, scheme.labelPath) }));

/**
 * Функция генерации data для UiMention инпута
 * @param arr - Массив элементов
 * @param scheme - Схема
 */
export const generateMentionOptions = <T extends object>(
  arr: T[],
  scheme: OptionsScheme = DEFAULT_OPTION_SCHEME,
): Array<UiMentionSuggestionItem & { data: T }> =>
  map(arr, (item) => ({
    id: get(item, scheme.valuePath),
    display: get(item, scheme.labelPath),
    data: item,
  }));

/**
 * Функция генерации Options для UiSelect инпута с вложенной data
 * @param arr - Массив элеметов
 * @param scheme - Схема
 */
export const generateSelectDataOptions = <T extends object>(
  arr: T[],
  scheme: OptionsScheme = DEFAULT_OPTION_SCHEME,
): Array<UiOptionData & { data: T }> =>
  map(arr, (item) => ({
    value: get(item, scheme.valuePath),
    label: get(item, scheme.labelPath),
    data: item,
  }));

/**
 * Функция генерации Options для UiSelect инпута
 * @param collection - Объект элеметов
 */
export const generateSelectOptionsByCollection = <K extends string, V extends string | number>(
  collection: Record<K, V>,
): UiOptionItem[] => map(collection, (value, key) => ({ value: key as string, label: toString(value) }));

/**
 * Функция генерации Options для UiAutoComplete инпута
 * @param arr - Массив элеметов
 * @param scheme - Схема
 */
export const generateAutoCompleteOptions = <T extends object>(
  arr: T[],
  scheme: OptionsScheme = DEFAULT_OPTION_SCHEME,
): UiOptionData[] =>
  map(arr, (item) => ({
    value: get(item, scheme.labelPath),
    label: get(item, scheme.labelPath),
    key: get(item, scheme.valuePath),
  }));

/**
 * Функция генерации Options для базовых инпутов UiOptionData
 * @param arr - Массив элеметов
 * @param scheme - Схема
 */
export const generateBaseSelectOptions = <T extends object>(
  arr: T[],
  scheme: OptionsScheme = DEFAULT_OPTION_SCHEME,
): UiOptionData[] =>
  map(arr, (item) => ({
    label: get(item, scheme.labelPath),
    value: get(item, scheme.valuePath),
    key: get(item, scheme.valuePath),
  }));

/**
 * Функция генерации одного Option для UiMultiSelect инпута
 * @param item - Элемент
 * @param scheme - Схема
 */
export const generateMultiSelectSingleOption = <T extends object>(
  item: T,
  scheme: OptionsScheme = DEFAULT_OPTION_SCHEME,
): OptionModel => ({
  value: get(item, scheme.valuePath),
  data: { label: get(item, scheme.labelPath), selectedLabel: get(item, scheme.labelPath) },
});

/**
 * Функция генерации Options для UiMultiSelect инпута
 * @param arr - Массив элеметов
 * @param scheme - Схема
 */
export const generateMultiSelectOptions = <T extends object>(
  arr: T[],
  scheme: OptionsScheme = DEFAULT_OPTION_SCHEME,
): OptionModel[] => map(arr, (item) => generateMultiSelectSingleOption(item, scheme));

type GenerateDateOptionsParams = {
  start: number;
  end: number;
};

/**
 * Функция генерации Options с годами для UiSelect
 * @param start - начальный год
 * @param end - последний год
 */
export const generateYearsOptions = ({ start, end }: GenerateDateOptionsParams): UiOptionItem[] =>
  generateSelectOptions(
    createArrayMock(end - start, (_, index) => {
      const yearStep = index + 1;
      const yearValue = yearStep + start;

      return {
        id: yearValue.toString(),
      };
    }),
    { valuePath: 'id', labelPath: 'id' },
  );
