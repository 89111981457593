import { createEffect, createEvent, restore } from 'effector';

export type SaveDraftEvent<DraftType> = (draft: DraftType) => Promise<DraftType>;
export type GetDraftSaveStorageParams<Draft> = { onSaveDraftEffect: SaveDraftEvent<Draft> };

const getDraftSaveStorage = <T>({ onSaveDraftEffect }: GetDraftSaveStorageParams<T>) => {
  const setSavedDraftEvent = createEvent<T | null>();
  const savedDraftStore = restore(setSavedDraftEvent, null);

  const setSavedDraftVisibilityEvent = createEvent<boolean>();
  const savedDraftNotifyVisibilityStore = restore(setSavedDraftVisibilityEvent, false);

  const saveDraftEffect = createEffect(onSaveDraftEffect);

  return {
    saveDraftEffect,
    setSavedDraftEvent,
    savedDraftStore,
    setSavedDraftVisibilityEvent,
    savedDraftNotifyVisibilityStore,
  };
};

export type DraftSaveStorageType = typeof getDraftSaveStorage;

export { getDraftSaveStorage };
