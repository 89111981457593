export const mergeAntDateAndTime = (date: Date, time: Date) => {
  const currentDate = new Date(date);

  const hour = time.getHours();
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();
  const milliseconds = time.getMilliseconds();

  currentDate.setHours(hour);
  currentDate.setMinutes(minutes);
  currentDate.setSeconds(seconds);
  currentDate.setMilliseconds(milliseconds);

  return currentDate;
};
