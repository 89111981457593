import React, { FC } from 'react';

import { GanttContentStage } from 'ant/components/widgets/Gantt/compact/stage/GanttContentStage';
import { useElementSize } from 'ant/helpers/hooks/use-element-size';
import { ProjectsStageModel } from 'ant/types/models/projects';

import { DisplayOptions } from '../../GanttCompact';
import { getYearByMonth } from '../../helpers/gantt-date-helper';
import styles from './GanttCompactContent.scss';

interface Props {
  displayOptions: DisplayOptions;
  stages: ProjectsStageModel[];
}

export const GanttCompactContent: FC<Props> = (props) => {
  const { stages, displayOptions } = props;
  const { year, daySize } = displayOptions;
  const { ref, height } = useElementSize([stages]);
  const months = getYearByMonth(year);

  return (
    <div className={styles.ganttCompactContent__container} style={{ height }}>
      <div className={styles.ganttCompactContent__background}>
        {months.map(({ id, days }) => (
          <div
            key={id}
            className={styles.ganttCompactContent__backgroundStages}
            style={{ minWidth: days * daySize }}
          />
        ))}
      </div>
      <div ref={ref} className={styles.ganttCompactContent__content}>
        {stages.map((item) => (
          <GanttContentStage daySize={daySize} year={year} key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
};
