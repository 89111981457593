// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".f4c505bb_empty-feed{display:flex;flex-direction:column;align-items:center;border-radius:8px;padding:24px}.f4c505bb_empty-feed_empty-padding[class]{padding:0}.f4c505bb_empty-feed_bordered{border:1px dashed #d5d8de}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/empty/feed/UiEmptyFeed.scss","webpack://./../ant/src/assets/scss/variables/content.scss"],"names":[],"mappings":"AACA,qBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,iBAAA,CACA,YCLY,CDOZ,0CACE,SAAA,CAGF,8BACE,yBCDW","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.empty-feed {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  border-radius: $borderRadius;\n  padding: $baseSpacing;\n\n  &_empty-padding[class] {\n    padding: 0;\n  }\n\n  &_bordered {\n    border: $dashedBorder;\n  }\n}\n","$borderRadiusBase: get('themeSizes.borderRadiusBase');\n$baseSpacing: 24px;\n$spacerHeight: 32px;\n$widgetMargin: get('themeOffsets.widgetMargin');\n\n$borderRadius: 8px; // TODO: вынести в тему\n$borderRadiusHalf: 4px; // TODO: вынести в тему\n$borderRadiusQuarter: 2px; // TODO: вынести в тему\n$blockquoteBorderRadius: get('themeLongRead.blockquoteBorderRadius');\n\n$modalOffset: 72px;\n$spacerBorder: 1px solid $colorStroke;\n$dashedBorder: 1px dashed $colorStrokeDark;\n\n$spaceBase: 4px;\n\n$space2XS: $spaceBase;\n$spaceXS: $spaceBase * 2;\n$spaceS: $spaceBase * 3;\n$spaceM: $spaceBase * 4;\n$spaceL: $spaceBase * 5;\n$spaceXL: $spaceBase * 6;\n$space2XL: $spaceBase * 8;\n$space3XL: $spaceBase * 9;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty-feed": "f4c505bb_empty-feed",
	"emptyFeed": "f4c505bb_empty-feed",
	"empty-feed_empty-padding": "f4c505bb_empty-feed_empty-padding",
	"emptyFeed_emptyPadding": "f4c505bb_empty-feed_empty-padding",
	"empty-feed_bordered": "f4c505bb_empty-feed_bordered",
	"emptyFeed_bordered": "f4c505bb_empty-feed_bordered"
};
export default ___CSS_LOADER_EXPORT___;
