import { Plugin } from '@ckeditor/ckeditor5-core';

import { VideoCaptionEditing } from './videocaption/videocaptionediting';
import { VideoCaptionUI } from './videocaption/videocaptionui';

import './theme/videocaption.scss';

export class VideoCaption extends Plugin {
  public static get requires() {
    return [VideoCaptionEditing, VideoCaptionUI] as const;
  }

  public static get pluginName() {
    return 'VideoCaption' as const;
  }
}
