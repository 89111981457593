import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import { UiStatCellBorderType } from '../UiStatCell';
import styles from './UiStatCellFrame.scss';

export type UiStatCellFrameProps = {
  className?: string;
  border?: UiStatCellBorderType;
};

export const UiStatCellFrame: FC<PropsWithChildren<UiStatCellFrameProps>> = (props) => {
  const { children, className, border } = props;

  return (
    <div
      className={classNames(className, styles.uiStatCellFrame, {
        [styles.uiStatCellFrame_bordered]: border === UiStatCellBorderType.Solid,
        [styles.uiStatCellFrame_dashed]: border === UiStatCellBorderType.Dashed,
      })}
    >
      {children}
    </div>
  );
};
