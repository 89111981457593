import { UserModel } from 'ant/types/models/user.model';

export enum PollQuestionTypes {
  SINGLE_CHOICE = 'STRING_SINGLE_CHOICE',
  MULTIPLE_CHOICE = 'STRING_MULTIPLE_CHOICE',
  FREE_ANSWER = 'FREE_ANSWER',
  FREE_FILE = 'FILE_FREE',
}

export enum PollPermissions {
  PollExportPdf = 'poll_export_pdf',
  PollPass = 'poll_pass',
  PollResults = 'poll_results',
  PollClone = 'poll_clone',
  PollComplete = 'poll_complete',
  PollCreate = 'poll_create',
  PollDelete = 'poll_delete',
  PollEdit = 'poll_edit',
  PollExportExcel = 'poll_export_excel',
}

export interface CreatePollOption {
  option: string;
}

export interface PollOptionModel extends CreatePollOption {
  id: number;
  number: number;
  optionAnswersCount?: number;
  answeredByMe?: boolean;
  answerRespondents: UserModel[];
}

export interface BasePollQuestionModel {
  type: PollQuestionTypes;
  text: string;
  options?: CreatePollOption[];
}

export interface PollFormQuestion extends Omit<BasePollQuestionModel, 'options'> {
  options?: string[];
}

export interface PollQuestionModel extends Omit<BasePollQuestionModel, 'options'> {
  id: number;
  number: number;
  options?: PollOptionModel[];
  answersCount?: number;
}

export interface PollForm {
  title: string;
  isAnonymous?: boolean;
  isMultipleChoice?: boolean;
  isLimited?: boolean;
  limitDate?: Date;
  limitHour?: Date;
  questions: PollFormQuestion[];
}

export interface CreateUpdatePollModel {
  title: string;
  isAnonymous?: boolean;
  isMultipleChoice?: boolean;
  moderators: string[];
  dateEnd?: string;
  questions: BasePollQuestionModel[];
  isClosed?: boolean;
}

export type PollId = number;

export interface PollModel extends CreateUpdatePollModel {
  id: PollId;
  author: UserModel;
  questions: PollQuestionModel[];
  votedRespondentsCount: number;
  userHasResult: boolean;
  hasResults: boolean;
  publishedAt?: string;
  isAuthor?: boolean;
  permissions?: PollPermissions[];
  totalAnswersCount: number;
}

export interface VoteModel {
  question: number;
  option?: number;
  value?: string;
}

export interface PollVoter {
  id: number;
  src: string;
}
