// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9d0e6278_ui-card_empty-padding .ant-card-head{padding:0;min-height:auto}._9d0e6278_ui-card_empty-padding .ant-card-head .ant-card-head-wrapper{padding:0}._9d0e6278_ui-card_empty-padding .ant-card-head .ant-card-head-wrapper .ant-card-head-title,._9d0e6278_ui-card_empty-padding .ant-card-head .ant-card-head-wrapper .ant-card-extra{padding:0}._9d0e6278_ui-card_shadow_small.ant-card:not(.ant-card-hoverable:hover){box-shadow:0px 1px 2px rgba(46,48,51,.08)}._9d0e6278_ui-card_shadow_middle.ant-card:not(.ant-card-hoverable:hover){box-shadow:0px 2px 4px rgba(46,48,51,.08)}._9d0e6278_ui-card_shadow_large.ant-card:not(.ant-card-hoverable:hover){box-shadow:0px 4px 8px rgba(46,48,51,.08)}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/card/UiCard.scss"],"names":[],"mappings":"AAUM,gDACE,SAAA,CACA,eAAA,CAEA,uEACE,SAAA,CAEA,mLAEE,SAAA,CASN,wEACE,yCA5BK,CA2BP,yEACE,yCA5BK,CA2BP,wEACE,yCA5BK","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n$shadowsMap: (\n  'small': $shadow2px,\n  'middle': $shadow4px,\n  'large': $shadow8px,\n);\n\n.ui-card {\n  &_empty-padding {\n    :global {\n      .ant-card-head {\n        padding: 0;\n        min-height: auto;\n\n        .ant-card-head-wrapper {\n          padding: 0;\n\n          .ant-card-head-title,\n          .ant-card-extra {\n            padding: 0;\n          }\n        }\n      }\n    }\n  }\n\n  &_shadow {\n    @each $name, $shadow in $shadowsMap {\n      &_#{$name}:global(.ant-card):not(:global(.ant-card-hoverable:hover)) {\n        box-shadow: $shadow;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-card_empty-padding": "_9d0e6278_ui-card_empty-padding",
	"uiCard_emptyPadding": "_9d0e6278_ui-card_empty-padding",
	"ui-card_shadow_small": "_9d0e6278_ui-card_shadow_small",
	"uiCard_shadow_small": "_9d0e6278_ui-card_shadow_small",
	"ui-card_shadow_middle": "_9d0e6278_ui-card_shadow_middle",
	"uiCard_shadow_middle": "_9d0e6278_ui-card_shadow_middle",
	"ui-card_shadow_large": "_9d0e6278_ui-card_shadow_large",
	"uiCard_shadow_large": "_9d0e6278_ui-card_shadow_large"
};
export default ___CSS_LOADER_EXPORT___;
