import { EntityId } from 'ant/types/blogs';
import { KeycloakId } from 'ant/types/models/keycloak-user';

const getEventsUrl = (url: string) => `/api/events/${url}`;

export class EventsEndpoints {
  static eventNew = () => getEventsUrl('event/new/');

  static event = (id: EntityId) => getEventsUrl(`event/${id}/`);

  static scheduleSlotNew = () => getEventsUrl('activity/new/');

  static scheduleSlot = (id: EntityId) => getEventsUrl(`activity/${id}/`);

  static eventsList = () => getEventsUrl('list/');

  static eventConfirmParticipation = (id: EntityId) => getEventsUrl(`event/${id}/confirm_participation/`);

  static eventDeclineParticipation = (id: EntityId) => getEventsUrl(`event/${id}/decline_participation/`);

  static activityConfirmParticipation = (id: EntityId) =>
    getEventsUrl(`activity/${id}/confirm_participation/`);

  static activityDeclineParticipation = (id: EntityId) =>
    getEventsUrl(`activity/${id}/decline_participation/`);

  static scheduleSlotsList = (eventId: EntityId) => getEventsUrl(`event/${eventId}/activity/list/`);

  static eventMembersV2 = (eventId: EntityId) => getEventsUrl(`event/${eventId}/v2/members/`);

  static eventInviteUsersV2 = (eventId: EntityId) => getEventsUrl(`event/${eventId}/v2/invite_users/`);

  static eventRequestUsersV2 = (eventId: EntityId) => getEventsUrl(`event/${eventId}/v2/request_users/`);

  static eventUsers = (eventId: EntityId) => getEventsUrl(`event/${eventId}/users/`);

  static eventGuest = (eventId: EntityId) => getEventsUrl(`event/${eventId}/guest/`);

  static scheduleGuest = (id: EntityId) => getEventsUrl(`activity/${id}/guest/`);

  static eventRequestDecide = (eventId: EntityId) => getEventsUrl(`event/${eventId}/decide/`);

  static eventMemberDelete = (eventId: EntityId, userId: KeycloakId) =>
    getEventsUrl(`event/${eventId}/members/${userId}/`);

  static eventCopy = (eventId: EntityId) => getEventsUrl(`event/${eventId}/copy/`);

  static eventParticipantsInfo = (eventId: EntityId) => getEventsUrl(`event/${eventId}/participants_info/`);

  static eventAdminList = () => getEventsUrl(`admin_roles/list/`);

  static eventAdmin = (keycloakId: KeycloakId) => getEventsUrl(`admin_roles/${keycloakId}/`);

  static eventAdminSave = () => getEventsUrl(`admin_roles/save/`);
}
