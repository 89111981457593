import React, { FC } from 'react';

import { UiCol } from 'ant/components/ui/grid';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiSpace } from 'ant/components/ui/space';

import { LayoutContentHeaderSize } from '../LayoutContentHeader';
import styles from '../LayoutContentHeader.scss';

interface Props {
  size: LayoutContentHeaderSize;
}

export const LayoutContentHeaderSkeleton: FC<Props> = ({ size }) => {
  const isHeaderSize = (headerSize: LayoutContentHeaderSize) => size === headerSize;

  return (
    <UiSpace size={isHeaderSize(LayoutContentHeaderSize.Small) ? 12 : 20} flex align="center">
      <UiSkeleton circle loading width={64} height={64} />
      <UiCol className={styles.layoutContentHeader__name}>
        <UiSkeleton loading width={80} height={20} />
        <UiSkeleton loading width={250} height={isHeaderSize(LayoutContentHeaderSize.Small) ? 20 : 32} />
      </UiCol>
    </UiSpace>
  );
};
