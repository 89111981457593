import classNames from 'classnames';
import React, { FC } from 'react';

import { UiUser } from 'ant/components/ui/user';
import { UiUserInfoSkeletonProps } from 'ant/components/ui/user/info/skeleton/UiUserInfoSkeleton';

import styles from '../UiLinkListItem.scss';
import { DEFAULT_LINK_LIST_ITEM } from '../index';

const UiLinkListItemSkeleton: FC<UiUserInfoSkeletonProps> = (props) => {
  const { className, size = DEFAULT_LINK_LIST_ITEM.size, ...restProps } = props;

  return (
    <UiUser.Info.Skeleton
      className={classNames(styles.uiLinkListItem, className)}
      size={size}
      {...restProps}
    />
  );
};

export { UiLinkListItemSkeleton };
