// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._010a9495_ui-upload-file-list-item{border-left:2px solid var(--default-themeVariables-colorStroke);padding:8px 16px}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/upload-file-list/item/UiUploadFileListItem.scss"],"names":[],"mappings":"AACA,oCACE,+DAAA,CACA,gBAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.ui-upload-file-list-item {\n  border-left: 2px solid $colorStroke;\n  padding: $spaceXS $spaceM;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-upload-file-list-item": "_010a9495_ui-upload-file-list-item",
	"uiUploadFileListItem": "_010a9495_ui-upload-file-list-item"
};
export default ___CSS_LOADER_EXPORT___;
