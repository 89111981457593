import { EntityId } from 'ant/types/blogs';
import { ReactionNewsContentType } from 'ant/types/models/reactions.model';

const getNewsUrl = (url: string) => `/api/news/${url}`;

export class NewsEndpoints {
  static feed = () => getNewsUrl('feed/');

  static article = (id: number) => getNewsUrl(`${id}/`);

  static categoriesList = () => getNewsUrl('category/list/');

  static reactionSave = () => getNewsUrl('reaction/save/');

  static reaction = (contentType: ReactionNewsContentType, objectId: EntityId) =>
    getNewsUrl(`reaction/${contentType}/${objectId}/`);

  static commentSave = () => getNewsUrl(`comment/save/`);

  static commentList = (objectId: EntityId) => getNewsUrl(`comment/list/${objectId}/`);

  static comment = (commentId: EntityId) => getNewsUrl(`comment/${commentId}/`);
}
