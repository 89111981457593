import React, { FC, useMemo, useState, createContext, PropsWithChildren } from 'react';

import { UiRoute } from 'ant/components/ui/breadcrumb';

export type PortaledBreadcrumbContextValue = {
  routes: UiRoute[];
  add: (routes: UiRoute[]) => void;
  pop: (count?: number) => void;
  set: (routes: UiRoute[]) => void;
  reset: () => void;
};

export const PortaledBreadcrumbContext = createContext<PortaledBreadcrumbContextValue | null>(null);

export const PortaledBreadcrumbProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const [breadcrumbRoutes, setBreadcrumbRoutes] = useState<UiRoute[]>([]);

  const contextValue = useMemo<PortaledBreadcrumbContextValue>(
    () => ({
      routes: breadcrumbRoutes,
      add: (routes) => setBreadcrumbRoutes((currentValue) => [...currentValue, ...routes]),
      pop: (count = 1) => setBreadcrumbRoutes((currentValue) => currentValue.slice(0, -count)),
      set: setBreadcrumbRoutes,
      reset: () => setBreadcrumbRoutes([]),
    }),
    [breadcrumbRoutes],
  );

  return (
    <PortaledBreadcrumbContext.Provider value={contextValue}>{children}</PortaledBreadcrumbContext.Provider>
  );
};
