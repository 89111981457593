import flatMap from 'lodash/flatMap';

import { getProfileStorage } from 'ant/store/profile';
import { getProfileRolesPermissionsStorage } from 'ant/store/roles';
import { AllAllowedPermissions } from 'ant/types/models/permissions.model';

export const currentProfileStorage = getProfileStorage().storage;

/** @deprecated Используй useCurrentProfile */
export const currentProfileStore = currentProfileStorage.store.map(({ data }) => data);
export const currentProfileGroupIdStore = currentProfileStorage.store.map(
  ({ data }) => data?.groupPath?.split('/')?.reverse()?.[0],
);
export const currentProfileOrganizationIdStore = currentProfileStorage.store.map(
  ({ data }) => data?.groupPath?.split('/')?.[0],
);

export const currentProfileRolesPermissionsStorage = getProfileRolesPermissionsStorage().storage;

export const currentProfileRolesPermissionsStore = currentProfileRolesPermissionsStorage.store.map(
  ({ data }) => data,
);
export const currentProfileRolesPermissionsMapStore = currentProfileRolesPermissionsStorage.store.map(
  ({ data }) =>
    new Map<AllAllowedPermissions, string>(flatMap(data).map((item) => [item.name, item.description])),
);

export const currentProfileRolesPermissionsSetStore = currentProfileRolesPermissionsStorage.store.map(
  ({ data }) => new Set<AllAllowedPermissions>(flatMap(data).map(({ name }) => name)),
);
