import { createStore, Store } from 'effector';
import React from 'react';

import { isFolderToDisplay } from './guards';
import { FileListItem } from './item/FileListItem';
import { FileOrFolderToDisplay } from './types';

export type FileListProps = {
  items: Array<FileOrFolderToDisplay>;
  uploadProgressMapStore?: Store<Record<string, number>>;
  isReadOnly?: boolean;
  className?: string;
};

const getKey = (item: FileOrFolderToDisplay) => {
  if (isFolderToDisplay(item)) {
    return `folder_${item.folderId}`;
  }

  return `file_${item.id}`;
};

const emptyMapStore = createStore({} as Record<string, number>);

/** @deprecated: Используй UiList с ant/components/widgets/File */
const FileList: React.FC<FileListProps> = ({
  items,
  uploadProgressMapStore = emptyMapStore,
  isReadOnly,
  className,
}) => {
  return (
    <div className={className}>
      {items.map((item) => (
        <FileListItem
          key={getKey(item)}
          item={item}
          uploadProgressMapStore={uploadProgressMapStore}
          isReadOnly={isReadOnly}
        />
      ))}
    </div>
  );
};

export { FileList };
