import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React, { useMemo, ComponentProps, FC, ComponentType, SVGProps } from 'react';

import { UiIconCircle } from './circle/UiIconCircle';
import { UiIconLabel } from './label/UiIconLabel';

export interface UiIconProps extends ComponentProps<typeof Icon> {
  fill?: CustomIconComponentProps['fill'];
}

type UiIconComposition = {
  Circle: typeof UiIconCircle;
  Label: typeof UiIconLabel;
};

type CustomIconStyledComponent = ComponentType<CustomIconComponentProps | SVGProps<SVGSVGElement>>;

export const UiIcon: FC<UiIconProps> & UiIconComposition = (props) => {
  const { width = '1em', height = '1em', fill, color, component: Component, ...iconProps } = props;
  const svgProps = fill ? { fill, color } : { color };

  const CustomComponent = useMemo<CustomIconStyledComponent | undefined>(() => {
    return Component
      ? // @ts-expect-error обязательный prop в antd: fill="none", в наших svg по умолчанию: fill="currentColor"
        ({ className }) => <Component className={className} width={width} height={height} {...svgProps} />
      : undefined;
  }, [Component, height, width, svgProps]);

  return <Icon {...iconProps} component={CustomComponent} />;
};

UiIcon.Circle = UiIconCircle;
UiIcon.Label = UiIconLabel;
