import { Row, RowProps } from 'antd';
import React, { FC, CSSProperties, RefAttributes } from 'react';

export interface UiRowProps extends RowProps, RefAttributes<HTMLDivElement> {
  padding?: number | [number, number];
}

export const UiRow: FC<UiRowProps> = (props) => {
  const { padding, style, ...rest } = props;
  let paddingStyle: CSSProperties = {};

  if (Array.isArray(padding)) {
    const [paddingV, paddingH] = padding;

    paddingStyle = {
      paddingTop: paddingV,
      paddingBottom: paddingV,
      paddingLeft: paddingH,
      paddingRight: paddingH,
    };
  } else {
    paddingStyle = {
      padding,
    };
  }

  return <Row {...rest} style={{ ...paddingStyle, ...style }} />;
};
