import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

import { useWindowDimensions } from 'ant/helpers/hooks/use-window-dimensions';
import { themeWidths } from 'ant/theme';

export enum DeviceLayouts {
  ExtraSmall = 'extra-small',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  ExtraLarge = 'extra-large',
}
type UseDeviceLayouts = { layout: DeviceLayouts; size: number; width: number };

const convertToNumber = (size: string): number => parseInt(size, 10);

const DEVICE_LAYOUTS: Record<DeviceLayouts, number> = {
  [DeviceLayouts.ExtraSmall]: convertToNumber(themeWidths.layoutXSWidth),
  [DeviceLayouts.Small]: convertToNumber(themeWidths.layoutSMWidth),
  [DeviceLayouts.Medium]: convertToNumber(themeWidths.layoutMDWidth),
  [DeviceLayouts.Large]: convertToNumber(themeWidths.layoutLGWidth),
  [DeviceLayouts.ExtraLarge]: convertToNumber(themeWidths.layoutXLWidth),
};

const useDeviceLayouts = (layoutSizes = DEVICE_LAYOUTS, throttleMs = 200): UseDeviceLayouts => {
  const { width } = useWindowDimensions({ throttleMs });
  const layoutEntries = useMemo(() => sortBy(Object.entries(layoutSizes), ([, size]) => size), [layoutSizes]);

  return useMemo(() => {
    const defaultRange = [DeviceLayouts.ExtraLarge, DEVICE_LAYOUTS[DeviceLayouts.ExtraLarge]];
    const [layout, size] = layoutEntries.find(([, layoutSize]) => layoutSize >= width) || defaultRange;

    return { layout, size, width } as UseDeviceLayouts;
  }, [width, layoutEntries]);
};

export { useDeviceLayouts };
