import React from 'react';

import { convertMinutesToPx } from 'ant/plugins/utils/convert-minutes-to-px';

import styles from './CalendarCell.scss';

interface CalendarCellProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
  height?: number;
}

export type CalendarCellPropsComponent = React.FC<CalendarCellProps>;

const CalendarCell: CalendarCellPropsComponent = (props) => {
  const { onClick, height = convertMinutesToPx(60) } = props;

  return <button type="button" style={{ height }} className={styles.calendarCell} onClick={onClick} />;
};

export { CalendarCell };
