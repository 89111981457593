import React, { FC } from 'react';

import { UiTypography } from 'ant/components/ui/typography';

import { DisplayOptions } from '../../GanttCompact';
import { getYearByMonth } from '../../helpers/gantt-date-helper';
import styles from './GanttContentHeader.scss';

interface Props {
  displayOptions: DisplayOptions;
}

export const GanttContentHeader: FC<Props> = ({ displayOptions }) => {
  const { year, daySize, isSmall } = displayOptions;
  const months = getYearByMonth(year, isSmall);

  return (
    <div className={styles.ganttContentHeader}>
      <div className={styles.ganttContentHeader__list}>
        {months.map(({ name, days }) => (
          <div
            key={name}
            className={styles.ganttContentHeader__listItem}
            style={{ minWidth: days * daySize }}
          >
            <UiTypography.Footnote>{name}</UiTypography.Footnote>
          </div>
        ))}
      </div>
    </div>
  );
};
