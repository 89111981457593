import { differenceInHours } from 'date-fns';
import React, { FC, useMemo } from 'react';

import DeleteSvg from 'ant/components/svg/delete.svg';
import EditSvg from 'ant/components/svg/edit.svg';
import FavoriteAddSvg from 'ant/components/svg/favorite-add.svg';
import FavoriteClearSvg from 'ant/components/svg/favorite-clear.svg';
import PinSvg from 'ant/components/svg/pin.svg';
import UnpinSvg from 'ant/components/svg/unpin.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSpace } from 'ant/components/ui/space';
import { ActionsDropdown, ActionsDropdownItem } from 'ant/components/widgets/ActionsDropdown';
import { useCurrentProfile } from 'ant/providers/current-profile';
import { useTheme } from 'ant/theme/provider';
import { BlogModerationType } from 'ant/types/blogs';
import { isBlogPost, isMicropost } from 'ant/types/guards/post';
import { BlogModel, PermissionsEnum, PermissionsV2Enum } from 'ant/types/models/blog.model';
import { PostModel, PostStatuses } from 'ant/types/models/post';

const ALLOWED_EDITING_HOURS = 24;

export type PostAction = () => void;
export type PostSwitchAction = (flag: boolean) => void;

type PostHeaderActionsProps = {
  post: PostModel;
  blog?: BlogModel;
  visible?: boolean;
  onEdit?: PostAction;
  onDelete?: PostAction;
  onPin?: PostSwitchAction;
  onFavorite?: PostSwitchAction;
};

export const PostHeaderActions: FC<PostHeaderActionsProps> = (props) => {
  const { visible = true, post, onEdit, onDelete, onFavorite, onPin, blog } = props;

  const { superAdmin } = useCurrentProfile();
  const [{ variables: themeVariables }] = useTheme();

  const postPermissionsSet = useMemo(() => new Set(post.permissions), [post]);
  const isBlogModerationType = (type: BlogModerationType) => blog?.moderationType === type;
  const isPostStatus = (status: PostStatuses) => status === post.flag;

  const dropdownActions: ActionsDropdownItem[] = useMemo(() => {
    const isMicropostPost = isMicropost(post);
    const actions: ActionsDropdownItem[] = [];

    let hasDeletePermission = postPermissionsSet.has(
      isMicropostPost ? PermissionsEnum.MicropostDelete : PermissionsEnum.EntryDelete,
    );
    let hasEditPermission = postPermissionsSet.has(
      isMicropostPost ? PermissionsEnum.MicropostUpdate : PermissionsEnum.EntryUpdate,
    );
    const hasPinPermission =
      blog?.permissionsV2?.[PermissionsV2Enum.PinPosts] && isPostStatus(PostStatuses.FlagPublished);

    if (isBlogModerationType(BlogModerationType.Premoderation)) {
      hasEditPermission =
        (hasEditPermission && isPostStatus(PostStatuses.AwaitingApprove)) ||
        Boolean(blog?.permissionsV2?.[PermissionsV2Enum.ModeratePosts]);
      hasDeletePermission =
        (hasDeletePermission && isPostStatus(PostStatuses.AwaitingApprove)) ||
        Boolean(blog?.permissionsV2?.[PermissionsV2Enum.ModeratePosts]);
    }

    if (onEdit) {
      // TODO:: Убрать хардкод с ALLOWED_EDITING_HOURS (https://jira.vk.team/browse/B2BCORE-7053)
      const diff = differenceInHours(Date.now(), new Date(post.createdAt));
      const isRestricted = isMicropostPost && diff >= ALLOWED_EDITING_HOURS;
      const canEdit = superAdmin || (hasEditPermission && !isRestricted);

      if (canEdit || isMicropostPost) {
        actions.push({
          label: canEdit
            ? 'Редактировать'
            : `Редактировать пост можно в течение ${ALLOWED_EDITING_HOURS} часов после публикации`,
          onClick: onEdit,
          icon: canEdit ? EditSvg : undefined,
          disabled: !canEdit,
        });
      }
    }

    if (onPin && isBlogPost(post) && hasPinPermission) {
      actions.push({
        label: post.isPinned ? 'Открепить' : 'Закрепить',
        onClick: () => onPin(!post.isPinned),
        icon: post.isPinned ? UnpinSvg : PinSvg,
      });
    }

    if (onDelete && hasDeletePermission) {
      actions.push({ label: 'Удалить', onClick: onDelete, icon: DeleteSvg });
    }

    return actions;
  }, [superAdmin, postPermissionsSet, post, onEdit, onDelete]);

  const isFavoriteVisible =
    (isBlogPost(post) && isPostStatus(PostStatuses.FlagPublished)) || isMicropost(post);

  return (
    <UiSpace size={16} align="start">
      {isFavoriteVisible && (
        <UiButton
          type="link-secondary"
          disabledFocus
          onClick={() => onFavorite?.(!post.favorite)}
          icon={
            <UiIcon
              component={post.favorite ? FavoriteClearSvg : FavoriteAddSvg}
              width={20}
              height={20}
              color={post.favorite ? themeVariables.colorBrand32 : undefined}
            />
          }
        />
      )}
      {visible && (
        <ActionsDropdown
          items={dropdownActions}
          overlayStyle={{ maxWidth: 220 }}
          disabled={!dropdownActions.length}
        />
      )}
    </UiSpace>
  );
};
