import { Drawer, DrawerProps } from 'antd';
import React, { FC } from 'react';

import CloseSVG from 'ant/components/svg/close.svg';
import { UiIcon } from 'ant/components/ui/icon';
import { ActionsDropdown, ActionsDropdownItem } from 'ant/components/widgets/ActionsDropdown';

import styles from './UiDrawer.scss';

export interface UiDrawerProps extends Omit<DrawerProps, 'closeIcon'> {
  withCustomCloseIcon?: boolean;
  actions?: ActionsDropdownItem[] | null;
}

export const UiDrawer: FC<UiDrawerProps> = ({ withCustomCloseIcon, actions, ...restProps }) => (
  <Drawer
    className={styles.uiDrawer}
    extra={actions && <ActionsDropdown items={actions} />}
    closeIcon={
      withCustomCloseIcon ? (
        <UiIcon className={styles.uiDrawer__closeIcon} width={20} height={20} component={CloseSVG} />
      ) : null
    }
    {...restProps}
  />
);
