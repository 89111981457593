import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { UiCard } from 'ant/components/ui/card';
import { UiTypography } from 'ant/components/ui/typography';

import styles from './SurveyQuestionSection.scss';

export type SurveyQuestionSectionProps = {
  index: number;
  className?: string;
  onFocus?: () => void;
};

const SurveyQuestionSection: React.FC<PropsWithChildren<SurveyQuestionSectionProps>> = ({
  index = 0,
  children,
  className,
  ...restProps
}) => (
  <UiCard {...restProps} size="default" className={classNames(styles.surveyQuestionSection, className)}>
    <UiTypography.Title level={3} type="secondary">
      {`Вопрос ${index + 1}`}
    </UiTypography.Title>
    {children}
  </UiCard>
);

export { SurveyQuestionSection };
