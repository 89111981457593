import { NotificationPayloads, NotificationTypes } from 'ant/plugins/notificationsService/events';
import { NotificationListener } from 'ant/plugins/notificationsService/hooks';
import { closeGlobalModal, openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';

export type NotificationEventListenersGlobal = {
  [K in NotificationTypes]?: NotificationListener<NotificationPayloads[K]>;
};

export const notificationEventListenersGlobal: NotificationEventListenersGlobal = {
  [NotificationTypes.NewThanks]: (thank: NotificationPayloads[NotificationTypes.NewThanks]) => {
    openGlobalModal(GlobalModalNames.ProfileBadgeThanksView, {
      onClose: () => closeGlobalModal(GlobalModalNames.ProfileBadgeThanksView),
      thank,
    });
  },
};
