import { useStore } from 'effector-react';
import React, { MouseEvent, TouchEvent, useMemo, useState } from 'react';

import CloseSVG from 'ant/components/svg/close.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiFormItemProps } from 'ant/components/ui/form';
import { UiCol } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiImage } from 'ant/components/ui/image';
import { message } from 'ant/components/ui/message';
import { UiModal, UiModalTypes } from 'ant/components/ui/modals';
import { UiTypography } from 'ant/components/ui/typography';
import { FigureType } from 'ant/components/widgets/CropImageArea';
import {
  ImageUploadCrop,
  ImageUploadCropProps,
  ImageUploadPreviewValue,
  uploadAttachmentEffectFactory,
} from 'ant/components/widgets/ImageUploadCrop';
import { UploadDraggerArea, UploadDraggerAreaProps } from 'ant/components/widgets/UploadDraggerArea';
import { uploadFileStorageAttachmentEffectFactory } from 'ant/store/filestorage';
import { FileStorageApiVersions } from 'ant/store/filestorage/api';
import { FileUploadAccepts } from 'ant/types/models/file-upload-accepts';
import { FileStorageEntryId, FileStorageEntryUrl, PreloadedFileModel } from 'ant/types/models/file.model';

import styles from './ImageAnswer.scss';

export type SurveyImageAnswerFormValue = { imageUrl: FileStorageEntryUrl; imageId: FileStorageEntryId };

const IMAGE_CROP_TEXT_DATA = {
  rulesTitle: '',
  rulesList: [],
  cropTitle: 'Выберите область',
};

const CROP_ASPECT = 15 / 10;

export interface ImageAnswerProps extends UiFormItemProps<SurveyImageAnswerFormValue> {
  onRemove?: () => void;
  index?: number;
}

const IMAGE_MIN_WIDTH = 350;
const IMAGE_MIN_HEIGHT = 274;

export const ImageAnswer: React.FC<ImageAnswerProps> = (props) => {
  const { value, index: answerShowIndex, onChange, onRemove } = props;
  const uploadAttachmentEffect = useMemo(() => {
    return uploadFileStorageAttachmentEffectFactory<PreloadedFileModel>(FileStorageApiVersions.v3);
  }, []);
  const isLoadingImage = useStore(uploadAttachmentEffect.pending);
  const [uploadImage, setUploadImage] = useState<ImageUploadPreviewValue['uploadImage']>();
  const [previewImage, setPreviewImage] = useState<ImageUploadPreviewValue['previewImage']>();
  const [isOpenSelectImageModal, setIsOpenSelectImageModal] = useState(false);
  const onToggleSelectImageModal = () => setIsOpenSelectImageModal((isOpen: boolean) => !isOpen);

  const onSaveImage: ImageUploadCropProps['onChange'] = ({ file, id }) => {
    onChange?.({ imageId: id, imageUrl: file });
  };

  const onRemoveImage = (event: MouseEvent | TouchEvent) => {
    event.stopPropagation();
    onRemove?.();
  };

  const onUploadAttachment: UploadDraggerAreaProps['onUploadAttachment'] = async (params) => {
    try {
      const uploadAttachment = uploadAttachmentEffectFactory({
        minWidth: IMAGE_MIN_WIDTH,
        minHeight: IMAGE_MIN_HEIGHT,
      });
      const uploadedAttachment = await uploadAttachment(params);

      setUploadImage(uploadedAttachment.uploadImage);
      setPreviewImage(uploadedAttachment.previewImage);
      onToggleSelectImageModal();
    } catch (e) {
      message.error(String(e));
    }
  };

  return (
    <>
      {value && (
        <UiCol className={styles.imageAnswer} style={{ width: IMAGE_MIN_WIDTH }}>
          {/* TODO:: Добавить Drag&Drop (B2BCORE-3282) */}
          <div className={styles.imageAnswer__controls}>
            <UiButton
              size="small"
              type="action"
              icon={<UiIcon component={CloseSVG} width={20} height={20} />}
              onClick={onRemoveImage}
            />
          </div>

          <UiImage height={IMAGE_MIN_HEIGHT} src={value.imageUrl} />
          <UiTypography.Text
            strong
            className={styles.imageAnswer__title}
          >{`Изображение ${answerShowIndex}`}</UiTypography.Text>
        </UiCol>
      )}

      {!value && (
        <UiCol style={{ width: IMAGE_MIN_WIDTH, height: IMAGE_MIN_HEIGHT }}>
          <UploadDraggerArea
            onUploadAttachment={onUploadAttachment}
            loading={isLoadingImage}
            multiple={false}
            accept={FileUploadAccepts.ImagePngJpg}
            wrapperClassName={styles.imageAnswer__dragDropArea}
          />
        </UiCol>
      )}

      <UiModal
        isMaskClosable={false}
        type={UiModalTypes.Large}
        isOpen={isOpenSelectImageModal}
        onClose={onToggleSelectImageModal}
      >
        <ImageUploadCrop
          title="Обрезка изображения"
          descriptionProps={IMAGE_CROP_TEXT_DATA}
          minWidth={IMAGE_MIN_WIDTH}
          minHeight={IMAGE_MIN_HEIGHT}
          onChange={onSaveImage}
          onClose={onToggleSelectImageModal}
          figureType={FigureType.Rectangle}
          aspect={CROP_ASPECT}
          value={{ uploadImage, previewImage }}
        />
      </UiModal>
    </>
  );
};
