import { api } from 'ant/plugins/api';
import { UserAgreementEndpoints } from 'ant/store/user-agreements/endpoints';
import {
  AcceptUserAgreement,
  CreateNewUserAgreement,
  GetUserAgreementParams,
} from 'ant/types/user-agreements';

export const acceptUserAgreementsRequest = (params: AcceptUserAgreement) => {
  return api.post({
    url: UserAgreementEndpoints.termsOfUseOpenApiIdFactsOfConsent(params.id),
  });
};

export const newUserAgreementsRequest = (data: CreateNewUserAgreement) => {
  return api.post({
    url: UserAgreementEndpoints.termsOfUseNew(),
    data,
  });
};

export const getUserAgreementRequest = (params: GetUserAgreementParams) => {
  return api.get({
    url: UserAgreementEndpoints.termsOfUseOpenApiId(params.id),
  });
};
