import { useStore } from 'effector-react';
import React, { forwardRef } from 'react';

import { DisplayOptionsFull } from 'ant/components/widgets/Gantt/GanttFullSize';
import { GanttFilter } from 'ant/components/widgets/Gantt/filter/GanttFilter';
import { GanttFullHeaderMonths } from 'ant/components/widgets/Gantt/full/header/months/GanttFullHeaderMonths';
import { GanttFullHeaderYears } from 'ant/components/widgets/Gantt/full/header/years/GanttFullHeaderYears';
import { ganttFilterStore, GanttViewType } from 'ant/components/widgets/Gantt/store';
import { ProjectsStageModel } from 'ant/types/models/projects';

import styles from './GanttFullHeader.scss';

interface Props {
  displayOptions: DisplayOptionsFull;
  stages: ProjectsStageModel[];
}

export const GanttFullHeader = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { viewType } = useStore(ganttFilterStore.store);
  const isActive = (type: GanttViewType) => type === viewType;

  return (
    <div ref={ref} className={styles.ganttFullHeader}>
      <GanttFilter />
      {isActive(GanttViewType.Year) && <GanttFullHeaderYears {...props} />}
      {isActive(GanttViewType.Month) && <GanttFullHeaderMonths {...props} />}
    </div>
  );
});
