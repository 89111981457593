import { AxiosError } from 'axios';
import { createEffect, Effect } from 'effector';

import { AbstractStorage, abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { authService } from 'ant/plugins/auth-service';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { PagesEndpoints } from 'ant/store/pages/endpoints';
import { DictPaginated } from 'ant/types/api';
import { Widget } from 'core-components/types';

import { updateUserWidgetData, updateWidgetDefaultData, UpdateWidgetDefaultDataParams } from './api';

export interface GetWidgetStorageParams {
  id: number;
  type: string;
}

export const getWidgetStorage = <WidgetType extends Widget>({
  id,
  type,
}: Partial<GetWidgetStorageParams>) => {
  const storage = abstractStorageFactory<DictPaginated<WidgetType> | WidgetType, WidgetType | null, null>({
    endpointBuilder: () =>
      type && !id
        ? buildEndpointWithQueryParams(PagesEndpoints.layoutWidgetList(), { types: type }) // TODO удалить когда виджет c типом navbar будет получаться по id
        : PagesEndpoints.widgetData(id as number),
    defaultValue: null,
    dataMapper: (data) =>
      Array.isArray((data as DictPaginated<WidgetType>).items)
        ? (data as DictPaginated<WidgetType>).items[0]
        : (data as WidgetType),
  });

  const updateUserWidgetDataEffect = createEffect<UpdateWidgetDefaultDataParams, WidgetType, AxiosError>(
    (params) =>
      updateUserWidgetData<WidgetType>({
        ...params,
        userId: String(authService.getCurrentUserId()),
      }).then(({ data }) => data),
  );

  const updateWidgetDefaultDataEffect = createEffect<UpdateWidgetDefaultDataParams, WidgetType, AxiosError>(
    (params) => updateWidgetDefaultData<WidgetType>(params).then(({ data }) => data),
  );

  storage.store.on(updateUserWidgetDataEffect.doneData, (state, { data }) => ({
    ...state,
    data: { ...state.data, data } as WidgetType,
  }));

  storage.store.on(updateWidgetDefaultDataEffect.doneData, (state, { defaultData }) => ({
    ...state,
    data: { ...state.data, defaultData } as WidgetType,
  }));

  return { storage, updateUserWidgetDataEffect, updateWidgetDefaultDataEffect };
};

type WidgetListStorageParams = { types: string[] };

export const getWidgetListStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<Widget>,
    DictPaginated<Widget>,
    null,
    WidgetListStorageParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(PagesEndpoints.layoutWidgetList(), params),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getWidgetTypesStorage = () => {
  const storage = abstractStorageFactory<string[], string[], string[]>({
    endpointBuilder: PagesEndpoints.widgetsTypes,
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export type GetWidgetStorage<WT extends Widget> = {
  storage: AbstractStorage<DictPaginated<WT> | WT, WT | null, null>;
  updateUserWidgetDataEffect: Effect<UpdateWidgetDefaultDataParams, WT, AxiosError>;
  updateWidgetDefaultDataEffect: Effect<UpdateWidgetDefaultDataParams, WT, AxiosError>;
};
