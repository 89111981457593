import { RecordResponse } from 'ant/types/dictionary';
import { Reaction, ReactionsCountInfo } from 'ant/types/models/reactions.model';

export const getTotalReactions = (reactions: Reaction[], reactionsData: RecordResponse[]) =>
  reactionsData.reduce(
    (res, reactionData) => [
      ...res,
      {
        id: reactionData.id,
        count: reactions?.find((r) => r.reactionId === reactionData.id)?.reactionCount || 0,
      },
    ],
    [] as ReactionsCountInfo[],
  );
