import { AvatarProps } from 'antd';
import { FC } from 'react';

import { UiAvatar as OriginUiAvatar } from './UiAvatar';
import { UiAvatarGroup } from './group/UiAvatarGroup';

export enum UiAvatarSize {
  XXXL = 120,
  XXL = 80,
  XL = 64,
  L = 48,
  M = 40,
  S = 32,
  XS = 24,
}

export interface UiAvatarProps extends Exclude<AvatarProps, 'size'> {
  size?: number;
  disabled?: boolean;
  withBadge?: boolean;
}

export type UiAvatarComposition = FC<UiAvatarProps> & {
  Group: typeof UiAvatarGroup;
};

export const UiAvatar = OriginUiAvatar as UiAvatarComposition;

UiAvatar.Group = UiAvatarGroup;
