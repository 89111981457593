import classnames from 'classnames';
import React, { useCallback, useMemo, useState, PropsWithChildren, FC } from 'react';

import { UiCollapse } from 'ant/components/ui/collapse';
import { UiMenu, UiMenuItemType } from 'ant/components/ui/menu';
import {
  SidebarItem,
  SidebarMenuItemComponent,
  UiSidebarMenuItem,
} from 'ant/components/ui/sidebar/item/UiSidebarMenuItem';
import { UiTypography } from 'ant/components/ui/typography';
import { ActionsDropdown, ActionsDropdownItem } from 'ant/components/widgets/ActionsDropdown';

import styles from './UiSidebarMenu.scss';

type SidebarMenuComposition = {
  Item: SidebarMenuItemComponent;
};

export enum UiSidebarType {
  Compact = 'compact',
  Default = 'default',
}

export type UiSidebarMenuProps = {
  title?: string | React.ReactNode;
  className?: string;
  collapsible?: boolean;
  value: SidebarItem;
  onSelect?: (value: string) => void;
  actions?: ActionsDropdownItem[];
  items: SidebarItem[];
  type?: UiSidebarType;
};

export type UiSidebarMenuComponent = FC<PropsWithChildren<UiSidebarMenuProps>> & SidebarMenuComposition;

export const UiSidebarMenu: UiSidebarMenuComponent = (props) => {
  const {
    className,
    title,
    collapsible,
    value: selectedItem,
    onSelect,
    actions = [],
    children,
    items,
    type = UiSidebarType.Default,
  } = props;
  const selectedKeys = useMemo(() => [String(selectedItem.value)], [selectedItem]);
  const [expanded, setExpanded] = useState(true);

  const selectHandler = useCallback(
    ({ key }: { key: React.ReactText }) => onSelect?.(String(key)),
    [onSelect],
  );

  const wrapContent = (content: React.ReactNode) => {
    if (collapsible) {
      return (
        <UiCollapse
          ghost
          onChange={() => setExpanded(!expanded)}
          expandIconPosition="right"
          activeKey={expanded ? 'panel' : undefined}
        >
          <UiCollapse.Panel key="panel" header={title}>
            {content}
          </UiCollapse.Panel>
        </UiCollapse>
      );
    }

    return (
      <>
        {title && (
          <div className={styles.uiSidebarMenu__heading}>
            <UiTypography.Title level={3} className={styles.uiSidebarMenu__title}>
              {title}
            </UiTypography.Title>
            {actions.length > 0 && <ActionsDropdown items={actions} />}
          </div>
        )}
        {content}
      </>
    );
  };

  const menuItems = useMemo<UiMenuItemType[]>(() => {
    return (items || []).map((item) => ({
      label: <UiSidebarMenuItem item={item} type={type} />,
      key: String(item.value),
    }));
  }, [items, type]);

  return (
    <div className={classnames(styles.uiSidebarMenu, className)}>
      {wrapContent(
        <>
          <UiMenu
            className={styles.uiSidebarMenu__menu}
            onSelect={selectHandler}
            selectedKeys={selectedKeys}
            items={menuItems}
          />
          {children}
        </>,
      )}
    </div>
  );
};

UiSidebarMenu.Item = UiSidebarMenuItem;
