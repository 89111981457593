// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--ck-mention-list-max-height: 300px}._57aec0ec_ck._57aec0ec_ck-mentions{max-height:var(--ck-mention-list-max-height);overflow-y:auto;overflow-x:hidden;overscroll-behavior:contain}._57aec0ec_ck._57aec0ec_ck-mentions>._57aec0ec_ck-list__item{overflow:hidden;flex-shrink:0}", "",{"version":3,"sources":["webpack://./../ant/src/components/widgets/Editor/plugins/mention/lib/view/MentionView.scss"],"names":[],"mappings":"AACA,MACE,mCAAA,CAGF,oCACE,4CAAA,CACA,eAAA,CAEA,iBAAA,CACA,2BAAA,CAEA,6DACE,eAAA,CACA,aAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n:root {\n  --ck-mention-list-max-height: 300px;\n}\n\n.ck.ck-mentions {\n  max-height: var(--ck-mention-list-max-height);\n  overflow-y: auto;\n  /* Prevent unnecessary horizontal scrollbar in Safari */\n  overflow-x: hidden;\n  overscroll-behavior: contain;\n  /* Prevent unnecessary vertical scrollbar in Safari */\n  & > .ck-list__item {\n    overflow: hidden;\n    flex-shrink: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ck": "_57aec0ec_ck",
	"ck-mentions": "_57aec0ec_ck-mentions",
	"ckMentions": "_57aec0ec_ck-mentions",
	"ck-list__item": "_57aec0ec_ck-list__item",
	"ckList__item": "_57aec0ec_ck-list__item"
};
export default ___CSS_LOADER_EXPORT___;
