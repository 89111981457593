import React, { CSSProperties, FC } from 'react';

import ViewSvg from 'ant/components/svg/view.svg';
import { UiIcon } from 'ant/components/ui/icon';
import { numberAbbreviator } from 'ant/plugins/number-formatters';

type Props = { count: number; style?: CSSProperties };

export const ReactionsViews: FC<Props> = (props) => {
  const { count, style } = props;

  return (
    <UiIcon.Label style={style} label={numberAbbreviator(count)} component={ViewSvg} width={20} height={20} />
  );
};
