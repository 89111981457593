import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
  ResponderProvided,
  OnDragEndResponder,
} from 'react-beautiful-dnd';

const UiDragDropContext = DragDropContext;
const UiDroppable = Droppable;
const UiDraggable = Draggable;

export type DropResultType = DropResult;
export type ResponderProvidedType = ResponderProvided;
export type OnDragEndResponderType = OnDragEndResponder;
export type DraggingStyleType = DraggingStyle;
export type NotDraggingStyleType = NotDraggingStyle;
export type DraggablePropsStyles = NotDraggingStyle | DraggingStyle | undefined;

export { UiDragDropContext, UiDroppable, UiDraggable };
