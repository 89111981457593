import React, { useCallback } from 'react';

import EmojiDisappointedFaceSvg from 'ant/components/svg/emoji-disappointed-face.svg';
import EmojiFrowningFaceSvg from 'ant/components/svg/emoji-frowning-face.svg';
import EmojiHeartEyesSvg from 'ant/components/svg/emoji-heart-eyes.svg';
import EmojiNeutralFaceSvg from 'ant/components/svg/emoji-neutral-face.svg';
import EmojiSmilingFaceSvg from 'ant/components/svg/emoji-smiling-face.svg';
import { UiCheckbox, UiCheckboxChangeEvent } from 'ant/components/ui/checkbox';
import { UiIcon } from 'ant/components/ui/icon';

import styles from './UiRateEmoji.scss';

const customIcons = [
  EmojiDisappointedFaceSvg,
  EmojiFrowningFaceSvg,
  EmojiNeutralFaceSvg,
  EmojiSmilingFaceSvg,
  EmojiHeartEyesSvg,
];

export type UiRateEmojiCheckedValue = number | undefined;
export type UiRateEmojiProps = {
  value?: UiRateEmojiCheckedValue;
  onChange?: (value: UiRateEmojiCheckedValue) => void;
  disabled?: boolean;
};

const UiRateEmoji: React.FC<UiRateEmojiProps> = ({ onChange, value, disabled }) => {
  const changeHandler = useCallback(
    (event: UiCheckboxChangeEvent) => {
      const newValue = event.target.value;

      onChange?.(value !== newValue ? newValue : undefined);
    },
    [value, onChange],
  );

  return (
    <div className={styles.uiEmoji}>
      {customIcons.map((icon, i) => (
        <UiCheckbox
          disabled={disabled}
          key={icon}
          value={i + 1}
          checked={i + 1 === value}
          onChange={changeHandler}
          className={styles.uiEmoji__radioButton}
        >
          <UiIcon className={styles.uiEmoji__icon} width={32} height={32} component={icon} />
        </UiCheckbox>
      ))}
    </div>
  );
};

export { UiRateEmoji };
