import AttachSvg from 'ant/components/svg/attach.svg';
import BlogSvg from 'ant/components/svg/blog.svg';
import CalendarSvg from 'ant/components/svg/calendar.svg';
import CommunitySvg from 'ant/components/svg/community.svg';
import EditSvg from 'ant/components/svg/edit.svg';
import EventSvg from 'ant/components/svg/event.svg';
import FaqSvg from 'ant/components/svg/faq.svg';
import LocationSvg from 'ant/components/svg/location.svg';
import MeatballSvg from 'ant/components/svg/meatball.svg';
import PictureSvg from 'ant/components/svg/picture.svg';
import ReplySvg from 'ant/components/svg/reply.svg';
import StatisticsSvg from 'ant/components/svg/statistics-chart.svg';
import SuccessSvg from 'ant/components/svg/success.svg';
import TimeSvg from 'ant/components/svg/time.svg';
import UserCheckedSvg from 'ant/components/svg/user-checked.svg';
import UserClearSvg from 'ant/components/svg/user-clear.svg';
import UserFollowerSvg from 'ant/components/svg/user-follower.svg';
import UserFollowingSvg from 'ant/components/svg/user-following.svg';
import VideoSvg from 'ant/components/svg/video.svg';
import { BlogIconType } from 'ant/types/blogs';

const iconsMap: Record<BlogIconType, SvgrComponent> = {
  [BlogIconType.Edit]: EditSvg,
  [BlogIconType.Statistics]: StatisticsSvg,
  [BlogIconType.Success]: SuccessSvg,
  [BlogIconType.UserChecked]: UserCheckedSvg,
  [BlogIconType.UserClear]: UserClearSvg,
  [BlogIconType.UserFollowing]: UserFollowingSvg,
  [BlogIconType.Members]: UserFollowerSvg,
  [BlogIconType.Picture]: PictureSvg,
  [BlogIconType.Faq]: FaqSvg,
  [BlogIconType.Files]: AttachSvg,
  [BlogIconType.Calendar]: CalendarSvg,
  [BlogIconType.BallsMore]: MeatballSvg,
  [BlogIconType.Reply]: ReplySvg,
  [BlogIconType.Location]: LocationSvg,
  [BlogIconType.Time]: TimeSvg,
  [BlogIconType.Video]: VideoSvg,
  [BlogIconType.Event]: EventSvg,
  [BlogIconType.Community]: CommunitySvg,
  [BlogIconType.BlogIcon]: BlogSvg,
};

export const getBlogIcon = (iconName: BlogIconType) => iconsMap[iconName];
