import { generateRandomString } from 'ant/plugins/utils/generate-random-string';
import { FileToUploadModel, FileToUploadModelWithoutData } from 'ant/types/models/file.model';

function readFileToStringPromise(file: File, shouldParseData: true): Promise<FileToUploadModel>;
function readFileToStringPromise(file: File, shouldParseData: false): Promise<FileToUploadModelWithoutData>;
function readFileToStringPromise(file: File, shouldParseData = false) {
  if (shouldParseData) {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();

      reader.onload = (e) => {
        const data = e.target?.result?.toString();

        if (data) {
          const fileToUpload: FileToUploadModel = {
            id: generateRandomString(),
            filename: file.name,
            rawFile: file,
            data,
          };

          resolve(fileToUpload);
        } else {
          reject();
        }
      };

      reader.onerror = reject;
      reader.onabort = reject;

      reader.readAsDataURL(file);
    });
  }

  return Promise.resolve({
    id: generateRandomString(),
    filename: file.name,
    rawFile: file,
  } as FileToUploadModelWithoutData);
}

export { readFileToStringPromise };
