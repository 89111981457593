import { useEffect, useRef } from 'react';

const useMount = (fn: () => void): void => {
  const fnRef = useRef(fn);

  fnRef.current = fn;

  useEffect(() => fnRef.current(), []);
};

export { useMount };
