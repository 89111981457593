import { Table as AntTable } from 'antd';
import {
  TableProps as AntTableProps,
  TablePaginationConfig as AntTablePaginationConfig,
  ColumnType as AntTableColumnType,
  ColumnGroupType as AntTableColumnGroupType,
} from 'antd/es/table';
import {
  FilterConfirmProps as AntTableFilterConfirmProps,
  SorterResult as AntTableSorterResult,
  TableRowSelection as AntTableRowSelection,
} from 'antd/es/table/interface.js';
import classNames from 'classnames';
import React from 'react';

import TableFilterSVG from 'ant/components/svg/table-filter.svg';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSpinner } from 'ant/components/ui/spinner';
import { UiUserLinkCell } from 'ant/components/ui/user-link-cell';

import styles from './UiTable.scss';
import { UiTableActionsPanel } from './actions-panel';
import { UiTableCellAvatar } from './cell-avatar/UiTableCellAvatar';
import { UiTableCellDash } from './cell-dash/UiTableCellDash';
import {
  UiTableColumnFilter,
  UiTableColumnSearch,
  UiTableColumnFilterUsers,
  UiTableColumnFilterPaths,
} from './column';
import { UiTableFooter } from './footer/UiTableFooter';

export interface UiTableProps<T extends object = any>
  extends Omit<AntTableProps<T>, 'rowClassName' | 'loading'> {
  loading?: boolean;
  activeRow?: boolean;
  rowClassName?: string;
}

export const UiTable = <RecordType extends Record<string, any>>(props: UiTableProps<RecordType>) => {
  const { className, rowClassName, activeRow, columns, pagination, loading } = props;

  return (
    <AntTable
      {...props}
      loading={{ indicator: UiSpinner.Indicator, spinning: Boolean(loading), size: 'large' }}
      columns={columns?.map((column) => ({
        ...column,
        filterIcon: column.filterIcon ? (
          column.filterIcon
        ) : (
          <UiIcon width={20} height={20} component={TableFilterSVG} />
        ),
      }))}
      pagination={pagination}
      className={classNames(
        styles.uiTable,
        {
          [styles.uiTableOverflow]: !pagination,
        },
        className,
      )}
      rowClassName={classNames(
        {
          [styles.uiTable__row]: activeRow,
        },
        rowClassName,
      )}
    />
  );
};

UiTable.CellDash = UiTableCellDash;
// TODO: переименовать
UiTable.CellUser = UiUserLinkCell;
UiTable.CellAvatar = UiTableCellAvatar;
UiTable.ActionsPanel = UiTableActionsPanel;
UiTable.Footer = UiTableFooter;
UiTable.ColumnSearch = UiTableColumnSearch;
UiTable.ColumnFilter = UiTableColumnFilter;
UiTable.ColumnFilterUsers = UiTableColumnFilterUsers;
UiTable.ColumnFilterPaths = UiTableColumnFilterPaths;

type AntTableDataIndex = AntTableColumnType<unknown>['dataIndex'];

export interface ColumnType<T, DIndex = AntTableDataIndex> extends Omit<AntTableColumnType<T>, 'dataIndex'> {
  dataIndex?: DIndex;
}

export type ColumnsType<T = unknown, DIndex = AntTableDataIndex> = (
  | AntTableColumnGroupType<T>
  | ColumnType<T, DIndex>
)[];

export type TableRowSelection<T> = AntTableRowSelection<T>;

export type FilterConfirmProps = AntTableFilterConfirmProps;
export type SorterResult<T> = AntTableSorterResult<T>;

export type TableDataCells = Record<string, React.ReactNode>;

export type TableData = {
  key: string | number;
} & TableDataCells;

export type TablePaginationConfig = AntTablePaginationConfig;
