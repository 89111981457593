import { UiUploadFile } from 'ant/components/ui/upload';
import { ImageUploadCropProps } from 'ant/components/widgets/ImageUploadCrop';
import { FilesUploadStore } from 'ant/helpers/storage/abstract-files-upload-factory';
import { GetFileStorageCategoriesStorage } from 'ant/store/filestorage';
import { DictsResponse } from 'ant/types/dictionary';
import { FileStorageCreatePayload, FileStorageUpdatePayload } from 'ant/types/file-storage';
import {
  EventsLongreadParams,
  EventsLongreadPayload,
  LongreadParams,
  NewsLongreadParams,
  NewsLongreadPayload,
  PostLongreadPayload,
  SurveysLongreadParams,
  SurveysLongreadPayload,
  TimelineLongreadParams,
  TimelineLongreadPayload,
} from 'ant/types/longread';
import { BlogManager, BlogModel, BlogsManagerRolePermissions } from 'ant/types/models/blog.model';
import { CalendarEventEditFormValuesType, CalendarEventModel } from 'ant/types/models/calendar';
import { CMSPageId, CMSSectionId } from 'ant/types/models/cms.model';
import { EventModel } from 'ant/types/models/event.model';
import {
  FileStorageCategoryModel,
  FileStorageEntryId,
  FileStorageEntryMove,
  PreloadedFileModel,
} from 'ant/types/models/file.model';
import {
  GamificationAddedThank,
  GamificationAdminBadge,
  GamificationAdminThanks,
} from 'ant/types/models/gamification.model';
import { IdeaModelId } from 'ant/types/models/ideas.model';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import { NewsCategoryParams } from 'ant/types/models/news.model';
import { PollModel } from 'ant/types/models/poll';
import { SkillType, SkillsUsersSteps, UserSkill } from 'ant/types/models/profile.model';
import { ProjectId, ProjectsTaskId } from 'ant/types/models/projects';
import { GroupsMailingId, SendingSurveyId, SurveyChoiceModalParams, SurveyId } from 'ant/types/models/survey';
import { Note } from 'ant/types/notes';
import { QueryParams } from 'ant/types/query';
import { ServiceRolesNames } from 'ant/types/roles';
import { SummaryAnalyticsReportType } from 'ant/types/summary-analytics-report';

export enum GlobalModalAppearances {
  PageOverlay,
  PageOverlayNoClose,
  LargeModal,
  LargeAlternativeModal,
  MediumModal,
  FullscreenModal,
  Drawer,
}

export type GlobalModalState<P, QP extends QueryParams = Record<string, any>> = {
  isVisible: boolean;
  isHiding: boolean;
  payload: P | null;
  queryParams?: QP;
};

export type GlobalModalSettings = {
  appearance: GlobalModalAppearances;
  moduleName: string;
};

/**
 * Новые модалки заводить здесь,
 * тип пейлоада при открытии задавать в GlobalModalsStorePayloads,
 * а настройки (внешний вид, название модуля) в globalModalsSettings
 */
export enum GlobalModalNames {
  TimelineLongread = 'TimelineLongread',
  PostLongread = 'PostLongread',
  NewsLongread = 'NewsLongread',
  EventsLongread = 'EventsLongread',
  SurveysLongread = 'SurveysLongread',
  EventsList = 'EventsList',
  CreateUpdateBlog = 'CreateUpdateBlog',
  CreateUpdateBlogManagerPermissions = 'CreateUpdateBlogManagerPermissions',
  CalendarCreateUpdateMeeting = 'CalendarCreateUpdateMeeting',
  CalendarCreateUpdateTask = 'CalendarCreateUpdateTask',
  CalendarEventDetail = 'CalendarEventDetail',
  CreateUpdateEvent = 'CreateUpdateEvent',
  MembershipApplications = 'MembershipApplications',
  NewsEditor = 'NewsEditor',
  NewsCreateCategory = 'NewsCreateCategory',
  SurveyEditor = 'SurveyEditor',
  SurveyDetails = 'SurveyDetails',
  SurveyChoiceDetails = 'SurveyChoiceDetails',
  SurveyGroupsMailing = 'SurveyGroupsMailing',
  ProfileBadgeThanksView = 'ProfileBadgeThanksView',
  NoteEditor = 'NoteEditor',
  ProjectPreview = 'ProjectPreview',
  ProjectCreate = 'ProjectCreate',
  ProjectStages = 'ProjectStages',
  ProjectParticipants = 'ProjectParticipants',
  TaskCreate = 'TaskCreate',
  TaskPreview = 'TaskPreview',
  PollEditor = 'PollEditor',
  AdminOrgStructureCreateUpdateOrganization = 'AdminOrgStructureCreateUpdateOrganization',
  CreateUpdateSubdivision = 'CreateUpdateSubdivision',
  UpdateEmployee = 'UpdateEmployee',
  AdministrationCreatePermissionsAdmin = 'AdministrationCreatePermissionsAdmin',
  AdminGroupsPagesAdministrators = 'AdminGroupsPagesAdministrators',
  AdminPersonalPagesAdministrators = 'AdminPersonalPagesAdministrators',
  AdministrationCreateUserAgreement = 'AdministrationCreateUserAgreement',
  AdministrationPreviewUserAgreement = 'AdministrationPreviewUserAgreement',
  ProfilePreviewUserAgreementCurrent = 'ProfilePreviewUserAgreementCurrent',
  ProfilePreviewUserAgreementArchive = 'ProfilePreviewUserAgreementArchive',
  TextFormatHelp = 'TextFormatHelp',
  SurveyGroupMailingCreate = 'SurveyGroupMailingCreate',
  SummaryAnalyticsExportToExcel = 'SummaryAnalyticsExportToExcel',
  BlogMembers = 'BlogMembers',
  FileStorageUpdate = 'FileStorageUpdate',
  FileStorageCreate = 'FileStorageCreate',
  FileStorageCreateMultiple = 'FileStorageCreateMultiple',
  FileStorageMoveEntries = 'FileStorageMoveEntries',
  DictionaryViewRecords = 'DictionaryViewRecords',
  FileStorageCategoryList = 'FileStorageCategoryList',
  FileStorageCategoryCreateUpdate = 'FileStorageCategoryCreateUpdate',
  IdeaCreateUpdate = 'IdeaCreateUpdate',
  GroupsPagesCreateGroup = 'GroupsPagesCreateGroup',
  GroupsPagesCreateSection = 'GroupsPagesCreateSection',
  GroupsPagesCreatePage = 'GroupsPagesCreatePage',
  GroupsPagesDownloadReport = 'GroupsPagesDownloadReport',
  CreateUpdateGamificationThanks = 'CreateUpdateGamificationThanks',
  CreateUpdateGamificationBadge = 'CreateUpdateGamificationBadge',
  ImageUploadCrop = 'ImageUploadCrop',
  TimelineSettings = 'TimelineSettings',
  SkillsUsers = 'SkillsUsers',
}

export type GlobalModalsStorePayloads = {
  [GlobalModalNames.TimelineLongread]: GlobalModalState<TimelineLongreadPayload, TimelineLongreadParams>;
  [GlobalModalNames.PostLongread]: GlobalModalState<PostLongreadPayload, LongreadParams>;
  [GlobalModalNames.NewsLongread]: GlobalModalState<NewsLongreadPayload, NewsLongreadParams>;
  [GlobalModalNames.EventsLongread]: GlobalModalState<EventsLongreadPayload, EventsLongreadParams>;
  [GlobalModalNames.SurveysLongread]: GlobalModalState<SurveysLongreadPayload, SurveysLongreadParams>;
  [GlobalModalNames.EventsList]: GlobalModalState<{ onSelect: (event: EventModel) => void }>;
  [GlobalModalNames.CreateUpdateBlog]: GlobalModalState<
    | {
        existingBlogId?: number;
        onSuccess?: () => void;
      }
    | Record<string, any>
  >;
  [GlobalModalNames.CreateUpdateBlogManagerPermissions]: GlobalModalState<{
    user?: BlogManager;
    onSuccess: (blogManager: BlogManager) => void;
    permissions: Map<BlogsManagerRolePermissions['name'], BlogsManagerRolePermissions>;
  }>;
  [GlobalModalNames.CalendarCreateUpdateMeeting]: GlobalModalState<{
    initialEventFormValues?: CalendarEventEditFormValuesType;
    customSection?: React.FC<React.HTMLAttributes<HTMLDivElement>>;
    formClassName?: string;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.CalendarCreateUpdateTask]: GlobalModalState<{
    initialEventFormValues?: CalendarEventEditFormValuesType;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.CalendarEventDetail]: GlobalModalState<{
    event: CalendarEventModel;
    onSuccess?: () => void;
    isNewEvent?: boolean;
  }>;
  [GlobalModalNames.NewsEditor]: GlobalModalState<{
    newsId?: number;
    onClose: () => void;
  }>;
  [GlobalModalNames.NewsCreateCategory]: GlobalModalState<{
    data?: NewsCategoryParams;
    onClose: () => void;
  }>;
  [GlobalModalNames.SurveyEditor]: GlobalModalState<{
    surveyId?: SurveyId;
    onClose: () => void;
  }>;
  [GlobalModalNames.SurveyDetails]: GlobalModalState<{
    sendingSurveyId: SendingSurveyId;
    onClose: () => void;
  }>;
  [GlobalModalNames.SurveyChoiceDetails]: GlobalModalState<{
    choiceParams: SurveyChoiceModalParams;
    onClose: () => void;
  }>;
  [GlobalModalNames.SurveyGroupsMailing]: GlobalModalState<{
    surveyId?: SurveyId;
    onClose: () => void;
  }>;
  [GlobalModalNames.CreateUpdateEvent]: GlobalModalState<{
    event?: EventModel;
    isCopy?: boolean;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.MembershipApplications]: GlobalModalState<{
    blogId: string;
  }>;
  [GlobalModalNames.ProfileBadgeThanksView]: GlobalModalState<{
    onClose: () => void;
    thank: GamificationAddedThank;
  }>;
  [GlobalModalNames.NoteEditor]: GlobalModalState<{
    note?: Note;
    onClose: () => void;
    onSave: (note: Note) => void;
  }>;
  [GlobalModalNames.ProjectPreview]: GlobalModalState<{
    projectId: ProjectId;
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.ProjectCreate]: GlobalModalState<{
    projectId?: ProjectId;
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.ProjectStages]: GlobalModalState<{
    projectId: ProjectId;
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.ProjectParticipants]: GlobalModalState<{
    projectId: ProjectId;
  }>;
  [GlobalModalNames.TaskCreate]: GlobalModalState<{
    projectId?: ProjectId;
    isProjectEdit?: boolean;
    taskId?: ProjectsTaskId;
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.TaskPreview]: GlobalModalState<{
    taskId: ProjectsTaskId;
    onSuccess?: () => void;
    onClose: () => void;
  }>;
  [GlobalModalNames.PollEditor]: GlobalModalState<{
    poll?: PollModel;
    onSuccess: (poll: PollModel) => void;
  }>;
  [GlobalModalNames.AdminOrgStructureCreateUpdateOrganization]: GlobalModalState<{
    organizationId?: string;
    onSuccess: () => void;
    onClose: () => void;
  }>;
  [GlobalModalNames.CreateUpdateSubdivision]: GlobalModalState<{
    subdivisionId?: string;
    onSuccess?: () => void;
    onClose: () => void;
  }>;
  [GlobalModalNames.UpdateEmployee]: GlobalModalState<{
    id: string;
    fullName: string;
    onSuccess: () => void;
    onClose: () => void;
    oldDepartmentId: string;
  }>;
  [GlobalModalNames.AdministrationCreatePermissionsAdmin]: GlobalModalState<{
    userId?: KeycloakId;
    onClose: () => void;
    serviceName: ServiceRolesNames;
    onSuccess: () => void;
  }>;
  [GlobalModalNames.AdminGroupsPagesAdministrators]: GlobalModalState<{
    userId?: KeycloakId;
    serviceName: ServiceRolesNames;
    onClose: () => void;
    onSuccess: () => void;
  }>;
  [GlobalModalNames.AdminPersonalPagesAdministrators]: GlobalModalState<{
    userId?: KeycloakId;
    serviceName: ServiceRolesNames;
    onClose: () => void;
    onSuccess: () => void;
  }>;
  [GlobalModalNames.AdministrationCreateUserAgreement]: GlobalModalState<{
    onSuccess: () => void;
  }>;
  [GlobalModalNames.AdministrationPreviewUserAgreement]: GlobalModalState<{
    name: string;
    htmlText: string;
  }>;
  [GlobalModalNames.ProfilePreviewUserAgreementCurrent]: GlobalModalState<{
    onClose: () => void;
  }>;
  [GlobalModalNames.ProfilePreviewUserAgreementArchive]: GlobalModalState<Record<string, any>>;
  [GlobalModalNames.TextFormatHelp]: GlobalModalState<{
    onClose: () => void;
  }>;
  [GlobalModalNames.SurveyGroupMailingCreate]: GlobalModalState<{
    groupMailingId?: GroupsMailingId;
    isCopyAction?: boolean;
    onClose: () => void;
  }>;
  [GlobalModalNames.SummaryAnalyticsExportToExcel]: GlobalModalState<{
    report: SummaryAnalyticsReportType;
  }>;
  [GlobalModalNames.BlogMembers]: GlobalModalState<{
    blog: BlogModel;
  }>;
  [GlobalModalNames.FileStorageCreate]: GlobalModalState<FileStorageCreatePayload>;
  [GlobalModalNames.FileStorageUpdate]: GlobalModalState<FileStorageUpdatePayload>;
  [GlobalModalNames.FileStorageCreateMultiple]: GlobalModalState<{
    uploadStore: FilesUploadStore<PreloadedFileModel, UiUploadFile>;
    parent: FileStorageEntryId;
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.FileStorageMoveEntries]: GlobalModalState<{
    rootId: FileStorageEntryId;
    rootName: string;
    moveItems: FileStorageEntryMove[];
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.DictionaryViewRecords]: GlobalModalState<{
    dictionary: DictsResponse;
    onClose: () => void;
    onSave: () => void;
  }>;
  [GlobalModalNames.FileStorageCategoryList]: GlobalModalState<{
    entryId: FileStorageEntryId;
    categoriesStorage: GetFileStorageCategoriesStorage;
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.FileStorageCategoryCreateUpdate]: GlobalModalState<{
    entryId: FileStorageEntryId;
    category?: FileStorageCategoryModel;
    onSuccess?: (category: FileStorageCategoryModel) => void;
    onClose: () => void;
  }>;
  [GlobalModalNames.IdeaCreateUpdate]: GlobalModalState<{
    ideaId?: IdeaModelId;
    onSuccess?: () => void;
    onClose: () => void;
  }>;
  [GlobalModalNames.GroupsPagesCreateGroup]: GlobalModalState<{
    sectionId?: CMSSectionId;
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.GroupsPagesCreateSection]: GlobalModalState<{
    rootSectionId: CMSSectionId;
    sectionId?: CMSSectionId;
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.GroupsPagesCreatePage]: GlobalModalState<{
    rootSectionId?: CMSSectionId;
    sectionId?: CMSSectionId;
    pageId?: CMSPageId;
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.GroupsPagesDownloadReport]: GlobalModalState<{
    onClose: () => void;
    onSuccess?: () => void;
    sectionId: CMSSectionId;
  }>;
  [GlobalModalNames.CreateUpdateGamificationThanks]: GlobalModalState<{
    thanks?: GamificationAdminThanks;
    onClose: () => void;
    onSuccess: () => void;
  }>;
  [GlobalModalNames.CreateUpdateGamificationBadge]: GlobalModalState<{
    badge?: GamificationAdminBadge;
    onClose: () => void;
    onSuccess: () => void;
  }>;
  [GlobalModalNames.ImageUploadCrop]: GlobalModalState<ImageUploadCropProps>;
  [GlobalModalNames.TimelineSettings]: GlobalModalState<{
    onClose: () => void;
  }>;
  [GlobalModalNames.SkillsUsers]: GlobalModalState<{
    userId: KeycloakId;
    userSkill: UserSkill;
    skillType: SkillType;
    step?: SkillsUsersSteps;
  }>;
};

export const globalModalsSettings: Record<GlobalModalNames, GlobalModalSettings> = {
  [GlobalModalNames.TimelineLongread]: {
    appearance: GlobalModalAppearances.PageOverlay,
    moduleName: 'TimelineLongread',
  },
  [GlobalModalNames.PostLongread]: {
    appearance: GlobalModalAppearances.PageOverlay,
    moduleName: 'PostLongread',
  },
  [GlobalModalNames.NewsLongread]: {
    appearance: GlobalModalAppearances.PageOverlay,
    moduleName: 'NewsLongread',
  },
  [GlobalModalNames.EventsLongread]: {
    appearance: GlobalModalAppearances.PageOverlay,
    moduleName: 'EventsLongread',
  },
  [GlobalModalNames.SurveysLongread]: {
    appearance: GlobalModalAppearances.PageOverlay,
    moduleName: 'SurveysLongread',
  },
  [GlobalModalNames.EventsList]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'EventsList',
  },
  [GlobalModalNames.CalendarCreateUpdateMeeting]: {
    appearance: GlobalModalAppearances.FullscreenModal,
    moduleName: 'CalendarCreateUpdateMeeting',
  },
  [GlobalModalNames.CalendarCreateUpdateTask]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'CalendarCreateUpdateTask',
  },
  [GlobalModalNames.CalendarEventDetail]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'CalendarEventDetail',
  },
  [GlobalModalNames.CreateUpdateBlog]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'BlogCreateUpdate',
  },
  [GlobalModalNames.CreateUpdateBlogManagerPermissions]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'CreateUpdateBlogManagerPermissions',
  },
  [GlobalModalNames.NewsEditor]: {
    appearance: GlobalModalAppearances.FullscreenModal,
    moduleName: 'NewsEditor',
  },
  [GlobalModalNames.NewsCreateCategory]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'NewsCreateCategory',
  },
  [GlobalModalNames.SurveyDetails]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'SurveyDetails',
  },
  [GlobalModalNames.SurveyChoiceDetails]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'SurveyChoiceDetails',
  },
  [GlobalModalNames.SurveyEditor]: {
    appearance: GlobalModalAppearances.FullscreenModal,
    moduleName: 'SurveyEditor',
  },
  [GlobalModalNames.SurveyGroupsMailing]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'SurveyGroupsMailing',
  },
  [GlobalModalNames.CreateUpdateEvent]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'CreateUpdateEvent',
  },
  [GlobalModalNames.MembershipApplications]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'MembershipApplications',
  },
  [GlobalModalNames.ProfileBadgeThanksView]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'ProfileBadgeThanksView',
  },
  [GlobalModalNames.NoteEditor]: {
    appearance: GlobalModalAppearances.FullscreenModal,
    moduleName: 'NoteEditor',
  },
  [GlobalModalNames.ProjectPreview]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'ProjectPreview',
  },
  [GlobalModalNames.ProjectCreate]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'ProjectCreate',
  },
  [GlobalModalNames.ProjectStages]: {
    appearance: GlobalModalAppearances.FullscreenModal,
    moduleName: 'ProjectStages',
  },
  [GlobalModalNames.ProjectParticipants]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'ProjectParticipants',
  },
  [GlobalModalNames.TaskCreate]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'TaskCreate',
  },
  [GlobalModalNames.TaskPreview]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'TaskPreview',
  },
  [GlobalModalNames.PollEditor]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'PollEditor',
  },
  [GlobalModalNames.AdminOrgStructureCreateUpdateOrganization]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'AdminOrgStructureCreateUpdateOrganization',
  },
  [GlobalModalNames.CreateUpdateSubdivision]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'CreateUpdateSubdivision',
  },
  [GlobalModalNames.UpdateEmployee]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'AdminOrgStructureUpdateEmployee',
  },
  [GlobalModalNames.AdministrationCreatePermissionsAdmin]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'AdministrationCreatePermissionsAdmin',
  },
  [GlobalModalNames.AdminGroupsPagesAdministrators]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'AdminGroupsPagesAdministrators',
  },
  [GlobalModalNames.AdminPersonalPagesAdministrators]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'AdminPersonalPagesAdministrators',
  },
  [GlobalModalNames.AdministrationCreateUserAgreement]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'AdministrationCreateUserAgreement',
  },
  [GlobalModalNames.AdministrationPreviewUserAgreement]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'AdministrationPreviewUserAgreement',
  },
  [GlobalModalNames.ProfilePreviewUserAgreementCurrent]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'ProfilePreviewUserAgreementCurrent',
  },
  [GlobalModalNames.ProfilePreviewUserAgreementArchive]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'ProfilePreviewUserAgreementArchive',
  },
  [GlobalModalNames.TextFormatHelp]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'TextFormatHelp',
  },
  [GlobalModalNames.SurveyGroupMailingCreate]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'SurveyGroupMailingCreate',
  },
  [GlobalModalNames.SummaryAnalyticsExportToExcel]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'SummaryAnalyticsExportToExcelModal',
  },
  [GlobalModalNames.BlogMembers]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'BlogMembers',
  },
  [GlobalModalNames.FileStorageCreate]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'FileStorageCreate',
  },
  [GlobalModalNames.FileStorageUpdate]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'FileStorageUpdate',
  },
  [GlobalModalNames.FileStorageCreateMultiple]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'FileStorageCreateMultiple',
  },
  [GlobalModalNames.FileStorageMoveEntries]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'FileStorageMoveEntries',
  },
  [GlobalModalNames.DictionaryViewRecords]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'DictionaryViewRecords',
  },
  [GlobalModalNames.FileStorageCategoryList]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'FileStorageCategoryList',
  },
  [GlobalModalNames.FileStorageCategoryCreateUpdate]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'FileStorageCategoryCreateUpdate',
  },
  [GlobalModalNames.IdeaCreateUpdate]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'IdeaCreateUpdate',
  },
  [GlobalModalNames.GroupsPagesCreateGroup]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'GroupsPagesCreateGroup',
  },
  [GlobalModalNames.GroupsPagesCreateSection]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'GroupsPagesCreateSection',
  },
  [GlobalModalNames.GroupsPagesCreatePage]: {
    appearance: GlobalModalAppearances.FullscreenModal,
    moduleName: 'GroupsPagesCreatePage',
  },
  [GlobalModalNames.GroupsPagesDownloadReport]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'GroupsPagesDownloadReport',
  },
  [GlobalModalNames.CreateUpdateGamificationThanks]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'CreateUpdateGamificationThanks',
  },
  [GlobalModalNames.CreateUpdateGamificationBadge]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'CreateUpdateGamificationBadge',
  },
  [GlobalModalNames.ImageUploadCrop]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'ImageUploadCrop',
  },
  [GlobalModalNames.TimelineSettings]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'TimelineSettings',
  },
  [GlobalModalNames.SkillsUsers]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'SkillsUsers',
  },
};
