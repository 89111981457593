import React, { FC } from 'react';

import { UiIcon, UiIconProps } from 'ant/components/ui/icon';
import { themePalette } from 'ant/theme';

export const ContactsLinkIcon: FC<Pick<UiIconProps, 'component' | 'height'>> = (props) => {
  const { component, height = 20 } = props;

  return <UiIcon component={component} color={themePalette.colorIcon} width={20} height={height} />;
};
