import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks';
import endOfMonth from 'date-fns/endOfMonth';
import endOfYear from 'date-fns/endOfYear';
import getDayOfYear from 'date-fns/getDayOfYear';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import getMonth from 'date-fns/getMonth';
import getWeek from 'date-fns/getWeek';
import getYear from 'date-fns/getYear';
import nextMonday from 'date-fns/nextMonday';
import previousMonday from 'date-fns/previousMonday';
import startOfMonth from 'date-fns/startOfMonth';
import subMonths from 'date-fns/subMonths';
import subWeeks from 'date-fns/subWeeks';

import { capitalizeFirstLetter } from 'ant/plugins/utils/capitalize-first-letter';
import { getFormattedDate } from 'ant/plugins/utils/get-formatted-date';
import { BaseTimeInterval } from 'ant/types/models/base/base-time-date';
import { ProjectsStageModel } from 'ant/types/models/projects';

const MONTH_IN_YEAR = 12;
const WEEK_MIN_SHOW = 24;
const MIN_WEEK_IN_MONTH = 2;

export const getDaysPerYear = (year: number) => getDayOfYear(endOfYear(new Date(year, 0, 1)));

export const getDaySizeInYear = (year: number, width?: number) => (width ? width / getDaysPerYear(year) : 0);

export const getOffsetInDays = (startDate: string, endDate: string) =>
  differenceInCalendarDays(new Date(endDate), new Date(startDate));

export const getYearByMonth = (year: number, isShort = false) =>
  Array.from(Array(MONTH_IN_YEAR)).map((_, index) => {
    const name = capitalizeFirstLetter(getFormattedDate(new Date(year, index), isShort ? 'MMM' : 'MMMM'));
    const days = getDaysInMonth(new Date(year, index));

    return { id: index, name, days };
  });

/** Возвращает данные о месяцах интервала, но не менее чем 12 и при отсутствии start считает с сегодняшнего дня */
export const getAllMonthsOfInterval = (start?: string, end?: string, shift = 1) => {
  const startDate = subMonths(start ? new Date(start) : new Date(), shift);
  const endDate = addMonths(end ? new Date(end) : addMonths(startDate, MONTH_IN_YEAR), shift + 1);
  const currentDiffMonthsCount = differenceInCalendarMonths(endDate, startDate);
  const diffMonthsCount = currentDiffMonthsCount < MONTH_IN_YEAR ? MONTH_IN_YEAR : currentDiffMonthsCount;

  return Array.from(Array(diffMonthsCount)).map((_, index) => {
    const currentDate = addMonths(startDate, index);
    const year = getYear(currentDate);
    const month = getMonth(currentDate);

    const name = capitalizeFirstLetter(getFormattedDate(new Date(year, month), 'MMMM'));
    const days = getDaysInMonth(new Date(year, month));

    return { id: `${year}_${month}`, year, month, days, name };
  });
};

export type GetAllMonthsOfInterval = ReturnType<typeof getAllMonthsOfInterval>[number];

export const getReferenceTimeMonthsOfInterval = (start?: string, end?: string) => {
  const months = getAllMonthsOfInterval(start, end);
  const [firstMonth] = months;

  return new Date(firstMonth.year, firstMonth.month, 1).toDateString();
};

/** Возвращает количество дней для каждого года попавших в интервал */
export const getCountDaysForYearsInInterval = (months: { year: number; days: number }[]) => {
  const uniqueYears = new Set(months.map(({ year }) => year));
  const yearSize = new Map();

  uniqueYears.forEach((item) => yearSize.set(item, 0));
  months.forEach(({ year, days }) => yearSize.set(year, yearSize.get(year) + days));

  return Array.from(yearSize).map(([year, days]) => ({ year, days }));
};

export const getStagesInterval = (stages: ProjectsStageModel[]): Partial<BaseTimeInterval> => {
  const timeStart = stages.at(0)?.timeStart;
  const timeEnd = stages.at(-1)?.timeEnd;

  return { timeStart, timeEnd };
};

export const getFirstDayInWeeksOfInterval = (start?: string) => {
  const preStartDate = previousMonday(start ? new Date(start) : new Date());

  return subWeeks(preStartDate, MIN_WEEK_IN_MONTH);
};

export const getReferenceTimeWeeksOfInterval = (start?: string) => {
  const startDate = getFirstDayInWeeksOfInterval(start);

  return addWeeks(startDate, 1).toDateString();
};

export const getAllWeeksOfInterval = (start?: string, end?: string) => {
  const startDate = getFirstDayInWeeksOfInterval(start);
  const endDate = nextMonday(end ? new Date(end) : addWeeks(startDate, WEEK_MIN_SHOW));

  const currentDiffWeeksCount = differenceInCalendarWeeks(endDate, startDate);
  const diffWeeksCount = currentDiffWeeksCount < WEEK_MIN_SHOW ? WEEK_MIN_SHOW : currentDiffWeeksCount;

  return Array.from(Array(diffWeeksCount)).map((_, index) => {
    const currentDate = addWeeks(startDate, index);
    const year = getYear(currentDate);
    const week = getWeek(currentDate);
    const month = getMonth(currentDate);
    const monthName = capitalizeFirstLetter(getFormattedDate(new Date(year, month), 'LLLL'));

    let monthDays = getDaysInMonth(new Date(year, month));

    if (index === 0) {
      monthDays = differenceInCalendarDays(endOfMonth(new Date(year, month)), currentDate);
    }

    if (index === diffWeeksCount - 1) {
      monthDays = differenceInCalendarDays(currentDate, startOfMonth(new Date(year, month)));
    }

    return { id: `${year}_${week}`, year, week, weekDays: 7, month, monthDays, monthName };
  });
};

export type GetCountWeeksForMonthInInterval = ReturnType<typeof getAllWeeksOfInterval>[number];
type ReturnCountWeeksForMonthInInterval = Pick<
  GetCountWeeksForMonthInInterval,
  'id' | 'year' | 'month' | 'monthDays' | 'monthName'
>;

export const getCountWeeksForMonthsInInterval = (weeks: GetCountWeeksForMonthInInterval[]) => {
  const months = new Map<string, ReturnCountWeeksForMonthInInterval>();

  weeks.forEach((item) => {
    const { year, month, monthDays, monthName } = item;
    const id = `${item.year}_${item.month}`;
    const currentMonthDays = months.get(id)?.monthDays || 0;

    if (!months.has(id) || currentMonthDays > item.monthDays) {
      months.set(id, { id, year, month, monthDays, monthName });
    }
  });

  return Array.from(months.values());
};
