import classNames from 'classnames';
import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';

import styles from './UiButtonDecorator.scss';

export type UiButtonDecoratorProps = HTMLAttributes<HTMLDivElement>;

export const UiButtonDecorator: FC<PropsWithChildren<UiButtonDecoratorProps>> = (props) => {
  const { children, className, ...rest } = props;

  return (
    <div className={classNames(styles.uiButtonDecorator, className)} role="button" tabIndex={0} {...rest}>
      {children}
    </div>
  );
};
