import classNames from 'classnames';
import React from 'react';
import SkeletonLoading, { SkeletonProps as ReactSkeletonProps } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import styles from './UiSkeleton.scss';

export type UiSkeletonProps = {
  loading?: boolean;
  block?: boolean;
  vertical?: boolean;
  children?: React.ReactNode;
} & ReactSkeletonProps;

export type UiSkeletonWrapperProps = {
  block: boolean;
  children: React.ReactNode;
};

const SkeletonWrapper: React.FC<UiSkeletonWrapperProps> = ({ block, children }) =>
  block ? <div>{children}</div> : <>{children}</>;

export const UiSkeleton: React.FC<UiSkeletonProps> = (props) => {
  const { loading = false, block = false, vertical = false, children, count, className } = props;
  const isVerticalList = Boolean(count && vertical);

  return loading ? (
    <SkeletonWrapper block={block}>
      <SkeletonLoading
        {...props}
        className={classNames(className, {
          [styles.uiSkeleton__vertical]: isVerticalList,
        })}
      />
    </SkeletonWrapper>
  ) : (
    <>{children}</>
  );
};
