// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._408f7c0b_ui-widget-header{flex:1;display:flex;align-items:center;justify-content:space-between;padding:24px 0}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/widget-header/UiWidgetHeader.scss"],"names":[],"mappings":"AACA,4BACE,MAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,cAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.ui-widget-header {\n  flex: 1;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: $baseSpacing 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-widget-header": "_408f7c0b_ui-widget-header",
	"uiWidgetHeader": "_408f7c0b_ui-widget-header"
};
export default ___CSS_LOADER_EXPORT___;
