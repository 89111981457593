import { BlogAlbumImageModel } from 'ant/types/models/blog.model';

import { dijkstra } from './dijkstra';

export function getRowHeight(images: BlogAlbumImageModel[], width: number, gapBetweenImages: number) {
  const rowWidth = width - (images.length - 1) * gapBetweenImages;

  let h = 0;

  for (let i = 0; i < images.length; ++i) {
    h += images[i].width / images[i].height;
  }

  return rowWidth / h;
}

export interface PreparedImage
  extends Pick<BlogAlbumImageModel, 'fileStorageImageUrl' | 'fileStorageImageId'> {
  id: number | string;
  width: number;
  height: number;
}

export const getImageObject = (image: BlogAlbumImageModel, width: number, height: number): PreparedImage => ({
  ...image,
  width,
  height,
});

export function getImagesDimensions(images: BlogAlbumImageModel[], height: number) {
  return images.map((image) => {
    const width = (height * image.width) / image.height;

    return getImageObject(image, width, height);
  });
}

function fCost(
  images: BlogAlbumImageModel[],
  i: number,
  j: number,
  width: number,
  preferredHeight: number,
  gapBetweenImages: number,
) {
  const slices = images.slice(i, j);
  const height = getRowHeight(slices, width, gapBetweenImages);

  let diff = 0;

  for (let index = 0; index < slices.length; ++index) {
    const idealArea = (preferredHeight * preferredHeight * slices[index].width) / slices[index].height;
    const effectiveArea = (height * height * slices[index].width) / slices[index].height;

    diff += Math.abs(idealArea - effectiveArea);
  }

  return diff;
}

type GetImageForGridOptions = {
  preferredHeight: number;
  containerWidth: number;
  gapBetweenImages: number;
};

type PreparedImageRow = {
  id: number;
  height: number;
  images: PreparedImage[];
};

export function getImagesForGrid(
  rowImages: BlogAlbumImageModel[],
  args: GetImageForGridOptions,
): PreparedImageRow[] {
  const { preferredHeight, containerWidth, gapBetweenImages } = args;

  const size = containerWidth;

  if (rowImages.length < 2) {
    return [{ id: 1, height: preferredHeight, images: getImagesDimensions(rowImages, preferredHeight) }];
  }

  const cachedCost: Record<string, number> = {};

  function cost(
    images: BlogAlbumImageModel[],
    i: number,
    j: number,
    width: number,
    preferreRowHeight: number,
  ) {
    const key = `${i},${j}`;

    if (!(key in cachedCost)) {
      cachedCost[key] = fCost(images, i, j, width, preferreRowHeight, gapBetweenImages);
    }

    return cachedCost[key];
  }

  const graph = (start: string) => {
    const results: Record<string, number> = {};

    const startNum = Number(start.replace(/[^0-9]/g, ''));

    results[`node${startNum}`] = 0;

    for (let i = startNum + 1; i < rowImages.length + 1; ++i) {
      if (i - startNum > 8) {
        break;
      }

      const c = cost(rowImages, startNum, i, size, preferredHeight);

      if (c !== null) {
        results[`node${i}`] = c;
      }
    }

    return results;
  };

  let path = dijkstra.find_path(graph, 'node0', `node${rowImages.length}`);

  path = path.map((e) => {
    return +e.replace(/[^0-9]/g, '');
  });

  const preparedRowsOfImages = [];

  for (let i = 1; i < path.length; ++i) {
    const slice = rowImages.slice(path[i - 1], path[i]);

    const height = getRowHeight(slice, size, gapBetweenImages);

    const row: PreparedImageRow = { id: i, images: getImagesDimensions(slice, height), height };

    preparedRowsOfImages.push(row);
  }

  return preparedRowsOfImages;
}
