import { useEffect, useCallback } from 'react';

import { NotificationPayloads, NotificationTypes } from './events';
import { NotificationEventListenersGlobal } from './events-listeners';
import { getNotificationEvent } from './helpers';

export type NotificationListener<P> = (payload: P) => void;

export function useNotificationEvent<K extends NotificationTypes>(
  eventName: K,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  listener: NotificationListener<NotificationPayloads[K]> = () => {},
) {
  const notificationEvent = getNotificationEvent(eventName);
  const actualListener = useCallback(listener, [eventName]);

  useEffect(() => {
    const unsubscribe = notificationEvent.watch(actualListener);

    return () => unsubscribe();
  }, [notificationEvent]);
}

export function useNotificationEventListenersGlobal<K extends NotificationTypes>(
  eventListeners: NotificationEventListenersGlobal,
) {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  Object.entries(eventListeners).forEach(([eventName, listener = () => {}]) => {
    useNotificationEvent(eventName as K, listener as NotificationListener<NotificationPayloads[K]>);
  });
}
