import React from 'react';

import { UiUser } from 'ant/components/ui/user';

type PostHeaderSkeletonProps = typeof UiUser.Info.Skeleton;

const PostHeaderSkeleton: PostHeaderSkeletonProps = (props) => {
  return <UiUser.Info.Skeleton size={40} {...props} />;
};

export { PostHeaderSkeleton };
