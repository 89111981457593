import isBoolean from 'lodash/isBoolean';
import React from 'react';
import { Route, Navigate } from 'react-router-dom';

import { RouteNames, routes } from './routes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type JSXFunction = (props: any) => JSX.Element;
export type PagesMapping<R = RouteNames> = {
  [key in R & string]: JSXFunction;
};

type LoginPath = string;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BuildRoutesFromConfig = any[]; // TODO избавиться от any

export function buildRoutesFromConfig<P extends PagesMapping>(
  pagesMapping: Partial<P>,
  loggedIn?: undefined,
  loginPath?: undefined,
): BuildRoutesFromConfig;

export function buildRoutesFromConfig<P extends PagesMapping>(
  pagesMapping: Partial<P>,
  loggedIn: boolean,
  loginPath: LoginPath,
): BuildRoutesFromConfig;

export function buildRoutesFromConfig(
  pagesMapping: Partial<PagesMapping>,
  loggedIn?: boolean,
  loginPath: LoginPath = '/',
): BuildRoutesFromConfig {
  return Object.keys(pagesMapping).map((routeName) => {
    const path = (routes as Record<string, string>)[routeName];
    const redirectTo = {
      pathname: loginPath as string,
      state: { referrer: path },
    };

    const Component = (pagesMapping as Record<string, JSXFunction>)[routeName];
    const ComponentRedirect = loggedIn ? Component : () => <Navigate to={redirectTo} />;
    const RouteRender = !isBoolean(loggedIn) ? Component : ComponentRedirect;

    return <Route path={path} key={routeName} Component={RouteRender} />;
  });
}
