import { AutoComplete as AntAutoComplete, AutoCompleteProps, Input as AntInput } from 'antd';
import classNames from 'classnames';
import React from 'react';

import DropDownSvg from 'ant/components/svg/drop-down.svg';
import { UiIcon } from 'ant/components/ui/icon';
import {
  UiBaseSelectRef,
  UiOptionData as UiSelectOptionData,
  UiRefSelectProps,
  UiSelectBaseOptionType,
  UiSelectMaxLengthProps,
} from 'ant/components/ui/select';

import styles from './UiAutoComplete.scss';

export { getPopupContainerParentElement } from '../select';

export const AnimatedDropDownIcon = () => <UiIcon width={20} height={20} component={DropDownSvg} />;

export type UiOptionData = UiSelectOptionData;
// @ts-expect-error TODO: проверить работает ли maxLength, потому что раньше это был хак
export interface UiAutoCompleteProps<
  ValueType = any,
  OptionType extends UiSelectBaseOptionType | UiOptionData = UiOptionData,
> extends AutoCompleteProps<ValueType, OptionType>,
    UiSelectMaxLengthProps {
  prefix?: React.ReactNode;
  placeholder?: React.ReactNode | string;
}

const InternalUiAutoComplete: React.ForwardRefRenderFunction<UiRefSelectProps, UiAutoCompleteProps> = (
  props,
  ref,
) => {
  const { suffixIcon, placeholder, size, value, className, prefix, maxLength, ...rest } = props;
  const suffix = suffixIcon !== undefined ? suffixIcon : <AnimatedDropDownIcon />;

  const placeholders = {
    input: typeof placeholder === 'string' ? placeholder : undefined,
    select: React.isValidElement(placeholder) ? placeholder : undefined,
  };

  return (
    <AntAutoComplete
      ref={ref}
      value={value}
      className={classNames(styles.uiAutoComplete, className)}
      placeholder={placeholders.select}
      // TODO: это единственный способ использовать maxLength в antd@4.20.3
      maxLength={maxLength}
      {...rest}
    >
      <AntInput size={size} placeholder={placeholders.input} value={value} suffix={suffix} prefix={prefix} />
    </AntAutoComplete>
  );
};

export const UiAutoComplete = React.forwardRef<UiRefSelectProps, UiAutoCompleteProps>(
  InternalUiAutoComplete,
) as unknown as (<ValueType = any, OptionType extends UiSelectBaseOptionType | UiOptionData = UiOptionData>(
  props: React.PropsWithChildren<UiAutoCompleteProps<ValueType, OptionType>> & {
    ref?: React.Ref<UiBaseSelectRef>;
  },
) => React.ReactElement) & {
  Option: typeof AntAutoComplete.Option;
};

UiAutoComplete.Option = AntAutoComplete.Option;
