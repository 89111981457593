import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import styles from './UiCardContent.scss';

export interface UiCardContentProps {
  className?: string;
  style?: React.CSSProperties;
  basePadding?: boolean;
}

export const UiCardContent: FC<PropsWithChildren<UiCardContentProps>> = (props) => {
  const { children, className, style, basePadding } = props;

  return (
    <div style={style} className={classNames(className, { [styles.uiCardContent_basePadding]: basePadding })}>
      {children}
    </div>
  );
};
