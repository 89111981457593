import React, { FC } from 'react';

import { UiSkeleton } from 'ant/components/ui/skeleton';

import styles from '../UiUserLinkCell.scss';

interface Props {
  size: number;
}

export const UiUserLinkCellLoading: FC<Props> = ({ size }) => {
  return (
    <>
      <div className={styles.userLinkCell}>
        <UiSkeleton circle loading width={size} height={size} />
        <UiSkeleton loading width={150} height={20} className={styles.userLinkCell__name} />
      </div>
    </>
  );
};
