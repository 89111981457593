import { BarConfig } from '@ant-design/charts';
import React, { FC, PropsWithChildren } from 'react';

import EmojiDisappointedFaceSvg from 'ant/components/svg/emoji-disappointed-face.svg';
import EmojiFrowningFaceSvg from 'ant/components/svg/emoji-frowning-face.svg';
import EmojiHeartEyesSvg from 'ant/components/svg/emoji-heart-eyes.svg';
import EmojiNeutralFaceSvg from 'ant/components/svg/emoji-neutral-face.svg';
import EmojiSmilingFaceSvg from 'ant/components/svg/emoji-smiling-face.svg';
import FavoriteSvg from 'ant/components/svg/favorite.svg';
import { UiIcon } from 'ant/components/ui/icon';
import { createArrayMock } from 'ant/plugins/create-mocks';
import { themePalette } from 'ant/theme';

import styles from './ChartBarLabel.scss';

export enum CustomLabels {
  Emoji = 'emoji',
  Stars = 'start',
  Default = 'default',
}

export enum RatingEmoji {
  HeartEyes = 'heart-eyes',
  SmilingFace = 'smiling-face',
  NeutralFace = 'neutral-face',
  FrowningFace = 'frowning-face',
  DisappointedFace = 'disappointed-face',
}

const iconsMap = {
  [RatingEmoji.HeartEyes]: EmojiHeartEyesSvg,
  [RatingEmoji.SmilingFace]: EmojiSmilingFaceSvg,
  [RatingEmoji.NeutralFace]: EmojiNeutralFaceSvg,
  [RatingEmoji.FrowningFace]: EmojiFrowningFaceSvg,
  [RatingEmoji.DisappointedFace]: EmojiDisappointedFaceSvg,
};

type BarConfigData = Pick<BarConfig, 'data'>;
export interface UiChartBarLabelProps extends BarConfigData {
  type: CustomLabels;
}

const UiChartBarLabel: FC<PropsWithChildren<UiChartBarLabelProps>> = (props) => {
  const { children, data, type = CustomLabels.Default } = props;
  const isType = (label: CustomLabels) => label === type;

  const LabelContainer: FC<PropsWithChildren> = (labelProps) => {
    return (
      <aside className={styles.chartBarLabel__labels}>
        <div className={styles.chartBarLabel__labelsContainer} {...labelProps} />
      </aside>
    );
  };

  const ChartContainer: FC<PropsWithChildren> = (chartProps) => {
    return <main className={styles.chartBarLabel} {...chartProps} />;
  };

  const renderStars = (stars: number) => {
    const starsArray = createArrayMock(stars, (_, idx) => idx + 1);

    return starsArray.map((star) => (
      <UiIcon
        width={20}
        height={20}
        component={FavoriteSvg}
        style={{ color: themePalette.colorAccidentOrange }}
        key={star}
      />
    ));
  };

  const renderStarRows = ({ data: starsData = [] }: BarConfigData) => {
    const rows = createArrayMock(starsData.length, (_, index) => index + 1);

    return rows.map((row) => (
      <div key={row} className={styles.chartBarLabel__stars}>
        {renderStars(row)}
      </div>
    ));
  };

  const renderEmoji = ({ data: emojiData = [] }: BarConfigData) => {
    return (
      <div className={styles.chartBarLabel__emoji}>
        {emojiData.map((item) => (
          <UiIcon
            className={styles.chartBarLabel__emojiIcon}
            width={20}
            height={20}
            component={iconsMap[item.type as RatingEmoji]}
            key={`${item.type}`}
          />
        ))}
      </div>
    );
  };

  if (isType(CustomLabels.Default)) {
    return <>{children}</>;
  }

  return (
    <ChartContainer>
      <LabelContainer>
        {isType(CustomLabels.Stars) ? renderStarRows({ data }) : renderEmoji({ data })}
      </LabelContainer>
      <div className={styles.chartBarLabel__chart}>{children}</div>
    </ChartContainer>
  );
};

export { UiChartBarLabel };
