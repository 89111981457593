import React, { FC, useCallback, useMemo } from 'react';

import { UiCheckbox } from 'ant/components/ui/checkbox';
import { UiFileDirection } from 'ant/components/ui/file';
import { UiList } from 'ant/components/ui/list';
import { ActionsDropdown, ActionsDropdownItem } from 'ant/components/widgets/ActionsDropdown';
import { FileProps } from 'ant/components/widgets/File';
import { FileList } from 'ant/components/widgets/FileList';
import { FileStorageListItemSelect } from 'ant/components/widgets/FileStorageList';
import { FileStorageListEntryModel } from 'ant/types/models/file.model';
import { Placement } from 'ant/types/placement';

type FileStorageItemFunction = (item: FileStorageListEntryModel) => void;

export interface FileStorageListItemProps extends Pick<FileProps, 'direction'> {
  fileSelect?: FileStorageListItemSelect;
  onClick: FileStorageItemFunction;
  file: FileStorageListEntryModel;
  onFileSelect?: FileStorageItemFunction;
  getFileActions: (file: FileStorageListEntryModel) => ActionsDropdownItem[];
}

export const FileStorageListItem: FC<FileStorageListItemProps> = (props) => {
  const { onClick, onFileSelect, fileSelect, file, direction, getFileActions, ...restProps } = props;

  const actions = useMemo(() => {
    const dropdownActions = getFileActions(file);

    return dropdownActions.length > 0 ? [<ActionsDropdown items={dropdownActions} />] : [];
  }, [file]);

  const onItemClick = useCallback(() => {
    if (fileSelect) {
      onFileSelect?.(file);
    } else {
      onClick(file);
    }
  }, [file, fileSelect, onClick, onFileSelect]);

  return (
    <UiList.Item
      style={{
        padding: direction === UiFileDirection.Horizontal ? '6px 24px' : 0,
        borderRadius: direction === UiFileDirection.Vertical ? 4 : 0,
      }}
      hoverable
      onClick={onItemClick}
    >
      {fileSelect && (
        <UiCheckbox
          style={{ marginRight: direction === UiFileDirection.Horizontal ? 24 : 0 }}
          placement={direction === UiFileDirection.Vertical ? Placement.TopRight : undefined}
          shift={10}
          value={file.id}
        />
      )}
      <FileList.Item {...restProps} actions={actions} file={file} direction={direction} />
    </UiList.Item>
  );
};
