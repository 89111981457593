import { AxiosError } from 'axios';
import { createEffect, createEvent } from 'effector';

import { UiOptionData } from 'ant/components/ui/select';
import { GroupsEndpoints } from 'ant/endpoints/groups';
import { ProfileEndpoints } from 'ant/endpoints/profile';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { getFullName } from 'ant/plugins/name-formatters';
import {
  buildEndpointWithQueryParams,
  EndpointQueryParamsBaseType,
} from 'ant/plugins/utils/endpoint-builder';
import { CreateDictionaryRecord } from 'ant/store/dictionaries/api';
import { DictDataParams, DictMatchTypes, DictPaginated } from 'ant/types/api';
import { FetchSelectEntityParams } from 'ant/types/creatable-multiselect';
import { RecordResponse } from 'ant/types/dictionary';
import {
  GroupDivisionModel,
  GroupMemberStructureInfo,
  GroupMemberStructureInfoModel,
} from 'ant/types/models/groups.model';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import { OptionBase } from 'ant/types/models/option';
import {
  ContactsModel,
  ProfileAdditionalInfoModel,
  ProfileBiographyModel,
  ProfileInfoModel,
  ProfileJobHistoryModel,
  ProfileProjectItemModel,
  ProfileProjectModel,
} from 'ant/types/models/profile.model';
import {
  UserProfileModel,
  UserJobModel,
  UserProfileJobModel,
  UserAuthorModel,
  FullNameModel,
  UserPositionModel,
} from 'ant/types/models/user.model';

import { getDictsStorage } from '../dictionaries';
import { Dictionaries } from '../dictionaries/dictionaries';
import {
  BiographyParams,
  ContactsParams,
  createProfileProject,
  JobsHistoryParams,
  MainEditParams,
  patchBiographyProfile,
  patchJobHistoryProfile,
  patchMainProfile,
  patchProfileContacts,
  patchProfileUserAdditionalInfo,
  patchProfileUserProjects,
  ProfileFullInfoParams,
  ProjectsParams,
} from './api';

export type UserIdParams = { userId: KeycloakId };

type FetchProfileParams = {
  userId: KeycloakId;
};

export const getProfileStorage = () => {
  const storage = abstractStorageFactory<UserProfileModel, UserProfileModel, null, FetchProfileParams>({
    endpointBuilder: ({ userId }) => ProfileEndpoints.main(userId),
    defaultValue: null,
  });

  return { storage };
};

export const getProfileJobStorage = () => {
  const storage = abstractStorageFactory<UserJobModel, UserJobModel, null, FetchProfileParams>({
    endpointBuilder: ({ userId }) => ProfileEndpoints.job(userId),
    defaultValue: null,
  });

  return { storage };
};

export interface ProfileListParams extends Partial<FullNameModel>, EndpointQueryParamsBaseType {
  ordering?: string;
  usersIds?: KeycloakId[];
  username?: string;
  email?: string;
  isActive?: boolean;
  skipEmptyName?: boolean;
  exact?: boolean;
  excludeUsers?: KeycloakId[];
  withContacts?: boolean;
  skipEmptyOrganization?: boolean;
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}

export const getProfileListOptionsStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<UserProfileJobModel>,
    UiOptionData[],
    UiOptionData[],
    Partial<ProfileListParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(ProfileEndpoints.v2List(), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) =>
      data.items.map((item) => ({
        value: item.id,
        label: getFullName(item.fullName),
        job: item.job?.position,
        avatar: item.smallAvatar,
        data: item,
      })),
  });

  return { storage };
};

export const getProfileContactsStorage = ({ userId }: UserIdParams) => {
  const storage = abstractStorageFactory<ContactsModel, ContactsModel, null>({
    endpointBuilder: () => ProfileEndpoints.contacts(userId),
    cancelPendingRequestOnFetch: true,
    defaultValue: null,
  });

  const patchProfileContactsEffect = createEffect<ContactsParams, ContactsModel, AxiosError>((params) =>
    patchProfileContacts(params).then((response) => response.data),
  );

  storage.store.on(patchProfileContactsEffect.doneData, (state, contacts) =>
    state.data
      ? {
          ...state,
          data: {
            ...state.data,
            ...contacts,
          },
        }
      : state,
  );

  return { storage, patchProfileContactsEffect };
};

export const getProfilesListStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<UserAuthorModel>,
    UserAuthorModel[],
    UserAuthorModel[],
    Partial<ProfileListParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(ProfileEndpoints.v2List(), params),
    dataMapper: (data) => data.items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    defaultValue: [],
    dataBuilder: (ids) => ids,
  });

  return { storage };
};

export type ProfilesListStorage = ReturnType<typeof getProfilesListStorage>;

type ProfilePositionListParams = {
  query: string;
};

export const getProfilePositionListStorage = () => {
  const storage = abstractStorageFactory<
    UserPositionModel[],
    UserPositionModel[],
    UserPositionModel[],
    Partial<ProfilePositionListParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(ProfileEndpoints.listPositions(), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

type ProfileStructureInfoMapParams = {
  ids: KeycloakId[];
};

export const getProfileStructureInfoMapStorage = () => {
  const storage = abstractStorageFactory<
    GroupMemberStructureInfoModel[],
    Record<KeycloakId, GroupMemberStructureInfo>,
    Record<KeycloakId, GroupMemberStructureInfo>,
    ProfileStructureInfoMapParams
  >({
    endpointBuilder: GroupsEndpoints.membersGroups,
    dataMapper: (data) => Object.fromEntries(data.map(({ userId, ...info }) => [userId, info])),
    dataBuilder: ({ ids }) => ({ ids }),
    requestMethod: 'post',
    defaultValue: {},
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

type ProfileStructureInfoParams = {
  id: KeycloakId;
};

export const getProfileStructureInfoStorage = () => {
  const storage = abstractStorageFactory<
    GroupMemberStructureInfoModel[],
    GroupMemberStructureInfo,
    GroupMemberStructureInfo | null,
    ProfileStructureInfoParams
  >({
    endpointBuilder: GroupsEndpoints.membersGroups,
    dataBuilder: ({ id }) => ({ ids: [id] }),
    dataMapper: ([{ division, organization }]) => ({ organization, division }),
    requestMethod: 'post',
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export type GetProfileStructureInfoStorage = ReturnType<typeof getProfileStructureInfoStorage>;

const getProfileFullInfoStorage = () => {
  const storage = abstractStorageFactory<ProfileInfoModel, ProfileInfoModel, null, ProfileFullInfoParams>({
    endpointBuilder: ({ userId }) => ProfileEndpoints.full(userId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  const updateAvatarEvent = createEvent<string>();

  storage.store.on(updateAvatarEvent, (state, avatar) => {
    if (!state.data) {
      return state;
    }

    return {
      ...state,
      data: {
        ...state.data,
        main: {
          ...state.data.main,
          avatar,
        },
      },
    };
  });

  const patchMainProfileEffect = createEffect<MainEditParams, UserProfileModel, AxiosError>((params) =>
    patchMainProfile(params).then((response) => response.data),
  );

  storage.store.on(patchMainProfileEffect.doneData, (state, main) => {
    if (!state.data) {
      return state;
    }

    return {
      ...state,
      data: {
        ...state.data,
        main,
      },
    };
  });

  const patchJobHistoryProfileEffect = createEffect<JobsHistoryParams, ProfileJobHistoryModel[], AxiosError>(
    (params) => patchJobHistoryProfile(params).then((response) => response.data),
  );

  storage.store.on(patchJobHistoryProfileEffect.doneData, (state, jobHistory) => {
    if (!state.data) {
      return state;
    }

    return {
      ...state,
      data: {
        ...state.data,
        jobHistory,
      },
    };
  });

  const patchBiographyProfileEffect = createEffect<BiographyParams, ProfileBiographyModel, AxiosError>(
    (params) => patchBiographyProfile(params).then((response) => response.data),
  );

  storage.store.on(patchBiographyProfileEffect.doneData, (state, biography) => {
    if (!state.data) {
      return state;
    }

    return {
      ...state,
      data: {
        ...state.data,
        biography,
      },
    };
  });

  const patchProfileUserAdditionalInfoEffect = createEffect<
    ProfileAdditionalInfoModel,
    ProfileAdditionalInfoModel,
    AxiosError
  >((params) => patchProfileUserAdditionalInfo(params).then((response) => response.data));

  storage.store.on(patchProfileUserAdditionalInfoEffect.doneData, (state, additionalInfo) => {
    if (!state.data) {
      return state;
    }

    return {
      ...state,
      data: {
        ...state.data,
        additionalInfo,
      },
    };
  });

  const patchProfileUserProjectsEffect = createEffect<ProjectsParams, ProfileProjectItemModel[], AxiosError>(
    (params) => patchProfileUserProjects(params).then((response) => response.data),
  );

  storage.store.on(patchProfileUserProjectsEffect.doneData, (state, projects) => {
    if (!state.data) {
      return state;
    }

    return {
      ...state,
      data: {
        ...state.data,
        projects,
      },
    };
  });

  return {
    storage,
    updateAvatarEvent,
    patchJobHistoryProfileEffect,
    patchMainProfileEffect,
    patchBiographyProfileEffect,
    patchProfileUserAdditionalInfoEffect,
    patchProfileUserProjectsEffect,
  };
};

export const profileFullInfoStorage = getProfileFullInfoStorage();

export const competenciesDictsStorage = getDictsStorage<RecordResponse, DictDataParams>({
  dictionaryName: Dictionaries.Names.Competencies,
  dataBuilder: ({ name: { type, value } }) => ({ name: { type, value } }),
  getEndpointParams: () => ({ ordering: 'name' }),
});

export const convertSearchProjectToDictLikeEntity = (item: OptionBase): RecordResponse => {
  const { id, name } = item;

  return {
    id: typeof id === 'string' ? id : String(id),
    attributes: { name },
    isDeleted: false,
    isEnabled: true,
    dictionary: '',
    deletedAt: '',
    created: '',
  };
};

export const getProfileProjectDictLikeStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<ProfileProjectModel>,
    RecordResponse[],
    RecordResponse[],
    FetchSelectEntityParams
  >({
    endpointBuilder: () =>
      buildEndpointWithQueryParams(ProfileEndpoints.searchProjects(), { ordering: 'name' }),
    dataBuilder: ({ value }) => ({ name: { type: DictMatchTypes.Icontains, value } }),
    requestMethod: 'post',
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) => data.items.map(convertSearchProjectToDictLikeEntity),
  });

  const createProfileProjectDictLikeEffect = createEffect<CreateDictionaryRecord, RecordResponse, AxiosError>(
    ({ attributes }) =>
      attributes.name
        ? createProfileProject<ProfileProjectModel>({ name: attributes.name }).then(({ data }) =>
            convertSearchProjectToDictLikeEntity(data),
          )
        : Promise.reject(),
  );

  storage.store.on(createProfileProjectDictLikeEffect.doneData, (state, newProject) => ({
    ...state,
    data: [...state.data, newProject],
  }));

  return { storage, createProfileProjectDictLikeEffect };
};

export const getProfilePositionDictLikeStorage = () => {
  const storage = abstractStorageFactory<
    UserPositionModel[],
    RecordResponse[],
    RecordResponse[],
    FetchSelectEntityParams
  >({
    endpointBuilder: ({ value }) =>
      buildEndpointWithQueryParams(ProfileEndpoints.listPositions(), { query: value }),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) => data.map(convertSearchProjectToDictLikeEntity),
  });

  return { storage };
};

export const getProfileDepartmentDictLikeStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<GroupDivisionModel>,
    RecordResponse[],
    RecordResponse[],
    FetchSelectEntityParams
  >({
    endpointBuilder: ({ value }) =>
      buildEndpointWithQueryParams(GroupsEndpoints.department(), { name: value }),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items.map(convertSearchProjectToDictLikeEntity),
  });

  return { storage };
};
