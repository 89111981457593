import React, { FC } from 'react';

import { UiDivider } from 'ant/components/ui/divider';
import { UiSpace } from 'ant/components/ui/space';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { RouterLink } from 'ant/components/widgets/RouterLink';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { BlogFullModel } from 'ant/types/models/blog.model';

type Props = {
  blogs: BlogFullModel[];
};

type BlogLinkProps = {
  blog: BlogFullModel;
};

export const PostHeaderBlogLink: FC<Props> = (props) => {
  const { blogs } = props;

  const BlogLink: FC<BlogLinkProps> = (prop) => {
    const { blog } = prop;

    return (
      <UiSpace size={0} align="center">
        <UiDivider.Dot type="secondary" />
        <RouterLink
          type="secondary"
          underline
          to={getRoutePath(RouteNames.GroupView, { id: blog.slug || blog.id })}
        >
          <UiTruncateMarkup truncate>{blog.name}</UiTruncateMarkup>
        </RouterLink>
      </UiSpace>
    );
  };

  return (
    <>
      {blogs.map((blog) => (
        <BlogLink key={blog.id} blog={blog} />
      ))}
    </>
  );
};
