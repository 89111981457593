import escapeRegExp from 'lodash/escapeRegExp';
import React, { useMemo, FC, HTMLAttributes, CSSProperties } from 'react';

export interface UiImageBackgroundProps extends HTMLAttributes<HTMLDivElement> {
  url: string;
  backgroundImageStyles?: CSSProperties['backgroundImage'];
}

export const UiImageBackground: FC<UiImageBackgroundProps> = (props) => {
  const { url, backgroundImageStyles = '', style, ...rest } = props;

  const backgroundImage: UiImageBackgroundProps['backgroundImageStyles'] = useMemo(() => {
    let bgImage = `url("${escapeRegExp(encodeURI(url))}")`;

    if (backgroundImageStyles) {
      bgImage = `${backgroundImageStyles}, ${bgImage}`;
    }

    return bgImage;
  }, [url, backgroundImageStyles]);

  return <div style={{ ...style, backgroundImage }} {...rest} />;
};
