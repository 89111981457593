import { KeycloakId } from 'ant/types/models/keycloak-user';
import {
  GroupsMailingId,
  SendingSurveyId,
  SurveyGroupId,
  SurveyId,
  SurveyQuestionId,
} from 'ant/types/models/survey';

const getSurveyUrl = (url: string) => `/api/async-survey/${url}`;

class SurveyEndpoints {
  static survey = (surveyId: SurveyId) => getSurveyUrl(`survey/${surveyId}/`);

  static surveyClone = (surveyId: SurveyId) => getSurveyUrl(`survey/${surveyId}/clone/`);

  static surveyList = () => getSurveyUrl(`survey/list/`);

  static surveySending = () => getSurveyUrl(`survey/sending/`);

  static surveySurveyIdSending = (surveyId: SurveyId) => getSurveyUrl(`survey/${surveyId}/sending/`);

  static surveySendingList = () => getSurveyUrl(`survey/sending/list/`);

  static surveySendingGroupGroupId = (surveyGroupId: SurveyGroupId) =>
    getSurveyUrl(`survey/sending/group/${surveyGroupId}/`);

  static groupList = () => getSurveyUrl(`group/list/`);

  static memberSurveys = () => getSurveyUrl(`member/surveys/`);

  static memberSendingSurveyIdSurvey = (sendingSurveyId: SendingSurveyId) =>
    getSurveyUrl(`member/${sendingSurveyId}/survey/`);

  static memberSendingSurveyIdQuestions = (sendingSurveyId: SendingSurveyId) =>
    getSurveyUrl(`member/${sendingSurveyId}/questions/`);

  static memberSendingSurveyIdAnswer = (sendingSurveyId: SendingSurveyId) =>
    getSurveyUrl(`member/${sendingSurveyId}/answer/`);

  static memberSendingSurveyIdComplete = (sendingSurveyId: SendingSurveyId) =>
    getSurveyUrl(`member/${sendingSurveyId}/complete/`);

  static surveySave = () => getSurveyUrl('survey/');

  static surveyAdminList = () => getSurveyUrl(`roles/list/`);

  static surveyAdmin = (id: KeycloakId) => getSurveyUrl(`roles/${id}/`);

  static surveyAdminSave = () => getSurveyUrl('roles/save/');

  static surveyInstanceSendingSurveyId = (sendingSurveyId: SendingSurveyId) =>
    getSurveyUrl(`survey/instance/${sendingSurveyId}/`);

  static surveyInstanceSendingSurveyIdMembers = (sendingSurveyId: SendingSurveyId) =>
    getSurveyUrl(`survey/instance/${sendingSurveyId}/members/`);

  static surveyInstanceSendingSurveyIdCancel = (sendingSurveyId: SendingSurveyId) =>
    getSurveyUrl(`survey/instance/${sendingSurveyId}/cancel/`);

  static statisticsSurveyInstanceSendingSurveyId = (sendingSurveyId: SendingSurveyId) =>
    getSurveyUrl(`statistics/survey/instance/${sendingSurveyId}/`);

  static statisticsSurveyInstanceSendingSurveyIdQuestionQuestionIdComments = (
    sendingSurveyId: SendingSurveyId,
    questionId: SurveyQuestionId,
  ) => getSurveyUrl(`statistics/survey/instance/${sendingSurveyId}/question/${questionId}/comments/`);

  static statisticsSurveySurveyIdCommon = (surveyId: SurveyId) =>
    getSurveyUrl(`statistics/survey/${surveyId}/common/`);

  static groupGroupId = (surveyGroupId: SurveyGroupId) => getSurveyUrl(`group/${surveyGroupId}/`);

  static groupGroupIdMembers = (surveyGroupId: SurveyGroupId) =>
    getSurveyUrl(`group/${surveyGroupId}/members/`);

  static group = () => getSurveyUrl(`group/`);
  static groupMailingClone = (surveyGroupId: SurveyGroupId) => getSurveyUrl(`group/${surveyGroupId}/clone/`);

  static groupMailingIdMembers = (groupMailingId: string) => getSurveyUrl(`group/${groupMailingId}/members/`);

  static membersGroupMailing = (groupMailingId: GroupsMailingId, memberId: KeycloakId) =>
    getSurveyUrl(`group/${groupMailingId}/members/${memberId}/`);
}

export { SurveyEndpoints };
