import React from 'react';
import { Link } from 'react-router-dom';

import ArrowFrontSVG from 'ant/components/svg/arrow-front.svg';
import { UiCard } from 'ant/components/ui/card';
import { UiIcon } from 'ant/components/ui/icon';
import { UiList } from 'ant/components/ui/list';
import { UiWidgetHeader } from 'ant/components/ui/widget-header';

import styles from './CardLinks.scss';

export type CardLinksProps = {
  header: { title: string; backgroundColor: string };
  icon?: SvgrComponent;
  links: { title: string; link: string }[];
};

const CardLinks: React.FC<CardLinksProps> = ({ header, links, icon }) => {
  const Title = (
    <UiWidgetHeader
      title={header.title}
      style={{ background: header.backgroundColor }}
      className={styles.cardLinks__header}
      icon={
        icon ? (
          <UiIcon className={styles.cardLinks__headerIcon} component={icon} width={20} height={20} />
        ) : null
      }
    />
  );

  return (
    <UiCard emptyPadding size="default" className={styles.cardLinks} title={Title}>
      <UiList
        dataSource={links}
        bordered={false}
        className={styles.cardLinks__list}
        renderItem={(linkItem) => (
          <Link to={linkItem.link}>
            <UiList.Item>
              <UiList.Item.Meta title={linkItem.title} />
              <UiIcon
                className={styles.cardLinks__colorIcon}
                component={ArrowFrontSVG}
                width={20}
                height={20}
              />
            </UiList.Item>
          </Link>
        )}
      />
    </UiCard>
  );
};

export { CardLinks };
