// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bf695ace_ck-content .bf695ace_video{display:table;clear:both;text-align:center;margin:.9em auto;min-width:50px}.bf695ace_ck-content .bf695ace_video video{display:block;margin:0 auto;max-width:100%;min-width:100%;height:auto}.bf695ace_ck.bf695ace_ck-editor__editable .bf695ace_video>figcaption.bf695ace_ck-placeholder::before{padding-left:inherit;padding-right:inherit;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.bf695ace_ck.bf695ace_ck-editor__editable .bf695ace_video{z-index:1}.bf695ace_ck.bf695ace_ck-editor__editable .bf695ace_video.bf695ace_ck-widget_selected{z-index:2}", "",{"version":3,"sources":["webpack://./../ant/src/components/widgets/Editor/plugins/video/lib/theme/video.scss"],"names":[],"mappings":"AAEE,qCACE,aAAA,CACA,UAAA,CACA,iBAAA,CACA,gBAAA,CACA,cAAA,CAEA,2CACE,aAAA,CACA,aAAA,CACA,cAAA,CACA,cAAA,CACA,WAAA,CAMJ,qGACE,oBAAA,CACA,qBAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CAGF,0DACE,SAAA,CAEA,sFACE,SAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.ck-content {\n  & .video {\n    display: table;\n    clear: both;\n    text-align: center;\n    margin: 0.9em auto;\n    min-width: 50px;\n\n    & video {\n      display: block;\n      margin: 0 auto;\n      max-width: 100%;\n      min-width: 100%;\n      height: auto;\n    }\n  }\n}\n\n.ck.ck-editor__editable {\n  & .video > figcaption.ck-placeholder::before {\n    padding-left: inherit;\n    padding-right: inherit;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n\n  & .video {\n    z-index: 1;\n\n    &.ck-widget_selected {\n      z-index: 2;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ck-content": "bf695ace_ck-content",
	"ckContent": "bf695ace_ck-content",
	"video": "bf695ace_video",
	"ck": "bf695ace_ck",
	"ck-editor__editable": "bf695ace_ck-editor__editable",
	"ckEditor__editable": "bf695ace_ck-editor__editable",
	"ck-placeholder": "bf695ace_ck-placeholder",
	"ckPlaceholder": "bf695ace_ck-placeholder",
	"ck-widget_selected": "bf695ace_ck-widget_selected",
	"ckWidget_selected": "bf695ace_ck-widget_selected"
};
export default ___CSS_LOADER_EXPORT___;
