import { Collapse, CollapsePanelProps, CollapseProps } from 'antd';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import ArrowCollapseSvg from 'ant/components/svg/arrow-collapse.svg';
import ArrowExpandSvg from 'ant/components/svg/arrow-expand.svg';
import { UiIcon } from 'ant/components/ui/icon';

import styles from './UiCollapse.scss';
import { UiCollapseContent } from './content/UiCollapseContent';
import { UiCollapsePanel } from './panel/UiCollapsePanel';

export type { UiCollapsePanelProps } from './panel/UiCollapsePanel';

interface PanelProps extends Partial<Omit<CollapsePanelProps, 'key' | 'id' | 'prefixCls'>> {
  isActive?: boolean;
}
export type UiCollapseExpandIcon = (panelProps: PanelProps) => ReactNode;
export interface UiCollapseProps extends Omit<CollapseProps, 'expandIcon'> {
  showLine?: boolean;
  expandIcon?: UiCollapseExpandIcon;
}

type UiCollapseComposition = {
  Panel: typeof UiCollapsePanel;
  Content: typeof UiCollapseContent;
};

export const UiCollapse: FC<UiCollapseProps> & UiCollapseComposition = (props) => {
  const { className, showLine, expandIcon: expandIconProp, ...collapseProps } = props;

  const defaultExpandIcon: UiCollapseExpandIcon = ({ isActive }) => (
    <UiIcon width={20} height={20} component={isActive ? ArrowCollapseSvg : ArrowExpandSvg} />
  );
  const expandIcon = expandIconProp || defaultExpandIcon;

  return (
    <Collapse
      {...collapseProps}
      expandIcon={expandIcon}
      className={classNames(className, { [styles.uiCollapse_showLine]: showLine })}
    />
  );
};

UiCollapse.Panel = UiCollapsePanel;
UiCollapse.Content = UiCollapseContent;
