import { Plugin, icons } from '@ckeditor/ckeditor5-core';
import { ButtonView } from '@ckeditor/ckeditor5-ui';

import type { ToggleVideoCaptionCommand } from './togglevideocaptioncommand';
import { VideoCaptionUtils } from './videocaptionutils';

export class VideoCaptionUI extends Plugin {
  public static get requires() {
    return [VideoCaptionUtils] as const;
  }

  public static get pluginName() {
    return 'VideoCaptionUI' as const;
  }

  public init(): void {
    const { editor } = this;
    const editingView = editor.editing.view;
    const videoCaptionUtils: VideoCaptionUtils = editor.plugins.get('VideoCaptionUtils');

    editor.ui.componentFactory.add('toggleVideoCaption', (locale) => {
      const command: ToggleVideoCaptionCommand = editor.commands.get('toggleVideoCaption')!;
      const view = new ButtonView(locale);

      view.set({
        icon: icons.caption,
        tooltip: true,
        isToggleable: true,
      });

      view.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled');
      view.bind('label').to(command, 'value', (value) => (value ? 'Выключить подпись' : 'Включить подпись'));

      this.listenTo(view, 'execute', () => {
        editor.execute('toggleVideoCaption', { focusCaptionOnShow: true });

        const modelCaptionElement = videoCaptionUtils.getCaptionFromModelSelection(
          editor.model.document.selection,
        );

        if (modelCaptionElement) {
          const figcaptionElement = editor.editing.mapper.toViewElement(modelCaptionElement);

          editingView.scrollToTheSelection();

          editingView.change((writer) => {
            writer.addClass('video__caption_highlighted', figcaptionElement!);
          });
        }

        editor.editing.view.focus();
      });

      return view;
    });
  }
}
