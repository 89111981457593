import { AxiosResponse } from 'axios';

import { api } from 'ant/plugins/api';
import {
  ProjectsTaskCreateModel,
  ProjectsTaskId,
  ProjectsTaskUpdateModel,
  ProjectId,
  ProjectsStageId,
} from 'ant/types/models/projects';
import { ProjectCreateData, ProjectUpdateData } from 'ant/types/tasks/project-create';

import { TasksEndpoints } from '../../endpoints/tasks';
import {
  CreateStageParams,
  TasksFilterParams,
  UpdateProjectParticipantsParams,
  UpdateStageParams,
} from './index';

export const browseTasks = <T = unknown>(data: TasksFilterParams): Promise<AxiosResponse<T>> =>
  api.get({
    url: TasksEndpoints.tasksBrowse(),
    data,
  });

export const createTask = <T = unknown>(data: ProjectsTaskCreateModel): Promise<AxiosResponse<T>> =>
  api.post({
    url: TasksEndpoints.taskNew(data.projectId),
    data,
  });

export const getTask = <T = unknown>(taskId: ProjectsTaskId): Promise<AxiosResponse<T>> =>
  api.get({
    url: TasksEndpoints.task(taskId),
  });

export const updateTask = <T = unknown>(data: ProjectsTaskUpdateModel): Promise<AxiosResponse<T>> =>
  api.patch({
    url: TasksEndpoints.task(data.id),
    data,
  });

export const deleteTask = (taskId: ProjectsTaskId): Promise<AxiosResponse> =>
  api.delete({
    url: TasksEndpoints.task(taskId),
  });

export const createProject = <T = unknown>(data: ProjectCreateData): Promise<AxiosResponse<T>> =>
  api.post({
    url: TasksEndpoints.projects(),
    data,
  });

export const updateProject = <T = unknown>(
  id: ProjectId,
  data: ProjectUpdateData,
): Promise<AxiosResponse<T>> =>
  api.patch({
    url: TasksEndpoints.project(id),
    data,
  });

export const updateProjectParticipants = <T = unknown>({
  projectId,
  ...data
}: UpdateProjectParticipantsParams): Promise<AxiosResponse<T>> =>
  api.patch({
    url: TasksEndpoints.projectParticipants(projectId),
    data,
  });

export const deleteProject = (projectId: ProjectId): Promise<AxiosResponse> =>
  api.delete({
    url: TasksEndpoints.project(projectId),
  });

export const createStage = <T = unknown>(data: CreateStageParams): Promise<AxiosResponse<T>> =>
  api.post({
    url: TasksEndpoints.stages(),
    data,
  });

export const updateStage = <T = unknown>({ id, ...rest }: UpdateStageParams): Promise<AxiosResponse<T>> =>
  api.patch({
    url: TasksEndpoints.stage(id),
    data: rest,
  });

export const deleteStage = (stageId: ProjectsStageId): Promise<AxiosResponse> =>
  api.delete({
    url: TasksEndpoints.stage(stageId),
  });
