import React, { FC, Fragment, PropsWithChildren, ReactNode } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiMarkdown } from 'ant/components/ui/markdown';
import { UiTypography } from 'ant/components/ui/typography';
import { parseTextPostContent } from 'ant/plugins/utils/markup-content';
import { PostTextExtendedModel } from 'ant/types/models/post';

import styles from './PostBodyMarkup.scss';

type Props = {
  post: PostTextExtendedModel;
  onClick?: () => void;
  extra?: ReactNode;
};

export const PostBodyMarkup: FC<Props> = (props) => {
  const { post, onClick, extra } = props;
  const { title } = post;
  const parsedTextContent = parseTextPostContent(post);

  const Wrapper: FC<PropsWithChildren> = (prop) => {
    if (onClick) {
      return <UiButton.Decorator style={{ display: 'inherit' }} onClick={onClick} {...prop} />;
    }

    return <Fragment {...prop} />;
  };

  return (
    <Wrapper>
      {title && <UiTypography.Title level={2}>{title}</UiTypography.Title>}
      {extra}
      <UiMarkdown htmlMarkup className={styles.postBodyMarkup}>
        {parsedTextContent}
      </UiMarkdown>
    </Wrapper>
  );
};
