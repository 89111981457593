import { EntityId } from 'ant/types/blogs';
import { BlogAlbumId, BlogId, BlogRegistrationId } from 'ant/types/models/blog.model';
import { PostTypes } from 'ant/types/models/post';
import { ReactionPostContentType } from 'ant/types/models/reactions.model';

const getAsyncBlogsUrl = (url: string) => `/api/async-blogs/${url}`;

const optional = (id?: EntityId) => (id ? `${id}/` : '');

export class BlogsEndpoints {
  static micropost = (id?: EntityId) => getAsyncBlogsUrl(`micropost/${optional(id)}`);

  static timelineUserId = (userId = 'my') => getAsyncBlogsUrl(`timeline/${userId}/`);
  static reportsTopPosts = () => getAsyncBlogsUrl(`reports/top_posts/`);

  static reportsTopBlogs = () => getAsyncBlogsUrl(`reports/top_blogs/`);

  static reportsTheBestAuthor = () => getAsyncBlogsUrl(`reports/the_best_author/`);

  static blogId = (id?: BlogId) => getAsyncBlogsUrl(`blog/${optional(id)}`);

  static blogSlug = (slug: string) => getAsyncBlogsUrl(`blog/slug/${slug}/`);

  static blogsFavoriteStatus = (contentType: PostTypes, id: EntityId) =>
    getAsyncBlogsUrl(`post/${contentType}/favorite_status/${id}/`);

  static blogFavoriteEntries = () => getAsyncBlogsUrl(`favorite/list/`);

  static blogEntries = (blogId: EntityId) => getAsyncBlogsUrl(`blog/${blogId}/entries/`);

  static blogSlugEntries = (blogSlug: string) => getAsyncBlogsUrl(`blog/slug/${blogSlug}/entries/`);

  static list = () => getAsyncBlogsUrl(`list/`);

  static blogRoles = () => getAsyncBlogsUrl(`blog-roles/`);

  static blogEntryId = (id?: EntityId) => getAsyncBlogsUrl(`blog/entry/${optional(id)}`);

  static blogInvite = (id: EntityId) => getAsyncBlogsUrl(`blog/${id}/invite/`);

  static blogRevokeInvite = (id: EntityId, userId: EntityId) =>
    getAsyncBlogsUrl(`blog/${id}/revoke_invite/user/${optional(userId)}`);

  static blogParticipants = (id: EntityId) => getAsyncBlogsUrl(`blog/${id}/participants/`);

  static blogParticipantsInfo = (id: EntityId) => getAsyncBlogsUrl(`blog/${id}/participants_info/`);

  static blogSentInvitations = (id: EntityId) => getAsyncBlogsUrl(`blog/${id}/sent_invitations/`);

  static blogAcceptInvite = (id: EntityId) => getAsyncBlogsUrl(`blog/${id}/accept_invite/`);

  static blogRejectInvite = (id: EntityId) => getAsyncBlogsUrl(`blog/${id}/reject_invite/`);

  static blogSubscriptions = (userId: EntityId, id: EntityId) =>
    getAsyncBlogsUrl(`subscriptions/${userId}/blog/${id}/`);

  static blogSubscriptionsMe = (id: EntityId) => getAsyncBlogsUrl(`subscriptions/my/blog/${id}/`);

  static blogSubscribeRequest = (id: EntityId, requestId?: EntityId) =>
    getAsyncBlogsUrl(`blog/${id}/subscribe-request/${optional(requestId)}`);

  static blogAlbumsList = (blogId: BlogId) => getAsyncBlogsUrl(`blog/${blogId}/albums/`);

  static blogAlbum = (blogId: BlogId, albumId: BlogAlbumId) =>
    getAsyncBlogsUrl(`blog/${blogId}/albums/${albumId}/`);

  static blogAlbumUpload = (blogId: BlogId, albumId: BlogAlbumId) =>
    getAsyncBlogsUrl(`blog/${blogId}/albums/${albumId}/upload/`);

  static blogAlbumImages = (blogId: BlogId, albumId: BlogAlbumId) =>
    getAsyncBlogsUrl(`blog/${blogId}/albums/${albumId}/images/`);

  static blogAlbumSingleImage = (blogId: BlogId, albumId: BlogAlbumId, imageId: EntityId) =>
    getAsyncBlogsUrl(`blog/${blogId}/albums/${albumId}/images/${imageId}/`);

  static blogCategories = () => getAsyncBlogsUrl('blog/categories/');

  static entrySearch = () => getAsyncBlogsUrl(`entry/search/`);

  static blogEntryList = () => getAsyncBlogsUrl(`blog/entry/list/`);
  static blogRegistrationExportXlsx = (blogId: BlogId, registrationId: BlogRegistrationId) =>
    getAsyncBlogsUrl(`blog/${blogId}/registration/${registrationId}/export/xlsx/`);

  static blogsChangePinStatus = (id: EntityId) => getAsyncBlogsUrl(`entry/${id}/change_pin_status/`);

  static blogAvailableUsers = (blogId: BlogId) => getAsyncBlogsUrl(`blog/${blogId}/available_users/`);

  static reactionId = (contentType: ReactionPostContentType, entityId: EntityId, reactionId: EntityId) =>
    getAsyncBlogsUrl(`reaction/${contentType}/${entityId}/${reactionId}/`);

  static blogAttachments = (contentType: PostTypes, id: EntityId) =>
    getAsyncBlogsUrl(`attachments/${contentType}/${id}/`);

  static blogAttachmentsId = (contentType: PostTypes, objectId: EntityId, id: EntityId) =>
    getAsyncBlogsUrl(`attachments/${contentType}/${objectId}/${id}/`);

  static settingsMain = () => getAsyncBlogsUrl('settings/main/');
}
