/**
 * https://usehooks-ts.com/react-hook/use-local-storage
 */
import { useCallback, useEffect, useState, Dispatch, SetStateAction } from 'react';

const LOCAL_STORAGE_CUSTOM_NAME = 'local-storage';

type UseLocalStorageKey = string;
type SetValue<T> = Dispatch<SetStateAction<T>>;
type CustomEventDetail = { key: UseLocalStorageKey };

declare global {
  interface WindowEventMap {
    [LOCAL_STORAGE_CUSTOM_NAME]: CustomEvent<CustomEventDetail>;
  }
}

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch {
    // eslint-disable-next-line no-console
    console.error('parsing error on', { value });
    return undefined;
  }
}

export function useLocalStorage<T>(key: UseLocalStorageKey, initialValue: T): [T, SetValue<T>] {
  // Get from local storage then parse stored json or return initialValue
  const readValue = useCallback((): T => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);

      return item ? (parseJSON(item) as T) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  }, [initialValue, key]);

  // State to store our value. Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(readValue);

  // Return a wrapped version of useState's setter function that, persists the new value to localStorage.
  const setValue: SetValue<T> = useCallback((value) => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window === 'undefined') {
      console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`);
    }

    try {
      const newValue = value instanceof Function ? value(storedValue) : value; // Allow value to be a function, so we have the same API as useState

      window.localStorage.setItem(key, JSON.stringify(newValue));
      setStoredValue(newValue);
      window.dispatchEvent(
        new CustomEvent<CustomEventDetail>(LOCAL_STORAGE_CUSTOM_NAME, { detail: { key } }), // We dispatch a custom event so every useLocalStorage hook are notified
      );
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  }, []);

  useEffect(() => {
    setStoredValue(readValue());
  }, []);

  const handleStorageChange = useCallback(
    (event: StorageEvent | CustomEvent<CustomEventDetail>) => {
      const eventKey = (event as StorageEvent).key || (event as CustomEvent<CustomEventDetail>)?.detail?.key;

      if (eventKey && eventKey === key) {
        setStoredValue(readValue());
      }
    },
    [key, readValue],
  );

  // this only works for other documents, not the current one
  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // this is a custom event, triggered in writeValueToLocalStorage
  useEffect(() => {
    window.addEventListener(LOCAL_STORAGE_CUSTOM_NAME, handleStorageChange);

    return () => {
      window.removeEventListener(LOCAL_STORAGE_CUSTOM_NAME, handleStorageChange);
    };
  }, []);

  return [storedValue, setValue];
}
