import classNames from 'classnames';
import React, { ReactNode, useEffect, useState, useRef } from 'react';

import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { CalendarEventType } from 'ant/types/models/calendar';

import styles from './CalendarCardTask.scss';

const getEventModifier = (eventType: CalendarEventType) => {
  const modifierColorMap: Record<CalendarEventType, string> = {
    [CalendarEventType.Learn]: styles.calendarCardTask__colorContainer_red,
    [CalendarEventType.Task]: styles.calendarCardTask__colorContainer_green,
    [CalendarEventType.Meeting]: styles.calendarCardTask__colorContainer_blue,
    [CalendarEventType.Activity]: styles.calendarCardTask__colorContainer_yellow,
  };

  if (modifierColorMap[eventType]) return modifierColorMap[eventType];
  throw new Error(`Event type '${eventType}' in get event modifier is unknown`);
};

const MIN_CONTENT_SHOW_WIDTH = 50;

type CalendarCardTaskProps = {
  eventType: CalendarEventType;
  title: string;
  titleSuffix?: string;
  subtitle: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  linesCount?: number;
  isEventPassed?: boolean;
  isEventUnconfirmed?: boolean;
  isLargeGap?: boolean;
  style?: React.CSSProperties;
};

export type CalendarCardTaskPropsComponent = React.FC<CalendarCardTaskProps>;

const CalendarCardTask: CalendarCardTaskPropsComponent = (props) => {
  const {
    eventType,
    title,
    titleSuffix,
    subtitle,
    onClick,
    linesCount,
    isEventPassed,
    isEventUnconfirmed,
    isLargeGap,
    style,
    ...wrapperProps
  } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isHidden, setIsHidden] = useState(false);

  const checkContentVisible = () => {
    if (buttonRef.current) {
      setIsHidden(buttonRef.current.clientWidth < MIN_CONTENT_SHOW_WIDTH);
    }
  };

  const isOneLine = linesCount === 1;

  const ellipsis = (node: ReactNode) => {
    // @ts-expect-error TODO: ждем пока react-truncate-markup обновит типы до react 18
    const renderedNodes = node?.props?.children;

    return renderedNodes[0]?.props.children?.length < title.length && '...';
  };

  useEffect(() => {
    checkContentVisible();
  }, [style]);

  useEffect(() => {
    window.addEventListener('resize', checkContentVisible);

    return () => {
      window.removeEventListener('resize', checkContentVisible);
    };
  }, [buttonRef.current]);

  return (
    <div
      {...wrapperProps}
      className={classNames(styles.calendarCardTask, {
        [styles.calendarCardTask_largeGap]: isLargeGap,
      })}
      style={style}
    >
      <button
        ref={buttonRef}
        className={classNames(styles.calendarCardTask__colorContainer, getEventModifier(eventType), {
          [styles.calendarCardTask__colorContainer_unconfirmed]: isEventUnconfirmed,
          [styles.calendarCardTask__colorContainer_passed]: isEventPassed,
        })}
        type="button"
        onClick={onClick}
      >
        {!isHidden &&
          (isOneLine ? (
            <UiTruncateMarkup
              truncate
              className={classNames(
                styles.calendarCardTask__contentContainer,
                styles.calendarCardTask__contentContainer_oneLine,
              )}
              lines={1}
              lineHeight="14px"
            >
              <div className={classNames(styles.calendarCardTask__time)}>{title}</div>
              {subtitle}
            </UiTruncateMarkup>
          ) : (
            <div className={styles.calendarCardTask__contentContainer}>
              <UiTruncateMarkup truncate ellipsis={ellipsis} lines={1} lineHeight="14px">
                <div className={classNames(styles.calendarCardTask__time)}>{title}</div>

                {Boolean(titleSuffix?.length) && (
                  <UiTruncateMarkup.Atom>
                    <span className={classNames(styles.calendarCardTask__time)}>{titleSuffix}</span>
                  </UiTruncateMarkup.Atom>
                )}
              </UiTruncateMarkup>
              <UiTruncateMarkup truncate lines={linesCount && linesCount - 1} lineHeight="14px">
                {subtitle}
              </UiTruncateMarkup>
            </div>
          ))}
      </button>
    </div>
  );
};

export { CalendarCardTask };
