import classNames from 'classnames';
import React, { FC } from 'react';

import { UiTypography, UiTypographyTitleProps } from 'ant/components/ui/typography';

import styles from './UiCardHeaderTitle.scss';

export interface UiCardHeaderTitleProps extends UiTypographyTitleProps {
  className?: string;
}

export const UiCardHeaderTitle: FC<UiCardHeaderTitleProps> = (props) => {
  const { children, className, ...rest } = props;

  return (
    <UiTypography.Title level={3} className={classNames(className, styles.uiCardHeaderTitle)} {...rest}>
      {children}
    </UiTypography.Title>
  );
};
