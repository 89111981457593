import { Modal as AntModal } from 'antd';

import { getAntdModalFuncProps, getAntdModalWarningFuncProps, getAntdModalSuccessFuncProps } from './helpers';
import { Modal, ModalFuncProps, UiModalBaseType } from './types';

const UiModalBase = AntModal as UiModalBaseType;

const baseConfirm = AntModal.confirm;
const baseSuccess = AntModal.success;

UiModalBase.confirm = (modalFuncProps: ModalFuncProps): Modal => {
  const modal = baseConfirm(getAntdModalFuncProps(modalFuncProps));
  const baseModalUpdate = modal.update;

  (modal as Modal).update = (modalFuncUpdateProps: ModalFuncProps) => {
    baseModalUpdate.call(modal, getAntdModalFuncProps(modalFuncUpdateProps));
  };

  return modal as Modal;
};

UiModalBase.warning = (modalFuncProps: ModalFuncProps): Modal => {
  const modal = baseConfirm(getAntdModalWarningFuncProps(modalFuncProps));
  const baseModalUpdate = modal.update;

  (modal as Modal).update = (modalFuncUpdateProps: ModalFuncProps) => {
    baseModalUpdate.call(modal, getAntdModalFuncProps(modalFuncUpdateProps));
  };

  return modal as Modal;
};

UiModalBase.success = (modalFuncProps: ModalFuncProps): Modal => {
  const modal = baseSuccess(getAntdModalSuccessFuncProps(modalFuncProps));
  const baseModalUpdate = modal.update;

  (modal as Modal).update = (modalFuncUpdateProps: ModalFuncProps) => {
    baseModalUpdate.call(modal, getAntdModalSuccessFuncProps(modalFuncUpdateProps));
  };

  return modal as Modal;
};

export { UiModalBase };
