import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { Endpoints } from 'ant/plugins/endpoints';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { PaginatedResults } from 'ant/types/api';
import {
  FetchNotificationParams,
  NotificationId,
  NotificationModel,
  NotificationStatuses,
} from 'ant/types/models/notification.model';

import { markNotificationRead } from './api';

export interface NotificationsInfo extends PaginatedResults<NotificationModel> {
  unreadCount: number;
}

const DEFAULT_NOTIFICATION_INFO: NotificationsInfo = {
  results: [],
  count: 0,
  unreadCount: 0,
};

export const markNotificationReadEffect = createEffect<NotificationId, void, AxiosError>((id) =>
  markNotificationRead(id).then(({ data }) => data),
);

export const getNotificationsStorage = () => {
  const storage = abstractStorageFactory<
    NotificationsInfo,
    NotificationsInfo,
    NotificationsInfo,
    FetchNotificationParams
  >({
    endpointBuilder: (params) =>
      buildEndpointWithQueryParams(Endpoints.notificationsList(), { isActive: true, ...params }),
    defaultValue: DEFAULT_NOTIFICATION_INFO,
    paginationInfoRetriever: ({ count }) => ({ count }),
  });

  storage.store.on(markNotificationReadEffect.done, (state, { params: notificationId }) => {
    const { data } = state;

    return {
      ...state,
      data: {
        ...data,
        results: data.results.map((notification) =>
          notification.id === notificationId
            ? {
                ...notification,
                status: NotificationStatuses.Read,
              }
            : notification,
        ),
        unreadCount: data.unreadCount > 0 ? data.unreadCount - 1 : 0,
      },
    };
  });

  return storage;
};

export const notificationsStore = getNotificationsStorage();
