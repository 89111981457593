import classNames from 'classnames';
import React, { PropsWithChildren, FC } from 'react';

import { UiSidebarMenuComponent, UiSidebarMenu } from 'ant/components/ui/sidebar/menu/UiSidebarMenu';

import styles from './UiSidebar.scss';

export enum UiSidebarPlacement {
  Left = 'left',
  Right = 'right',
}

export type UiSidebarProps = {
  className?: string;
  placement?: UiSidebarPlacement;
};

type SidebarComposition = {
  Menu: UiSidebarMenuComponent;
};

const UiSidebar: FC<PropsWithChildren<UiSidebarProps>> & SidebarComposition = (props) => {
  const { children, placement = UiSidebarPlacement.Left, className } = props;

  const sidebarClassname = classNames(
    styles.uiSidebar,
    {
      [styles.uiSidebar_withBorderLeft]: placement === UiSidebarPlacement.Right,
      [styles.uiSidebar_withBorderRight]: placement === UiSidebarPlacement.Left,
    },
    className,
  );

  return <div className={sidebarClassname}>{children}</div>;
};

UiSidebar.Menu = UiSidebarMenu;

export { UiSidebar };
