import { Tag } from 'antd';
import classNames from 'classnames';
import React, { FC, MouseEventHandler, CSSProperties } from 'react';

import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';

import styles from './UiTag.scss';
import { UiTagGroup } from './group/UiTagGroup';

export type { UiTagGroupProps } from './group/UiTagGroup';

export enum UiTagTypes {
  Marker = 'marker',
  Dot = 'dot',
}

export type UiTagProps = {
  title?: string;
  color?: string;
  textColor?: string;
  className?: string;
  wrapperClassName?: string;
  shift?: number;
  size?: number;
  type?: UiTagTypes;
  children?: React.ReactNode;
  strong?: boolean;
  dotClassName?: string;
  rhomb?: boolean;
  onMouseOver?: MouseEventHandler<HTMLSpanElement>;
  style?: CSSProperties;
};

export interface UiTagComponent extends FC<UiTagProps> {
  Group: typeof UiTagGroup;
}

const UiTag: UiTagComponent = (props) => {
  const {
    strong,
    className,
    wrapperClassName,
    color,
    textColor,
    title = 'Без категории',
    shift = 8,
    size = 10,
    type = UiTagTypes.Marker,
    children,
    dotClassName,
    rhomb,
    style,
    ...rest
  } = props;

  const TagWithDot = (
    <div className={classNames(styles.uiTag, wrapperClassName)} style={style} {...rest}>
      <div
        style={{ backgroundColor: color, marginRight: shift, width: size, height: size }}
        className={classNames(dotClassName ?? styles.uiTag__dot, {
          [styles.uiTag__dot_rhomb]: rhomb,
        })}
      />
      {children ?? (
        <UiTypography.Text className={classNames({ [styles.uiTag__dot_strong]: strong }, className)}>
          <UiTruncateMarkup contentStyle={{ display: 'inherit', color: textColor }} truncate>
            {title}
          </UiTruncateMarkup>
        </UiTypography.Text>
      )}
    </div>
  );

  const TagMarker = (
    <Tag
      color={color}
      className={classNames(styles.uiTag__marker, { [styles.uiTag__marker_strong]: strong })}
      style={{ color: textColor, ...style }}
      {...rest}
    >
      <UiTruncateMarkup truncate>{title}</UiTruncateMarkup>
    </Tag>
  );

  return type === UiTagTypes.Dot ? TagWithDot : TagMarker;
};

UiTag.Group = UiTagGroup;

export { UiTag };
