import humps from 'humps';

import { RolesEndpoints } from 'ant/endpoints/roles';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import {
  ServiceRolesNames,
  ServiceRolesPermissionModelList,
  ServiceRolesPermissionsModel,
} from 'ant/types/roles';

export type RolesPermissionsStorageParams = { serviceName: ServiceRolesNames };
type FetchProfilePermissionsParams = {
  userId: KeycloakId;
  groupBy: ServiceRolesNames;
  serviceName: ServiceRolesNames[];
};

export const getRolesServiceNamePermissionsStorage = ({ serviceName }: RolesPermissionsStorageParams) => {
  const storage = abstractStorageFactory<
    ServiceRolesPermissionModelList,
    ServiceRolesPermissionModelList,
    ServiceRolesPermissionModelList
  >({
    endpointBuilder: () => RolesEndpoints.serviceNamePermissions(serviceName),
    defaultValue: [],
  });

  return { storage };
};

export const getProfileRolesPermissionsStorage = () => {
  const storage = abstractStorageFactory<
    Partial<ServiceRolesPermissionsModel>,
    Partial<ServiceRolesPermissionsModel>,
    null,
    FetchProfilePermissionsParams
  >({
    endpointBuilder: ({ userId, groupBy, serviceName }) => {
      const stringServicesNames = serviceName.join(',');

      return buildEndpointWithQueryParams(RolesEndpoints.userPermissionsAll(userId), {
        groupBy,
        serviceName: stringServicesNames,
      });
    },
    defaultValue: null,
    dataMapper: (data) => humps.decamelizeKeys(data, { separator: '-' }),
  });

  return { storage };
};
