import { EndpointQueryParamsBaseType } from 'ant/plugins/utils/endpoint-builder';
import { PaginationParams } from 'ant/types/api';
import { EntityContentData } from 'ant/types/editor';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import {
  UserProfileModel,
  UserProfileJobModel,
  UserAvatarModel,
  FullNameModel,
  JobModel,
  BaseUserProfileModel,
} from 'ant/types/models/user.model';
import { UiUploadFileAttach } from 'ant/types/uploads-form-item';

import { AttachmentEntryId } from './attachment.model';
import { FileStorageEntryId, FileStorageImage, FileStorageObjectModel } from './file.model';

export type EventId = string;
export type EventMemberId = number;
export type EventInviteId = number;
export type EventRequestId = number;
export type EventScheduleSlotId = string;

export enum EventStatus {
  Published = 'published',
  Draft = 'draft',
}

export enum EventType {
  ForAll = 'for_all',
  Private = 'private',
  StrictClosed = 'strict_closed',
}

export enum EventSeatingType {
  Fixed = 'fixed',
  Freely = 'freely',
}

export enum EventFormatType {
  Online = 'online',
  Offline = 'offline',
}

export enum OrganizersType {
  Local = 'local',
  External = 'external',
}

export type OrganizerExternalData = {
  name?: string;
  phone?: string;
  mailto?: string;
};

export type OrganizerExternalFormData = OrganizerExternalData & {
  id?: number;
};

export type OrganizerExternal = {
  type: OrganizersType.External;
  id?: number;
  data: OrganizerExternalData;
};

export type OrganizerLocal = {
  type: OrganizersType.Local;
  id: string;
};

export interface CreateUpdateEventModel extends FileStorageImage {
  id?: EventId;
  name: string;
  organizers?: (OrganizerLocal | OrganizerExternal)[];
  author: string;
  contactPerson: string;
  since: string;
  till: string;
  type: EventType;
  shortDescription?: string;
  longDescription?: string;
  descriptionBody?: EntityContentData;
  broadcastLink?: string;
  seatsCount?: number;
  guestsCount?: number | null;
  place?: string | null;
  typeSeating?: EventSeatingType;
  format?: EventFormatType;
  isDraft?: boolean;
  isImportant?: boolean;
  isPinned?: boolean;
  fileStorageObjects: FileStorageEntryId[];
}

export interface EventModel extends Omit<CreateUpdateEventModel, 'fileStorageObjects'> {
  id: EventId;
  authorProfile: UserProfileModel;
  memberTotalUids: string[];
  memberProfileList: UserProfileModel[];
  invitedGuestsByCurrentUser: number;
  isUserParticipate: boolean;
  isUserInvite: boolean;
  seatsLeft: number;
  isMy: boolean;
  isPublished: boolean;
  isRemoved: boolean;
  publishedAt: string;
  isAuthoredByMe: boolean;
  fileStorageObjects: FileStorageObjectModel[];
}

export interface ExternalSpeakerModel extends FileStorageImage {
  speakerName?: string;
  speakerSign?: string;
}

export interface EventForm extends FileStorageImage {
  name: string;
  sinceDate: Date;
  sinceTime: Date;
  tillDate: Date;
  tillTime: Date;
  privateType: EventType;
  id?: string;
  organizers?: OrganizerExternalFormData[];
  descriptionBody?: EntityContentData;
  repeat?: boolean;
  format?: EventFormatType;
  place?: string;
  broadcastLink?: string;
  typeSeating?: EventSeatingType;
  seatsCount?: number;
  guestsCount?: number;
  exportToCalendar?: boolean;
  fileStorageObjects: UiUploadFileAttach<FileStorageEntryId>[];
}

export interface CreateUpdateScheduleSlotBase extends FileStorageImage {
  theme: string;
  description: string;
  since: string;
  till: string;
  speakersUids?: string[];
  externalSpeakers?: ExternalSpeakerModel[];
  seatsCount?: number;
  guestsCount?: number | null;
  isNeedRegistration?: boolean;
  place?: string;
  id?: EventScheduleSlotId;
  event?: string;
  speakerProfileList?: UserProfileJobModel[];
  fileStorageObjects: FileStorageEntryId[];
}

export type CreateUpdateScheduleSlotModel = CreateUpdateScheduleSlotBase;

export interface UnsavedScheduleSlotModel extends Omit<CreateUpdateScheduleSlotModel, 'fileStorageObjects'> {
  fileStorageObjects: UiUploadFileAttach<FileStorageEntryId>[];
  temporaryId?: string;
}

export interface ScheduleSlotModel extends Omit<CreateUpdateScheduleSlotModel, 'fileStorageObjects'> {
  id: EventScheduleSlotId;
  fileStorageObjects: FileStorageObjectModel[];
}

export interface ScheduleSlotForm extends FileStorageImage {
  theme: string;
  description: string;
  sinceDate: Date;
  sinceTime: Date;
  tillDate: Date;
  tillTime: Date;
  speakersUids?: string[];
  seatsCount?: number;
  guestsCount?: number;
  isNeedRegistration?: boolean;
  place?: string;
  externalSpeakers?: ExternalSpeakerModel[];
  fileStorageObjects: UiUploadFileAttach<FileStorageEntryId>[];
}

export type GetByEventIdParams = {
  eventId: string;
};

export interface GetEventParticipantsQueryParams extends PaginationParams, EndpointQueryParamsBaseType {
  query?: string;
}

export type GetAttachmentsParams = {
  ids?: AttachmentEntryId[];
};

export type EventRequestDecideParams = {
  memberUid: KeycloakId;
  decide: boolean;
};

export type ConfirmEventParticipationModel = {
  invitedGuestsCount: number;
  seat?: number;
};

export type EventDate = {
  sinceDate: Date;
  sinceTime: Date;
  tillDate: Date;
  tillTime: Date;
};

export interface EventMemberModel extends Partial<UserAvatarModel>, FullNameModel {
  event: EventId;
  id: EventMemberId;
  invitedGuestsCount: number;
  isParticipate: boolean;
  isSubscribed: boolean;
  memberUid: KeycloakId;
  updated: string;
  created: string;
  job: JobModel;
  isActive: boolean;
}

export type EventGuestsCount = Pick<EventMemberModel, 'invitedGuestsCount'>;

export type UpdateEventScheduleGuestsCountParams = {
  id: EventId | EventScheduleSlotId;
  invitedGuestsCount: number;
};

export interface EventInviteModel extends Partial<UserAvatarModel>, FullNameModel {
  createdAt: string;
  event: EventId;
  id: EventInviteId;
  isAllowed: boolean;
  memberUid: KeycloakId;
  job: JobModel;
  isInvited: boolean;
}

export interface EventRequestModel extends Partial<UserAvatarModel>, FullNameModel {
  createdAt: string;
  event: EventId;
  id: EventRequestId;
  isAllowed: boolean;
  memberUid: KeycloakId;
  job: JobModel;
}

export interface EventUsersModel extends BaseUserProfileModel {
  isInvited: boolean;
  job: JobModel;
  isActive: boolean;
}

export type EventParticipantsInfoModel = {
  members: number;
  invites: number;
  requests: number;
};

export type EventRequestDecideModel = { decide: boolean };
export type InviteUserToEventModel = { memberUid: KeycloakId; event: EventId; isInvite: boolean };
export type CancelInviteUserToEventModel = { memberUid: KeycloakId };
