import classNames from 'classnames';
import React, { FC, ReactNode, CSSProperties, HTMLAttributes } from 'react';

import style from './Header.scss';

export interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
  before: ReactNode;
  after: ReactNode;
  fixed?: boolean;
  contentStyle?: CSSProperties;
}

const Header: FC<HeaderProps> = (props) => {
  const { before, after, fixed = true, children, contentStyle, className, ...restProps } = props;

  return (
    <header className={classNames(className, style.header, { [style.header_fixed]: fixed })} {...restProps}>
      <div className={style.header__before}>{before}</div>
      <div className={style.header__content} style={contentStyle}>
        {children}
      </div>
      <div className={style.header__after}>{after}</div>
    </header>
  );
};

export { Header };
