// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d68b8bc3_ck-content video.d68b8bc3_video_resized{height:auto}.d68b8bc3_ck-content .d68b8bc3_video.d68b8bc3_video_resized{max-width:100%;display:block;box-sizing:border-box}.d68b8bc3_ck-content .d68b8bc3_video.d68b8bc3_video_resized video{width:100%}.d68b8bc3_ck-content .d68b8bc3_video.d68b8bc3_video_resized>figcaption{display:block}[dir=ltr] .d68b8bc3_ck.d68b8bc3_ck-button.d68b8bc3_ck-button_with-text.d68b8bc3_ck-resize-video-button .d68b8bc3_ck-button__icon{margin-right:var(--ck-spacing-standard)}[dir=rtl] .d68b8bc3_ck.d68b8bc3_ck-button.d68b8bc3_ck-button_with-text.d68b8bc3_ck-resize-video-button .d68b8bc3_ck-button__icon{margin-left:var(--ck-spacing-standard)}.d68b8bc3_ck.d68b8bc3_ck-dropdown .d68b8bc3_ck-button.d68b8bc3_ck-resize-video-button .d68b8bc3_ck-button__label{width:4em}", "",{"version":3,"sources":["webpack://./../ant/src/components/widgets/Editor/plugins/video/lib/theme/videoresize.scss"],"names":[],"mappings":"AACA,kDACE,WAAA,CAGF,4DACE,cAAA,CACA,aAAA,CACA,qBAAA,CAEA,kEACE,UAAA,CAGF,uEACE,aAAA,CAIJ,iIACE,uCAAA,CAGF,iIACE,sCAAA,CAGF,iHACE,SAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.ck-content video.video_resized {\n  height: auto;\n}\n\n.ck-content .video.video_resized {\n  max-width: 100%;\n  display: block;\n  box-sizing: border-box;\n\n  & video {\n    width: 100%;\n  }\n\n  & > figcaption {\n    display: block;\n  }\n}\n\n[dir='ltr'] .ck.ck-button.ck-button_with-text.ck-resize-video-button .ck-button__icon {\n  margin-right: var(--ck-spacing-standard);\n}\n\n[dir='rtl'] .ck.ck-button.ck-button_with-text.ck-resize-video-button .ck-button__icon {\n  margin-left: var(--ck-spacing-standard);\n}\n\n.ck.ck-dropdown .ck-button.ck-resize-video-button .ck-button__label {\n  width: 4em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ck-content": "d68b8bc3_ck-content",
	"ckContent": "d68b8bc3_ck-content",
	"video_resized": "d68b8bc3_video_resized",
	"video": "d68b8bc3_video",
	"ck": "d68b8bc3_ck",
	"ck-button": "d68b8bc3_ck-button",
	"ckButton": "d68b8bc3_ck-button",
	"ck-button_with-text": "d68b8bc3_ck-button_with-text",
	"ckButton_withText": "d68b8bc3_ck-button_with-text",
	"ck-resize-video-button": "d68b8bc3_ck-resize-video-button",
	"ckResizeVideoButton": "d68b8bc3_ck-resize-video-button",
	"ck-button__icon": "d68b8bc3_ck-button__icon",
	"ckButton__icon": "d68b8bc3_ck-button__icon",
	"ck-dropdown": "d68b8bc3_ck-dropdown",
	"ckDropdown": "d68b8bc3_ck-dropdown",
	"ck-button__label": "d68b8bc3_ck-button__label",
	"ckButton__label": "d68b8bc3_ck-button__label"
};
export default ___CSS_LOADER_EXPORT___;
