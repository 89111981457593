import React from 'react';
import { animated, useTransition } from 'react-spring';

import { UiRow } from 'ant/components/ui/grid';

export type AnimatedItem = { icon: React.ReactNode; key: string; width: string | number };

type Props = {
  items: AnimatedItem[];
  rateBtnSize: number | string;
};

const AnimatedIcons: React.FC<Props> = ({ items, rateBtnSize }) => {
  const transitions = useTransition(items, {
    keys: (item) => item.key,
    enter: ({ width }) => ({
      transform: `translate3d(${rateBtnSize}px,0,0)`,
      width,
      opacity: 1,
    }),
    update: { transform: `translate3d(${rateBtnSize}px,0,0)` },
    leave: { transform: `translate3d(${rateBtnSize}px,0,0)`, width: 0, opacity: 0 },
    config: { mass: 1, tension: 250, friction: 20 },
  });

  return (
    <UiRow style={{ marginLeft: 4 }}>
      {transitions((style, item) => (
        <animated.div key={item.key} style={style}>
          {item.icon}
        </animated.div>
      ))}
    </UiRow>
  );
};

export { AnimatedIcons };
