import React, { FC, useContext } from 'react';
import ReactDOM from 'react-dom';

import { UiBreadcrumb, UiBreadcrumbProps } from 'ant/components/ui/breadcrumb';

import { PortaledBreadcrumbContext, PortaledBreadcrumbProvider } from './provider/PortaledBreadcrumbProvider';

export const PortaledBreadcrumbHTMLElementId = 'breadcrumb-container';

interface PortaledBreadcrumbComposition extends FC<UiBreadcrumbProps> {
  Provider: typeof PortaledBreadcrumbProvider;
}

const PortaledBreadcrumb: PortaledBreadcrumbComposition = (props) => {
  const { breadcrumbRoutes, ...restProps } = props;

  const context = useContext(PortaledBreadcrumbContext);
  const routes = context ? breadcrumbRoutes.concat(context.routes) : breadcrumbRoutes;

  const breadcrumbContent = <UiBreadcrumb breadcrumbRoutes={routes} {...restProps} />;
  const portalElement = document.getElementById(PortaledBreadcrumbHTMLElementId);

  if (portalElement) {
    return ReactDOM.createPortal(breadcrumbContent, portalElement);
  }

  return null;
};

PortaledBreadcrumb.Provider = PortaledBreadcrumbProvider;

export { PortaledBreadcrumb };
