import { AxiosError, AxiosResponse } from 'axios';
import { createEffect } from 'effector';
import humps from 'humps';

import { BlogsEndpoints } from 'ant/endpoints/blogs';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { DictPaginated } from 'ant/types/api';
import { Blog, BlogsSettingsModel, BlogTypes, EntityId } from 'ant/types/blogs';
import { BlogId, BlogRequestStatus, SubscribeToBlogRequestModel } from 'ant/types/models/blog.model';

import {
  acceptBlogInvite,
  rejectBlogInvite,
  BlogStorageParams,
  setBlogsSettingsMain,
  removeMember,
  getBlogParticipantsEndpoint,
  GetParticipantsParams,
  RemoveMemberParams,
  RemoveMemberFromBlogModel,
  getBlogInfo,
  UpdateBlogDescriptionParams,
  subscribeToBlog,
  BlogDataStorage,
  switchBlogNotification,
  SubscribeToBlogApiParams,
  subscribeToBlogRequest,
  BlogResponse,
  updateBlogDescription,
  blogEndpointsMap,
  SubscribeToBlogRequestParams,
  SwitchBlogNotificationsParams,
} from './api';

export const acceptInviteEffect = createEffect<BlogStorageParams, unknown, AxiosError>((params) =>
  acceptBlogInvite(params),
);

export const rejectInviteEffect = createEffect<BlogStorageParams, unknown, AxiosError>((params) =>
  rejectBlogInvite(params),
);

export const getBlogsSettingsMain = () => {
  const storage = abstractStorageFactory<BlogsSettingsModel, BlogsSettingsModel, null>({
    endpointBuilder: BlogsEndpoints.settingsMain,
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  const setBlogsSettingsMainEffect = createEffect<BlogsSettingsModel, BlogsSettingsModel, AxiosError>(
    (params) => setBlogsSettingsMain(params).then(({ data }) => data),
  );

  storage.store.on(setBlogsSettingsMainEffect, (state, settings) => ({ ...state, ...settings }));

  return { storage, setBlogsSettingsMainEffect };
};

export const removeMemberFromBlogEffect = createEffect<
  RemoveMemberParams,
  RemoveMemberFromBlogModel,
  AxiosError
>((params) => removeMember(params).then(({ data }) => data));

export const getBlogMembersStorage = (blogId: BlogId) => {
  const storage = abstractStorageFactory<
    DictPaginated<Blog.Member>,
    Blog.Member[],
    Blog.Member[],
    GetParticipantsParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(getBlogParticipantsEndpoint(blogId), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  storage.store.on(removeMemberFromBlogEffect.done, (state, { result }) => ({
    ...state,
    data: state.data.filter((member) => member.keycloakId !== result.keycloakId),
  }));

  return {
    storage,
  };
};

export type BlogMembersStorage = ReturnType<typeof getBlogMembersStorage>;
export type SingleBlogStorageParams<T> = {
  slugId: EntityId;
  type: BlogTypes;
  defaultValue?: T | null;
};

export const getSingleBlogStorage = <T extends BlogDataStorage>({
  slugId,
  type,
  defaultValue = null,
}: SingleBlogStorageParams<T>) => {
  const storage = abstractStorageFactory<T, T, T | null>({
    endpointBuilder: () => blogEndpointsMap[type](slugId as never),
    defaultValue,
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) =>
      data.permissionsV2 ? { ...data, permissionsV2: humps.decamelizeKeys(data.permissionsV2) } : data,
  });
  const { refetchWithLastParams } = storage;

  const getStateBlogId = (): BlogId => {
    const { data } = storage.store.getState();

    return Number(data?.id);
  };

  const updateDescriptionEffect = createEffect<UpdateBlogDescriptionParams, unknown, AxiosError>({
    handler: (params) =>
      updateBlogDescription({ blogId: getStateBlogId(), ...params }).then(refetchWithLastParams),
  });

  const subscribeToBlogEffect = createEffect<SubscribeToBlogApiParams, unknown, AxiosError>((params) =>
    subscribeToBlog(params),
  );

  const subscribeToBlogRequestEffect = createEffect<
    SubscribeToBlogRequestParams,
    SubscribeToBlogRequestModel | void,
    AxiosError
  >((params) => subscribeToBlogRequest(params).then((response) => response.data));

  const updateBlogPermissionsEffect = createEffect<void, AxiosResponse<BlogResponse>, AxiosError>(() =>
    getBlogInfo<BlogResponse>({ blogId: getStateBlogId() }),
  );

  const switchBlogNotificationEffect = createEffect<
    SwitchBlogNotificationsParams,
    { notify: boolean },
    AxiosError
  >({
    handler: (params) => switchBlogNotification({ blogId: getStateBlogId(), ...params }),
  });

  storage.store.on(switchBlogNotificationEffect.done, (state, { result: { notify } }) => {
    if (state.data) {
      return {
        ...state,
        data: {
          ...state.data,
          notify,
        },
      };
    }

    return state;
  });

  storage.store.on(subscribeToBlogEffect.done, (state, { params: { follow } }) => {
    if (state.data) {
      return {
        ...state,
        data: {
          ...state.data,
          isSubscribed: follow,
        },
      };
    }

    return state;
  });

  storage.store.on(subscribeToBlogRequestEffect.doneData, (state, payload) => {
    if (state.data) {
      if (payload) {
        return {
          ...state,
          data: {
            ...state.data,
            requestStatus: BlogRequestStatus.Pending,
            requestId: payload.requestId,
          },
        };
      }

      return {
        ...state,
        data: {
          ...state.data,
          requestStatus: null,
          requestId: null,
        },
      };
    }

    return state;
  });

  storage.store.on(updateBlogPermissionsEffect.done, (state, { result }) => {
    if (state.data) {
      return {
        ...state,
        data: {
          ...state.data,
          permissions: result.data?.permissions,
        },
      };
    }

    return state;
  });

  return {
    storage,
    updateDescriptionEffect,
    subscribeToBlogEffect,
    subscribeToBlogRequestEffect,
    updateBlogPermissionsEffect,
    switchBlogNotificationEffect,
  };
};
