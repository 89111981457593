import { Segmented, SegmentedProps } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import styles from './UiSegmented.scss';

export type UiSegmentedOption<T> = {
  label: string;
  icon?: ReactNode;
  value: T;
};

type BaseSegmentedProps = Omit<SegmentedProps, 'ref' | 'defaultValue' | 'value' | 'onChange' | 'options'>;

export interface UiSegmentedProps<T> extends BaseSegmentedProps {
  defaultValue?: T;
  value?: T;
  onChange?: (value: T) => void;
  options: UiSegmentedOption<T>[];
}

export const UiSegmented = <T extends SegmentedValue = SegmentedValue>(props: UiSegmentedProps<T>) => {
  const { className, onChange, ...restProps } = props;

  const onSegmentedChange = (value: SegmentedValue) => {
    onChange?.(value as T);
  };

  return (
    <Segmented
      className={classNames(styles.uiSegmented, className)}
      onChange={onSegmentedChange}
      {...restProps}
    />
  );
};
