import classNames from 'classnames';
import React, { FC } from 'react';
import {
  MentionItem,
  MentionsInput,
  MentionsInputProps,
  OnChangeHandlerFunc,
  SuggestionDataItem,
} from 'react-mentions';

import { UiConfigProviderSizeType } from 'ant/components/ui/config-provider';
import { useGetSizeClassname } from 'ant/helpers/hooks/use-get-size-classname';

import styles from './UiMentionsInput.scss';
import {
  UiMentionInputSearch,
  UiMentionClearInputSearchHandler as MentionClearInputSearchHandler,
} from './search/UiMentionInputSearch';

export type UiMentionsInputValue = string;
export type UiMentionItem = MentionItem;
export type UiMentionSuggestionItem = SuggestionDataItem;
export type OnChangeMentionsInputHandler = (
  newValue: UiMentionsInputValue,
  newPlainTextValue: UiMentionsInputValue,
  mentions: UiMentionItem[],
  event: { target: { value: UiMentionsInputValue } },
) => void;

export type UiMentionClearInputSearchHandler = MentionClearInputSearchHandler;

export interface UiMentionsInputProps extends Omit<MentionsInputProps, 'onChange'> {
  onChange?: OnChangeMentionsInputHandler;
  noStyle?: boolean;
  size?: UiConfigProviderSizeType;
}

export type UiMentionsInputComposition = {
  Search: typeof UiMentionInputSearch;
};

export type UiMentionsInputComponent = FC<UiMentionsInputProps> & UiMentionsInputComposition;

export const UiMentionsInput: UiMentionsInputComponent = (props) => {
  const { value = '', onChange, style, className, size: customizeSize, noStyle, ...restProps } = props;
  const sizeCls = useGetSizeClassname(customizeSize);
  const classNameStyle = classNames(
    styles.uiMentionsInput,
    {
      [styles.uiMentionsInput_noStyle]: noStyle,
      [`${styles.uiMentionsInput}-${sizeCls}`]: sizeCls && !noStyle,
    },
    className,
  );

  const onChangeHandler: OnChangeHandlerFunc = (event, ...args) => {
    onChange?.(...args, event);
  };

  return (
    <MentionsInput
      a11ySuggestionsListLabel="Текст с ссылками через '@'"
      {...restProps}
      value={value}
      autoComplete="off"
      onChange={onChangeHandler}
      className={classNameStyle}
      style={{
        ...style,
        highlighter: noStyle && {
          border: 0,
        },
        suggestions: {
          zIndex: 2,
          list: { backgroundColor: 'none' },
        },
        '&singleLine': {
          input: { backgroundColor: 'none' },
        },
      }}
    />
  );
};

UiMentionsInput.Search = UiMentionInputSearch;
