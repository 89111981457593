import React from 'react';

import DropDownSvg from 'ant/components/svg/drop-down.svg';
import DropUpSvg from 'ant/components/svg/drop-up.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiFormListItemMoveActionType } from 'ant/components/ui/form';
import { UiIcon } from 'ant/components/ui/icon';
import { UiItemType } from 'ant/components/ui/menu';

type Props = {
  onMove: UiFormListItemMoveActionType;
  index: number;
  items: UiItemType[];
};

export const UiMenuCollapsibleControls: React.FC<Props> = (props) => {
  const { onMove, index, items } = props;

  const handleUpClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onMove?.(index, index - 1);
  };

  const handleDownClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onMove?.(index, index + 1);
  };

  return (
    <div>
      <UiButton type="link-secondary" onClick={handleUpClick} disabled={!index}>
        <UiIcon width={20} height={20} component={DropUpSvg} />
      </UiButton>
      <UiButton type="link-secondary" onClick={handleDownClick} disabled={items.length - 1 === index}>
        <UiIcon width={20} height={20} component={DropDownSvg} />
      </UiButton>
    </div>
  );
};
