import React from 'react';

import { UiImage } from 'ant/components/ui/image';
import { UiOverlay } from 'ant/components/ui/overlay';

import { PreparedImage } from '../helpers';

type Props = {
  gap: number;
  image: PreparedImage;
  onClick: (image: PreparedImage) => void;
};

export const UiImageGridItem: React.FC<Props> = (props) => {
  const { gap, image, onClick } = props;

  const defaultImageStyle: React.CSSProperties = {
    width: image.width,
    height: image.height,
  };

  const imageContainerStyle: React.CSSProperties = {
    ...defaultImageStyle,
    margin: `0 ${gap}px`,
    display: 'inline-block',
    position: 'relative',
  };

  const onKeyPress: React.KeyboardEventHandler = ({ key }) => {
    if (key === 'Enter') {
      onClick(image);
    }
  };

  return (
    <div
      role="link"
      tabIndex={-1}
      onClick={() => onClick(image)}
      onKeyPress={onKeyPress}
      style={imageContainerStyle}
    >
      <UiOverlay trigger="hover">
        <UiImage src={image.fileStorageImageUrl} style={defaultImageStyle} alt="Изображение из фотоальбома" />
      </UiOverlay>
    </div>
  );
};
