export type ReactionsCountInfo = {
  id: string;
  count: number;
  isReacted?: boolean;
};

export type ReactionId = string;
export type Reaction = {
  reactionId: ReactionId;
  reactionCount: number;
  isReacted: boolean;
};

export interface ReactableModel {
  reactions?: Reaction[];
}

export enum ReactionPostContentType {
  Post = 'entry',
  Micropost = 'micropost',
  Comment = 'comment',
  GamificationEntry = 'gamificationentry',
  ProfileEntry = 'profileentry',
}

export enum ReactionNewsContentType {
  News = 'news',
  Comment = 'comment',
}
