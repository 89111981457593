import React, { FC, ReactNode, useMemo } from 'react';

import { UiFile, UiFileDirection } from 'ant/components/ui/file';
import { UiImage } from 'ant/components/ui/image';
import { UiSpace } from 'ant/components/ui/space';
import { UiVideo } from 'ant/components/ui/video/UiVideo';
import { checkFileMimeType } from 'ant/plugins/utils/check-file-mime-type';
import { isVideoFile } from 'ant/plugins/utils/is-video-file';
import { useTheme } from 'ant/theme/provider';
import { FileUploadAccepts } from 'ant/types/models/file-upload-accepts';

import { FileActions } from '../FileActions/FileActions';
import { BasicFileProps } from '../types';

export const FileColumn: FC<BasicFileProps> = (props) => {
  const { file, actions, subtitle, title, fileName, isFolder, ...fileProps } = props;
  const [{ variables: themeVariables }] = useTheme();

  const isSupportedImageFile =
    file?.additional && checkFileMimeType(FileUploadAccepts.ImageAll, file.additional.mimeType);
  const isSupportedVideoFile = isVideoFile(file.name);

  const thumbnail = useMemo<ReactNode>(() => {
    if (isSupportedVideoFile) {
      return <UiVideo src={file.file} width={162} height={100} />;
    }

    if (isSupportedImageFile) {
      return (
        <UiImage
          placeholderSize={100}
          style={{ backgroundColor: themeVariables.colorBrand16, objectFit: 'contain' }}
          width={162}
          height={100}
          src={file.file}
        />
      );
    }

    return null;
  }, [file]);

  return (
    <UiSpace size={6} direction="vertical" align="center">
      <UiFile
        direction={UiFileDirection.Vertical}
        title={title}
        subtitle={subtitle}
        isFolder={isFolder}
        thumbnail={thumbnail}
        fileName={fileName}
        {...fileProps}
        size="small"
      />
      {actions?.length > 0 && <FileActions actions={actions} />}
    </UiSpace>
  );
};
