import { api } from 'ant/plugins/api';
import { PagesEndpoints } from 'ant/store/pages/endpoints';

export type WidgetId = number;
export type UserIdParams = { userId: string };
export type UpdateWidgetDefaultDataParams = { widgetId: WidgetId; data: any };
export type UpdateUserWidgetDataParams = UserIdParams & UpdateWidgetDefaultDataParams;

export const updateUserWidgetData = <T>({ userId, widgetId, data }: UpdateUserWidgetDataParams) =>
  api.post<T>({
    url: PagesEndpoints.widgetDataNew(),
    data: { user: userId, widget: widgetId, data },
  });

export const updateWidgetDefaultData = <T>({ widgetId, data }: UpdateWidgetDefaultDataParams) =>
  api.post<T>({
    url: PagesEndpoints.widgetDataNew(),
    data: { widget: widgetId, data },
  });
