import React, { ForwardRefRenderFunction, PropsWithChildren } from 'react';
import { animated, SpringConfig, useSpring } from 'react-spring';

import { UiInputProps } from 'ant/components/ui/input';
import { useTheme } from 'ant/theme/provider';
import ClearSVG from 'components-frontend/components/svg/clear.svg';
import SearchSVG from 'components-frontend/components/svg/search.svg';

import styles from './AnimatedSearch.scss';

const AnimatedSearchInputBase = animated.input;
const headerSearchAnimationConfig: SpringConfig = { duration: 300 };

interface Props extends Omit<UiInputProps, 'value' | 'size' | 'prefix'> {
  isFocused?: boolean;
  value?: string;
}

const AnimatedSearchInput: ForwardRefRenderFunction<HTMLInputElement, PropsWithChildren<Props>> = (
  props,
  ref,
) => {
  const { value, isFocused, children, onClear, style: outerStyles, ...inputProps } = props;
  const [{ variables: themeVariables }] = useTheme();

  const inputStyles = useSpring({
    from: {
      background: themeVariables.colorInputOnBrand,
    },
    to: {
      background: isFocused ? themeVariables.colorHoverOnBrand : themeVariables.colorInputOnBrand,
    },
    config: headerSearchAnimationConfig,
  });

  const searchStyles = useSpring({
    from: {
      background: themeVariables.colorBrand,
    },
    to: {
      background: isFocused ? themeVariables.colorHoverOnBrand : themeVariables.colorBrand,
    },
    config: headerSearchAnimationConfig,
  });

  return (
    <animated.div className={styles.animatedSearch} style={{ ...searchStyles, ...outerStyles }}>
      <div className={styles.animatedSearch__inputWrapper}>
        <AnimatedSearchInputBase
          ref={ref}
          type="text"
          placeholder={isFocused ? '' : 'Поиск'}
          value={value}
          {...inputProps}
          className={styles.animatedSearch__input}
          style={inputStyles}
        />
        {isFocused && value && value.length > 0 && onClear && (
          <ClearSVG className={styles.animatedSearch__cross} onClick={onClear} />
        )}
      </div>

      {isFocused && children}

      <SearchSVG className={styles.animatedSearch__icon} />
    </animated.div>
  );
};

export const AnimatedSearchInputWidget = React.forwardRef<HTMLInputElement, PropsWithChildren<Props>>(
  AnimatedSearchInput,
);
