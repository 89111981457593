import classNames from 'classnames';
import React, { FC } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiFormItemProps } from 'ant/components/ui/form';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypography } from 'ant/components/ui/typography';

import { AvailableAlphabetsPointers, Alphabets } from '../constants';
import styles from './AlphabetPointers.scss';

export type AlphabetSymbol = string;

export interface AlphabetPointersProps extends Pick<UiFormItemProps<AlphabetSymbol>, 'onChange' | 'value'> {
  data: Alphabets;
}

export const AlphabetPointers: FC<AlphabetPointersProps> = (props) => {
  const { data, value, onChange } = props;
  const alphabet = AvailableAlphabetsPointers[data];
  const heading = `${alphabet[0]}-${alphabet[alphabet.length - 1]}`;

  return (
    <UiSpace full wrap size={0} align="center">
      <UiTypography.Text strong className={styles.alphabetPointers__heading}>
        {heading}
      </UiTypography.Text>

      {alphabet.map((symbol) => {
        const isActive = value === symbol;

        return (
          <UiButton
            type="link-secondary"
            key={symbol}
            onClick={() => onChange?.(symbol)}
            className={classNames(styles.alphabetPointers__symbol, {
              [styles.alphabetPointers__symbol_active]: isActive,
            })}
            label={symbol}
          />
        );
      })}
    </UiSpace>
  );
};
