import { stringify, parse } from 'query-string';
import { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const useTabHistory = <T extends string>(tabList: T[] = []) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const currentTab = useMemo<T>(() => {
    const { tab: tabKey } = parse(search);

    if (!tabKey || !tabList.includes(tabKey as T)) {
      return tabList[0];
    }

    return tabKey.toString() as T;
  }, [search]);

  const handleTabChange = useCallback(
    (value: T) => {
      navigate({
        pathname,
        search: stringify({
          ...parse(search),
          tab: value.toString(),
          p: undefined,
        }),
      });
    },
    [pathname, search],
  );

  return {
    handleTabChange,
    currentTab,
  };
};
