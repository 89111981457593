import { Plugin } from '@ckeditor/ckeditor5-core';

import { VideoUploadEditing } from './videoupload/videouploadediting';
import { VideoUploadUI } from './videoupload/videouploadui';

export class VideoUpload extends Plugin {
  public static get pluginName() {
    return 'VideoUpload' as const;
  }

  public static get requires() {
    return [VideoUploadEditing, VideoUploadUI] as const;
  }
}
