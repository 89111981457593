import { Badge } from 'antd';
import { BadgeProps } from 'antd/es/badge';
import classNames from 'classnames';
import React from 'react';

import styles from './UiBadge.scss';

export const MAX_BADGE_COUNT = 9999999;

export enum BadgeColors {
  Primary = 'primary',
  Grey = 'grey',
  Negative = 'negative',
  Warning = 'warning',
  Brand = 'brand',
}

const themeColorsMap: Record<BadgeColors, string> = {
  [BadgeColors.Primary]: styles.uiBadge__primary,
  [BadgeColors.Grey]: styles.uiBadge__grey,
  [BadgeColors.Negative]: styles.uiBadge__negative,
  [BadgeColors.Warning]: styles.uiBadge__warning,
  [BadgeColors.Brand]: styles.uiBadge__brand,
};

export interface UiBadgeProps extends Omit<BadgeProps, 'color'> {
  color?: BadgeColors;
  reverse?: boolean;
  strong?: boolean;
}

export const UiBadge: React.FC<UiBadgeProps> = ({
  color = BadgeColors.Grey,
  className,
  text,
  strong,
  reverse,
  ...props
}) => (
  <Badge
    {...props}
    className={classNames(
      styles.uiBadge,
      [themeColorsMap[color]],
      {
        [styles.uiBadge__text]: text,
        [styles.uiBadge__text_reverse]: reverse,
        [styles.uiBadge__text_strong]: strong,
      },
      className,
    )}
    text={text}
  />
);
