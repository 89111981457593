import {
  AbstractStorageConfiguration,
  abstractStorageFactory,
} from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { NewsEndpoints } from 'ant/store/news/endpoints';
import { DictPaginated, PaginationParams } from 'ant/types/api';
import { NewsArticle, NewsCategoriesDist, NewsCategory } from 'ant/types/models/news.model';

export interface NewsFeedListParams extends PaginationParams {
  type: string;
  important: boolean;
  pinned: boolean;
  category: string;
  categoryIds: string;
  authorId: string;
  searchString: string;
}

type GetNewsFeedStorageParams = Pick<
  AbstractStorageConfiguration<
    DictPaginated<NewsArticle>,
    NewsArticle[],
    NewsArticle[],
    Partial<NewsFeedListParams>
  >,
  'shouldAppendData'
>;

export const getNewsFeedStorage = (
  { shouldAppendData }: GetNewsFeedStorageParams = { shouldAppendData: true },
) => {
  const storage = abstractStorageFactory<
    DictPaginated<NewsArticle>,
    NewsArticle[],
    NewsArticle[],
    Partial<NewsFeedListParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(NewsEndpoints.feed(), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.pagesCount, page: meta.pageNumber }),
    shouldAppendData,
  });

  return { storage };
};

export const getNewsCategoriesStorage = () => {
  const storage = abstractStorageFactory<DictPaginated<NewsCategory>, NewsCategoriesDist, null>({
    endpointBuilder: NewsEndpoints.categoriesList,
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items.reduce((acc, category) => ({ ...acc, [category.id]: category }), {}),
  });

  return { storage };
};

export type NewsCategoriesStorage = ReturnType<typeof getNewsCategoriesStorage>;

export const newsCategoriesStore = getNewsCategoriesStorage();
