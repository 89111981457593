// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b1be8f02_ui-card-header{display:flex;align-items:center;padding:24px}.b1be8f02_ui-card-header_empty-padding{padding:0}.b1be8f02_ui-card-header_empty-padding>button{margin-left:24px}.b1be8f02_ui-card-header_bottom-border{border-bottom:1px solid var(--default-themeVariables-colorStroke)}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/card/header/UiCardHeader.scss","webpack://./../ant/src/assets/scss/variables/content.scss"],"names":[],"mappings":"AACA,yBACE,YAAA,CACA,kBAAA,CACA,YCHY,CDKZ,uCACE,SAAA,CAEA,8CACE,gBCTQ,CDaZ,uCACE,iECJW","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.ui-card-header {\n  display: flex;\n  align-items: center;\n  padding: $baseSpacing;\n\n  &_empty-padding {\n    padding: 0;\n\n    > button {\n      margin-left: $baseSpacing;\n    }\n  }\n\n  &_bottom-border {\n    border-bottom: $spacerBorder;\n  }\n}\n","$borderRadiusBase: get('themeSizes.borderRadiusBase');\n$baseSpacing: 24px;\n$spacerHeight: 32px;\n$widgetMargin: get('themeOffsets.widgetMargin');\n\n$borderRadius: 8px; // TODO: вынести в тему\n$borderRadiusHalf: 4px; // TODO: вынести в тему\n$borderRadiusQuarter: 2px; // TODO: вынести в тему\n$blockquoteBorderRadius: get('themeLongRead.blockquoteBorderRadius');\n\n$modalOffset: 72px;\n$spacerBorder: 1px solid $colorStroke;\n$dashedBorder: 1px dashed $colorStrokeDark;\n\n$spaceBase: 4px;\n\n$space2XS: $spaceBase;\n$spaceXS: $spaceBase * 2;\n$spaceS: $spaceBase * 3;\n$spaceM: $spaceBase * 4;\n$spaceL: $spaceBase * 5;\n$spaceXL: $spaceBase * 6;\n$space2XL: $spaceBase * 8;\n$space3XL: $spaceBase * 9;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-card-header": "b1be8f02_ui-card-header",
	"uiCardHeader": "b1be8f02_ui-card-header",
	"ui-card-header_empty-padding": "b1be8f02_ui-card-header_empty-padding",
	"uiCardHeader_emptyPadding": "b1be8f02_ui-card-header_empty-padding",
	"ui-card-header_bottom-border": "b1be8f02_ui-card-header_bottom-border",
	"uiCardHeader_bottomBorder": "b1be8f02_ui-card-header_bottom-border"
};
export default ___CSS_LOADER_EXPORT___;
