import { startOfWeek, startOfMonth, endOfMonth, getISODay, addDays } from 'date-fns';

export type MonthRangeByWeeksType = {
  sinceDate: Date;
  tillDate: Date;
};

export const getMonthRangeByWeeks = (date: Date): MonthRangeByWeeksType => {
  const sinceDate = startOfWeek(startOfMonth(date), { weekStartsOn: 1 });
  const endOfMonthDate = endOfMonth(date);
  const tillDate = addDays(endOfMonthDate, 7 - getISODay(endOfMonthDate));

  return { sinceDate, tillDate };
};
