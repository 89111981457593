import { AttachmentEntryId } from 'ant/types/models/attachment.model';
import {
  CMSPageId,
  CMSPageSlugId,
  CMSReactionId,
  CMSRolesUserRoleId,
  CMSSectionId,
  CMSSectionSlugId,
} from 'ant/types/models/cms.model';
import { KeycloakId } from 'ant/types/models/keycloak-user';

const getUrl = (url: string) => `/api/cms/${url}`;
const getPageSlugUrl = (url: string) => getUrl(`pages/slug/${url}`);
const getFavoriteUrl = (url: string) => getUrl(`favorite/${url}`);
const getUrlRoles = (url: string) => `/api/cms/roles/${url}`;

export class CMSEndpoints {
  static attachment = (attachmentId: AttachmentEntryId) => getUrl(`attachments/${attachmentId}/`);
  static attachments = () => getUrl(`attachments/`);
  static page = (pageId: CMSPageId) => getUrl(`pages/${pageId}/`);
  static pages = () => getUrl(`pages/`);
  static pagesUsers = (sectionId: CMSSectionId) => getUrl(`pages/${sectionId}/users/`);
  static pagesPaths = (sectionId: CMSSectionId) => getUrl(`pages/${sectionId}/paths/`);
  static pagesSlug = (sectionSlugId: CMSSectionSlugId) => getPageSlugUrl(`${sectionSlugId}/`);
  static pagesSlugBreadcrumbs = (pageSlugId: CMSSectionSlugId) =>
    getPageSlugUrl(`${pageSlugId}/breadcrumbs/`);
  static pagesFavorite = () => getFavoriteUrl(`pages/`);
  static pagesSlugFavorite = (pageSlugId: CMSSectionSlugId) => getFavoriteUrl(`pages/${pageSlugId}/`);
  static pagesSlugDetail = (pageSlugId: CMSPageSlugId) => getPageSlugUrl(`${pageSlugId}/detail/`);
  static pagesSlugPopular = (sectionSlugId: CMSSectionSlugId) => getPageSlugUrl(`${sectionSlugId}/popular/`);
  static reaction = (pageId: CMSPageId, reactionId: CMSReactionId) =>
    getUrl(`reactions/${pageId}/${reactionId}/`);
  static reactions = () => getUrl(`reactions/`);
  static roles = () => getUrlRoles(``);
  static rolesList = () => getUrlRoles(`list/`);
  static rolesSave = () => getUrlRoles(`save/`);
  static rolesUserId = (userId: KeycloakId) => getUrlRoles(`${userId}/`);
  static rolesUserRoleId = (userRoleId: CMSRolesUserRoleId) => getUrlRoles(`${userRoleId}/`);
  static rolesUsers = () => getUrlRoles(`users/`);
  static section = (sectionId: CMSSectionId) => getUrl(`sections/${sectionId}/`);
  static sections = () => getUrl(`sections/`);
  static sectionsUsers = (sectionId: CMSSectionId) => getUrl(`sections/${sectionId}/users/`);
  static sectionsPaths = (sectionId: CMSSectionId) => getUrl(`sections/${sectionId}/paths/`);
  static sectionsSlug = (slugId: CMSSectionSlugId) => getUrl(`sections/slug/${slugId}/`);
  static sectionsSlugBreadcrumbs = (slugId: CMSSectionSlugId) =>
    getUrl(`sections/slug/${slugId}/breadcrumbs/`);
  static sectionsSlugChildren = (slugId: CMSSectionSlugId) => getUrl(`sections/slug/${slugId}/children/`);
  static sectionsSlugTree = (slugId: CMSSectionSlugId) => getUrl(`sections/slug/${slugId}/tree/`);
  static sectionReport = (sectionId: CMSSectionId) => getUrl(`sections/${sectionId}/report/`);
}
