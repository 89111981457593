import { KeycloakId } from 'ant/types/models/keycloak-user';

const getProfileUrl = (url: string) => `/profile/${url}`;
const getProfileV2Url = (url: string) => `/profile/v2/${url}`;
const getProfileV3Url = (url: string) => `/profile/v3/${url}`;

export class ProfileEndpoints {
  static openToken = () => getProfileUrl(`open-api/token/`);

  static getUserId = () => getProfileUrl(`get_user_id/`);

  static contactsSearch = () => getProfileUrl(`contacts/search`);

  static v2List = () => getProfileUrl('v2/list/');

  static v3AdvancedSearch = () => getProfileV3Url('advanced_search/');

  static v3AdvancedSearchDownload = () => getProfileV3Url('advanced_search/download/');

  static main = (userId: KeycloakId) => getProfileUrl(`${userId}/main/`);

  static job = (userId: KeycloakId) => getProfileUrl(`${userId}/job/`);

  static jobHistory = (userId: KeycloakId) => getProfileUrl(`${userId}/job/history/`);

  static contacts = (userId: KeycloakId) => getProfileUrl(`${userId}/contacts/`);

  static full = (userId: KeycloakId) => getProfileUrl(`${userId}/full/`);

  static biography = (userId: KeycloakId) => getProfileUrl(`${userId}/biography/`);

  static avatarCreate = (userId: KeycloakId) => getProfileV3Url(`${userId}/avatar/create/`);

  static userProjects = (userId: KeycloakId) => getProfileUrl(`user/${userId}/projects/`);

  static additionalInfo = (userId: KeycloakId) => getProfileUrl(`${userId}/additional_info/`);

  static projects = () => getProfileUrl('projects/');

  static searchProjects = () => getProfileUrl('projects/search/');

  static searchHobby = () => getProfileUrl('/hobby/search/');

  static counters = (id: string) => getProfileUrl(`${id}/counters/`);

  static skills = (id: string) => getProfileV2Url(`${id}/skills/`);

  static skillAction = (id: string, skillId: string) => getProfileV2Url(`${id}/skills/${skillId}/`);

  static skillPeoples = (id: string) => getProfileV2Url(`skills/${id}/people/`);

  static substitution = (userId: KeycloakId) => getProfileUrl(`${userId}/substitutions/`);

  static userSettings = () => getProfileUrl('user/settings/');

  static listPositions = () => getProfileUrl('list/positions/');
}
