import { Plugin } from '@ckeditor/ckeditor5-core';

import { ImageCropUiPlugin } from './ImageCropUiPlugin';
import { fileIdAttributeModelName, fileIdAttributeName } from './constants';

export class ImageCropPlugin extends Plugin {
  static get requires() {
    return [ImageCropUiPlugin];
  }

  init() {
    const { editor } = this;

    editor.conversion.for('upcast').attributeToAttribute({
      view: fileIdAttributeName,
      model: fileIdAttributeModelName,
    });

    editor.conversion.for('downcast').add((dispatcher) => {
      dispatcher.on(`attribute:${fileIdAttributeModelName}:imageBlock`, (evt, data, conversionApi) => {
        if (!conversionApi.consumable.consume(data.item, evt.name)) {
          return;
        }

        const viewWriter = conversionApi.writer;
        const figure = conversionApi.mapper.toViewElement(data.item);
        const img = figure.getChild(0);

        if (data.attributeNewValue !== null) {
          viewWriter.setAttribute(fileIdAttributeName, data.attributeNewValue, img);
        } else {
          viewWriter.removeAttribute(fileIdAttributeName, img);
        }
      });
    });
  }

  public static get pluginName() {
    return 'ImageCropPlugin' as const;
  }
}
