import { SinceTillFetchParams } from 'ant/types/api';
import { CalendarEventModelId } from 'ant/types/models/calendar';
import { UserModel } from 'ant/types/models/user.model';

type DateString = string;
export type MeetingId = number;
export type MeetingFileId = number;
export type MeetingServiceId = string;
export type MeetingSelectedServiceId = number;
export type MeetingPhoneNumberId = number;
export type MeetingRoomFreeSlotId = number;

export enum MeetingScales {
  HalfAnHour = 'half_an_hour',
  Hour = 'hour',
  HourAndHalf = 'hour_and_half',
  TwoHours = 'two_hours',
}

export interface MeetingPhoneNumber {
  id: MeetingPhoneNumberId;
  number: string;
  label: string;
  meetingRoom: number;
}

export interface MeetingFile {
  id: MeetingFileId;
  attachmentFileURL: string;
  name: string;
  description: string;
  tags: string[];
  info: MeetingSelectedService;
  uploadedAt: DateString;
  updatedAt: DateString;
  size: number;
  attachmentFile: string;
  ctModelName: string;
  objectID: number;
}

export interface MeetingSelectedService {
  id: MeetingSelectedServiceId;
  name: string;
  file: MeetingFile;
  iconName: string;
}

export interface MeetingRoomImage {
  image: string;
  meetingRoom: number;
}

export interface Meeting {
  id: MeetingId;
  name: string;
  initiator: UserModel;
  dateTimeBegin: DateString;
  dateTimeEnd: DateString;
  meetingRoom: number;
  regulator: string;
  status: string;
  serviceBuffet: boolean;
  serviceConference: boolean;
  serviceInterpretation: boolean;
  selectedServices: MeetingSelectedService[];
  comment: string;
  calendarEventId: CalendarEventModelId;
}

export interface MeetingService {
  id: MeetingServiceId;
  name: string;
  iconName: string;
  type: string;
  regulatorEmail: string;
  file: File;
}

export enum ServiceTypes {
  Video = 'video',
  Board = 'board',
  Display = 'display',
}

export type FetchMeetingsParams = {
  name?: string;
  number?: string;
  serviceName?: string;
  serviceType?: ServiceTypes[];
  capacityTotal?: number;
  capacityTotalLte?: number;
  capacityTotalGte?: number;
  capacityTable?: number;
  space?: number;
  since?: string;
  till?: string;
  floor?: number;
  scale?: string;
};

export interface MeetingRoomFreeSlot extends SinceTillFetchParams {
  id: MeetingRoomFreeSlotId;
  name: string;
  description: string;
  floor: number;
  number: string;
  capacityTotal: number;
  capacityTable: number;
  space: number;
  phoneNumbers: MeetingPhoneNumber[];
  meetings: Meeting[];
  services: MeetingService[];
  photo: string;
  meetingRoomImages: MeetingRoomImage[];
}
