import { useEffect, useState } from 'react';
import screenfull from 'screenfull';

type UseFullscreenParams = {
  querySelector: string;
};

type UseFullscreenResult = {
  isFullscreen: boolean;
  onToggleFullscreen: () => void;
};

export const useFullscreen = ({ querySelector }: UseFullscreenParams): UseFullscreenResult => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const toggleFullscreenHandler = () => {
      setIsFullscreen((prevState) => !prevState);
    };

    screenfull.on('change', toggleFullscreenHandler);

    return () => screenfull.off('change', toggleFullscreenHandler);
  }, []);

  const onToggleFullscreen = async () => {
    const element = document.querySelector(querySelector);

    if (element) {
      await screenfull.toggle(element);
    }
  };

  return { isFullscreen, onToggleFullscreen };
};
