import { DictPaginated } from 'ant/types/api';
import { AttachmentDetail } from 'ant/types/models/attachment.model';
import { EventFormatType } from 'ant/types/models/event.model';
import { FileStorageEntryId, FileStorageObjectModel } from 'ant/types/models/file.model';
import { KeycloakUser } from 'ant/types/models/keycloak-user';
import { MeetingScales } from 'ant/types/models/meetings';
import { UserPositionModel } from 'ant/types/models/user.model';

export enum CalendarUserStatus {
  NeedAction = 'need-action',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Maybe = 'maybe',
  NotAttend = 'not_attend',
}

// TODO: На бекенде нужно убрать айдишники на человекочитаемое название.
export enum CalendarEventType {
  Meeting = 'e8ad7e19-8e91-48a7-9eb9-402be8920c1f',
  Task = '41582cac-9eed-4777-b291-9b734b19eea0',
  Learn = '1895d255-69be-45fc-9aff-9b3251c85daa',
  Activity = 'a4068e5e-f1aa-4a4d-a20e-97532a884a42',
}

export enum CalendarViewType {
  Week = 'week',
  Month = 'month',
  Day = 'day',
}

export enum CalendarFrequency {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export enum CalendarSourceType {
  Exchange = 'exchange',
  Sps = 'sps',
  Iasup = 'iasup',
}

export enum CalendarRequiredMembers {
  All = 'all',
  RequiredOnly = 'required_only',
}

export type CalendarWeekDays = 'mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa' | 'su';

export type CalendarWeekDaysMap = Record<CalendarWeekDays, boolean>;

export type CalendarRepeatRule = {
  days: CalendarWeekDaysMap;
  everyWeek: number;
  beforeDate: string;
};

export type CalendarAttendee = {
  userId: string;
  status: CalendarUserStatus;
  fullName: string;
  image: string;
  mailto: string;
  keycloakUser?: KeycloakUser;
  position?: UserPositionModel;
};

export type CalendarAttendeeToAdd = {
  userId: string;
  status: CalendarUserStatus;
  fullName?: string;
  image?: string;
  mailto: string;
  keycloakUser?: KeycloakUser;
  position?: UserPositionModel;
  isActive?: boolean;
};

export type CalendarAttendeeList = CalendarAttendee[];
export type CalendarAttendeeToAddList = CalendarAttendeeToAdd[];

export type CalendarEventModelId = string;

export interface CalendarEventModel {
  id: CalendarEventModelId;
  calendarId: string;
  organizer: CalendarAttendee;
  summary: string;
  since: string;
  till: string;
  isFullDay?: boolean;
  isDeleted: boolean;
  repeat?: boolean;
  format: EventFormatType;
  repeatRule?: CalendarRepeatRule;
  firstAttendees?: CalendarAttendee[];
  eventType: CalendarEventType;
  sourceType: CalendarSourceType;
  description?: string;
  place?: string;
  myStatus: CalendarUserStatus;
  link?: string;
  externalId?: string;
  attachmentDetails: AttachmentDetail[];
  groupName?: string;
  slug?: string;
  // TODO: Решить с бекендом, нужен ли формат string, если используется только number ('1')
  meetingRoomId?: string | null;
  fileStorageObjects: FileStorageObjectModel[];
}

export type CalendarReducedEvent = Record<string, CalendarEventModel[]>;

export type CalendarFreeSlot = {
  since: string;
  till: string;
  meetingRoomsCount?: number;
};

export type CalendarBusyInterval = {
  calendarEventId?: CalendarEventModelId;
  since: string;
  till: string;
  status: CalendarUserStatus;
};

export type CalendarBusySlots = {
  userId: string;
  intervals: CalendarBusyInterval[];
};

export type CalendarEventDates = {
  since: string;
  till: string;
};

export interface CalendarEventEditFormValuesType {
  id?: string;
  summary?: string;
  sinceDate?: Date;
  tillDate?: Date;
  isFullDay?: boolean;
  repeat?: boolean;
  format?: EventFormatType;
  repeatRule?: CalendarRepeatRule;
  repeatRuleJson?: CalendarRepeatRule;
  attendees?: CalendarAttendeeToAdd[];
  description?: string;
  link?: string;
  place?: string;
  allowInvitation?: string;
  meetingScale?: MeetingScales;
  meetingRoomId?: string;
  isEdit?: boolean;
  organizer?: CalendarAttendee;
  firstAttendees?: CalendarAttendee[];
  fileStorageObjects?: FileStorageObjectModel[];
}

export type CalendarListItem = {
  id: string;
  name: string;
  sourceType: CalendarSourceType;
};

export type CalendarList = DictPaginated<CalendarListItem>;
export type CalendarSubscriptions = CalendarList;

export interface CalendarEventsSummary {
  events: Event;
  eventTypeName: number;
}

export interface CalendarEventCreateParams {
  summary: string;
  since: string;
  till: string;
  isFullDay?: boolean;
  repeat?: boolean;
  format?: EventFormatType;
  repeatRule?: CalendarRepeatRule;
  repeatRuleJson?: CalendarRepeatRule;
  attendees?: CalendarAttendeeToAdd[];
  eventType: CalendarEventType;
  sourceType: CalendarSourceType;
  description?: string;
  place?: string;
  link?: string;
  meetingRoomId?: string;
  fileStorageObjects: FileStorageEntryId[];
}

export interface CalendarEventPatchParams {
  id: string;
  attendeesToAdd?: CalendarAttendeeToAdd[];
  attendeesToRemove?: string[];
}

export interface CalendarEventUpdateParams extends CalendarEventPatchParams {
  summary?: string;
  since?: string;
  till?: string;
  isFullDay?: boolean;
  repeatRule?: CalendarRepeatRule;

  eventType?: CalendarEventType;
  description?: string;
  place?: string;
  link?: string;
  meetingRoomId?: string | null;
}

export interface CalendarStatusUpdate {
  accepted?: CalendarEventModelId[];
  rejected?: CalendarEventModelId[];
  maybe?: CalendarEventModelId[];
}

export interface CalendarPotentialAttendee {
  id: string;
  fullName: string;
  email: string;
  avatar: string;
}
