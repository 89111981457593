import { Tree as AntTree } from 'antd';
import { DataNode as AntDataNode } from 'antd/lib/tree';
import { AntTreeNodeProps, TreeProps } from 'antd/lib/tree/Tree';
import classNames from 'classnames';
import { Key as RcKey } from 'rc-tree/lib/interface';
import React, { FC } from 'react';

import DropDownSvg from 'ant/components/svg/drop-down.svg';

import styles from './UiTree.scss';

export type UiTreeKey = RcKey;

export interface UiTreeNode extends AntDataNode {
  children?: UiTreeNode[];
}

export type UiTreeNodeParams = AntTreeNodeProps;
export type UiTreeProps = TreeProps;

export const UiTree: FC<UiTreeProps> = (props) => {
  const { className, ...restProps } = props;
  const containerClassName = classNames(styles.uiTree, className);

  return <AntTree className={containerClassName} switcherIcon={DropDownSvg} {...restProps} />;
};
