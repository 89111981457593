import { Plugin } from '@ckeditor/ckeditor5-core';
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';
import { ButtonView } from '@ckeditor/ckeditor5-ui';

import { message } from 'ant/components/ui/message';
import { closeGlobalModal, openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';

import { fileIdAttributeModelName } from './constants';

export class ImageCropUiPlugin extends Plugin {
  init() {
    const { editor } = this;
    const crop = editor.config.get('crop');

    editor.ui.componentFactory.add('uploadImageCrop', (locale) => {
      const view = new ButtonView(locale);

      view.set({
        label: 'Загрузить изображение',
        tooltip: 'Загрузить изображение',
        icon: imageIcon,
      });

      view.on('execute', () => {
        if (editor.model.schema.isRegistered('imageBlock')) {
          const imageSchema = editor.model.schema.getDefinition('imageBlock');

          if (!imageSchema?.allowAttributes?.includes?.(fileIdAttributeModelName)) {
            editor.model.schema.extend('imageBlock', { allowAttributes: fileIdAttributeModelName });
          }
        }

        if (crop) {
          openGlobalModal(GlobalModalNames.ImageUploadCrop, {
            ...crop,
            title: 'Загрузка изображения',
            onClose: () => closeGlobalModal(GlobalModalNames.ImageUploadCrop),
            onChange: (image) => {
              editor.execute('insertImage', {
                source: [
                  {
                    src: image.file,
                    [fileIdAttributeModelName]: image.id,
                  },
                ],
              });
            },
          });
        } else {
          message.error('Не установлены настройки для изображений');
        }
      });

      return view;
    });
  }

  public static get pluginName() {
    return 'ImageCropUiPlugin' as const;
  }
}
