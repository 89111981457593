import React, { useMemo } from 'react';
import { VariableSizeList } from 'react-window';

import { BlogAlbumImageModel } from 'ant/types/models/blog.model';

import { UiSpinner } from '../spinner';
import styles from './UiImageGrid.scss';
import { getImagesForGrid, PreparedImage } from './helpers';
import { UiImageGridItem } from './image-grid-item';

type Props = {
  images: BlogAlbumImageModel[];
  width: number;
  height: number;
  gap?: number;
  preferredRowHeight: number;
  isLoading?: boolean;
  onSelectImage: (image: PreparedImage) => void;
};

export const UiImageGrid: React.FC<Props> = (props) => {
  const {
    images: imagesProp,
    gap: gapProp,
    onSelectImage,
    width,
    height,
    preferredRowHeight,
    isLoading,
  } = props;

  const gap = useMemo(() => {
    return typeof gapProp === 'undefined' ? 4 : gapProp / 2;
  }, [gapProp]);

  const imageRows = getImagesForGrid(imagesProp, {
    preferredHeight: preferredRowHeight,
    containerWidth: width - 24,
    gapBetweenImages: gap * 2,
  });

  if (isLoading) {
    return <UiSpinner spinning />;
  }

  return (
    <VariableSizeList
      overscanCount={6}
      itemCount={imageRows.length}
      height={height}
      itemSize={(idx) => imageRows[idx].height + gap * 2}
      width={width}
      className={styles.imageGrid}
    >
      {({ style, index }) => {
        return (
          <div style={style} className={styles.imageGrid__row}>
            {imageRows[index].images.map((img) => (
              <UiImageGridItem key={img.id} gap={gap} image={img} onClick={onSelectImage} />
            ))}
          </div>
        );
      }}
    </VariableSizeList>
  );
};
