import { AxiosResponse } from 'axios';

import { ProfileEndpoints } from 'ant/endpoints/profile';
import { api } from 'ant/plugins/api';
import { ContactLinkAttributes } from 'ant/types/dictionary';
import { ContactLink } from 'ant/types/models/contact-links';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import {
  ContactsModel,
  PatchProfileJobHistoryModel,
  ProfileAdditionalInfoModel,
  ProfileBiographyHobbyId,
  ProfileBiographyModel,
  ProfileJobHistoryModel,
  ProfileProjectItemModel,
} from 'ant/types/models/profile.model';
import { ProjectId } from 'ant/types/models/projects';
import { PhoneNames, UserProfileModel } from 'ant/types/models/user.model';

export interface ExternalLinkContact extends ContactLinkAttributes {
  value?: string;
}

export type PatchProfileProjectParams = { name: string };

export type ProfileContactsParams = {
  mobilePhone: string;
  personalEmail: string;
  externalLinks: ExternalLinkContact[];
  otherContacts: ContactLink[];
  otherLinks: ContactLink[];
  workEmail: PhoneNames.WorkEmail;
  workPhone: PhoneNames.WorkPhone;
};

export type ProfileFullInfoParams = { userId: KeycloakId };
export type ContactsParams = ProfileFullInfoParams & ProfileContactsParams;
export type MainEditParams = ProfileFullInfoParams & Partial<UserProfileModel>;

export interface ProjectsParams extends ProfileFullInfoParams {
  projects: ProjectId[];
}

export interface JobsHistoryParams extends ProfileFullInfoParams {
  jobHistory: PatchProfileJobHistoryModel[];
}

export interface BiographyParams extends ProfileFullInfoParams, Omit<ProfileBiographyModel, 'hobbies'> {
  hobbies: ProfileBiographyHobbyId[];
}

export const createProfileProject = <T = unknown>({
  name,
}: PatchProfileProjectParams): Promise<AxiosResponse<T>> =>
  api.post({
    url: ProfileEndpoints.projects(),
    data: { name },
  });

export const patchProfileContacts = (params: ContactsParams) => {
  const { userId, ...data } = params;

  return api.patch<ContactsModel>({
    url: ProfileEndpoints.contacts(userId),
    data,
  });
};

export const patchProfileUserProjects = (params: ProjectsParams) => {
  const { userId, projects } = params;

  return api.patch<ProfileProjectItemModel[]>({
    url: ProfileEndpoints.userProjects(userId),
    data: { projects },
  });
};

export const patchMainProfile = (params: MainEditParams) => {
  const { userId, ...data } = params;

  return api.patch<UserProfileModel>({
    url: ProfileEndpoints.main(userId),
    data,
  });
};

export const patchJobHistoryProfile = (params: JobsHistoryParams) => {
  const { userId, jobHistory } = params;

  return api.patch<ProfileJobHistoryModel[]>({
    url: ProfileEndpoints.jobHistory(userId),
    data: jobHistory.map(({ pk, ...job }) => (!pk ? job : { pk, ...job })),
  });
};

export const patchBiographyProfile = (params: BiographyParams) => {
  const { userId, ...data } = params;

  return api.patch<ProfileBiographyModel>({
    url: ProfileEndpoints.biography(userId),
    data: {
      ...data,
      education: {
        ...data.education,
        additional: data.education.additional.map(({ id, ...educ }) => (!id ? educ : { id, ...educ })),
      },
      languages: data.languages,
    },
  });
};

export const patchProfileUserAdditionalInfo = (params: ProfileAdditionalInfoModel) => {
  const { id, cars, devices } = params;

  return api.patch<ProfileAdditionalInfoModel>({
    url: ProfileEndpoints.additionalInfo(id),
    data: {
      cars: cars.filter((car) => car.carRid || car.carNumber),
      devices: devices.filter((device) => device.typeRid || device.deviceRid || device.osRid),
    },
  });
};
