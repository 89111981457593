import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { UiAvatar, UiAvatarSize } from 'ant/components/ui/avatar';
import { UiTooltip } from 'ant/components/ui/tooltip';
import { UiTypography } from 'ant/components/ui/typography';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { UserModel } from 'ant/types/models/user.model';

import styles from './AvatarsTooltip.scss';

type AvatarsTooltipProps = {
  children: React.ReactNode;
  users: UserModel[];
  title: string | React.ReactNode;
  maxCount?: number;
  onClickSuffix?: () => void;
  isActivate?: boolean;
  visible?: boolean;
};

export const AvatarsTooltip: React.FC<AvatarsTooltipProps> = ({
  children,
  users,
  title,
  maxCount = 5,
  onClickSuffix,
  isActivate = true,
  ...props
}: AvatarsTooltipProps) => {
  const navigate = useNavigate();

  if (!users || users.length === 0 || !isActivate) {
    return <>{children}</>;
  }

  const onOpenUserProfile = (user: UserModel) => {
    const id = user.keycloakUser?.keycloakId || user.id;

    navigate(getRoutePath(RouteNames.Profile, { id }));
  };

  const tooltipContent = (
    <>
      {typeof title === 'string' ? (
        <UiTypography.Text className={classNames(styles.avatarsTooltip__title, styles.avatarsTooltip_center)}>
          {title}
        </UiTypography.Text>
      ) : (
        <>{title}</>
      )}
      <UiAvatar.Group
        maxCount={maxCount}
        onClickSuffix={onClickSuffix}
        className={classNames(styles.avatarsTooltip__group, styles.avatarsTooltip_center)}
      >
        {users.map((user) => (
          <UiTypography.Link key={user.id} onClick={() => onOpenUserProfile(user)}>
            <UiAvatar key={user.id} src={user.avatar} size={UiAvatarSize.S} />
          </UiTypography.Link>
        ))}
      </UiAvatar.Group>
    </>
  );

  return (
    <UiTooltip title={tooltipContent} {...props}>
      {children}
    </UiTooltip>
  );
};
