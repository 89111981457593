import { abstractStoreFactory } from 'ant/helpers/store/abstract-store-factory';
import { GetTasksParams } from 'ant/store/tasks';

export enum GanttViewType {
  Month,
  Year,
}

interface GanttFilterStore extends GetTasksParams {
  viewType?: GanttViewType;
}

export const ganttFilterStore = abstractStoreFactory<GanttFilterStore>({
  viewType: GanttViewType.Month,
});
