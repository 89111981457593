import { EntityId } from 'ant/types/blogs';
import { GamificationBadgeId, GamificationThanksId } from 'ant/types/models/gamification.model';
import { KeycloakId } from 'ant/types/models/keycloak-user';

export const gamificationUrls = {
  administratorThanks: () => '/api/gamification/administrator/thanks/',
  administratorThanksType: () => `/api/gamification/administrator/thanks-type/`,
  administratorThanksTypeThanksId: (thanksId: GamificationThanksId) =>
    `/api/gamification/administrator/thanks-type/${thanksId}/`,
  administratorBadges: () => '/api/gamification/administrator/badges/',
  badges: () => `/api/gamification/badges/`,
  badgesBadgeId: (badgeId: GamificationBadgeId) => `/api/gamification/badges/${badgeId}/`,
  badgesSend: () => `/api/gamification/badges/send/`,
  badgesBadgeIdUsers: (badgeId: GamificationBadgeId) => `/api/gamification/badges/${badgeId}/users/`,
  thanks: () => `/api/gamification/thanks/`,
  thanksCreate: () => `/api/gamification/thanks/create/`,
  thanksEntityId: (entityId: EntityId) => `/api/gamification/thanks/${entityId}/`,
  transactionHistory: () => `/api/gamification/transaction/history/`,
  userBadgesEntityId: (entityId: EntityId) => `/api/gamification/badges/userbadges/${entityId}/`,
  userIdBadges: (userId: KeycloakId) => `/api/gamification/${userId}/badges/`,
  userIdBadgesSummary: (userId: KeycloakId) => `/api/gamification/${userId}/badges/summary/`,
  userIdThanksReceived: (userId: KeycloakId) => `/api/gamification/${userId}/thanks/received/`,
  userIdThanksReceivedSummary: (userId: KeycloakId) => `/api/gamification/${userId}/thanks/received/summary/`,
  userIdWallet: (userId: KeycloakId) => `/api/gamification/${userId}/wallet/`,
};
