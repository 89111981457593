import { Bar, BarConfig } from '@ant-design/charts';
import React, { FC, PropsWithChildren } from 'react';

import { UiChartBarLabel } from 'ant/components/ui/chart/bar/label/ChartBarLabel';
import { themePalette } from 'ant/theme';
import { useTheme } from 'ant/theme/provider';

type UiChartBarComposition = {
  Label: typeof UiChartBarLabel;
};

type Props = Partial<BarConfig>;

const UiChartBar: FC<PropsWithChildren<Props>> & UiChartBarComposition = (props) => {
  const [{ variables: themeVariables }] = useTheme();

  const defaultBarConfig: BarConfig = {
    xField: 'value',
    yField: 'type',
    seriesField: 'type',
    legend: false,
    color: [
      themePalette.colorAccidentBlue,
      themePalette.colorAccidentBlue2,
      themePalette.colorAccidentCoral,
      themePalette.colorAccidentCyan,
      themePalette.colorAccidentGold,
      themePalette.colorAccidentBlueDark,
      themePalette.colorAccidentLavender,
      themePalette.colorAccidentLime,
      themePalette.colorAccidentMagenta,
      themePalette.colorAccidentOrange,
      themePalette.colorAccidentPink,
      themePalette.colorAccidentRed,
      themePalette.colorAccidentSea,
      themePalette.colorAccidentTagSilver,
      themePalette.colorAccidentTurquoise,
    ],
    xAxis: {
      title: null,
      grid: {
        line: {
          style: {
            stroke: themeVariables.colorStroke,
          },
        },
      },
      label: {
        style: {
          fill: themeVariables.colorTextSecondary,
          fontWeight: 600,
          fontSize: 13,
          lineHeight: 14,
        },
      },
    },
    yAxis: {
      grid: {
        line: {
          style: {
            stroke: themeVariables.colorStroke,
          },
        },
      },
      label: {
        style: {
          fill: themeVariables.colorTextSecondary,
          fontWeight: 600,
          fontSize: 13,
          lineHeight: 14,
        },
      },
    },
    data: [],
    tooltip: {
      domStyles: {
        'g2-tooltip': {
          backgroundColor: themeVariables.colorTextPrimary,
          fontSize: 15,
        },
        'g2-tooltip-value': {
          fontWeight: 600,
          color: themeVariables.colorBgPrimary,
        },
        'g2-tooltip-list': {
          color: themeVariables.colorBgPrimary,
        },
        'g2-tooltip-marker': {
          display: 'none',
        },
        'g2-tooltip-title': {
          display: 'none',
        },
      },
      offset: 0,
    },
    label: {
      position: 'right',
      offsetX: 6,
      offsetY: 0,
      style: {
        fontSize: 13,
        fontWeight: 600,
        fill: themeVariables.colorTextSecondary,
        lineWidth: 2,
      },
    },
    maxBarWidth: 20,
    height: 220,
  };

  return <Bar {...defaultBarConfig} {...props} />;
};

UiChartBar.Label = UiChartBarLabel;

export { UiChartBar };
