import mapValues from 'lodash/mapValues';
import React, { FC, useCallback, useMemo } from 'react';

import GearSvg from 'ant/components/svg/gear.svg';
import ViewSvg from 'ant/components/svg/view.svg';
import { UiButton, UiButtonProps } from 'ant/components/ui/button';
import { UiCheckbox, UiCheckboxOptionType, UiCheckboxValueType } from 'ant/components/ui/checkbox';
import { UiRow, UiRowProps } from 'ant/components/ui/grid';
import { UiRadio, UiRadioGroupOnChange, UiRadioGroupOptions } from 'ant/components/ui/radio';
import { UiSpace } from 'ant/components/ui/space';
import { getIdentityObjectKeys } from 'ant/plugins/utils/get-identity-object-keys';
import { PostSettings, PostSettingsVisibilityType, PostTypes } from 'ant/types/models/post';

export type PostFooterAction = Pick<UiButtonProps, 'label' | 'onClick' | 'type' | 'disabled' | 'loading'>;

type InteractionsSettings = Pick<PostSettings, 'isComments' | 'isReacted'>;

const INTERACTIONS_SETTINGS_LABELS: Partial<Record<keyof PostSettings, string>> = {
  isReacted: 'Разрешить реакции',
  isComments: 'Разрешить комментировать',
};

const availabilitySettingsItems: UiRadioGroupOptions = [
  { value: PostSettingsVisibilityType.Followers, label: 'Подписчикам' },
  { value: PostSettingsVisibilityType.All, label: 'Всем пользователям' },
];

interface Props extends UiRowProps {
  actions: PostFooterAction[];
  postType: PostTypes;
  settings?: PostSettings;
  isShowSettingsVisibility?: boolean;
  onSettingsChange?: (settings: PostSettings) => void;
}

export const PostFooterActions: FC<Props> = (props) => {
  const { actions, settings, onSettingsChange, postType, isShowSettingsVisibility, ...rest } = props;

  const { interactionsSettings, availabilitySettingsValues } = useMemo<{
    interactionsSettings: InteractionsSettings;
    availabilitySettingsValues: PostSettingsVisibilityType;
  }>(
    () => ({
      interactionsSettings: {
        isComments: settings?.isComments || true,
        isReacted: settings?.isReacted || true,
      },
      availabilitySettingsValues: settings?.visibility || PostSettingsVisibilityType.Followers,
    }),
    [settings],
  );

  const interactionsSettingsValues = useMemo<string[]>(
    () => (interactionsSettings ? getIdentityObjectKeys(interactionsSettings) : []),
    [interactionsSettings],
  );

  const interactionsSettingsItems = useMemo<UiCheckboxOptionType[]>(() => {
    if (interactionsSettings) {
      return Object.keys(interactionsSettings).map((key: string) => ({
        value: key,
        label: INTERACTIONS_SETTINGS_LABELS[key as keyof PostSettings],
      }));
    }

    return [];
  }, [settings]);

  const onChangeInteractionsSettings = useCallback(
    (active: UiCheckboxValueType[]) => {
      if (settings && interactionsSettings) {
        const activeSet = new Set(active);
        const newSettings = mapValues(interactionsSettings, (_, key) => activeSet.has(key));

        onSettingsChange?.({ ...settings, ...newSettings });
      }
    },
    [settings, interactionsSettings, onSettingsChange],
  );

  const onChangeAvailabilitySettings = useCallback<UiRadioGroupOnChange>(
    (e) => {
      if (settings) {
        onSettingsChange?.({
          ...settings,
          visibility: e?.target?.value || PostSettingsVisibilityType.Followers,
        });
      }
    },
    [settings, onSettingsChange],
  );

  const actionButtons = useMemo<JSX.Element[]>(() => {
    return actions.map((action) => <UiButton key={action.label} size="large" {...action} />);
  }, [actions]);

  return (
    <UiRow justify="space-between" align="middle" {...rest}>
      <UiSpace size={12} flex>
        {actionButtons}
      </UiSpace>

      {settings && (
        <UiSpace size={16} flex>
          {postType === PostTypes.Micropost && isShowSettingsVisibility && (
            <UiRadio.Menu
              placement="topRight"
              icon={ViewSvg}
              onChange={onChangeAvailabilitySettings}
              value={availabilitySettingsValues}
              items={availabilitySettingsItems}
            />
          )}
          <UiCheckbox.Menu
            placement="topRight"
            icon={GearSvg}
            onChange={onChangeInteractionsSettings}
            value={interactionsSettingsValues}
            items={interactionsSettingsItems}
          />
        </UiSpace>
      )}
    </UiRow>
  );
};
