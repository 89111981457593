import React, { FC } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiIcon, UiIconProps } from 'ant/components/ui/icon';
import { UiPopover, UiPopoverProps } from 'ant/components/ui/popover';

import { UiRadio, UiRadioGroupOnChange, UiRadioGroupOptions, UiRadioGroupProps } from '../UiRadio';
import styles from './UiRadioMenu.scss';

export interface UiRadioMenu extends UiPopoverProps {
  value?: UiRadioGroupProps['value'];
  items: UiRadioGroupOptions;
  icon: UiIconProps['component'];
  onChange?: UiRadioGroupOnChange;
}

export const UiRadioMenu: FC<UiRadioMenu> = (props) => {
  const { icon, items, value, onChange, ...popoverProps } = props;

  const RadioContent: FC = () => (
    <UiRadio.Group options={items} onChange={onChange} className={styles.uiRadioMenu__group} value={value} />
  );

  return (
    <UiPopover
      trigger={['click']}
      content={<RadioContent />}
      overlayClassName={styles.uiRadioMenu}
      {...popoverProps}
    >
      <UiButton
        disabledFocus
        type="link-secondary"
        icon={<UiIcon width={20} height={20} component={icon} />}
      />
    </UiPopover>
  );
};
