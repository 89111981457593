import { ClipboardPipeline } from '@ckeditor/ckeditor5-clipboard';
import { Plugin } from '@ckeditor/ckeditor5-core';

import { VideoUtils } from '../videoutils';
import { downcastVideoAttribute, upcastVideoFigure } from './converters';
import { getVideoViewElementMatcher, createBlockVideoViewElement } from './utils';
import { VideoEditing } from './videoediting';

export class VideoBlockEditing extends Plugin {
  public static get requires() {
    return [VideoEditing, VideoUtils, ClipboardPipeline] as const;
  }

  public static get pluginName() {
    return 'VideoBlockEditing' as const;
  }

  public init(): void {
    const { editor } = this;
    const { schema } = editor.model;

    schema.register('videoBlock', {
      inheritAllFrom: '$blockObject',
      allowAttributes: ['src'],
    });

    this.setupConversion();
  }

  private setupConversion(): void {
    const { editor } = this;
    const { conversion } = editor;
    const videoUtils: VideoUtils = editor.plugins.get('VideoUtils');

    conversion.for('dataDowncast').elementToStructure({
      model: 'videoBlock',
      view: (_modelElement, { writer }) => createBlockVideoViewElement(writer),
    });

    conversion.for('editingDowncast').elementToStructure({
      model: 'videoBlock',
      view: (_modelElement, { writer }) => {
        return videoUtils.toVideoWidget(createBlockVideoViewElement(writer), writer);
      },
    });

    conversion.for('downcast').add(downcastVideoAttribute(videoUtils, 'src'));

    conversion
      .for('upcast')
      .elementToElement({
        view: getVideoViewElementMatcher(editor),
        model: (viewVideo, { writer }) =>
          writer.createElement(
            'videoBlock',
            viewVideo.hasAttribute('src') ? { src: viewVideo.getAttribute('src') } : undefined,
          ),
      })
      .add(upcastVideoFigure(videoUtils));
  }
}
