import { BarConfig } from '@ant-design/charts';
import { Bar } from '@ant-design/plots';
import React, { FC } from 'react';

import { themePalette } from 'ant/theme';
import { useTheme } from 'ant/theme/provider';

export type UiPercentBarConfig = BarConfig;
type Props = Partial<UiPercentBarConfig>;

const UiChartPercentBar: FC<Props> = (props) => {
  const { data = [] } = props;
  const [{ variables: themeVariables }] = useTheme();

  const defaultPercentBarConfig: UiPercentBarConfig = {
    data,
    xField: 'value',
    yField: 'type',
    yAxis: {
      label: {
        style: {
          fontSize: 13,
          fontWeight: 600,
          fill: themeVariables.colorTextSecondary,
        },
      },
    },
    xAxis: {
      label: {
        formatter: (value) => `${Math.round(+value * 100)}%`,
        style: {
          fontSize: 13,
          fontWeight: 600,
          fill: themeVariables.colorTextSecondary,
        },
      },
      tickCount: 6,
    },
    seriesField: 'place',
    isPercent: true,
    isStack: true,
    legend: {
      position: 'bottom',
    },
    color: [
      themePalette.colorAccidentBlue,
      themePalette.colorAccidentBlue2,
      themePalette.colorAccidentCoral,
      themePalette.colorAccidentCyan,
      themePalette.colorAccidentGold,
      themePalette.colorAccidentBlueDark,
      themePalette.colorAccidentLavender,
      themePalette.colorAccidentLime,
      themePalette.colorAccidentMagenta,
      themePalette.colorAccidentOrange,
      themePalette.colorAccidentPink,
      themePalette.colorAccidentRed,
      themePalette.colorAccidentSea,
      themePalette.colorAccidentTagSilver,
      themePalette.colorAccidentTurquoise,
    ],
    label: {
      position: 'middle',
      content: (item) => {
        return `${(item.value * 100).toFixed(0)}%`;
      },
      style: {
        fill: '#fff',
        fontWeight: 700,
        fontSize: 13,
      },
    },
    tooltip: {
      domStyles: {
        'g2-tooltip': {
          backgroundColor: themeVariables.colorTextPrimary,
          fontSize: 15,
        },
        'g2-tooltip-value': {
          fontWeight: 600,
          color: themeVariables.colorBgPrimary,
        },
        'g2-tooltip-list': {
          color: themeVariables.colorBgPrimary,
        },
        'g2-tooltip-marker': {
          display: 'none',
        },
        'g2-tooltip-title': {
          display: 'none',
        },
      },
      offset: 0,
    },
    maxBarWidth: 20,
    height: 200,
  };

  return <Bar {...defaultPercentBarConfig} {...props} />;
};

export { UiChartPercentBar };
