import React from 'react';

import { UiTypography } from 'ant/components/ui/typography';
import { convertMinutesToPx } from 'ant/plugins/utils/convert-minutes-to-px';

import styles from './CalendarTimeCell.scss';

const MSK_OFFSET_MINUTES = 180;

interface CalendarTimeCellProps {
  hour: number;
  height?: number;
  isAlwaysShowTimeZone?: boolean;
}

export type CalendarTimeCellPropsComponent = React.FC<CalendarTimeCellProps>;

const CalendarTimeCell: CalendarTimeCellPropsComponent = (props) => {
  const { hour, height = convertMinutesToPx(60), isAlwaysShowTimeZone } = props;
  const time = `${hour}:00`;
  const timeZoneOffsetHours = (new Date().getTimezoneOffset() + MSK_OFFSET_MINUTES) / 60;
  const isShowMskTime = isAlwaysShowTimeZone || timeZoneOffsetHours !== 0;
  const mskTime = `${hour + timeZoneOffsetHours}:00\nМСК`;

  return (
    <div style={{ height }} className={styles.calendarTimeCell}>
      <UiTypography.Text className={styles.calendarTimeCell__time}>{time}</UiTypography.Text>
      {isShowMskTime && (
        // TODO: должен быть props типо timeZone
        <UiTypography.Text className={styles.calendarTimeCell__zone}>{mskTime}</UiTypography.Text>
      )}
    </div>
  );
};

export { CalendarTimeCell };
