import { UiOptionData } from 'ant/components/ui/select';
import { SearchOptionItem } from 'ant/components/widgets/SearchOptionComplete';
import { CreateStageParams } from 'ant/store/tasks';
import { DictBaseRecord } from 'ant/types/dictionary';
import { ProjectId, ProjectModel, ProjectsStageId, ProjectsStageModel } from 'ant/types/models/projects';

export enum ProjectCurrencies {
  Ruble,
  Dollar,
  Euro,
}

type ProjectCreateBaseFields = Pick<ProjectModel, 'key' | 'title' | 'targets' | 'description'>;
export type ProjectExtraData = {
  budget: number;
  partners: DictBaseRecord[];
  currencies: ProjectCurrencies;
};
export type ProjectCreateExtraData = {
  budget: number;
  partners: string[];
  currencies: ProjectCurrencies;
};

export type ProjectCreateData = ProjectCreateBaseFields &
  Pick<ProjectModel, 'leader' | 'isDraft' | 'moderators' | 'timeStart' | 'timeEnd'> & {
    extraData?: ProjectCreateExtraData;
    stages?: ProjectsStageId[];
  };

export interface ProjectUpdateData extends Partial<Omit<ProjectCreateData, 'id'>> {
  id: ProjectId;
}

export interface ProjectStagesForm
  extends Omit<CreateStageParams, 'projectId' | 'tasks' | 'timeStart' | 'timeEnd'> {
  id?: ProjectsStageId;
  projectId?: ProjectId;
  range: Date[];
}

export interface ProjectStagesData
  extends Omit<ProjectsStageModel, 'id' | 'permissions' | 'tasks' | 'projectId' | 'tasksCount'> {
  id?: ProjectsStageId;
  projectId?: ProjectId;
}

export type ProjectCreateForm = ProjectCreateBaseFields &
  Omit<ProjectCreateExtraData, 'partners'> & {
    leader: SearchOptionItem;
    participants: UiOptionData[];
    partners: UiOptionData[];
    range?: Date[];
    stages?: ProjectStagesForm[];
  };

export type ProjectInitialForm = Partial<ProjectCreateForm>;
