import React, { FC, PropsWithChildren } from 'react';

import { UiImage, UiImageProps } from 'ant/components/ui/image';
import { Editor } from 'ant/components/widgets/Editor';
import { getActualEditorFormat } from 'ant/plugins/utils/markup-content';
import { EntityContentType } from 'ant/types/models/entity-text';
import { SurveyModel } from 'ant/types/models/survey';

import { SurveyPreviewSkeleton } from './skeleton/SurveyPreviewSkeleton';

export interface SurveyPreviewProps extends PropsWithChildren {
  src: UiImageProps['src'];
  description?: SurveyModel['description'];
}

export interface SurveyPreviewComposition {
  Skeleton: typeof SurveyPreviewSkeleton;
}

export const SurveyPreview: SurveyPreviewComposition & FC<SurveyPreviewProps> = (props) => {
  const { src, description = { data: '', version: EntityContentType.CKEditor5 }, children } = props;
  const editorData = getActualEditorFormat(description).data;

  return (
    <>
      <UiImage src={src} height={432} style={{ marginBottom: 12 }} />
      <Editor.Viewer html={editorData} />
      {children}
    </>
  );
};

SurveyPreview.Skeleton = SurveyPreviewSkeleton;
