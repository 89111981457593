import type { Editor } from '@ckeditor/ckeditor5-core';
import type {
  MatcherPattern,
  ViewContainerElement,
  DowncastWriter,
  ViewElement,
} from '@ckeditor/ckeditor5-engine';

import type { VideoUtils } from '../videoutils';

export function createBlockVideoViewElement(writer: DowncastWriter): ViewContainerElement {
  return writer.createContainerElement('figure', { class: 'video' }, [
    writer.createEmptyElement('video'),
    writer.createSlot('children'),
  ]);
}

export function getVideoViewElementMatcher(editor: Editor): MatcherPattern {
  const videoUtils: VideoUtils = editor.plugins.get('VideoUtils');

  function getPositiveMatchPattern(element: ViewElement) {
    const pattern: Record<string, unknown> = {
      name: true,
    };

    if (element.hasAttribute('src')) {
      pattern.attributes = ['src'];
    }

    return pattern;
  }

  return (element) => {
    if (!videoUtils.isVideoView(element)) {
      return null;
    }

    return getPositiveMatchPattern(element);
  };
}
