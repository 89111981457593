const DOT_EXTENSION_SEPARATOR = '.';

export const getFileExtension = (fileName = '') => {
  if (!fileName) return '';

  const splitByDot = fileName.split(DOT_EXTENSION_SEPARATOR);
  const hasExtension = splitByDot.length > 1;

  if (hasExtension) {
    return splitByDot[splitByDot.length - 1];
  }

  return '';
};

export const getFileNameWithoutExtension = (fileName = '') =>
  fileName.split(DOT_EXTENSION_SEPARATOR).slice(0, -1).join(DOT_EXTENSION_SEPARATOR) || '';
