import classNames from 'classnames';
import React, { forwardRef, HTMLAttributes } from 'react';

import styles from '../Navbar.scss';

const NavbarContainer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>((props, ref) => {
  const { className, children, ...restProps } = props;

  return (
    <nav ref={ref} {...restProps} className={classNames(className, styles.navbar)}>
      {children}
    </nav>
  );
});

export { NavbarContainer };
