import { Collapse } from 'antd';
import { CollapsePanelProps } from 'antd/es/collapse';
import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './UiCollapsePanel.scss';

export interface UiCollapsePanelProps extends CollapsePanelProps {
  unsetHover?: boolean;
  unsetActive?: boolean;
  extraActiveHover?: boolean;
  extraPosition?: 'start' | 'end';
  basePadding?: boolean;
  hasBottomBorder?: boolean;
}

export const UiCollapsePanel: FC<UiCollapsePanelProps> = (props) => {
  const {
    className,
    unsetHover,
    unsetActive,
    extraActiveHover,
    extraPosition = 'end',
    basePadding = false,
    hasBottomBorder = false,
    ...panelProps
  } = props;

  return (
    <Collapse.Panel
      {...panelProps}
      className={classNames(className, {
        [styles.uiCollapsePanel_unsetHover]: unsetHover,
        [styles.uiCollapsePanel_unsetActive]: unsetActive,
        [styles.uiCollapsePanel_extraActiveHover]: extraActiveHover,
        [styles.uiCollapsePanel__extraPosition_start]: extraPosition === 'start',
        [styles.uiCollapsePanel_basePadding]: basePadding,
        [styles.uiCollapsePanel_borderedBottom]: hasBottomBorder,
      })}
    />
  );
};
