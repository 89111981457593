import React from 'react';

import CommentAltSvg from 'ant/components/svg/comment-alt.svg';
import { UiIcon } from 'ant/components/ui/icon';
import { ColumnsType, UiTableProps, UiTable } from 'ant/components/ui/table';
import { UiTypography } from 'ant/components/ui/typography';
import { SurveyChoicePosition } from 'ant/types/models/survey';

export type SurveyQuestionTableData = {
  key: string;
  answer: string;
  amount: number;
  percent: number;
  comments: number;
  positionChoice: SurveyChoicePosition;
};

export interface SurveyQuestionTableProps extends UiTableProps {
  data: SurveyQuestionTableData[];
}

const columns: ColumnsType<SurveyQuestionTableData> = [
  {
    title: 'Ответы',
    dataIndex: 'answer',
    key: 'answer',
  },
  {
    title: 'Количество',
    dataIndex: 'amount',
    key: 'amount',
    sorter: ({ amount: a }, { amount: b }) => a - b,
  },
  {
    title: 'Доля',
    dataIndex: 'percent',
    key: 'percent',
    render: (value) => <UiTypography.Text strong>{value}%</UiTypography.Text>,
    sorter: ({ percent: a }, { percent: b }) => a - b,
  },
  {
    title: '',
    dataIndex: 'comments',
    key: 'comments',
    render: (value) => {
      return value ? <UiIcon.Label component={CommentAltSvg} label={value} /> : null;
    },
  },
];

const SurveyQuestionTable: React.FC<SurveyQuestionTableProps> = ({ data, ...restProps }) => {
  return <UiTable columns={columns} dataSource={data} pagination={false} {...restProps} />;
};

export { SurveyQuestionTable };
