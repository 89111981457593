import { Plugin } from '@ckeditor/ckeditor5-core';

import { VideoUtils } from '../videoutils';
import { VideoLoadObserver } from './videoloadobserver';

export class VideoEditing extends Plugin {
  public static get requires() {
    return [VideoUtils] as const;
  }

  public static get pluginName() {
    return 'VideoEditing' as const;
  }

  public init(): void {
    const { editor } = this;
    const { conversion } = editor;

    editor.editing.view.addObserver(VideoLoadObserver);

    conversion.for('upcast').attributeToAttribute({
      view: {
        name: 'video',
        key: 'src',
      },
      model: 'src',
    });
  }
}
