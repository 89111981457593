import { Steps as AntSteps, StepsProps as AntStepsProps, StepProps as AntStepProps } from 'antd';
import { FC } from 'react';

interface StepsProps extends React.FC<AntStepsProps> {
  Step: FC<AntStepProps>;
}

export type UiStepsProps = React.ComponentProps<typeof AntSteps>;

const UiSteps = AntSteps as unknown as StepsProps;

export { UiSteps };
