import { eachDayOfInterval, startOfWeek, endOfWeek, format } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';

const weekArr = eachDayOfInterval({
  start: startOfWeek(new Date(), { weekStartsOn: 1 }),
  end: endOfWeek(new Date(), { weekStartsOn: 1 }),
});

export const weekDaysEn = weekArr.map((day) => {
  return format(day, 'EEEEEE', { locale: enLocale }).toLowerCase();
});

export const weekDaysRu = weekArr.map((day) => {
  return format(day, 'EEEEEE', { locale: ruLocale });
});
