import { AxiosResponse } from 'axios';

import { SurveyEndpoints } from 'ant/endpoints/survey';
import { api } from 'ant/plugins/api';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import {
  GroupsMailingSendingParams,
  GroupsMailingId,
  SendingSurveyId,
  SurveyId,
  SurveyModel,
  SurveySendingParams,
  SurveySendingUpdateParams,
  GroupsMailingUpdateParams,
} from 'ant/types/models/survey';

export type DefaultSurveyParams = { surveyId: SurveyId };
export type DefaultSendingSurveyParams = { sendingSurveyId: SendingSurveyId };
export type DefaultGroupMailingParams = { groupMailingId: GroupsMailingId };
export type DeleteMemberParams = { groupMailingId: GroupsMailingId; memberId: KeycloakId };

export const deleteSurvey = ({ surveyId }: DefaultSurveyParams) =>
  api.delete({
    url: SurveyEndpoints.survey(surveyId),
  });

export const cloneSurvey = <T = unknown>({ surveyId }: DefaultSurveyParams): Promise<AxiosResponse<T>> =>
  api.post({
    url: SurveyEndpoints.surveyClone(surveyId),
  });

export const saveSurvey = <T = unknown>(data: SurveyModel): Promise<AxiosResponse<T>> =>
  api.post({
    url: SurveyEndpoints.surveySave(),
    data,
  });

export const getSurvey = <T = unknown>({ surveyId }: DefaultSurveyParams): Promise<AxiosResponse<T>> =>
  api.get({
    url: SurveyEndpoints.survey(surveyId),
  });

export const surveySending = <T = unknown>(data: SurveySendingParams): Promise<AxiosResponse<T>> =>
  api.post({
    url: SurveyEndpoints.surveySending(),
    data,
  });

export const surveySendingUpdate = <T = unknown>(
  data: SurveySendingUpdateParams,
): Promise<AxiosResponse<T>> =>
  api.patch({
    url: SurveyEndpoints.surveyInstanceSendingSurveyId(data.id),
    data,
  });

export const surveySendingCancel = <T = unknown>(id: SendingSurveyId): Promise<AxiosResponse<T>> =>
  api.delete({
    url: SurveyEndpoints.surveyInstanceSendingSurveyIdCancel(id),
  });

export const deleteGroupMailing = ({ groupMailingId }: DefaultGroupMailingParams) =>
  api.delete({
    url: SurveyEndpoints.groupGroupId(groupMailingId),
  });

export const createGroupMailing = <T = unknown>(
  data: GroupsMailingSendingParams,
): Promise<AxiosResponse<T>> =>
  api.post({
    url: SurveyEndpoints.group(),
    data,
  });

export const updateGroupMailing = <T = unknown>(data: GroupsMailingUpdateParams): Promise<AxiosResponse<T>> =>
  api.patch({
    url: SurveyEndpoints.groupGroupId(data.id),
    data,
  });

export const getGroupMailing = <T = unknown>(groupMailingId: GroupsMailingId): Promise<AxiosResponse<T>> =>
  api.get({
    url: SurveyEndpoints.groupGroupId(groupMailingId),
  });

export const cloneGroupMailing = <T = unknown>(groupMailingId: GroupsMailingId): Promise<AxiosResponse<T>> =>
  api.post({ url: SurveyEndpoints.groupMailingClone(groupMailingId) });

export const deleteMemberGroupMailing = <T = unknown>(
  groupMailingId: GroupsMailingId,
  memberId: KeycloakId,
): Promise<AxiosResponse<T>> =>
  api.delete({
    url: SurveyEndpoints.membersGroupMailing(groupMailingId, memberId),
  });
