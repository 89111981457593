// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fb414eb6_toolbar{display:flex;flex-direction:row;justify-content:flex-end;width:100%;align-items:center;margin-bottom:20px}.fb414eb6_toolbar_left{justify-content:flex-start;display:flex;margin-right:auto}.fb414eb6_toolbar__empty-margin{margin:0}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/toolbar/UiToolbar.scss","webpack://./../ant/src/assets/scss/variables/content.scss"],"names":[],"mappings":"AACA,kBACE,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,UAAA,CACA,kBAAA,CACA,kBCJa,CDMb,uBACE,0BAAA,CACA,YAAA,CACA,iBAAA,CAGF,gCACE,QAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.toolbar {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  width: 100%;\n  align-items: center;\n  margin-bottom: $widgetMargin;\n\n  &_left {\n    justify-content: flex-start;\n    display: flex;\n    margin-right: auto;\n  }\n\n  &__empty-margin {\n    margin: 0;\n  }\n}\n","$borderRadiusBase: get('themeSizes.borderRadiusBase');\n$baseSpacing: 24px;\n$spacerHeight: 32px;\n$widgetMargin: get('themeOffsets.widgetMargin');\n\n$borderRadius: 8px; // TODO: вынести в тему\n$borderRadiusHalf: 4px; // TODO: вынести в тему\n$borderRadiusQuarter: 2px; // TODO: вынести в тему\n$blockquoteBorderRadius: get('themeLongRead.blockquoteBorderRadius');\n\n$modalOffset: 72px;\n$spacerBorder: 1px solid $colorStroke;\n$dashedBorder: 1px dashed $colorStrokeDark;\n\n$spaceBase: 4px;\n\n$space2XS: $spaceBase;\n$spaceXS: $spaceBase * 2;\n$spaceS: $spaceBase * 3;\n$spaceM: $spaceBase * 4;\n$spaceL: $spaceBase * 5;\n$spaceXL: $spaceBase * 6;\n$space2XL: $spaceBase * 8;\n$space3XL: $spaceBase * 9;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbar": "fb414eb6_toolbar",
	"toolbar_left": "fb414eb6_toolbar_left",
	"toolbar__empty-margin": "fb414eb6_toolbar__empty-margin",
	"toolbar__emptyMargin": "fb414eb6_toolbar__empty-margin"
};
export default ___CSS_LOADER_EXPORT___;
