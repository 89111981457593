// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dfcd1e55_ui-poll-group{display:flex;flex-direction:column}.dfcd1e55_ui-poll-group label[class~=ant-radio-wrapper]:not(:last-child),.dfcd1e55_ui-poll-group label[class~=ant-checkbox-wrapper]:not(:last-child){margin-bottom:8px}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/poll/poll-group/UiPollGroup.scss"],"names":[],"mappings":"AACA,wBAEE,YAAA,CACA,qBAAA,CAGE,qJAEE,iBAPE","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.ui-poll-group {\n  $gap: 8px;\n  display: flex;\n  flex-direction: column;\n\n  :global {\n    label[class~='ant-radio-wrapper']:not(:last-child),\n    label[class~='ant-checkbox-wrapper']:not(:last-child) {\n      margin-bottom: $gap;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-poll-group": "dfcd1e55_ui-poll-group",
	"uiPollGroup": "dfcd1e55_ui-poll-group"
};
export default ___CSS_LOADER_EXPORT___;
