import classNames from 'classnames';
import React, {
  PropsWithChildren,
  CSSProperties,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { AnimatedIcons, AnimatedItem } from 'ant/components/widgets/Reactions/animated-icons/AnimatedIcons';
import { reactionsIcons } from 'ant/components/widgets/Reactions/constants';
import { getTotalReactions } from 'ant/components/widgets/Reactions/helpers';
import { ReactionsIcon } from 'ant/components/widgets/Reactions/icon/ReactionsIcon';
import { ReactionsPopover } from 'ant/components/widgets/Reactions/popover/ReactionsPopover';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { getTotalSumByKey } from 'ant/plugins/utils/get-total-sum-by-key';
import { getDictsStorage } from 'ant/store/dictionaries';
import { Dictionaries } from 'ant/store/dictionaries/dictionaries';
import { DictDataParams } from 'ant/types/api';
import { RecordResponse } from 'ant/types/dictionary';
import { Reaction, ReactionId, ReactionsCountInfo } from 'ant/types/models/reactions.model';

import styles from './Reactions.scss';
import { ReactionsComments } from './comments/ReactionsComments';
import { ReactionsViews } from './views/ReactionsViews';

const RATE_BTN_MARGIN = 8;
const RATE_BTN_DEFAULT_WIDTH = 61 + RATE_BTN_MARGIN;
const REACTION_WIDTH = 16;

export type ReactionsAttributes = { name: string; iconName: string };
export type ReactionHandler = (reactionId: ReactionId, isLike: boolean) => void;

export const reactionsDictsStorage = getDictsStorage<RecordResponse<ReactionsAttributes>, DictDataParams>({
  dictionaryName: Dictionaries.Names.Reactions,
  getEndpointParams: () => ({ ordering: 'weight' }),
});

type Props = {
  style?: CSSProperties;
  reactions: Reaction[];
  disabled?: boolean;
  onReaction?: ReactionHandler;
};

type ReactionsComponent = {
  Comments: typeof ReactionsComments;
  Views: typeof ReactionsViews;
};

// TODO доделать закоментированные части (и в ./icon/ReactionsIcon)
export const Reactions: ReactionsComponent & FC<PropsWithChildren<Props>> = (props) => {
  const { reactions, onReaction, children, style } = props;
  const [updatedReactions, setUpdatedReactions] = useState<ReactionsCountInfo[]>([]);
  const [actualMyReactionId, setActualMyReactionId] = useState<string | null>(null);
  const [emojiPopoverShown, setEmojiPopoverShown] = useState(false);
  const [rateBtnSize, setRateBtnSize] = useState(onReaction ? RATE_BTN_DEFAULT_WIDTH : 0);
  const rateBtnTextRef = useRef<HTMLSpanElement>(null);

  const { data: reactionsData, loading } = useAbstractStorage(reactionsDictsStorage.storage, {
    autoFetchAndRefetch: ({ fetchedAtLeastOnce }) => !fetchedAtLeastOnce,
  });

  // TODO убрать Array.isArray, когда все реакции будут массивом B2BCORE-1685
  const totalCount = useMemo(() => {
    return Array.isArray(reactions) ? getTotalSumByKey(updatedReactions, 'count') : 0;
  }, [updatedReactions]);

  const getRateBtnText = useCallback(() => {
    const myReactions = reactionsData.find((o) => o.id === actualMyReactionId);

    return myReactions?.attributes?.name || 'Оценить';
  }, [reactionsData, actualMyReactionId]);

  useEffect(() => {
    setUpdatedReactions((Array.isArray(reactions) && getTotalReactions(reactions, reactionsData)) || []);
  }, [reactions, reactionsData]);

  useEffect(() => {
    setActualMyReactionId(
      (Array.isArray(reactions) && reactions.find((r) => r.isReacted)?.reactionId) || null,
    );
  }, [reactions]);

  const doReact = (reactionId: ReactionId) => {
    if (!onReaction) {
      return;
    }

    const isLike = actualMyReactionId !== reactionId;

    setEmojiPopoverShown(false);

    setUpdatedReactions((currentUpdatedReactions) => {
      const result = [...currentUpdatedReactions];

      const current = result.find(({ id }) => id === reactionId);
      const previous = result.find(({ id }) => id === actualMyReactionId);

      if (current) {
        current.count += isLike ? 1 : -1;
      }

      if (previous && isLike) {
        previous.count -= 1;
      }

      return result;
    });

    setActualMyReactionId(isLike ? reactionId : null);

    onReaction(reactionId, isLike);
  };

  // const showUsersPopup = (/* reactionName?: string, index?: number */) => {
  // const { reactedUsersPopup, reactedUsersModalOpened } = this.state;
  //
  // if (reactedUsersModalOpened) {
  //   return;
  // }
  //
  // if (isUndefined(reactionName)) {
  //   reactionName = reactedUsersPopup?.reactionName;
  //   index = reactedUsersPopup?.showIndex;
  // }
  //
  // window.clearTimeout(this.usersPopupCloseTimeout);
  //
  // const { reactedUsers, reactedUsersRequireReloading } = this.state;
  //
  // if (!reactedUsers || reactedUsersRequireReloading) {
  //   this.fetchReactedUsers$.next();
  // }
  //
  // if (!isUndefined(reactionName) && !isUndefined(index)) {
  //   this.setState({
  //     reactedUsersPopup: {
  //       showIndex: index,
  //       reactionName,
  //       visible: true,
  //     },
  //   });
  // }
  // };

  // const onItemHover = (reactionName: string, index: number, forPopover = false) => {
  //   if (!forPopover) {
  //   }
  //
  //   // showUsersPopup(reactionName, index);
  // };

  // const onItemHoverOff = () => {
  // this.usersPopupCloseTimeout = window.setTimeout(
  //   () =>
  //     this.setState(prevState =>
  //       produce(prevState, draft => {
  //         if (draft.reactedUsersPopup) {
  //           draft.reactedUsersPopup.visible = false;
  //         }
  //       }),
  //     ),
  //   POPUP_HIDE_TIME,
  // );
  // };

  // const onCaptionHover = () => {
  //   // TODO show emoji popup
  //   // window.clearTimeout(this.emojiPopupCloseTimeout);
  //   //
  //   // this.setState({
  //   //   emojiPopupShown: actualMyReactionName === null,
  //   // });
  // };

  // const onCaptionHoverOff = () => {
  //   // this.emojiPopupCloseTimeout = window.setTimeout(() => {
  //   //   this.setState({ emojiPopupShown: false });
  //   // }, POPUP_HIDE_TIME);
  // };

  const animatedReactionIcons = () => {
    return reactionsData
      .filter(({ attributes: { iconName }, id }, index) => {
        return (
          Boolean(
            updatedReactions.find((updatedReaction) => updatedReaction.id === id)?.count &&
              reactionsIcons.find((icon) => icon.name === iconName),
          ) ||
          (index === 0 && totalCount === 0)
        );
      })
      .map(({ attributes: { iconName }, id }) => {
        return {
          icon: <ReactionsIcon id={id} iconName={iconName} onReact={doReact} />,
          key: id,
          width: REACTION_WIDTH,
        };
      });
  };

  useEffect(() => {
    if (!rateBtnTextRef?.current?.clientWidth) {
      return;
    }

    setRateBtnSize(rateBtnTextRef.current.clientWidth + RATE_BTN_MARGIN);
  }, [actualMyReactionId, rateBtnTextRef.current]);

  const iconsBarItems = useMemo(() => {
    const animatedItems: AnimatedItem[] = [
      ...animatedReactionIcons(),
      {
        icon: <div className={styles.reactions__totalCount}>{totalCount}</div>,
        key: 'reactions-count',
        width: 'auto',
      },
    ];

    if (children) {
      animatedItems.push({ icon: children, key: 'children', width: 'auto' });
    }

    return animatedItems;
  }, [reactionsData, updatedReactions, rateBtnSize, children]);

  const getRateButton = () => {
    const rateButton = (
      <UiButton
        type="link"
        className={classNames([
          styles.reactions__rateBtn,
          { [styles.reactions__rateBtn_liked]: actualMyReactionId !== null },
        ])}
        // onMouseEnter={onCaptionHover}
        // onMouseLeave={onCaptionHoverOff}
        onClick={() => doReact(actualMyReactionId !== null ? actualMyReactionId : reactionsData[0]?.id)}
      >
        <span ref={rateBtnTextRef}>{getRateBtnText()}</span>
      </UiButton>
    );

    if (actualMyReactionId === null) {
      return (
        <ReactionsPopover
          reactionsData={reactionsData}
          onReact={doReact}
          open={emojiPopoverShown}
          onOpenChange={setEmojiPopoverShown}
        >
          {rateButton}
        </ReactionsPopover>
      );
    }

    return rateButton;
  };

  return (
    <div className={styles.reactions} style={style}>
      <UiSkeleton loading={loading} height={20} width={100} style={{ display: 'block' }}>
        {onReaction && getRateButton()}
        <div className={styles.reactions__icons}>
          <AnimatedIcons items={iconsBarItems} rateBtnSize={rateBtnSize} />

          {/* <ReactedUsersPopup */}
          {/*  visible={reactedUsersPopup !== null && reactedUsersInfo.length > 0
          && reactedUsersPopup.visible} */}
          {/*  listRef={this.emojiListRef} */}
          {/*  users={reactedUsersInfo} */}
          {/*  modalOpened={reactedUsersModalOpened} */}
          {/*  toggleModal={this.toggleReactedUsersModal} */}
          {/*  listItemIndex={reactedUsersPopup?.showIndex} */}
          {/*  onMouseEnter={() => this.showUsersPopup()} */}
          {/*  onMouseLeave={() => this.onItemHoverOff()} */}
          {/* /> */}
        </div>
      </UiSkeleton>
    </div>
  );
};

Reactions.Comments = ReactionsComments;
Reactions.Views = ReactionsViews;
