export type UiModalStepValues = {
  title: string | null | undefined;
  badgeValue?: string | number | null;
};

export type UiModalSteps = Record<number, UiModalStepValues>;

export enum UiModalTypes {
  Auto = 'auto',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  LargeAlternative = 'large-alternative',
  XL = 'xl',
  FullScreen = 'fullscreen',
  Fit = 'fit',
}

export enum UiModalStyleType {
  Default,
  Primary,
  Centered,
}
