import React, { FC } from 'react';

import { UiCol, UiColProps, UiRow, UiRowProps } from '../../grid';
import { UiSpace, UiSpaceProps } from '../../space';
import { UiTypography, UiTypographyTextProps } from '../../typography';

export interface UiDescriptionsItemProps extends UiColProps {
  spaceProps?: UiSpaceProps;
  label?: string;
  labelProps?: UiTypographyTextProps;
  labelRowProps?: UiRowProps;
}

export const UiDescriptionsItem: FC<UiDescriptionsItemProps> = (props) => {
  const { children, label, spaceProps, labelProps, labelRowProps, ...rest } = props;

  return (
    <UiCol {...rest}>
      <UiSpace direction="vertical" size={4} {...spaceProps}>
        {Boolean(label) && (
          <UiRow {...labelRowProps}>
            <UiTypography.Text type="secondary" {...labelProps}>
              {label}
            </UiTypography.Text>
          </UiRow>
        )}
        {children}
      </UiSpace>
    </UiCol>
  );
};
