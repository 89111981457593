import React, { useEffect, FC, PropsWithChildren } from 'react';

import { Layout } from 'ant/components/layout/layout';
import { UiEmpty } from 'ant/components/ui/empty';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { useLocalStorage } from 'ant/helpers/hooks/use-local-storage';
import { SettingsConfigProvider } from 'ant/providers/settings-config/provider/SettingsConfigProvider';
import { settingsAuthConfigStorage, settingsLayoutsConfigStorage } from 'ant/store/settings';

export const SettingsConfigService: FC<PropsWithChildren> = (props) => {
  const { data: settingsLayoutsData } = useAbstractStorage(settingsLayoutsConfigStorage.storage, {
    autoFetchAndRefetch: true,
  });

  const { data: settingsAuthData, loading: isSettingsAuthLoading } = useAbstractStorage(
    settingsAuthConfigStorage.storage,
    {
      autoFetchAndRefetch: true,
    },
  );

  const [authData, setAuthData] = useLocalStorage('settingsAuthData', settingsAuthData);

  useEffect(() => {
    if (settingsAuthData) {
      setAuthData(settingsAuthData);
    }
  }, [settingsAuthData]);

  if (isSettingsAuthLoading && !authData) {
    return <Layout.Loading />;
  }

  if (authData) {
    return <SettingsConfigProvider {...props} config={{ layouts: settingsLayoutsData }} />;
  }

  return <UiEmpty.Feed emptyMessage={{ header: 'Авторизация не может быть выполнена' }} />;
};
