import React, { useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { UiTable } from 'ant/components/ui/table';
import { UiUser } from 'ant/components/ui/user';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { AbstractStorage } from 'ant/helpers/storage/abstract-storage-factory';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { DictPaginated } from 'ant/types/api';
import { UserProfileModel } from 'ant/types/models/user.model';

import { UiTableColumnFilterItem, UiTableColumnFilterProps } from '../UiTableColumnFilter';

interface Props<T extends Record<string, unknown>>
  extends Pick<UiTableColumnFilterProps, 'filterDropdownProps'> {
  storage: AbstractStorage<DictPaginated<UserProfileModel>, UserProfileModel[], UserProfileModel[], T>;
  params: T;
  onChange: (values: string[]) => void;
}

export const UiTableColumnFilterUsers = <T extends Record<string, unknown>>(props: Props<T>) => {
  const { storage, params, ...restProps } = props;

  const [query, setQuery] = useState('');

  const { data, loading, error } = useAbstractStorage(storage, {
    autoFetchAndRefetch: true,
    // TODO https://gitlab.digital-insider.ru/di/main-frontend/-/issues/197
    autoFetchParams: { ...params, pageSize: 12, query },
    cancelPendingRequestOnUnmount: true,
  });

  const onSearch = useDebouncedCallback(setQuery, 400);

  const options = useMemo<UiTableColumnFilterItem[]>(() => {
    return data.map((user) => {
      const { id, fullName } = user;

      return {
        value: id,
        label: (
          <UiUser.Info
            to={getRoutePath(RouteNames.Profile, { id })}
            title={getFullNameWithoutPatronymic(fullName)}
            avatar={{ size: 32 }}
          />
        ),
      };
    });
  }, [data]);

  return (
    <UiTable.ColumnFilter
      loading={loading}
      options={options}
      error={Boolean(error)}
      onSearch={onSearch}
      {...restProps}
    />
  );
};
