import { Tabs as AntTabs } from 'antd';
import { TabsProps, TabPaneProps } from 'antd/es/tabs';
import classNames from 'classnames';
import { Tab } from 'rc-tabs/lib/interface';
import React, { FC } from 'react';

import MeatballSvg from 'ant/components/svg/meatball.svg';
import { UiIcon } from 'ant/components/ui/icon';

import styles from './UiTabs.scss';

const ccn = classNames.bind(styles);

type UiTabComponent = FC<TabPaneProps>;

interface UiTabsComposition {
  Tab: UiTabComponent;
}

export type UiTabsProps = TabsProps;
type UiTabsComponent = FC<UiTabsProps> & UiTabsComposition;

export type UiTabItem<Key extends string> = Omit<Tab, 'key'> & { key: Key };

const UiTab: UiTabComponent = (props) => <AntTabs.TabPane {...props} />;

const UiTabs: UiTabsComponent = (props) => {
  const { className, ...restProps } = props;

  return (
    <AntTabs
      className={ccn(styles.tabs, className)}
      moreIcon={<UiIcon width={20} height={20} component={MeatballSvg} />}
      {...restProps}
    />
  );
};

UiTabs.Tab = UiTab;

export { UiTabs, UiTab };
