import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { BlogsEndpoints } from 'ant/endpoints/blogs';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { declension, employeeDeclension } from 'ant/plugins/declension';
import { downloadFile } from 'ant/plugins/utils/download-file';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { getReport, getRolesReport } from 'ant/store/reports/api';
import { DictPaginated, PaginationParams } from 'ant/types/api';
import { BlogsListItem } from 'ant/types/blogs';
import { PostModel } from 'ant/types/models/post';
import {
  BestPostAuthor,
  ReportsDashboardAnalytic,
  ReportsDashboardCard,
  RolesReportParams,
} from 'ant/types/reports';
import {
  GetSummaryAnalyticsReportParams,
  SummaryAnalyticsReportType,
} from 'ant/types/summary-analytics-report';

import { ReportsEndpoints } from './endpoints';

const convertPercentNumberDotToComma = (value = 0) =>
  value
    .toFixed(2)
    .replace(/(\.0+|0+)$/, '')
    .toString()
    .replace('.', ',');

export const getReportsDashboardAnalyticStorage = () => {
  const storage = abstractStorageFactory<ReportsDashboardAnalytic, ReportsDashboardCard[], []>({
    endpointBuilder: ReportsEndpoints.dashboardAnalytic,
    dataMapper: ({ dauPercent, dauUsers, mauPerDay, mauUsers, currentStickyFactor, deltaStickyFactor }) => [
      {
        title: 'Отчёт DAU',
        cells: [
          {
            value: dauUsers.toString(),
            // eslint-disable-next-line sonarjs/no-duplicate-string
            title: 'Количество пользователей',
            subtitle: declension(dauUsers, employeeDeclension),
          },
          {
            value: convertPercentNumberDotToComma(dauPercent),
            title: 'От общего количества пользователей',
            subtitle: '%',
          },
        ],
        report: SummaryAnalyticsReportType.Dau,
      },
      {
        title: 'Отчёт MAU',
        cells: [
          {
            value: mauUsers.toString(),
            title: 'Количество пользователей',
            subtitle: declension(mauUsers, employeeDeclension),
          },
          {
            value: convertPercentNumberDotToComma(mauPerDay),
            title: 'Среднее количество пользователей в день',
            subtitle: declension(mauPerDay, employeeDeclension),
          },
        ],
        report: SummaryAnalyticsReportType.Mau,
      },
      {
        title: 'Отчёт Sticky factor за месяц',
        cells: [
          {
            value: convertPercentNumberDotToComma(currentStickyFactor),
            title: 'За последние 30 дней на текущую дату',
            subtitle: '%',
          },
          {
            type: (deltaStickyFactor > 0 && 'success') || (deltaStickyFactor < 0 && 'danger') || undefined,
            value:
              deltaStickyFactor > 0
                ? `+${convertPercentNumberDotToComma(deltaStickyFactor)}`
                : convertPercentNumberDotToComma(deltaStickyFactor),
            title: 'В сравнении с предыдущим периодом',
            subtitle: '%',
          },
        ],
        report: SummaryAnalyticsReportType.Sticky,
      },
    ],
    defaultValue: [],
  });

  return { storage };
};

export const getBlogsReportsTheBestAuthorStorage = () => {
  const storage = abstractStorageFactory<BestPostAuthor, BestPostAuthor, null>({
    endpointBuilder: BlogsEndpoints.reportsTheBestAuthor,
    defaultValue: null,
  });

  return { storage };
};

export const getBlogsReportsTopPostsStorage = () => {
  const storage = abstractStorageFactory<PostModel[], PostModel[], PostModel[]>({
    endpointBuilder: BlogsEndpoints.reportsTopPosts,
    defaultValue: [],
  });

  return { storage };
};

export const getBlogsListTopStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<BlogsListItem>,
    BlogsListItem[],
    BlogsListItem[],
    PaginationParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(BlogsEndpoints.reportsTopBlogs(), params),
    dataMapper: ({ items }) => items,
    defaultValue: [],
  });

  return { storage };
};

export const downloadReportEffect = createEffect<GetSummaryAnalyticsReportParams, Blob, AxiosError>(
  (params) => getReport(params).then(({ data }) => data),
);

export const downloadRolesReportEffect = createEffect<RolesReportParams, void, AxiosError>((params) =>
  getRolesReport(params).then(({ data }) => downloadFile(data, `${params.serviceName}_admins_list.xlsx`)),
);
