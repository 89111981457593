import classNames from 'classnames';
import React from 'react';

import { UiAvatar, UiAvatarSize } from 'ant/components/ui/avatar';
import { UiMentionProps } from 'ant/components/ui/mentions';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypography } from 'ant/components/ui/typography';
import { UserModel } from 'ant/types/models/user.model';

export const renderSuggestion: UiMentionProps['renderSuggestion'] = (
  _,
  __,
  highlightedDisplay,
  ___,
  focused,
) => {
  return <div className={classNames('user', { focused })}>{highlightedDisplay}</div>;
};

export const renderUserSuggestion: UiMentionProps<UserModel>['renderSuggestion'] = (
  suggestion,
  __,
  highlightedDisplay,
  ___,
  focused,
) => {
  const { data } = suggestion;

  return (
    <UiSpace size={8} align="center" style={{ display: 'flex' }} className={classNames('user', { focused })}>
      <UiAvatar size={UiAvatarSize.XS} src={data?.smallAvatar} />
      <UiTypography.Text>{highlightedDisplay}</UiTypography.Text>
    </UiSpace>
  );
};
