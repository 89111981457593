import { Dropdown as AntDropdown, DropDownProps as AntDropDownProps } from 'antd';
import { DropdownButtonProps as AntDropdownButtonProps } from 'antd/lib/dropdown';
import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './UiDropdown.scss';

export type UiDropDownProps = AntDropDownProps;
export type UiDropDownButtonProps = AntDropdownButtonProps;

const UiDropdown: typeof AntDropdown = (props) => <AntDropdown {...props} />;

const UiDropdownButton: FC<UiDropDownButtonProps> = ({ className, ...props }) => {
  return (
    <AntDropdown.Button
      {...props}
      className={classNames(styles.uiDropdown__dropdownButton, className)}
      // @ts-expect-error Переопределил block для Space.Compact, через ...restProps
      block={false}
    />
  );
};

UiDropdown.Button = UiDropdownButton;

export { UiDropdown };
