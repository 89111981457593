import React, { FC } from 'react';

import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { DisplayOptionsFull } from 'ant/components/widgets/Gantt/GanttFullSize';
import { ProjectsStageModel } from 'ant/types/models/projects';

import {
  getAllWeeksOfInterval,
  getCountWeeksForMonthsInInterval,
  getStagesInterval,
} from '../../../helpers/gantt-date-helper';
import styles from './GanttFullHeaderMonths.scss';

const MINIMAL_TITLE_WIDTH = 40;

interface Props {
  displayOptions: DisplayOptionsFull;
  stages: ProjectsStageModel[];
}

export const GanttFullHeaderMonths: FC<Props> = (props) => {
  const { displayOptions, stages } = props;
  const { daySize } = displayOptions;
  const { timeStart, timeEnd } = getStagesInterval(stages);

  const weeks = getAllWeeksOfInterval(timeStart, timeEnd);
  const months = getCountWeeksForMonthsInInterval(weeks);

  return (
    <div className={styles.ganttFullHeaderMonths}>
      <div className={styles.ganttFullHeaderMonths__months}>
        {months.map(({ id, monthDays, monthName }) => {
          const minWidth = monthDays * daySize;

          return (
            <div key={id} className={styles.ganttFullHeaderMonths__monthsItem} style={{ minWidth }}>
              {minWidth > MINIMAL_TITLE_WIDTH && (
                <UiTypography.Title level={4}>
                  <UiTruncateMarkup truncate tooltipProps={{ title: monthName }}>
                    {monthName}
                  </UiTruncateMarkup>
                </UiTypography.Title>
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.ganttFullHeaderMonths__weeks}>
        {weeks.map(({ id, week, weekDays }) => (
          <div
            key={id}
            className={styles.ganttFullHeaderMonths__weeksItem}
            style={{ minWidth: weekDays * daySize }}
          >
            <UiTypography.Title level={4}>{week}</UiTypography.Title>
          </div>
        ))}
      </div>
    </div>
  );
};
