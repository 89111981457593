// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3cd83c4e_ui-card-header-back{/*! Не удалять комментарий, иначе класс удалится из итоговой сборки !*/}._3cd83c4e_ui-card-header-title.ant-typography.ant-typography{margin-bottom:0}[class*=ui-card-header-back]+._3cd83c4e_ui-card-header-title{margin-left:16px}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/card/header/title/UiCardHeaderTitle.scss","webpack://./../ant/src/components/ui/card/header/back/UiCardHeaderBack.scss"],"names":[],"mappings":"AAAA,+BCKA,sEACE,CAAA,CAAA,8DDFA,eACE,CAAA,6DAGF,gBACE","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n@import '../back/UiCardHeaderBack';\n\n.ui-card-header-title {\n  &:global(.ant-typography.ant-typography) {\n    margin-bottom: 0;\n  }\n\n  [class*='#{$ui-card-header-back}'] + & {\n    margin-left: 16px;\n  }\n}\n","// TODO: B2BCORE-1769: Заменить на использование @forward + prefix + use после перехода с node-sass на Dart Sass\n// Note that only Dart Sass currently supports @use. Users of other implementations must use the @import rule instead.\n// @use support Dart Sass >= 1.23.0\n$ui-card-header-back: 'ui-card-header-back';\n\n.#{$ui-card-header-back} {\n  /*! Не удалять комментарий, иначе класс удалится из итоговой сборки !*/\n  /* Нужен для повышение специфичности */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-card-header-back": "_3cd83c4e_ui-card-header-back",
	"uiCardHeaderBack": "_3cd83c4e_ui-card-header-back",
	"ui-card-header-title": "_3cd83c4e_ui-card-header-title",
	"uiCardHeaderTitle": "_3cd83c4e_ui-card-header-title"
};
export default ___CSS_LOADER_EXPORT___;
