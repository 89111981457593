import { CreateIssuerDateModel } from 'ant/types/models/issuer-date.model';
import { ProjectId } from 'ant/types/models/projects/projects.model';
import { ProjectsStageId } from 'ant/types/models/projects/stages.model';
import { ProjectsTaskId } from 'ant/types/models/projects/tasks.model';
import { UserModel } from 'ant/types/models/user.model';

export type ProjectsHistoryId = ProjectId | ProjectsStageId | ProjectsTaskId;

export enum ProjectsHistoryType {
  Project = 'project',
  Task = 'task',
  Stage = 'stage',
}

export interface ProjectsHistoryModel extends CreateIssuerDateModel<UserModel> {
  field: string;
  previousValue?: string;
  newValue: string;
  contentObject: ProjectsHistoryType;
  objectId: ProjectsHistoryId;
  description: string;
}
