import { UserAgreementId } from 'ant/types/user-agreements';

class UserAgreementEndpoints {
  static termsOfUse = () => '/api/terms-of-use/';

  static termsOfUseNew = () => '/api/terms-of-use/new/';

  static termsOfUseOpenApi = () => '/api/terms-of-use/open-api/';

  static termsOfUseOpenApiId = (id: UserAgreementId) => `/api/terms-of-use/open-api/${id}/`;

  static termsOfUseOpenApiIdFactsOfConsent = (id: UserAgreementId) =>
    `/api/terms-of-use/open-api/${id}/facts_of_consent/`;
}

export { UserAgreementEndpoints };
