import classNames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

import styles from './LayoutContent.scss';

export interface LayoutContentProps extends HTMLAttributes<HTMLDivElement> {
  justifyCenter?: boolean;
  middle?: boolean;
  column?: boolean;
  stretch?: boolean;
  shiftTop?: boolean;
}

export const LayoutContent: FC<LayoutContentProps> = (props) => {
  const { children, middle, column, justifyCenter, stretch, shiftTop, style } = props;

  return (
    <div
      style={style}
      className={classNames(styles.layoutContent, {
        [styles.layoutContent_justifyCenter]: justifyCenter,
        [styles.layoutContent_middle]: middle,
        [styles.layoutContent_column]: column,
        [styles.layoutContent_stretch]: stretch,
        [styles.layoutContent_shiftTop]: shiftTop,
      })}
    >
      {children}
    </div>
  );
};
