import uniqueId from 'lodash/uniqueId';
import React, { FC, ReactNode } from 'react';

import AddCircleSvg from 'ant/components/svg/add-circle.svg';
import NotificationSvg from 'ant/components/svg/notification.svg';
import { UiAvatar, UiAvatarSize } from 'ant/components/ui/avatar';
import { UiButton } from 'ant/components/ui/button';
import { UiCheckbox } from 'ant/components/ui/checkbox';
import { UiCol } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiRadio } from 'ant/components/ui/radio';
import { UiSpace } from 'ant/components/ui/space';
import { UiSwitch } from 'ant/components/ui/switch';
import { UiTypography } from 'ant/components/ui/typography';
import { AnimatedSearchInputWidget } from 'ant/components/widgets/AnimatedSearch';
import { useTheme } from 'ant/theme/provider';
import { ColorName } from 'ant/theme/store';
import { ColorTheme } from 'ant/theme/typings';

type Props = {
  colorName: ColorName;
};

export const ColorPickerComponentsPreview: FC<Props> = (props) => {
  const { colorName } = props;
  const [{ variables: themeVariables }] = useTheme();

  const componentPreviewMap: Partial<Record<ColorTheme['name'], ReactNode[]>> = {
    colorBrandFaded: [<UiAvatar size={UiAvatarSize.M} />],
    colorLinkBrand: [
      <UiTypography.Link>Ссылка</UiTypography.Link>,
      <UiButton type="link" icon={<UiIcon component={AddCircleSvg} />} label="Кнопка ссылка" />,
    ],
    colorBrand: [
      <UiButton type="primary" size="large" label="Кнопка" />,
      <UiCheckbox checked />,
      <UiRadio checked />,
      <UiSwitch checked />,
    ],
    colorInputOnBrand: [
      <UiSpace
        style={{
          backgroundColor: themeVariables.colorBrand,
          padding: '0 8px',
          borderRadius: 4,
        }}
      >
        <AnimatedSearchInputWidget style={{ zIndex: 0 }} />
        <UiButton
          size="middle"
          round
          style={{
            backgroundColor: themeVariables.colorInputOnBrand,
            color: themeVariables.colorTextOnBrand,
          }}
          icon={<UiIcon component={NotificationSvg} width={20} height={20} />}
        />
      </UiSpace>,
    ],
    colorHoverOnBrand: [<UiButton type="primary" size="large" label="Кнопка" />],
    colorBrand08: [<UiButton type="secondary" size="large" label="Кнопка" />],
    colorBrand16: [<UiButton type="secondary" size="large" label="Кнопка" />],
    colorTextOnBrand: [<UiButton type="primary" size="large" label="Кнопка" />],
  };

  return (
    <UiSpace size={40}>
      {componentPreviewMap[colorName]?.map((preview) => (
        <UiCol key={uniqueId(colorName)}>{preview}</UiCol>
      ))}
    </UiSpace>
  );
};
