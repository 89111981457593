import React from 'react';

import { UiTypography } from 'ant/components/ui/typography';

type Props = {
  total: number;
};

const UiPaginationTotal: React.FC<Props> = ({ total }) => {
  return (
    <UiTypography.Text>
      из <UiTypography.Text strong>{total}</UiTypography.Text>
    </UiTypography.Text>
  );
};

export { UiPaginationTotal };
