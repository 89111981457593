import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import Sun404Svg from 'ant/components/svg/sun404.svg';
import { UiEmpty, UiEmptyFeedProps } from 'ant/components/ui/empty';
import { UiCol } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiTypography } from 'ant/components/ui/typography';
import { getRoutePath, RouteNames } from 'ant/plugins/router';

export type LayoutNotFoundProps = UiEmptyFeedProps;

// TODO: Этот компонент не должен знать про RouteNames.Main и Link, либо перенести его в components-frontend
export const LayoutNotFound: FC<LayoutNotFoundProps> = (props) => {
  return (
    <UiCol style={{ marginTop: 190 }} span={12} offset={6}>
      <UiEmpty.Feed
        icon={<UiIcon width={214} height={104} component={Sun404Svg} />}
        emptyMessage={{
          header: 'Страница не найдена',
          description: (
            <UiTypography.Text type="secondary">
              Вернитесь на&nbsp;
              <Link to={getRoutePath(RouteNames.Main)}>Главную</Link>
            </UiTypography.Text>
          ),
        }}
        bordered
        {...props}
      />
    </UiCol>
  );
};
