import { EditorValue } from 'ant/components/widgets/Editor';
import { Dictionaries } from 'ant/store/dictionaries/dictionaries';
import {
  UpdateIssuerDateModel,
  CreateIssuerDateModel,
  DeleteIssuerDateModel,
} from 'ant/types/models/issuer-date.model';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import { ReactableModel } from 'ant/types/models/reactions.model';
import { UserProfileModel } from 'ant/types/models/user.model';

export type CMSPageId = string;
export type CMSPageSlugId = string;
export type CMSReactionId = string;
export type CMSRolesUserId = string;
export type CMSRolesUserRoleId = string;
export type CMSSectionId = string;
export type CMSSectionSlugId = string;

type IssuerDate = CreateIssuerDateModel<KeycloakId> & DeleteIssuerDateModel<KeycloakId>;

interface BaseCMSSectionPage<T = unknown> {
  url: string;
  extraData?: T;
  path: string;
  name: string;
  createdByProfile: UserProfileModel;
}

export type CMSSectionExtraData = {
  isShownSection: boolean;
};

export interface CMSSectionModel extends BaseCMSSectionPage<CMSSectionExtraData>, IssuerDate {
  id: CMSSectionId;
  parentId?: CMSSectionId;
}

export type CMSPageExtraData = {
  isShowViewCount: boolean;
  isReactionsDisabled: boolean;
  isCommentDisabled: boolean;
};

export interface CMSPageModel
  extends BaseCMSSectionPage<CMSPageExtraData>,
    IssuerDate,
    UpdateIssuerDateModel<KeycloakId>,
    ReactableModel {
  id: CMSPageId;
  sectionId: CMSSectionId;
  body: EditorValue;
  sectionSlug: CMSSectionSlugId;
  tags: Dictionaries.Dict[];
  viewsCount: number;
  commentsCount: number;
  isActual: boolean;
  isFavorite: boolean;
  isSearchable: boolean;
  updatedByProfile: UserProfileModel;
}

export enum CMSPageType {
  Group = 'group',
  Personal = 'personal',
}

export enum CMSViewType {
  Section = 'section',
  Page = 'page',
  Personal = 'personal',
}

export type CMSTreeModel = {
  value: CMSPageSlugId | CMSSectionSlugId;
  title: string;
  type: CMSViewType;
  children?: CMSTreeModel[];
  childrenCount?: number;
};

export enum CMSRolesUserRolePermissions {
  AccessAdminPageGroup = 'access_admin_page_group',
  AccessContentManagementPageGroup = 'access_content_management_page_group',
  CreatePage = 'create_page',
  CreatePageGroup = 'create_page_group',
  CreateSection = 'create_section',
  DeletePage = 'delete_page',
  DeletePageGroup = 'delete_page_group',
  DeleteSection = 'delete_section',
  EditPage = 'edit_page',
  EditPageGroup = 'edit_page_group',
  EditSection = 'edit_section',
  ManageAdmins = 'manage_admins',
  Report = 'report',
  ViewPage = 'view_page',
  ViewPageGroup = 'view_page_group',
  ViewSection = 'view_section',
}

export type CMSRolesUserRole = {
  name: string;
  id: CMSRolesUserRoleId;
  permissions: CMSRolesUserRolePermissions[];
};

export type CMSRolesUserModel = {
  id: CMSRolesUserId;
  userProfile: UserProfileModel;
  role: CMSRolesUserRole;
  sectionInfo: Omit<CMSSectionModel, 'createdByProfile'>;
};
