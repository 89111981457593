import { CMSEndpoints } from 'ant/endpoints/cms';
import { api } from 'ant/plugins/api';
import {
  CreatePageParams,
  CreateSectionParams,
  GetSectionReportParams,
  UpdatePageParams,
  UpdateSectionParams,
} from 'ant/store/cms';
import { CMSPageId, CMSSectionId } from 'ant/types/models/cms.model';

export type ReactOnPageParams = {
  reactionId: string;
  pageId: CMSPageId;
};

export type SectionReportParams = {
  sectionId: CMSSectionId;
  dateFrom: Date;
  dateTo: Date;
};

export const createSection = <T = unknown>(data: CreateSectionParams) =>
  api.post<T>({
    url: CMSEndpoints.sections(),
    data,
  });

export const getSection = <T = unknown>(sectionId: CMSSectionId) =>
  api.get<T>({
    url: CMSEndpoints.section(sectionId),
  });

export const updateSection = <T = unknown>({ id, ...data }: UpdateSectionParams) =>
  api.patch<T>({
    url: CMSEndpoints.section(id),
    data,
  });

export const deleteSection = (id: CMSSectionId) =>
  api.delete({
    url: CMSEndpoints.section(id),
  });

export const getSectionReport = ({ sectionId, ...data }: GetSectionReportParams) =>
  api.post<Blob>({
    url: CMSEndpoints.sectionReport(sectionId),
    data: { sectionId, ...data },
    responseType: 'blob',
  });

export const createPage = <T = unknown>(data: CreatePageParams) =>
  api.post<T>({
    url: CMSEndpoints.pages(),
    data,
  });

export const updatePage = <T = unknown>({ id, ...data }: UpdatePageParams) =>
  api.patch<T>({
    url: CMSEndpoints.page(id),
    data,
  });

export const deletePage = (id: CMSPageId) =>
  api.delete({
    url: CMSEndpoints.page(id),
  });

export const reactOnPage = ({ pageId, reactionId }: ReactOnPageParams) => {
  return api.post({
    url: CMSEndpoints.reactions(),
    data: {
      emojiUuid: reactionId,
      pageId,
    },
  });
};

export const deleteReactOnPage = ({ pageId, reactionId }: ReactOnPageParams) => {
  return api.delete({
    url: CMSEndpoints.reaction(pageId, reactionId),
  });
};

export const addPageToFavorites = (pageId: CMSPageId) => {
  return api.post({
    url: CMSEndpoints.pagesSlugFavorite(pageId),
  });
};

export const removePageFromFavorites = (pageId: CMSPageId) => {
  return api.delete({
    url: CMSEndpoints.pagesSlugFavorite(pageId),
  });
};
