import { createArrayMock } from 'ant/plugins/create-mocks';

export enum Alphabets {
  Latin = 'Latin',
  Cyrillic = 'Cyrillic',
  Numbers = 'Numbers',
}

const generateNumberAlphabetPointers = () => createArrayMock(10, (_, i) => String(i));
const generateLatinAlphabetPointers = () => [...Array(26)].map((_, i) => String.fromCharCode(i + 65));
const generateCyrillicAlphabetPointers = (symbolsToExclude: string[]) => {
  const charCodeSymbolE = 1045;
  const charCodeSymbolEWithDots = 1025;
  const alphabetLengthWithoutEWithDots = 32;

  return [...Array(alphabetLengthWithoutEWithDots)].reduce((acc, _, i) => {
    const currentCharCode = i + 1040;
    const symbol = String.fromCharCode(currentCharCode);

    if (!symbolsToExclude.includes(symbol)) {
      acc.push(symbol);
    }

    if (currentCharCode === charCodeSymbolE) {
      const symbolEWithDots = String.fromCharCode(charCodeSymbolEWithDots);

      if (!symbolsToExclude.includes(symbolEWithDots)) {
        acc.push(symbolEWithDots);
      }
    }

    return acc;
  }, []);
};

export const AvailableAlphabetsPointers: Record<Alphabets, string[]> = {
  [Alphabets.Numbers]: generateNumberAlphabetPointers(),
  [Alphabets.Latin]: generateLatinAlphabetPointers(),
  [Alphabets.Cyrillic]: generateCyrillicAlphabetPointers(['Ы', 'Ъ', 'Ь']),
};
