import { AxiosResponse } from 'axios';

import { BlogsEndpoints } from 'ant/endpoints/blogs';
import { api } from 'ant/plugins/api';
import { Endpoints } from 'ant/plugins/endpoints';
import { AttachmentEntryId } from 'ant/types/models/attachment.model';
import { FileModel } from 'ant/types/models/file.model';
import { PostTypes } from 'ant/types/models/post';
import {
  PostAttachedEntity,
  PostAttachmentModel,
  PostFormAttachmentId,
} from 'ant/types/models/post-attachment';

export type UpdateAttachmentParams = { attachmentId: AttachmentEntryId; name: string };

export type GetAttachmentByIdParams = {
  contentType: PostTypes;
  objectId?: number;
  id: PostFormAttachmentId;
};

export type DeleteAttachmentParams = Required<GetAttachmentByIdParams>;
export type DeleteAttachmentParamsById = Pick<GetAttachmentByIdParams, 'id'>;

export type ReloadAttachmentByIdParams = {
  attachmentId: PostFormAttachmentId;
};

export type ReplaceAttachedEntityByAttachmentIdParams = {
  attachmentId: AttachmentEntryId;
  attachedEntity: PostAttachedEntity;
};

export type ReplaceAttachmentByIdParams = {
  attachmentId: PostFormAttachmentId;
  attachment: PostAttachmentModel;
};

export const updateAttachment = ({ attachmentId, name }: UpdateAttachmentParams) =>
  api.patch<FileModel>({
    url: Endpoints.fileStorageAttachments(attachmentId),
    data: {
      name,
    },
  });

export const deleteAttachmentPollById = ({ id }: DeleteAttachmentParams): Promise<AxiosResponse<unknown>> => {
  return api.delete({
    url: Endpoints.pollById(id),
  });
};

export const getAttachmentById = ({ contentType, objectId, id }: GetAttachmentByIdParams) =>
  api.get<PostAttachmentModel>({
    url: BlogsEndpoints.blogAttachmentsId(contentType, Number(objectId), id),
  });

export const deleteAttachment = ({ contentType, objectId, id }: DeleteAttachmentParams) =>
  api.delete<PostAttachmentModel>({
    url: BlogsEndpoints.blogAttachmentsId(contentType, objectId, id),
  });

export const deleteAttachmentById = ({ id }: DeleteAttachmentParamsById) => {
  return api.delete({
    url: Endpoints.fileStorageAttachments(id),
  });
};
