import merge from 'lodash/merge';
import React, { forwardRef, useMemo } from 'react';

import { UiForm, UiFormInstance } from 'ant/components/ui/form';
import { UiInput } from 'ant/components/ui/input';
import { UiOptionData } from 'ant/components/ui/select';
import { CkEditorConfig, Editor, EditorMentionType, EditorValue } from 'ant/components/widgets/Editor';
import { TagsField } from 'ant/components/widgets/TagsField';
import { useEditorMentionsConfig } from 'ant/helpers/hooks/use-editor-mentions-config';
import { MAX_LENGTH_INPUT_255 } from 'ant/helpers/validation';
import { parseTextPostContent } from 'ant/plugins/utils/markup-content';
import { generateTagFieldOptions } from 'ant/plugins/utils/tag-helper';
import { EntityContentType } from 'ant/types/models/entity-text';
import { PostTextModel } from 'ant/types/models/post';
import { PostAttachmentModel } from 'ant/types/models/post-attachment';

import styles from './PostBodyForm.scss';

export type PostFormFields = {
  title: string;
  body: EditorValue;
  tags: UiOptionData[];
  attachments: PostAttachmentModel[];
};

type Props = {
  post?: PostTextModel;
  onSubmit: (fields: PostFormFields) => void;
  editorExtraConfig?: Partial<CkEditorConfig>;
};

export const PostBodyForm = forwardRef<UiFormInstance, Props>((props, ref) => {
  const { post, onSubmit, editorExtraConfig } = props;
  const [form] = UiForm.useForm<PostFormFields>();
  const { editorMentionsConfig } = useEditorMentionsConfig([EditorMentionType.User]);

  const initialValue = useMemo<Partial<PostFormFields>>(() => {
    if (post) {
      const { title, tags } = post;

      return {
        title,
        body: {
          data: parseTextPostContent(post),
          version: EntityContentType.CKEditor5,
        },
        tags: generateTagFieldOptions(tags),
      };
    }

    return {};
  }, [post]);

  return (
    <UiForm layout="vertical" initialValues={initialValue} form={form} ref={ref} onFinish={onSubmit}>
      <UiForm.Item name="title" noStyle>
        <UiInput
          noStyle
          className={styles.postBodyForm__titleInput}
          size="large"
          bordered={false}
          maxLength={MAX_LENGTH_INPUT_255}
          placeholder="Заголовок поста"
        />
      </UiForm.Item>
      <UiForm.Item name="body" noStyle>
        <Editor config={merge(editorMentionsConfig, editorExtraConfig)} />
      </UiForm.Item>
      <UiForm.Item style={{ margin: '8px 0 0 0' }} name="tags" label="Теги">
        <TagsField />
      </UiForm.Item>
    </UiForm>
  );
});
