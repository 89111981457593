import { List, ListProps } from 'antd';
import React from 'react';

import { UiPaginationProps } from 'ant/components/ui/pagination';
import { UiSpinner } from 'ant/components/ui/spinner';

import { UiListItem } from './item/UiListItem';

/** TODO обсудить нужен ли тут onClick и состояние selected */
export type UiListProps<T> = ListProps<T>;
export type UiListOnChangePagination = UiPaginationProps['onChange'];

export const UiList = <T,>(props: UiListProps<T>) => {
  const { loading } = props;

  return (
    <List
      {...props}
      loading={{ indicator: UiSpinner.Indicator, spinning: Boolean(loading), size: 'large' }}
    />
  );
};

UiList.Item = UiListItem;
