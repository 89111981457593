import classNames from 'classnames';
import React, { FC } from 'react';

import { UiIcon, UiIconProps } from '../UiIcon';
import styles from './UiIconCircle.scss';

const UiIconCircle: FC<UiIconProps> = ({ className, ...restProps }) => (
  <UiIcon {...restProps} className={classNames(styles.uiIconCircle, className)} />
);

export { UiIconCircle };
