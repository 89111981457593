import React, { FC, useMemo } from 'react';

import { UiList, UiListProps } from 'ant/components/ui/list';
import { PostAttachmentModel } from 'ant/types/models/post-attachment';

import { PostBodyAttachmentsGroup, groupAttachments } from './helpers';

interface Props extends UiListProps<PostBodyAttachmentsGroup> {
  attachments: PostAttachmentModel[];
}

export const PostBodyAttachments: FC<Props> = (props) => {
  const { attachments, renderItem, ...restProps } = props;
  const groupedAttachments = useMemo(
    () => groupAttachments(attachments).filter((group) => group.attachments.length > 0),
    [attachments],
  );

  return (
    <UiList
      grid={{ column: 1 }}
      split={false}
      dataSource={groupedAttachments}
      renderItem={(item, index) => (
        <UiList.Item style={{ marginBottom: index === groupedAttachments.length - 1 ? 0 : 8 }}>
          {renderItem?.(item, index)}
        </UiList.Item>
      )}
      {...restProps}
    />
  );
};
