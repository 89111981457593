import React from 'react';
import { Link } from 'react-router-dom';

import AboutSvg from 'ant/components/svg/about.svg';
import ContactMailSvg from 'ant/components/svg/contact-mail.svg';
import { UiAvatar } from 'ant/components/ui/avatar';
import { UiButton } from 'ant/components/ui/button';
import { UiCol } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSpace } from 'ant/components/ui/space';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';

import styles from './LayoutContentHeader.scss';
import { LayoutContentHeaderSkeleton } from './skeleton/LayoutContentHeaderSkeleton';

export enum LayoutContentHeaderSize {
  Small,
  Medium,
}

export interface LayoutContentHeaderProps {
  size: LayoutContentHeaderSize;
  onClickInfo?: () => void;
  className?: string;
  name: string;
  title: string;
  to?: string;
}

export interface LayoutContentHeader extends React.FC<LayoutContentHeaderProps> {
  Skeleton: typeof LayoutContentHeaderSkeleton;
}

export const LayoutContentHeader: LayoutContentHeader = (props) => {
  const { size, onClickInfo, className, name, title, to } = props;
  const isHeaderSize = (headerSize: LayoutContentHeaderSize) => size === headerSize;

  return (
    <UiSpace size={isHeaderSize(LayoutContentHeaderSize.Small) ? 12 : 20} className={className}>
      <UiAvatar size={64} icon={<UiIcon component={ContactMailSvg} height={40} width={40} />} />
      <UiCol className={styles.layoutContentHeader__name}>
        {to ? <Link to={to}>{name}</Link> : <UiTypography.Text>{name}</UiTypography.Text>}
        <UiTypography.Title
          level={isHeaderSize(LayoutContentHeaderSize.Small) ? 3 : 1}
          className={styles.layoutContentHeader__title}
        >
          <UiTruncateMarkup truncate>{title}</UiTruncateMarkup>
          {isHeaderSize(LayoutContentHeaderSize.Small) && (
            <UiButton
              type="link-secondary"
              icon={<UiIcon component={AboutSvg} width={20} height={20} />}
              onClick={onClickInfo}
            />
          )}
        </UiTypography.Title>
      </UiCol>
    </UiSpace>
  );
};

LayoutContentHeader.Skeleton = LayoutContentHeaderSkeleton;
