import { EntityId } from 'ant/types/blogs';
import {
  FileStorageEntryVersionId,
  FileStorageEntryVersionModel,
  FileStorageListEntryModel,
} from 'ant/types/models/file.model';
import {
  CreateIssuerIdDateModel,
  DeleteIssuerIdDateModel,
  UpdateIssuerIdDateModel,
} from 'ant/types/models/issuer-date.model';
import { NewsArticle, NewsId } from 'ant/types/models/news.model';
import { ReactableModel } from 'ant/types/models/reactions.model';
import { UserModel, UserProfileModel } from 'ant/types/models/user.model';

import { BlogAlbumImageModel, BlogModel } from './blog.model';
import { CMSPageModel } from './cms.model';
import { PostBlogModel, MicropostModel } from './post';
import { ProjectModel, ProjectsTasksModel } from './projects';

export enum CommentContentTypes {
  Post = 'entry',
  Micropost = 'micropost',
  AlbumImage = 'albumimage',
  NewsEntry = 'newsentry',
  Gamification = 'gamificationentry',
  ProfileEntry = 'profileentry',
  File = 'file',
  FileVersion = 'file-version',
  Cms = 'cms',
  Project = 'project',
  Task = 'task',
  Ideas = 'ideas',
  Thanks = 'thanks',
  UserBadge = 'user-badge',
  TimelineRecord = 'timeline-record',
}

export enum CommentPermissions {
  DeleteComment = 'comment_delete',
  UpdateComment = 'comment_update',
}

export type CommentId = number;
export type CommentObjectId = EntityId | NewsId | FileStorageEntryVersionId;

export interface CommentModel extends ReactableModel {
  id: CommentId;
  text: string;
  createdAt: string;
  parentId: number | null;
  user: UserModel;
  childrens: CommentModel[];
  deleted: boolean;
  permissions: CommentPermissions[];
  // TODO вернуть когда сделаем аттачменты к комментариям
  // attachments: AttachmentFileModel[];
  // TODO вернуть когда будем делать ролевую модель комментариев
  // roles: BlogSubscriptionRoles[];
}

export interface CommentFlags {
  isSearchable: boolean;
  isDeleted: boolean;
}

export type CommentChangesModel = CreateIssuerIdDateModel & UpdateIssuerIdDateModel & DeleteIssuerIdDateModel;

type CommentText = {
  text: string;
  version: string;
};

export type CommentAlbumImageEntryModel = {
  albumImage: BlogAlbumImageModel;
  blog: BlogModel;
};

export type CommentFileVersionEntryModel = {
  fileVersion: FileStorageEntryVersionModel;
  file: FileStorageListEntryModel;
};

export interface CommentBaseModel {
  id: CommentId;
  text: CommentText;
  objectId: CommentObjectId;
  contentType: CommentContentTypes;
  parentId: CommentId | null;
}

export interface CommentModelV2 extends CommentBaseModel, CommentChangesModel, CommentFlags {}

export type CommentDetailDataModel =
  | PostBlogModel
  | MicropostModel
  | NewsArticle
  | CMSPageModel
  | UserProfileModel
  | CommentAlbumImageEntryModel
  | ProjectModel
  | ProjectsTasksModel
  | FileStorageListEntryModel
  | CommentFileVersionEntryModel;

export interface CommentDetailListModel<T = CommentDetailDataModel>
  extends Omit<CommentBaseModel, 'text'>,
    ReactableModel,
    Pick<CommentFlags, 'isDeleted'>,
    Pick<CommentChangesModel, 'createdAt' | 'updatedAt'> {
  contentType: CommentContentTypes;
  data: T;
  user: UserModel;
  level: number;
  children: CommentDetailListModel<T>[];
  text: string;
  permissions: CommentPermissions[];
}

export interface CommentSaveModel
  extends CommentBaseModel,
    Pick<CommentChangesModel, 'createdAt' | 'createdById' | 'updatedAt'>,
    CommentFlags {}
