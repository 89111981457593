import { FileStorageEntryId } from 'ant/types/models/file.model';

import { KeycloakId, KeycloakUser } from './keycloak-user';

export enum Gender {
  Female = '0',
  Male = '1',
}

export type UserModelId<T> = T;

export type UserAvatarModel = {
  avatar: string;
  smallAvatar: string;
  mediumAvatar?: string;
};

export type UserPositionId = string;

export type UserPositionModel = {
  id: UserPositionId;
  name: string;
};

export interface UserModel extends Partial<UserAvatarModel> {
  id: KeycloakId;
  username?: string;
  email?: string;
  patronymic?: string;
  firstName?: string;
  lastName?: string;
  gender?: Gender;
  avatar?: string;
  smallAvatar?: string;
  keycloakUser?: KeycloakUser;
  position?: UserPositionModel;
  isActive?: boolean;
}

export type FullNameModel = {
  firstName: string;
  lastName: string;
  patronymic: string;
};

export interface BaseUserProfileModel extends UserAvatarModel {
  id: KeycloakId;
  fullName: FullNameModel;
  gender: Gender;
  actualAddress: string;
  email: string;
  superAdmin: boolean;
  position?: UserPositionModel;
  workPhone: string | null;
}

export interface UserProfileModel extends BaseUserProfileModel {
  groupPath: string | null;
  absence: {
    begin: string;
    cause: string;
    end: string;
  };
  birthDate: string;
  birthDayMonth: string;
  birthYear: number;
  clothesSize: string;
  clothesSizeRid: string | null;
  colleague: boolean | null;
  eyeColor: string;
  eyeColorRid: string | null;
  isBirthYearHidden?: boolean;
  follow: boolean;
  footSize: number | string;
  footSizeRid: string | null;
  height: string;
  startOfWork: string;
  townLive?: string;
  townLiveRid: string | null;
  metroStation?: string;
  metroStationRid: string | null;
  workMobilePhone: string | null;
  timezone: string;
  remoteEmployee: boolean;
  memberOf: string[];
  isActive?: boolean;
  blockedAt?: string;
  fileStorageRootFolderId?: FileStorageEntryId;
}

export interface BaseJobModel {
  position?: UserPositionModel;
}

export interface JobModel extends BaseJobModel {
  pk: string;
  dateStart: string;
  dateEnd: string;
  user: string;
  remoteEmployee: boolean;
}

export enum PhoneNames {
  MobilePhone = 'mobilePhone',
  PersonalEmail = 'personalEmail',
  WorkEmail = 'workEmail',
  WorkPhone = 'workPhone',
  ContactLocation = 'contactLocation',
}

export type BossModel = {
  avatar: string;
  colleague: string;
  follow: string;
  fullName: FullNameModel;
  id: string;
  job: JobModel;
  workEmail: PhoneNames.WorkEmail;
  workPhone: PhoneNames.WorkPhone;
};

export type UserJobModel = {
  boss: BossModel;
  user: string;
} & JobModel;

export type UserProfileJobModel = {
  job: JobModel;
} & UserProfileModel;

export type UserInitiatorModel = {
  username: string;
  firstName: string;
  lastName: string;
  keycloakId: KeycloakId;
  fullName: FullNameModel;
  gender?: Gender;
  isActive: boolean;
};

export type UserAuthorModel = Pick<
  UserProfileJobModel,
  'id' | 'avatar' | 'fullName' | 'smallAvatar' | 'mediumAvatar' | 'job'
>;
