import generatePicker from 'antd/es/date-picker/generatePicker';
import { PickerProps as AntPickerProps } from 'antd/lib/date-picker/generatePicker';
import rusLocale from 'antd/lib/date-picker/locale/ru_RU';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import React, { FC } from 'react';

import CalendarSvg from 'ant/components/svg/calendar.svg';
import ClearSvg from 'ant/components/svg/clear.svg';

import { UiDatePickerRange } from './range/UiDatePickerRange';

rusLocale.lang.locale = 'ru';

export enum UiDatePickerDataFormat {
  Data,
  DataAndTime,
  Year,
}

export const SHOW_TIME_FORMAT = { format: 'HH:mm' };

export const datePickerDataFormat = {
  [UiDatePickerDataFormat.Data]: ['DD.MM.YYYY', 'D.MM.YYYY', 'DD.M.YYYY'],
  [UiDatePickerDataFormat.DataAndTime]: ['DD.MM.YYYY HH:mm', 'D.MM.YYYY HH:mm', 'DD.M.YYYY HH:mm'],
  [UiDatePickerDataFormat.Year]: ['YYYY'],
};

const DateFnsDatePicker = generatePicker<Date>(dateFnsGenerateConfig);

type UiDatePickerComposition = {
  RangePicker: typeof UiDatePickerRange;
};

export const UiDatePicker: FC<AntPickerProps<Date>> & UiDatePickerComposition = (props) => (
  <DateFnsDatePicker
    getPopupContainer={(trigger) => trigger?.parentElement || document.body}
    allowClear={false}
    locale={rusLocale}
    format={datePickerDataFormat[UiDatePickerDataFormat.Data]}
    placeholder="––.––.––––"
    clearIcon={<ClearSvg />}
    suffixIcon={<CalendarSvg />}
    {...props}
  />
);

UiDatePicker.RangePicker = UiDatePickerRange;
