import { SettingsConfigLayoutModel } from 'ant/types/models/settings.model';

export const defaultConfigLayout: SettingsConfigLayoutModel = {
  headerEventsMeetingsDropdownButtonDisabled: {
    value: true,
    label: 'Скрыть кнопку для создания мероприятия в шапке',
  },
  profileStartOfWorkEnabled: {
    value: true,
    label: 'Отображать поле "Дата начала работы" в профиле',
  },
  profileWorkPhoneExtension: {
    value: false,
    label: 'Отображать поле "Добавочный номер" в профиле',
  },
  profileEditAccessControlEnabled: {
    value: false,
    label: 'Отображать меню "Управление доступом" в редактировании профиля',
  },
  adminSurveyTabEnabled: {
    value: false,
    label: 'Отображать вкладку "Опросы" в "Управление контентом" и "Администрирование"',
  },
  timelinesServiceEnabled: {
    value: true,
    label: 'Использовать новый сервис для ленты постов',
  },
  pageMainSnowfallEnabled: {
    value: false,
    label: 'Отображать на главной станице "Новогодний снегопад"',
  },
  pageVKHRTechEnabled: {
    value: false,
    label: 'Отображать страницу vk-hr-tech с виджетом для VKHRTech',
  },
};
