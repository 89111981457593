import { Plugin } from '@ckeditor/ckeditor5-core';
import type { Element, UpcastElementEvent } from '@ckeditor/ckeditor5-engine';

import type { VideoStyleOptionDefinition } from '../videoconfig';
import { VideoUtils } from '../videoutils';
import { viewToModelStyleAttribute, modelToViewStyleAttribute } from './converters';
import utils from './utils';
import { VideoStyleCommand } from './videostylecommand';

export class VideoStyleEditing extends Plugin {
  public static get pluginName() {
    return 'VideoStyleEditing' as const;
  }

  public static get requires() {
    return [VideoUtils] as const;
  }

  public normalizedStyles?: Array<VideoStyleOptionDefinition>;

  public init(): void {
    const { normalizeStyles, getDefaultStylesConfiguration } = utils;
    const { editor } = this;
    const isBlockPluginLoaded = editor.plugins.has('VideoBlockEditing');

    editor.config.define('video.styles', getDefaultStylesConfiguration(isBlockPluginLoaded));

    this.normalizedStyles = normalizeStyles({
      configuredStyles: editor.config.get('video.styles')!,
      isBlockPluginLoaded,
    });

    this.setupConversion(isBlockPluginLoaded);
    this.setupPostFixer();

    editor.commands.add('videoStyle', new VideoStyleCommand(editor, this.normalizedStyles));
  }

  private setupConversion(isBlockPluginLoaded: boolean): void {
    const { editor } = this;
    const { schema } = editor.model;

    const modelToViewConverter = modelToViewStyleAttribute(this.normalizedStyles!);
    const viewToModelConverter = viewToModelStyleAttribute(this.normalizedStyles!);

    editor.editing.downcastDispatcher.on('attribute:videoStyle', modelToViewConverter);
    editor.data.downcastDispatcher.on('attribute:videoStyle', modelToViewConverter);

    if (isBlockPluginLoaded) {
      schema.extend('videoBlock', { allowAttributes: 'videoStyle' });

      editor.data.upcastDispatcher.on<UpcastElementEvent>('element:figure', viewToModelConverter, {
        priority: 'low',
      });
    }
  }

  private setupPostFixer(): void {
    const { editor } = this;
    const { document } = editor.model;

    const videoUtils = editor.plugins.get(VideoUtils);
    const stylesMap = new Map(this.normalizedStyles!.map((style) => [style.name, style]));

    document.registerPostFixer((writer) => {
      let changed = false;

      for (const change of document.differ.getChanges()) {
        // eslint-disable-next-line eqeqeq
        if (change.type == 'insert' || (change.type == 'attribute' && change.attributeKey == 'videoStyle')) {
          // eslint-disable-next-line eqeqeq
          let element = change.type == 'insert' ? change.position.nodeAfter! : change.range.start.nodeAfter!;

          if (element && element.is('element', 'paragraph') && element.childCount > 0) {
            element = element.getChild(0)!;
          }

          if (!videoUtils.isVideo(element as Element)) {
            // eslint-disable-next-line no-continue
            continue;
          }

          const videoStyle = element.getAttribute('videoStyle') as string | undefined;

          if (!videoStyle) {
            // eslint-disable-next-line no-continue
            continue;
          }

          const videoStyleDefinition = stylesMap.get(videoStyle);

          if (
            !videoStyleDefinition ||
            !videoStyleDefinition.modelElements.includes((element as Element).name)
          ) {
            writer.removeAttribute('videoStyle', element);
            changed = true;
          }
        }
      }

      return changed;
    });
  }
}
