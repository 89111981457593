import classNames from 'classnames';
import React, { useContext, CSSProperties, FC, ReactNode } from 'react';

import ClearSVG from 'ant/components/svg/clear.svg';
import SearchIcon from 'ant/components/svg/search.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiConfigContext } from 'ant/components/ui/config-provider';
import { UiIcon } from 'ant/components/ui/icon';
import { UiMentionsInput, UiMentionsInputProps } from 'ant/components/ui/mentions';
import { UiSpace, useCompactItemContext } from 'ant/components/ui/space';
import { useGetSizeClassname } from 'ant/helpers/hooks/use-get-size-classname';

import styles from './UiMentionInputSearch.scss';

export type UiMentionClearInputSearchHandler = () => void;

export interface UiMentionInputSearchProps extends UiMentionsInputProps {
  onClear?: UiMentionClearInputSearchHandler;
  clearIcon?: ReactNode;
  wrapperStyle?: CSSProperties;
}

export const UiMentionInputSearch: FC<UiMentionInputSearchProps> = (props) => {
  const { onClear, clearIcon, wrapperStyle, className, size: customizeSize, ...mentionProps } = props;
  const { children, value, disabled } = mentionProps;
  const { getPrefixCls, direction } = useContext(UiConfigContext);
  const antInputCls = getPrefixCls('input');
  const { compactItemClassnames } = useCompactItemContext(antInputCls, direction);
  const sizeCls = useGetSizeClassname(customizeSize);
  const searchClassname = classNames(
    antInputCls,
    styles.uiMentionInputSearch,
    {
      [styles.uiMentionInputSearch_disabled]: disabled,
      [`${styles.uiMentionInputSearch}-${sizeCls}`]: sizeCls,
    },
    className,
    compactItemClassnames,
  );

  const defaultClearIcon = <UiIcon component={ClearSVG} width={20} height={20} />;

  return (
    <div className={searchClassname} style={wrapperStyle}>
      <UiIcon component={SearchIcon} width={20} height={20} className={styles.uiMentionInputSearch__prefix} />
      <UiMentionsInput noStyle {...mentionProps} className={styles.uiMentionInputSearch__input}>
        {children}
      </UiMentionsInput>
      {/* TODO: Выяснить почему не работает без UiSpace.NoCompactStyle в версии antd@4.24.7 https://github.com/ant-design/ant-design/issues/38590 */}
      {value && onClear && (
        <UiSpace.NoCompactStyle>
          <UiButton type="link-secondary" icon={clearIcon || defaultClearIcon} onClick={onClear} />
        </UiSpace.NoCompactStyle>
      )}
    </div>
  );
};
