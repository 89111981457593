import { type Editor, Plugin } from '@ckeditor/ckeditor5-core';
import type { ViewElement } from '@ckeditor/ckeditor5-engine';

import { VideoUtils } from '../videoutils';
import { ResizeVideoCommand } from './resizevideocommand';

export class VideoResizeEditing extends Plugin {
  public static get requires() {
    return [VideoUtils] as const;
  }

  public static get pluginName() {
    return 'VideoResizeEditing' as const;
  }

  constructor(editor: Editor) {
    super(editor);

    editor.config.define('video', {
      resizeUnit: '%',
      resizeOptions: [
        {
          name: 'resizeVideo:original',
          value: null,
          icon: 'original',
        },
        {
          name: 'resizeVideo:25',
          value: '25',
          icon: 'small',
        },
        {
          name: 'resizeVideo:50',
          value: '50',
          icon: 'medium',
        },
        {
          name: 'resizeVideo:75',
          value: '75',
          icon: 'large',
        },
      ],
    });
  }

  public init(): void {
    const { editor } = this;
    const resizeVideoCommand = new ResizeVideoCommand(editor);

    this.registerSchema();
    this.registerConverter();

    editor.commands.add('resizeVideo', resizeVideoCommand);
    editor.commands.add('videoResize', resizeVideoCommand);
  }

  private registerSchema(): void {
    if (this.editor.plugins.has('VideoBlockEditing')) {
      this.editor.model.schema.extend('videoBlock', { allowAttributes: 'width' });
    }
  }

  private registerConverter() {
    const { editor } = this;

    editor.conversion.for('downcast').add((dispatcher) =>
      dispatcher.on(`attribute:width:videoBlock`, (evt, data, conversionApi) => {
        if (!conversionApi.consumable.consume(data.item, evt.name)) {
          return;
        }

        const viewWriter = conversionApi.writer;
        const figure = conversionApi.mapper.toViewElement(data.item);

        if (data.attributeNewValue !== null) {
          viewWriter.setStyle('width', data.attributeNewValue, figure);
          viewWriter.addClass('video_resized', figure);
        } else {
          viewWriter.removeStyle('width', figure);
          viewWriter.removeClass('video_resized', figure);
        }
      }),
    );

    editor.conversion.for('upcast').attributeToAttribute({
      view: {
        name: 'figure',
        styles: {
          width: /.+/,
        },
      },
      model: {
        key: 'width',
        value: (viewElement: ViewElement) => viewElement.getStyle('width'),
      },
    });
  }
}
