import { FC } from 'react';

import { UiPollButtonInput } from 'ant/components/ui/poll/poll-button/input/UiPollButtonInput';

import { UiPollButton, UiPollButtonTypes } from './poll-button/UiPollButton';
import { UiPollGroup } from './poll-group/UiPollGroup';

export interface UiPollComposition extends FC {
  Button: typeof UiPollButton;
  ButtonTypes: typeof UiPollButtonTypes;
  ButtonInput: typeof UiPollButtonInput;
  Group: typeof UiPollGroup;
}

export const UiPoll: UiPollComposition = () => null;

UiPoll.Button = UiPollButton;
UiPoll.ButtonTypes = UiPollButtonTypes;
UiPoll.ButtonInput = UiPollButtonInput;
UiPoll.Group = UiPollGroup;
// UiPoll.Progress = UiPollProgress; // TODO: объединить и переместить PollAnswersWrapper из group-frontend
