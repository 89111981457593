// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._77cf116d_image-actions{position:absolute;top:0;right:0;display:flex}._77cf116d_image-actions :last-child{border-radius:0px 2px 0px 0px}", "",{"version":3,"sources":["webpack://./../ant/src/components/widgets/ImageActions/ImageActions.scss"],"names":[],"mappings":"AACA,yBACE,iBAAA,CACA,KAAA,CACA,OAAA,CACA,YAAA,CAEA,qCACE,6BAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.image-actions {\n  position: absolute;\n  top: 0;\n  right: 0;\n  display: flex;\n\n  :last-child {\n    border-radius: 0px 2px 0px 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image-actions": "_77cf116d_image-actions",
	"imageActions": "_77cf116d_image-actions"
};
export default ___CSS_LOADER_EXPORT___;
