import { Card, CardProps } from 'antd';
import classNames from 'classnames';
import React, { PropsWithChildren, FC } from 'react';

import { UiConfigProviderSizeType } from 'ant/components/ui/config-provider';

import styles from './UiCard.scss';
import { UiCardContent } from './content/UiCardContent';
import { UiCardFooter } from './footer/UiCardFooter';
import { UiCardHeader } from './header/UiCardHeader';

export interface UiCardProps extends CardProps {
  shadow?: UiConfigProviderSizeType;
  emptyPadding?: boolean;
}

export interface UiCardComposition {
  Grid: typeof Card.Grid;
  Meta: typeof Card.Meta;
  Header: typeof UiCardHeader;
  Footer: typeof UiCardFooter;
  Content: typeof UiCardContent;
}

export const UiCard: FC<PropsWithChildren<UiCardProps>> & UiCardComposition = (props) => {
  const {
    bordered = false,
    emptyPadding = false,
    shadow,
    size = 'small',
    className,
    bodyStyle,
    ...cardProps
  } = props;

  const bodyStyles = { ...(emptyPadding && { padding: 0 }), ...bodyStyle };

  return (
    <Card
      size={size}
      bordered={bordered}
      bodyStyle={bodyStyles}
      className={classNames(className, {
        [styles.uiCard_emptyPadding]: emptyPadding,
        [styles[`uiCard_shadow_${shadow}`]]: Boolean(shadow),
      })}
      {...cardProps}
    />
  );
};

UiCard.Grid = Card.Grid;
UiCard.Meta = Card.Meta;
UiCard.Header = UiCardHeader;
UiCard.Footer = UiCardFooter;
UiCard.Content = UiCardContent;
