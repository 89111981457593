import React, { FC, PropsWithChildren } from 'react';

import { UiTypography } from 'ant/components/ui/typography';

import { SurveyQuestionSection, SurveyQuestionSectionProps } from './section/SurveyQuestionSection';

export interface SurveyQuestionProps extends SurveyQuestionSectionProps {
  title: string;
}

const SurveyQuestion: FC<PropsWithChildren<SurveyQuestionProps>> = ({ title, children, ...props }) => (
  <SurveyQuestionSection {...props}>
    <UiTypography.Title level={1} style={{ margin: '0 0 16px' }}>
      {title}
    </UiTypography.Title>
    {children}
  </SurveyQuestionSection>
);

export { SurveyQuestion };
