import { Plugin } from '@ckeditor/ckeditor5-core';
import { ButtonView, createDropdown, addToolbarToDropdown, SplitButtonView } from '@ckeditor/ckeditor5-ui';
import identity from 'lodash/identity';
import isObject from 'lodash/isObject';

import type { VideoStyleDropdownDefinition, VideoStyleOptionDefinition } from '../videoconfig';
import utils from './utils';
import type { VideoStyleCommand } from './videostylecommand';
import { VideoStyleEditing } from './videostyleediting';

import '../theme/videostyle.scss';

function translateStyles<T extends VideoStyleOptionDefinition | VideoStyleDropdownDefinition>(
  styles: Array<T>,
  titles: Record<string, string>,
): Array<T> {
  for (const style of styles) {
    if (titles[style.title!]) {
      style.title = titles[style.title!];
    }
  }

  return styles;
}

function getUIComponentName(name: string): string {
  return `videoStyle:${name}`;
}

function getDropdownButtonTitle(dropdownTitle: string | undefined, buttonTitle: string): string {
  return (dropdownTitle ? `${dropdownTitle}: ` : '') + buttonTitle;
}

export class VideoStyleUI extends Plugin {
  public static get requires() {
    return [VideoStyleEditing] as const;
  }

  public static get pluginName() {
    return 'VideoStyleUI' as const;
  }

  // eslint-disable-next-line class-methods-use-this
  public get localizedDefaultStylesTitles(): Record<string, string> {
    return {
      'Wrap text': 'Обтекать текст',
      'Break text': 'Разрывать текст',
      'In line': 'В тексте',
      'Full size video': 'Оригинальный размер видео',
      'Side video': 'Боковое видео',
      'Left aligned video': 'Выравнивание по левому краю',
      'Centered video': 'Выравнивание по центру',
      'Right aligned video': 'Выравнивание по правому краю',
    };
  }

  public init(): void {
    const { plugins } = this.editor;
    const toolbarConfig = this.editor.config.get('video.toolbar') || [];
    const videoStyleEditing: VideoStyleEditing = plugins.get('VideoStyleEditing');

    const definedStyles = translateStyles(
      videoStyleEditing.normalizedStyles!,
      this.localizedDefaultStylesTitles,
    );

    for (const styleConfig of definedStyles) {
      this.createButton(styleConfig);
    }

    const definedDropdowns = translateStyles(
      [
        ...(toolbarConfig.filter(isObject) as Array<VideoStyleDropdownDefinition>),
        ...utils.getDefaultDropdownDefinitions(plugins),
      ],
      this.localizedDefaultStylesTitles,
    );

    for (const dropdownConfig of definedDropdowns) {
      this.createDropdown(dropdownConfig, definedStyles);
    }
  }

  private createDropdown(
    dropdownConfig: VideoStyleDropdownDefinition,
    definedStyles: Array<VideoStyleOptionDefinition>,
  ): void {
    const factory = this.editor.ui.componentFactory;

    factory.add(dropdownConfig.name, (locale) => {
      let defaultButton: ButtonView | undefined;

      const { defaultItem, items, title } = dropdownConfig;
      const buttonViews = items
        .filter((itemName) => definedStyles.find(({ name }) => getUIComponentName(name) === itemName))
        .map((buttonName) => {
          const button = factory.create(buttonName) as ButtonView;

          if (buttonName === defaultItem) {
            defaultButton = button;
          }

          return button;
        });

      if (items.length !== buttonViews.length) {
        utils.warnInvalidStyle({ dropdown: dropdownConfig });
      }

      const dropdownView = createDropdown(locale, SplitButtonView);
      const splitButtonView = dropdownView.buttonView as SplitButtonView;
      const splitButtonViewArrow = splitButtonView.arrowView;

      addToolbarToDropdown(dropdownView, buttonViews, { enableActiveItemFocusOnDropdownOpen: true });

      splitButtonView.set({
        label: getDropdownButtonTitle(title, defaultButton!.label!),
        class: null,
        tooltip: true,
      });

      splitButtonViewArrow.unbind('label');
      splitButtonViewArrow.set({
        label: title,
      });

      splitButtonView.bind('icon').toMany(buttonViews, 'isOn', (...areOn) => {
        const index = areOn.findIndex(identity);

        return index < 0 ? defaultButton!.icon : buttonViews[index].icon;
      });

      splitButtonView.bind('label').toMany(buttonViews, 'isOn', (...areOn) => {
        const index = areOn.findIndex(identity);

        return getDropdownButtonTitle(title, index < 0 ? defaultButton!.label! : buttonViews[index].label!);
      });

      splitButtonView.bind('isOn').toMany(buttonViews, 'isOn', (...areOn) => areOn.some(identity));

      splitButtonView
        .bind('class')
        .toMany(buttonViews, 'isOn', (...areOn) =>
          areOn.some(identity) ? 'ck-splitbutton_flatten' : undefined,
        );

      splitButtonView.on('execute', () => {
        if (!buttonViews.some(({ isOn }) => isOn)) {
          defaultButton!.fire('execute');
        } else {
          dropdownView.isOpen = !dropdownView.isOpen;
        }
      });

      dropdownView
        .bind('isEnabled')
        .toMany(buttonViews, 'isEnabled', (...areEnabled) => areEnabled.some(identity));

      this.listenTo(dropdownView, 'execute', () => {
        this.editor.editing.view.focus();
      });

      return dropdownView;
    });
  }

  private createButton(buttonConfig: VideoStyleOptionDefinition): void {
    const buttonName = buttonConfig.name;

    this.editor.ui.componentFactory.add(getUIComponentName(buttonName), (locale) => {
      const command: VideoStyleCommand = this.editor.commands.get('videoStyle')!;
      const view = new ButtonView(locale);

      view.set({
        label: buttonConfig.title,
        icon: buttonConfig.icon,
        tooltip: true,
        isToggleable: true,
      });

      view.bind('isEnabled').to(command, 'isEnabled');
      view.bind('isOn').to(command, 'value', (value) => value === buttonName);
      view.on('execute', this.executeCommand.bind(this, buttonName));

      return view;
    });
  }

  private executeCommand(name: string): void {
    this.editor.execute('videoStyle', { value: name });
    this.editor.editing.view.focus();
  }
}
