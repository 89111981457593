// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".efe8247e_ui-progress_hide-chart .ant-progress-circle,.efe8247e_ui-progress_hide-chart .ant-progress-outer{display:none}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/progress/UiProgress.scss"],"names":[],"mappings":"AAIM,2GAEE,YAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.ui-progress {\n  &_hide-chart {\n    :global {\n      .ant-progress-circle,\n      .ant-progress-outer {\n        display: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-progress_hide-chart": "efe8247e_ui-progress_hide-chart",
	"uiProgress_hideChart": "efe8247e_ui-progress_hide-chart"
};
export default ___CSS_LOADER_EXPORT___;
