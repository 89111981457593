import React from 'react';

import ArrowUpSvg from 'ant/components/svg/arrow-up.svg';
import { UiBackTop } from 'ant/components/ui/back-top';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';

import styles from './ScrollToTopButton.scss';

export const ScrollToTopButton = () => {
  return (
    <UiBackTop className={styles.backTop} visibilityHeight={250}>
      <UiButton
        size="middle"
        type="primary"
        icon={<UiIcon component={ArrowUpSvg} width={20} height={20} />}
        round
      />
    </UiBackTop>
  );
};
