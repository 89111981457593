import { formatDistanceToNow } from 'date-fns';
import ru from 'date-fns/locale/ru';
import React, { FC } from 'react';

import { UiTypography } from 'ant/components/ui/typography';

const dateFormatter = (date: string) => `${formatDistanceToNow(new Date(date), { locale: ru })} назад`;

export type NotificationBodyFooterProps = {
  date: string;
};

export const NotificationBodyFooter: FC<NotificationBodyFooterProps> = (props) => {
  const { date } = props;

  return <UiTypography.Footnote type="secondary">{dateFormatter(date)}</UiTypography.Footnote>;
};
