import React, { FC, PropsWithChildren } from 'react';
import { ErrorBoundary as ReactErrorBoundary, ErrorBoundaryPropsWithComponent } from 'react-error-boundary';

import { ErrorBoundaryFallback } from './fallback/ErrorBoundaryFallback';

export interface ErrorBoundaryProps extends Omit<ErrorBoundaryPropsWithComponent, 'FallbackComponent'> {
  FallbackComponent?: ErrorBoundaryPropsWithComponent['FallbackComponent'];
}

export const ErrorBoundary: FC<PropsWithChildren<ErrorBoundaryProps>> = (props) => {
  const { children, FallbackComponent = ErrorBoundaryFallback, ...restProps } = props;

  return (
    <ReactErrorBoundary FallbackComponent={FallbackComponent} {...restProps}>
      {children}
    </ReactErrorBoundary>
  );
};
