import classNames from 'classnames';
import React from 'react';

import { UiAvatar } from 'ant/components/ui/avatar';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';

import styles from './UiCommonListItem.scss';

export interface UiCommonListItemProps {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  image?: string | null;
  handleItemClick?: () => void;
  icon?: JSX.Element;
  isLoading?: boolean;
  className?: string;
}

const AVATAR_SIZE = 40;

export const UiCommonListItem: React.FC<UiCommonListItemProps> = (props) => {
  const { image, title, subtitle, handleItemClick, icon, isLoading, className } = props;

  const uiCommonListItemClassName = classNames(
    styles.uiCommonListItem,
    {
      [styles.uiCommonListItem_loading]: isLoading,
    },
    className,
  );

  const onKeyPress = ({ key }: React.KeyboardEvent) => {
    if (key === 'Enter' && handleItemClick) {
      handleItemClick();
    }
  };

  return (
    <div
      role="button"
      tabIndex={-1}
      className={uiCommonListItemClassName}
      onClick={handleItemClick}
      onKeyPress={onKeyPress}
    >
      <UiSkeleton loading={isLoading} circle width={AVATAR_SIZE} height={AVATAR_SIZE}>
        <UiAvatar src={image} size={AVATAR_SIZE} />
      </UiSkeleton>

      <div className={styles.uiCommonListItem__content}>
        <UiSkeleton loading={isLoading} width={140} height={16}>
          <UiTypography.Text className={styles.uiCommonListItem__title}>
            <UiTruncateMarkup truncate>{title}</UiTruncateMarkup>
          </UiTypography.Text>
        </UiSkeleton>

        {subtitle !== undefined && (
          <UiSkeleton loading={isLoading} width={115} height={16}>
            <UiTypography.Text type="secondary">
              <UiTruncateMarkup truncate>{subtitle}</UiTruncateMarkup>
            </UiTypography.Text>
          </UiSkeleton>
        )}
      </div>
      {icon && <div className={styles.uiCommonListItem__iconWrapper}>{icon}</div>}
    </div>
  );
};
