import { ClipboardPipeline, type ViewDocumentClipboardInputEvent } from '@ckeditor/ckeditor5-clipboard';
import { Plugin, type Editor } from '@ckeditor/ckeditor5-core';
import { type Element, type DataTransfer } from '@ckeditor/ckeditor5-engine';
import { Notification } from '@ckeditor/ckeditor5-ui';
import { FileRepository, type UploadResponse } from '@ckeditor/ckeditor5-upload';

import {
  attributeControlsModelName,
  attributeControlsName,
  attributeFileIdModelName,
  attributeFileIdName,
} from './const';
import { UploadVideoCommand } from './uploadvideocommand';
import { createVideoTypeRegExp } from './utils';

export function isHtmlIncluded(dataTransfer: DataTransfer): boolean {
  return Array.from(dataTransfer.types).includes('text/html') && dataTransfer.getData('text/html') !== '';
}

export type VideoUploadCompleteData = {
  data: UploadResponse;
  videoElement: Element;
};

export type VideoUploadCompleteEvent = {
  name: 'uploadComplete';
  args: [data: VideoUploadCompleteData];
};

export class VideoUploadEditing extends Plugin {
  public static get requires() {
    return [FileRepository, Notification, ClipboardPipeline] as const;
  }

  public static get pluginName() {
    return 'VideoUploadEditing' as const;
  }

  constructor(editor: Editor) {
    super(editor);

    editor.config.define('video', {
      upload: {
        types: ['mp4', 'webm', 'ogg'],
      },
    });
  }

  public init(): void {
    const { editor } = this;
    const { conversion } = editor;
    const videoTypes = createVideoTypeRegExp(editor.config.get('video.upload.types')!);
    const uploadVideoCommand = new UploadVideoCommand(editor);

    editor.commands.add('uploadVideo', uploadVideoCommand);
    editor.commands.add('videoUpload', uploadVideoCommand);

    conversion.for('upcast').attributeToAttribute({
      view: attributeFileIdName,
      model: attributeFileIdModelName,
    });

    conversion.for('upcast').attributeToAttribute({
      view: attributeControlsName,
      model: attributeControlsModelName,
    });

    const setVideoAttribute = ({
      attributeName,
      value,
      evt,
      data,
      conversionApi,
    }: {
      attributeName: string;
      value: any;
      evt: any;
      data: any;
      conversionApi: any;
    }) => {
      if (!conversionApi.consumable.consume(data.item, evt.name)) {
        return;
      }

      const { writer } = conversionApi;
      const figure = conversionApi.mapper.toViewElement(data.item);
      const video = figure.getChild(0);

      if (data.attributeNewValue !== null) {
        writer.setAttribute(attributeName, value, video);
      } else {
        writer.removeAttribute(attributeName, video);
      }
    };

    conversion.for('downcast').add((dispatcher) => {
      dispatcher.on(`attribute:${attributeFileIdModelName}:videoBlock`, (evt, data, conversionApi) => {
        setVideoAttribute({
          attributeName: attributeFileIdName,
          value: data.attributeNewValue,
          conversionApi,
          data,
          evt,
        });
      });

      dispatcher.on(`attribute:${attributeControlsModelName}:videoBlock`, (evt, data, conversionApi) => {
        setVideoAttribute({
          attributeName: attributeControlsName,
          value: '',
          conversionApi,
          data,
          evt,
        });
      });
    });

    this.listenTo<ViewDocumentClipboardInputEvent>(
      editor.editing.view.document,
      'clipboardInput',
      (evt, data) => {
        if (isHtmlIncluded(data.dataTransfer)) {
          return;
        }

        const videos = Array.from(data.dataTransfer.files).filter((file) => {
          if (!file) {
            return false;
          }

          return videoTypes.test(file.type);
        });

        if (!videos.length) {
          return;
        }

        evt.stop();

        editor.model.change((writer) => {
          if (data.targetRanges) {
            writer.setSelection(
              data.targetRanges.map((viewRange) => editor.editing.mapper.toModelRange(viewRange)),
            );
          }

          editor.execute('uploadVideo', { file: videos });
        });
      },
    );

    editor.editing.view.document.on('dragover', (_, data) => {
      data.preventDefault();
    });
  }

  public afterInit(): void {
    const { schema } = this.editor.model;

    if (this.editor.plugins.has('VideoBlockEditing')) {
      schema.extend('videoBlock', {
        allowAttributes: [attributeControlsModelName, attributeFileIdModelName],
      });
    }
  }
}
