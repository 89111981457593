import React, { FC } from 'react';

import UserBlockedAvatarPlaceholderSvg from 'ant/components/svg/user-blocked-avatar-placeholder.svg';
import { UiAvatar, UiAvatarProps } from 'ant/components/ui/avatar';
import { UserModel } from 'ant/types/models/user.model';

export interface UserAvatarProps extends UiAvatarProps {
  user: Partial<UserModel>;
}

export const UserAvatar: FC<UserAvatarProps> = (props) => {
  const { user, size, icon, src, ...avatarProps } = props;
  const { keycloakUser, avatar, isActive } = user;

  const iconProps =
    keycloakUser?.isActive || isActive
      ? { icon, src: src || avatar }
      : { icon: <UserBlockedAvatarPlaceholderSvg height={size} width={size} />, disabled: true };

  return <UiAvatar {...iconProps} size={size} {...avatarProps} />;
};
