/** https://gist.github.com/strogonoff/08e9a3eb180ff6858e7bdd887412c8d0 */
export type FlattenObject = Record<string, unknown>;

export const flattenObject = (
  obj: FlattenObject,
  pref: false | string = false,
  _result: FlattenObject | null = null,
): FlattenObject => {
  const result: FlattenObject = _result ?? {};

  // Preserve empty objects and arrays, they are lost otherwise
  if (pref !== false && typeof obj === 'object' && obj !== null && Object.keys(obj).length === 0) {
    result[pref] = Array.isArray(obj) ? [] : {};
    return result;
  }

  // TODO потенциальная ошибка с ключами в которых есть точка
  const prefix = pref !== false ? `${pref}.` : '';

  Object.keys(obj).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // Recursion on deeper objects
        // TODO Удалить "as FlattenObject" после перехода на typescript@4.9
        flattenObject(obj[key] as FlattenObject, prefix + key, result);
      } else {
        result[prefix + key] = obj[key];
      }
    }
  });

  return result;
};
