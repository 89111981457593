import React, { FC, KeyboardEvent, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { defaultUserMentionsProps, UiMention, UiMentionsInput } from 'ant/components/ui/mentions';
import { UiMentionInputSearchProps } from 'ant/components/ui/mentions/input/search/UiMentionInputSearch';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { generateMentionOptions } from 'ant/plugins/utils/options-helper';
import { tagsDictsStorage } from 'ant/store/tags';

interface Props extends Omit<UiMentionInputSearchProps, 'children'> {
  onSearch?: (value: string) => void;
}

export const TagMentionSearch: FC<Props> = (props) => {
  const {
    value,
    placeholder = 'Поиск по содержанию или #тегу',
    wrapperStyle,
    onSearch,
    ...restProps
  } = props;
  const handleKeyPress = useCallback(
    ({ key }: KeyboardEvent<HTMLElement>) => {
      if (key === 'Enter') {
        onSearch?.(value ?? '');
      }
    },
    [onSearch, value],
  );

  const onSearchMentionDebounced = useDebouncedCallback((query, callback) => {
    tagsDictsStorage.storage
      .fetchEffect({ value: query })
      .then(({ objects }) => callback(generateMentionOptions(objects)))
      .catch((e) => getErrorResponseMessage(e, 'Не удалось загрузить теги'));
  }, 300);

  return (
    <UiMentionsInput.Search
      onKeyDown={handleKeyPress}
      singleLine
      value={value}
      placeholder={placeholder}
      wrapperStyle={wrapperStyle}
      {...restProps}
    >
      <UiMention
        {...defaultUserMentionsProps}
        trigger="#"
        markup="@[__display__](tag/__id__)"
        displayTransform={(_, display) => `#${display}`}
        data={onSearchMentionDebounced}
      />
    </UiMentionsInput.Search>
  );
};
