import { Popover as AntPopover } from 'antd';
import { PopoverProps } from 'antd/es/popover';
import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './UiPopover.scss';

export type UiPopoverProps = PopoverProps;

export const UiPopover: FC<UiPopoverProps> = ({ children, className, ...props }) => (
  <AntPopover className={classNames(className, styles.uiPopover)} {...props}>
    {children}
  </AntPopover>
);
