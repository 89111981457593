/**
 * Если трогаешь эти MIME-типы,
 * не забудь про чекеры здесь
 * packages/ant/src/plugins/utils/check-file-mime-type.ts
 */
export enum FileUploadAccepts {
  All = '',
  ImageAll = 'image/*',
  ImagePng = 'image/png',
  ImagePngJpg = 'image/png,image/jpeg,image/jpg',
  ImagePngJpgGif = 'image/png,image/jpeg,image/jpg,image/gif',
  ImagePngJpgGifBmp = 'image/png,image/jpeg,image/jpg,image/gif,image/bmp',
  ImageSvg = 'image/svg+xml',
  VideoAll = 'video/*',
  PackageZip = 'application/zip,application/octet-stream,application/x-zip-compressed,multipart/x-zip',
  ExcelXlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  Html = 'text/html',
}
