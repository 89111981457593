import React, { FC } from 'react';

import { UiUser, UiUserInfoProps } from 'ant/components/ui/user';

import { AVATAR_SIZE } from '../Notification';

export type NotificationHeaderProps = UiUserInfoProps;

export const NotificationHeader: FC<NotificationHeaderProps> = (props) => {
  const { title, subTitle, avatar, isActive, to } = props;

  return (
    <UiUser.Info
      title={title}
      subTitle={subTitle}
      isActive={isActive}
      avatar={{
        ...avatar,
        size: AVATAR_SIZE,
      }}
      to={to}
    />
  );
};
