import { endpoints } from 'ant/endpoints';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { DictPaginated } from 'ant/types/api';
import { EntityId } from 'ant/types/blogs';
import {
  GamificationAddedThank,
  GamificationBadgeId,
  GamificationBadges,
} from 'ant/types/models/gamification.model';

type SingleGamificationStorageParams = {
  entityId: EntityId;
};

type GamificationUsersByBadgeIdParams = { badgeId: GamificationBadgeId; userName?: string };

export const getSingleThankStorage = () => {
  const storage = abstractStorageFactory<
    GamificationAddedThank,
    GamificationAddedThank,
    null,
    SingleGamificationStorageParams
  >({
    endpointBuilder: ({ entityId }) => endpoints.gamification.thanksEntityId(entityId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getSingleBadgesStorage = () => {
  const storage = abstractStorageFactory<
    GamificationBadges,
    GamificationBadges,
    null,
    SingleGamificationStorageParams
  >({
    endpointBuilder: ({ entityId }) => endpoints.gamification.userBadgesEntityId(entityId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getGamificationUsersByBadgeIdStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<GamificationBadges>,
    GamificationBadges[],
    GamificationBadges[],
    GamificationUsersByBadgeIdParams
  >({
    endpointBuilder: ({ badgeId, userName }) =>
      buildEndpointWithQueryParams(endpoints.gamification.badgesBadgeIdUsers(badgeId), { userName }),
    dataMapper: (data) => data.items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};
