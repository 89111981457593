import React, { FC } from 'react';

import { UiButton, UiButtonProps } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSpace, UiSpaceCompactProps } from 'ant/components/ui/space';

import styles from './UserProfileActions.scss';

export interface UserProfileActionProps extends Omit<UiButtonProps, 'icon'> {
  icon: SvgrComponent;
}

export interface UserProfileActionsProps extends UiSpaceCompactProps {
  items: UserProfileActionProps[];
}

export const UserProfileActions: FC<UserProfileActionsProps> = (props) => {
  const { items, ...spaceProps } = props;

  return (
    <UiSpace.Compact block {...spaceProps} className={styles.userProfileActions}>
      {items.map(({ icon, label, ...buttonProps }) => (
        <UiButton
          block
          key={label}
          type="action-label"
          {...buttonProps}
          className={styles.userProfileActions__action}
        >
          <UiIcon component={icon} width={20} height={20} />
          {label}
        </UiButton>
      ))}
    </UiSpace.Compact>
  );
};
