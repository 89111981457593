import React, { FC, useMemo } from 'react';

import CommentAltSvg from 'ant/components/svg/comment-alt.svg';
import LikeSvg from 'ant/components/svg/like.svg';
import ViewSvg from 'ant/components/svg/view.svg';
import { UiFile, UiFileDirection, UiFileProps } from 'ant/components/ui/file';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { File } from 'ant/components/widgets/File';
import { getFullName } from 'ant/plugins/name-formatters';
import { numberAbbreviator } from 'ant/plugins/number-formatters';
import { getTotalSumByKey } from 'ant/plugins/utils/get-total-sum-by-key';
import { FileStorageEntryType } from 'ant/types/models/file.model';

import { FileListItemProps } from '../FileListItem';

type Props = Pick<FileListItemProps, 'file' | 'actions'> & UiFileProps;

export const FileListItemRow: FC<Props> = (props) => {
  const { file, actions = [], ...fileProps } = props;
  const { objectViewsCount, commentsCount, reactions, createdBy } = file;
  const reactionsCount = useMemo(() => getTotalSumByKey(reactions, 'reactionCount'), [reactions]);
  const author = createdBy ? getFullName(createdBy) : 'Автор неизвестен';
  const isFolder = file.type === FileStorageEntryType.Folder;

  return (
    <UiRow style={{ width: '100%' }} align="middle" gutter={[16, 0]}>
      <UiCol span={20} xl={12} xxl={9}>
        <UiFile direction={UiFileDirection.Horizontal} {...fileProps} />
      </UiCol>
      <UiCol span={0} xl={8} xxl={6}>
        <UiTypography.Text strong>
          <UiTruncateMarkup truncate tooltipProps={{ title: author }}>
            {author}
          </UiTruncateMarkup>
        </UiTypography.Text>
      </UiCol>
      {!isFolder && (
        <>
          <UiCol span={0} xxl={2}>
            <UiIcon.Label component={ViewSvg}>{numberAbbreviator(objectViewsCount)}</UiIcon.Label>
          </UiCol>
          <UiCol span={0} xxl={2}>
            <UiIcon.Label component={LikeSvg}>{numberAbbreviator(reactionsCount)}</UiIcon.Label>
          </UiCol>
          <UiCol span={0} xxl={2}>
            <UiIcon.Label component={CommentAltSvg}>{numberAbbreviator(commentsCount)}</UiIcon.Label>
          </UiCol>
        </>
      )}
      <UiCol span={4} xxl={isFolder ? 9 : 3}>
        <UiRow justify="end">
          <File.Actions actions={actions} />
        </UiRow>
      </UiCol>
    </UiRow>
  );
};
