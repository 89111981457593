import React from 'react';

import ClearSvg from 'ant/components/svg/clear.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { PostFormAttachmentId } from 'ant/types/models/post-attachment';

import styles from './ImageList.scss';

export type ImageListImage = {
  id: PostFormAttachmentId;
  url: string;
};

type Props = {
  images: ImageListImage[];
  onDelete?: (id: PostFormAttachmentId) => void;
};

const ImageList: React.FC<Props> = ({ images, onDelete }) => {
  return (
    <div className={styles.imageList}>
      {images.map((image) => (
        <div key={image.id} className={styles.imageList__imageWrapper}>
          <div
            className={styles.imageList__image}
            style={{
              backgroundImage: `url("${image.url}")`,
            }}
          >
            {Boolean(onDelete) && (
              <UiButton
                type="link-secondary"
                className={styles.imageList__delete}
                onClick={() => onDelete?.(image.id)}
                icon={<UiIcon width={20} height={20} component={ClearSvg} />}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export { ImageList };
