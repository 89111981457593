import { /* React, */ FC } from 'react';
import { FallbackProps } from 'react-error-boundary';

// import { UiEmpty } from 'ant/components/ui/empty';
// import { Post } from 'ant/components/widgets/Post';

type ErrorBoundaryFallbackProps = FallbackProps;

export const ErrorBoundaryFallbackTimeline: FC<ErrorBoundaryFallbackProps> = () => {
  // TODO: B2BCORE-6727: Фронт. Лента постов. Вернуть отображение постов с ошибками

  // return (
  //   <Post>
  //     <UiEmpty.Feed
  //       emptyMessage={{
  //         header: 'Ошибка',
  //         description: 'Невозможно отобразить пост',
  //       }}
  //     />
  //   </Post>
  // );

  return null;
};
