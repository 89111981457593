import React, { FC } from 'react';

import { Layout, LayoutProps } from 'ant/components/layout/layout';
import { UiSpinner, UiSpinnerProps } from 'ant/components/ui/spinner';

export type LayoutLoadingProps = {
  style?: LayoutProps['style'];
  spinning?: UiSpinnerProps['spinning'];
};

export const LayoutLoading: FC<LayoutLoadingProps> = (props) => {
  const { style, spinning = true } = props;

  return (
    <UiSpinner spinning={spinning}>
      <Layout style={{ height: '100vh', ...style }} />
    </UiSpinner>
  );
};
