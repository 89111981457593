import { Command } from '@ckeditor/ckeditor5-core';

import type { VideoUtils } from '../videoutils';

export class ResizeVideoCommand extends Command {
  public declare value: null | {
    width: string | null;
    height: string | null;
  };

  public override refresh(): void {
    const { editor } = this;
    const videoUtils: VideoUtils = editor.plugins.get('VideoUtils');
    const element = videoUtils.getClosestSelectedVideoElement(editor.model.document.selection);

    this.isEnabled = !!element;

    if (!element || !element.hasAttribute('width')) {
      this.value = null;
    } else {
      this.value = {
        width: element.getAttribute('width') as string,
        height: null,
      };
    }
  }

  public override execute(options: { width: string | null }): void {
    const { editor } = this;
    const { model } = editor;
    const videoUtils: VideoUtils = editor.plugins.get('VideoUtils');
    const videoElement = videoUtils.getClosestSelectedVideoElement(model.document.selection);

    this.value = {
      width: options.width,
      height: null,
    };

    if (videoElement) {
      model.change((writer) => {
        writer.setAttribute('width', options.width, videoElement);
      });
    }
  }
}
