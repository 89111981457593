import React, { FC, PropsWithChildren, ReactNode, useMemo } from 'react';

import { UiAvatarSize } from 'ant/components/ui/avatar';
import { UiRow, UiRowProps } from 'ant/components/ui/grid';
import { UiUser } from 'ant/components/ui/user';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { utcToLocalDate } from 'ant/plugins/utils/get-formatted-date';
import { useCurrentProfile } from 'ant/providers/current-profile';
import { BlogFullModel } from 'ant/types/models/blog.model';
import { UserModel } from 'ant/types/models/user.model';

import { PostHeaderActions } from './actions/PostHeaderActions';
import { PostHeaderBlogLink } from './blog-link/PostHeaderBlogLink';
import { PostHeaderSkeleton } from './skeleton/PostHeaderSkeleton';

export interface PostHeaderProps extends UiRowProps {
  blogs?: BlogFullModel[];
  subTitle?: ReactNode;
  extra?: ReactNode;
  author: UserModel;
  postDate: string;
}

type PostHeaderComponent = {
  Actions: typeof PostHeaderActions;
  Skeleton: typeof PostHeaderSkeleton;
};

export const PostHeader: PostHeaderComponent & FC<PropsWithChildren<PostHeaderProps>> = (props) => {
  const { author, postDate, blogs = [], children, subTitle, extra, ...restProps } = props;

  const profile = useCurrentProfile();
  const authorId = author.keycloakUser?.keycloakId || author.id;
  const isAuthorActive = Boolean(author.isActive || author.keycloakUser?.isActive);

  const avatarProps = {
    size: UiAvatarSize.M,
    src: profile?.id === authorId ? profile?.smallAvatar : author.smallAvatar,
  };

  const UserInfoSubtitle = useMemo(() => {
    if (subTitle) {
      return subTitle;
    }

    return (
      <>
        {utcToLocalDate(postDate, 'dd MMMM в H:mm')}
        {blogs.length > 0 && <PostHeaderBlogLink blogs={blogs} />}
      </>
    );
  }, [postDate, subTitle]);

  return (
    <UiRow justify="space-between" wrap={false} {...restProps}>
      <UiUser.Info
        avatar={avatarProps}
        isActive={isAuthorActive}
        title={getFullNameWithoutPatronymic(author)}
        subTitle={UserInfoSubtitle}
        to={getRoutePath(RouteNames.Profile, { id: authorId })}
        extra={extra}
      />
      {children}
    </UiRow>
  );
};

PostHeader.Actions = PostHeaderActions;
PostHeader.Skeleton = PostHeaderSkeleton;
