import React, { FC, PropsWithChildren } from 'react';

import CalendarSvg from 'ant/components/svg/calendar.svg';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSpace } from 'ant/components/ui/space';
import { UiTooltip } from 'ant/components/ui/tooltip';
import { UiTypography, UiTypographySymbolName } from 'ant/components/ui/typography';
import { getFormattedDate } from 'ant/plugins/utils/get-formatted-date';
import { themePalette } from 'ant/theme';
import { useTheme } from 'ant/theme/provider';
import { BaseTimeInterval } from 'ant/types/models/base/base-time-date';
import { ProjectsStageModel } from 'ant/types/models/projects';

interface Props extends BaseTimeInterval {
  compact?: boolean;
  title?: ProjectsStageModel['title'];
}

export const GanttRangeTooltip: FC<PropsWithChildren<Props>> = (props) => {
  const { title, timeStart, timeEnd, children, compact } = props;
  const [{ variables: themeVariables }] = useTheme();
  const rangeFormat = 'd MMMM yyyy';

  const RangeDate: FC = () => (
    <UiIcon.Label component={CalendarSvg} style={{ color: themePalette.colorStrokeDark }} shift={4}>
      <UiSpace size={2}>
        {getFormattedDate(timeStart, rangeFormat)}
        <UiTypography.Symbol name={UiTypographySymbolName.EmDash} />
        {getFormattedDate(timeEnd, rangeFormat)}
      </UiSpace>
    </UiIcon.Label>
  );

  const Title: FC = () => (
    <UiSpace direction="vertical" size={4}>
      <UiTypography.Text strong style={{ color: themeVariables.colorBgPrimary }}>
        {title}
      </UiTypography.Text>
      <RangeDate />
    </UiSpace>
  );

  return (
    <UiTooltip placement="topRight" title={compact ? <RangeDate /> : <Title />}>
      {children}
    </UiTooltip>
  );
};
