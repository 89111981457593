import { Column, ColumnConfig } from '@ant-design/charts';
import React, { FC } from 'react';

import { themePalette } from 'ant/theme';
import { useTheme } from 'ant/theme/provider';

export type UiChartColumnConfig = ColumnConfig;
type Props = Partial<UiChartColumnConfig>;

const UiChartColumn: FC<Props> = (props) => {
  const { data = [] } = props;
  const [{ variables: themeVariables }] = useTheme();

  const defaultGroupedColumnConfig: UiChartColumnConfig = {
    data,
    legend: {
      position: 'bottom',
      layout: 'horizontal',
    },
    xField: 'type',
    yField: 'value',
    yAxis: {
      min: 0,
      title: null,
      grid: {
        line: {
          style: {
            stroke: themeVariables.colorStroke,
          },
        },
      },
      label: {
        style: {
          fontSize: 13,
          fontWeight: 600,
          fill: themeVariables.colorTextSecondary,
        },
      },
    },
    xAxis: {
      label: {
        style: {
          fontSize: 13,
          fontWeight: 600,
          fill: themeVariables.colorTextSecondary,
        },
      },
      grid: {
        line: {
          style: {
            stroke: themeVariables.colorStroke,
          },
        },
      },
      title: null,
    },
    label: {
      position: 'top',
      style: {
        fontSize: 13,
        fontWeight: 600,
        fill: themeVariables.colorTextSecondary,
      },
    },
    groupField: 'name',
    seriesField: 'name',
    isGroup: true,
    color: [
      themePalette.colorAccidentBlue,
      themePalette.colorAccidentBlue2,
      themePalette.colorAccidentCoral,
      themePalette.colorAccidentCyan,
      themePalette.colorAccidentGold,
      themePalette.colorAccidentBlueDark,
      themePalette.colorAccidentLavender,
      themePalette.colorAccidentLime,
      themePalette.colorAccidentMagenta,
      themePalette.colorAccidentOrange,
      themePalette.colorAccidentPink,
      themePalette.colorAccidentRed,
      themePalette.colorAccidentSea,
      themePalette.colorAccidentTagSilver,
      themePalette.colorAccidentTurquoise,
    ],
    tooltip: {
      domStyles: {
        'g2-tooltip': {
          backgroundColor: themeVariables.colorTextPrimary,
          fontSize: 15,
        },
        'g2-tooltip-value': {
          fontWeight: 600,
          color: themeVariables.colorBgPrimary,
        },
        'g2-tooltip-list': {
          color: themeVariables.colorBgPrimary,
        },
        'g2-tooltip-marker': {
          display: 'none',
        },
        'g2-tooltip-title': {
          display: 'none',
        },
      },
      offset: 0,
    },
    maxColumnWidth: 24,
    dodgePadding: 16,
  };

  return <Column {...defaultGroupedColumnConfig} {...props} />;
};

export { UiChartColumn };
