// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b22587ea_ui-dropdown__dropdown-button .ant-btn:first-child:not(:last-child){border-right:1px solid var(--default-themeVariables-colorBrandFaded)}.b22587ea_ui-dropdown__dropdown-button .ant-btn:last-child:not(:first-child){border-left:1px solid var(--default-themeVariables-colorBrandFaded)}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/dropdown/UiDropdown.scss"],"names":[],"mappings":"AAIM,6EACE,oEAAA,CAGF,6EACE,mEAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.ui-dropdown {\n  &__dropdown-button {\n    :global(.ant-btn) {\n      &:first-child:not(:last-child) {\n        border-right: 1px solid $colorBrandFaded;\n      }\n\n      &:last-child:not(:first-child) {\n        border-left: 1px solid $colorBrandFaded;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-dropdown__dropdown-button": "b22587ea_ui-dropdown__dropdown-button",
	"uiDropdown__dropdownButton": "b22587ea_ui-dropdown__dropdown-button"
};
export default ___CSS_LOADER_EXPORT___;
