import { Editor } from '@ckeditor/ckeditor5-core';
import axios, { Canceler } from 'axios';

import { api } from 'ant/plugins/api';
import { FileStorageEndpoints } from 'ant/store/filestorage/endpoints';
import { PreloadedFileModel } from 'ant/types/models/file.model';

class FileUploadAdapter {
  private loader: any;
  private cancel: Canceler | null = null;

  constructor(loader: any) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      (file: File) =>
        new Promise((resolve, reject) => {
          this.initRequest(file, resolve, reject);
        }),
    );
  }

  // Aborts the upload process.
  abort() {
    if (this.cancel !== null) {
      this.cancel();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  initRequest(file: File, resolve: any, reject: any) {
    const formData = new window.FormData();

    formData.append('file', file);

    const source = axios.CancelToken.source();

    this.cancel = source.cancel;

    api
      .post<PreloadedFileModel>({
        url: FileStorageEndpoints.fileUploadV3(),
        data: formData,
        cancelToken: source.token,
        onUploadProgress: (progressEvent) => {
          this.loader.uploadTotal = progressEvent.total;
          this.loader.uploaded = progressEvent.loaded;
        },
      })
      .then(({ data }) =>
        resolve({
          urls: {
            default: data.fileUrl,
          },
          fileId: data.storageObject,
        }),
      )
      .catch((e) => reject(e));
  }
}

export function UploadAdapterPlugin(editor: Editor) {
  // eslint-disable-next-line no-param-reassign
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    // Configure the URL to the upload script in your back-end here!
    return new FileUploadAdapter(loader);
  };
}
