import React, { FC } from 'react';

import AttachSVG from 'ant/components/svg/attach.svg';
import ClearSVG from 'ant/components/svg/clear.svg';
import ErrorSvg from 'ant/components/svg/error.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiFile } from 'ant/components/ui/file';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSpace } from 'ant/components/ui/space';
import { UiSpinner } from 'ant/components/ui/spinner';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography, UiTypographyTextTypes } from 'ant/components/ui/typography';
import { UiUploadFile, UiUploadFileStatusType } from 'ant/components/ui/upload';
import { UiUploadFileListProps, UiUploadFileType } from 'ant/components/ui/upload-file-list';
import { getFormattedFileSize } from 'ant/plugins/utils/get-formatted-file-size';
import { themePalette } from 'ant/theme';

import styles from './UiUploadFileListItem.scss';

interface UiUploadFileListItemProps extends Omit<UiUploadFileListProps, 'files'> {
  file: UiUploadFile;
}

export const UiUploadFileListItem: FC<UiUploadFileListItemProps> = (props) => {
  const { file, onDelete, type = UiUploadFileType.Simple } = props;
  const { status, name: fileName, uid, size } = file;
  const fileSize = getFormattedFileSize(size);

  const isUploadingStatus = (statusType: UiUploadFileStatusType) => status === statusType;

  const isUploading = isUploadingStatus(UiUploadFileStatusType.Uploading);
  const isError = isUploadingStatus(UiUploadFileStatusType.Error);
  const isUploaded =
    isUploadingStatus(UiUploadFileStatusType.Done) || isUploadingStatus(UiUploadFileStatusType.Success);

  return (
    <UiRow justify="space-between" align="middle" wrap={false} className={styles.uiUploadFileListItem}>
      <UiCol>
        {type === UiUploadFileType.Simple && (
          <>
            {isUploading && (
              <UiSpace size={8}>
                <UiSpinner size="default" />
                <UiTypography.Text>{fileName}</UiTypography.Text>
              </UiSpace>
            )}
            {isError && (
              <UiIcon.Label
                type={UiTypographyTextTypes.Primary}
                strong
                component={ErrorSvg}
                color={themePalette.colorNegative}
              >
                <UiTruncateMarkup truncate>
                  Ошибка загрузки: {file.error ? `(${file.error}) - ${fileName}` : fileName}
                </UiTruncateMarkup>
              </UiIcon.Label>
            )}
            {isUploaded && (
              <UiIcon.Label
                type={UiTypographyTextTypes.Primary}
                component={AttachSVG}
                color={themePalette.colorIcon}
              >
                <UiTruncateMarkup truncate>{fileName}</UiTruncateMarkup>
              </UiIcon.Label>
            )}
          </>
        )}
        {type === UiUploadFileType.Verbose && (
          <UiFile
            title={
              <UiTypography.Text strong>
                {isError && `Ошибка загрузки: `}
                {fileName}
              </UiTypography.Text>
            }
            subtitle={<UiTypography.Text type="secondary">{fileSize}</UiTypography.Text>}
            fileName={fileName}
            loading={isUploadingStatus(UiUploadFileStatusType.Uploading)}
          />
        )}
      </UiCol>
      <UiCol>
        {onDelete && (
          <UiButton
            size="small"
            type="link-secondary"
            onClick={() => onDelete(uid)}
            icon={<UiIcon component={ClearSVG} width={20} height={20} />}
          />
        )}
      </UiCol>
    </UiRow>
  );
};
