import { EditorConfig } from '@ckeditor/ckeditor5-core';
import { MentionFeed } from '@ckeditor/ckeditor5-mention/src/mentionconfig';
import { useCallback, useMemo } from 'react';

import {
  editorMentionFeedConfig,
  EditorMentionType,
  EditorSearchMentionsHandler,
  getEditorMentionFeed,
  UserMentionFeedItem,
} from 'ant/components/widgets/Editor';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { getProfilesListStorage } from 'ant/store/profile';
import { UserAuthorModel } from 'ant/types/models/user.model';

const mapUsersToMention = (users: UserAuthorModel[]): UserMentionFeedItem[] => {
  const { marker } = editorMentionFeedConfig[EditorMentionType.User];

  return users.map((user) => {
    const name = getFullNameWithoutPatronymic(user.fullName);

    return {
      id: `${marker}${name}`,
      userId: user.id,
      name,
      avatar: user.avatar,
      text: name,
      link: `${marker}${getRoutePath(RouteNames.Profile, { id: user.id })}`,
    };
  });
};

export const useEditorMentionsConfig = (mentionsToInclude: EditorMentionType[]) => {
  const profileListStorage = useMemo(getProfilesListStorage, []);
  const { fetchFx: fetchProfileList, cancel: cancelFetchProfileList } = useAbstractStorage(
    profileListStorage.storage,
    {
      cancelPendingRequestOnUnmount: true,
      resetStoreOnUnmount: true,
    },
  );

  const onSearchUserMentions = useCallback<EditorSearchMentionsHandler<UserMentionFeedItem>>(
    (query: string) => {
      cancelFetchProfileList();

      return fetchProfileList({ query, pageSize: 12, isActive: true, skipEmptyName: true }).then(
        ({ items }) => mapUsersToMention(items),
      );
    },
    [cancelFetchProfileList, fetchProfileList],
  );

  const mentionFeeds = useMemo<Record<EditorMentionType, MentionFeed>>(() => {
    return {
      [EditorMentionType.User]: getEditorMentionFeed<UserMentionFeedItem>({
        type: EditorMentionType.User,
        onSearchMentions: onSearchUserMentions,
      }),
    };
  }, [onSearchUserMentions]);

  const editorMentionsConfig = useMemo<EditorConfig>(() => {
    return {
      mention: {
        feeds: mentionsToInclude.map((type) => mentionFeeds[type]),
      },
    };
  }, [mentionsToInclude, mentionFeeds]);

  return { editorMentionsConfig };
};
