import { CommentContentTypes, CommentId, CommentObjectId } from 'ant/types/models/comment.model';

const getCommentsCommentUrl = (url: string) => `/api/comments/comment/${url}`;
const getCommentsReactionUrl = (url: string) => `/api/comments/reaction/${url}`;

export class CommentsEndpoints {
  static commentList = (contentType: CommentContentTypes, objectId: CommentObjectId) =>
    getCommentsCommentUrl(`list/${contentType}/${objectId}/`);

  static commentSave = () => getCommentsCommentUrl(`save/`);

  static comment = (commentId?: CommentId) => getCommentsCommentUrl(`${commentId}/`);

  static reactionSave = () => getCommentsReactionUrl(`save/`);

  static reaction = (commentId: CommentId) => getCommentsReactionUrl(`${commentId}/`);

  static commentSearch = () => getCommentsCommentUrl('search/');
}
