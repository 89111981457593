import { ModalFuncProps as AntModalFuncProps } from 'antd/es/modal';
import classNames from 'classnames';

import uiButtonStyles from '../../button/UiButton.scss';
import { ModalFuncProps } from './types';

const defaultModalProps = {
  icon: null,
  width: 460,
  autoFocusButton: null,
};

export const getAntdModalFuncProps = ({
  disabled,
  ...modalFuncProps
}: ModalFuncProps): AntModalFuncProps => ({
  ...modalFuncProps,
  ...defaultModalProps,
  okButtonProps: {
    size: 'large',
    disabled: Boolean(disabled),
    className: classNames(uiButtonStyles.uiButton, uiButtonStyles.uiButton__primary),
  },
  cancelButtonProps: {
    size: 'large',
    disabled: Boolean(disabled),
    className: classNames(uiButtonStyles.uiButton, uiButtonStyles.uiButton__tertiary),
  },
});

export const getAntdModalWarningFuncProps = ({
  disabled,
  ...modalFuncProps
}: ModalFuncProps): AntModalFuncProps => ({
  ...modalFuncProps,
  ...defaultModalProps,
  okButtonProps: {
    size: 'large',
    danger: true,
    disabled: Boolean(disabled),
  },
  cancelButtonProps: {
    size: 'large',
    disabled: Boolean(disabled),
    className: classNames(uiButtonStyles.uiButton, uiButtonStyles.uiButton__tertiary),
  },
});

export const getAntdModalSuccessFuncProps = ({
  disabled,
  ...modalFuncProps
}: ModalFuncProps): AntModalFuncProps => ({
  ...modalFuncProps,
  ...defaultModalProps,
  okButtonProps: {
    size: 'large',
    disabled: Boolean(disabled),
    className: classNames(uiButtonStyles.uiButton__success),
  },
});
