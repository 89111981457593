import { SorterResult } from 'ant/components/ui/table';

export enum SortOrder {
  Ascend = 'ascend',
  Descend = 'descend',
}

export const getSortField = <T>(sorterResult: SorterResult<T>) => {
  let newSortField: string | null = null;

  if (sorterResult && sorterResult.order === SortOrder.Ascend) {
    newSortField = String(sorterResult.columnKey);
  }

  if (sorterResult && sorterResult.order === SortOrder.Descend) {
    newSortField = `-${sorterResult.columnKey}`;
  }

  return { sortField: newSortField || undefined };
};
