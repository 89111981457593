// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7a95b53e_image-answer{box-shadow:0px 4px 8px rgba(46,48,51,.08)}._7a95b53e_image-answer__title{display:block;padding:16px}._7a95b53e_image-answer__controls{position:absolute;top:0;right:0}._7a95b53e_image-answer__drag-drop-area{width:100%;height:100%}", "",{"version":3,"sources":["webpack://./../ant/src/components/widgets/Survey/survey-answer/image-answer/ImageAnswer.scss","webpack://./../ant/src/assets/scss/variables/box-shadows.scss"],"names":[],"mappings":"AACA,wBACE,yCCEU,CAAA,+BDCR,aAAA,CACA,YAAA,CAGF,kCACE,iBAAA,CACA,KAAA,CACA,OAAA,CAGF,wCACE,UAAA,CACA,WAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.image-answer {\n  box-shadow: $shadow8px;\n\n  &__title {\n    display: block;\n    padding: 16px;\n  }\n\n  &__controls {\n    position: absolute;\n    top: 0;\n    right: 0;\n  }\n\n  &__drag-drop-area {\n    width: 100%;\n    height: 100%;\n  }\n}\n","$fade32: rgba(31, 32, 34, 0.32);\n$fade64: rgba(31, 32, 34, 0.64);\n$shadow2px: 0px 1px 2px rgba(46, 48, 51, 0.08);\n$shadow4px: 0px 2px 4px rgba(46, 48, 51, 0.08);\n$shadow8px: 0px 4px 8px rgba(46, 48, 51, 0.08);\n$shadow16px: 0px 2px 16px rgba(46, 48, 51, 0.08);\n$shadowAvatar: inset 0px 0px 4px rgba(41, 44, 46, 0.08);\n$shadowActiveItem: 0px 8px 16px rgba(46, 48, 51, 0.08);\n$shadowSmall: 0px 1px 4px rgba(17, 17, 17, 0.12);\n$shadowInput: 0 0 0 1px $colorBrandFaded;\n$shadowSwitch: 0 0 0 2px $colorBrandFaded;\n$shadow4pxSelect: 0 0 0 4px $colorBrand;\n$shadow2pxBtn: 0 0 0 2px $colorBrandFaded;\n$shadow2pxBtnNegative08: 0 0 0 2px $colorNegative08;\n$shadow2pxBtnNegativeFaded: 0 0 0 2px $colorNegativeFaded;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image-answer": "_7a95b53e_image-answer",
	"imageAnswer": "_7a95b53e_image-answer",
	"image-answer__title": "_7a95b53e_image-answer__title",
	"imageAnswer__title": "_7a95b53e_image-answer__title",
	"image-answer__controls": "_7a95b53e_image-answer__controls",
	"imageAnswer__controls": "_7a95b53e_image-answer__controls",
	"image-answer__drag-drop-area": "_7a95b53e_image-answer__drag-drop-area",
	"imageAnswer__dragDropArea": "_7a95b53e_image-answer__drag-drop-area"
};
export default ___CSS_LOADER_EXPORT___;
