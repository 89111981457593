import React, { FC, ReactNode, useMemo } from 'react';

import { UiFile, UiFileDirection, UiFileProps } from 'ant/components/ui/file';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiImage } from 'ant/components/ui/image';
import { UiSpace } from 'ant/components/ui/space';
import { UiVideo } from 'ant/components/ui/video';
import { File } from 'ant/components/widgets/File';
import { checkFileMimeType } from 'ant/plugins/utils/check-file-mime-type';
import { isVideoFile } from 'ant/plugins/utils/is-video-file';
import { FileUploadAccepts } from 'ant/types/models/file-upload-accepts';

import { FileListItemProps } from '../FileListItem';

type Props = Pick<FileListItemProps, 'file' | 'actions'> & UiFileProps;

export const FileListItemColumn: FC<Props> = (props) => {
  const { file, actions = [], ...fileProps } = props;

  const isSupportedImageFile =
    file?.additional?.mimeType && checkFileMimeType(FileUploadAccepts.ImageAll, file.additional.mimeType);
  const isSupportedVideoFile = isVideoFile(file.name);

  const thumbnail = useMemo<ReactNode>(() => {
    if (isSupportedVideoFile) {
      return <UiVideo src={file.file} width={162} height={100} />;
    }

    if (isSupportedImageFile) {
      return (
        <UiImage
          placeholderSize={100}
          style={{ objectFit: 'contain' }}
          width={162}
          height={100}
          src={file.file}
        />
      );
    }

    return null;
  }, [file]);

  return (
    <UiCol span={24} style={{ padding: '24px 0 6px 0' }}>
      <UiRow justify="center">
        <UiSpace size={6} direction="vertical" align="center">
          <UiFile direction={UiFileDirection.Vertical} thumbnail={thumbnail} {...fileProps} size="small" />
          {actions?.length > 0 && <File.Actions actions={actions} />}
        </UiSpace>
      </UiRow>
    </UiCol>
  );
};
