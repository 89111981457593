import { api } from 'ant/plugins/api';
import { PagesEndpoints } from 'ant/store/pages/endpoints';
import { FileStorageEntryId, FileStorageObjectModel } from 'ant/types/models/file.model';

export type HeaderLogoImageData = {
  fileStorageObject: FileStorageEntryId | null;
};

export type HeaderLogoImageModel = {
  fileStorageObject: FileStorageObjectModel | null;
};

export const updateHeaderLogoImage = (data: HeaderLogoImageData) => {
  return api.post<HeaderLogoImageModel>({
    url: PagesEndpoints.image(),
    data,
  });
};

export const deleteHeaderLogoImage = () =>
  api.delete<HeaderLogoImageModel>({
    url: PagesEndpoints.image(),
  });
