// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8611c2a5_ui-button-decorator{display:contents;cursor:pointer}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/button/decorator/UiButtonDecorator.scss"],"names":[],"mappings":"AACA,+BACE,gBAAA,CACA,cAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.ui-button-decorator {\n  display: contents;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-button-decorator": "_8611c2a5_ui-button-decorator",
	"uiButtonDecorator": "_8611c2a5_ui-button-decorator"
};
export default ___CSS_LOADER_EXPORT___;
