import classNames from 'classnames';
import React, { FC } from 'react';

import { UiCheckbox, UiCheckboxProps } from 'ant/components/ui/checkbox';
import { UiRadio, UiRadioProps } from 'ant/components/ui/radio';

import styles from './UiPollButton.scss';

export enum UiPollButtonTypes {
  Single = 'single',
  Multiple = 'multiple',
}
export const UI_POLL_BUTTON_TYPE_DEFAULT = UiPollButtonTypes.Single;
export type UiPollButtonItemProps = UiRadioProps | UiCheckboxProps;
export type UiPollButtonTypesProps = {
  type?: UiPollButtonTypes;
};
export type UiPollButtonProps<T = UiPollButtonItemProps> = T & UiPollButtonTypesProps;

const UiPollButton: FC<UiPollButtonProps> = (props) => {
  const { type = UI_POLL_BUTTON_TYPE_DEFAULT, className, ...restProps } = props;
  const classNameStyles = classNames(styles.uiPollButton, className);
  const UiPollButtonComponent = type === UiPollButtonTypes.Single ? UiRadio : UiCheckbox;

  return (
    <UiPollButtonComponent {...restProps} className={classNameStyles}>
      {restProps.children}
    </UiPollButtonComponent>
  );
};

export { UiPollButton };
