import { Plugin } from '@ckeditor/ckeditor5-core';
import { WidgetToolbarRepository } from '@ckeditor/ckeditor5-widget';
import isObject from 'lodash/isObject';

import type { VideoStyleDropdownDefinition } from './videoconfig';
import { VideoUtils } from './videoutils';

function normalizeDeclarativeConfig(config: Array<string | VideoStyleDropdownDefinition>): Array<string> {
  return config.map((item) => (isObject(item) ? item.name : item));
}

export class VideoToolbar extends Plugin {
  public static get requires() {
    return [WidgetToolbarRepository, VideoUtils] as const;
  }

  public static get pluginName() {
    return 'VideoToolbar' as const;
  }

  public afterInit(): void {
    const { editor } = this;
    const widgetToolbarRepository = editor.plugins.get(WidgetToolbarRepository);
    const videoUtils: VideoUtils = editor.plugins.get('VideoUtils');

    widgetToolbarRepository.register('video', {
      ariaLabel: 'Панель инструментов видео',
      items: normalizeDeclarativeConfig(editor.config.get('video.toolbar') || []),
      getRelatedElement: (selection) => videoUtils.getClosestSelectedVideoWidget(selection)!,
    });
  }
}
