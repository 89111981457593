import { useStore } from 'effector-react';
import React, { FC, useMemo } from 'react';

import CalendarAddSvg from 'ant/components/svg/calendar-add.svg';
import ContactMailSvg from 'ant/components/svg/contact-mail.svg';
import ContactPhoneSvg from 'ant/components/svg/contact-phone.svg';
import { UiTooltip, UiTooltipProps } from 'ant/components/ui/tooltip';
import { UiTypography } from 'ant/components/ui/typography';
import { UserProfile, UserProfileActionsProps } from 'ant/components/widgets/UserProfile';
import { currentProfileStore } from 'ant/plugins/current-profile-service';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { themePalette } from 'ant/theme';
import { CalendarUserStatus } from 'ant/types/models/calendar';
import { BaseUserProfileModel } from 'ant/types/models/user.model';

type User = Pick<BaseUserProfileModel, 'id' | 'fullName' | 'avatar' | 'position' | 'email' | 'workPhone'>;

export interface UserProfileTooltipProps extends Omit<UiTooltipProps, 'title'> {
  user: User | null;
}

export const colorTextInvertTooltipStyle = { color: themePalette.colorTextInvert };

export const UserProfileTooltip: FC<UserProfileTooltipProps> = (props) => {
  const { children, user, ...tooltipProps } = props;
  const currentProfileStoreData = useStore(currentProfileStore);

  const meetingAction = useMemo(() => {
    if (!user || !currentProfileStoreData) {
      return null;
    }

    const onCreateMeeting = () => {
      const attendees = [
        {
          userId: user.id,
          status: CalendarUserStatus.NeedAction,
          fullName: getFullNameWithoutPatronymic(user.fullName),
          image: user.avatar,
          position: user.position,
          mailto: user.email,
        },
        {
          status: CalendarUserStatus.Accepted,
          userId: currentProfileStoreData.id,
          image: currentProfileStoreData.avatar,
          mailto: currentProfileStoreData.email,
        },
      ];

      openGlobalModal(GlobalModalNames.CalendarCreateUpdateMeeting, {
        initialEventFormValues: { attendees },
      });
    };

    return { label: 'Встреча', icon: CalendarAddSvg, onClick: onCreateMeeting };
  }, [user, currentProfileStoreData]);

  const actions = useMemo<UserProfileActionsProps['items']>(() => {
    if (!user) {
      return [];
    }

    const items: UserProfileActionsProps['items'] = [];
    const { email, workPhone } = user;

    if (meetingAction) {
      items.push(meetingAction);
    }

    if (workPhone) {
      items.push({ label: 'Позвонить', icon: ContactPhoneSvg, href: `tel:${workPhone}` });
    }

    if (email) {
      items.push({ label: 'Сообщение', icon: ContactMailSvg, href: `mailto:${email}` });
    }

    return items;
  }, [user, meetingAction]);

  const title = useMemo(() => {
    if (!user) {
      return <UserProfile.Skeleton count={4} />;
    }

    const { fullName, position } = user;

    return (
      <>
        <UserProfile.Info
          align="top"
          size="large"
          user={{ ...user, ...fullName }}
          style={{ padding: 20 }}
          fullNameProps={{ style: colorTextInvertTooltipStyle }}
        >
          {position && <UiTypography.Footnote type="secondary">{position.name}</UiTypography.Footnote>}
        </UserProfile.Info>
        <UserProfile.Actions items={actions} />
      </>
    );
  }, [user, actions]);

  return (
    <UiTooltip
      title={title}
      overlayInnerStyle={{ minWidth: 440, maxWidth: 440, borderRadius: 8, padding: 0 }}
      {...tooltipProps}
    >
      {children}
    </UiTooltip>
  );
};
