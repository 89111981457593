import { Plugin } from '@ckeditor/ckeditor5-core';
import type { Element, ViewElement, ViewContainerElement } from '@ckeditor/ckeditor5-engine';
import { WidgetResize } from '@ckeditor/ckeditor5-widget';

import { type VideoLoadedEvent, VideoLoadObserver } from '../video/videoloadobserver';
import type { ResizeVideoCommand } from './resizevideocommand';

const VIDEO_WIDGETS_CLASSES_MATCH_REGEXP = /(video)/;
const RESIZABLE_VIDEOS_CSS_SELECTOR = 'figure.video.ck-widget > video,';
const RESIZED_VIDEO_CLASS = 'video_resized';

export class VideoResizeHandles extends Plugin {
  public static get requires() {
    return [WidgetResize] as const;
  }

  public static get pluginName() {
    return 'VideoResizeHandles' as const;
  }

  public init(): void {
    const command: ResizeVideoCommand = this.editor.commands.get('resizeVideo')!;

    this.bind('isEnabled').to(command);

    this.setupResizerCreator();
  }

  private setupResizerCreator(): void {
    const { editor } = this;
    const editingView = editor.editing.view;

    editingView.addObserver(VideoLoadObserver);

    this.listenTo<VideoLoadedEvent>(editingView.document, 'videoLoaded', (_, domEvent) => {
      if (!(domEvent.target as HTMLElement).matches(RESIZABLE_VIDEOS_CSS_SELECTOR)) {
        return;
      }

      const { domConverter } = editor.editing.view;
      const videoView = domConverter.domToView(domEvent.target as HTMLElement) as ViewElement;
      const widgetView = videoView.findAncestor({
        classes: VIDEO_WIDGETS_CLASSES_MATCH_REGEXP,
      }) as ViewContainerElement;
      let resizer = this.editor.plugins.get(WidgetResize).getResizerByViewElement(widgetView);

      if (resizer) {
        resizer.redraw();

        return;
      }

      const { mapper } = editor.editing;
      const videoModel = mapper.toModelElement(widgetView)!;

      resizer = editor.plugins.get(WidgetResize).attachTo({
        unit: editor.config.get('video.resizeUnit')!,

        modelElement: videoModel,
        viewElement: widgetView,
        editor,

        getHandleHost(domWidgetElement) {
          return domWidgetElement.querySelector('video')!;
        },
        getResizeHost() {
          return domConverter.mapViewToDom(
            mapper.toViewElement(videoModel.parent as Element)!,
          ) as HTMLElement;
        },
        isCentered() {
          const videoStyle = videoModel.getAttribute('videoStyle');

          // eslint-disable-next-line eqeqeq
          return !videoStyle || videoStyle == 'block' || videoStyle == 'alignCenter';
        },

        onCommit(newValue) {
          editingView.change((writer) => {
            writer.removeClass(RESIZED_VIDEO_CLASS, widgetView);
          });

          editor.execute('resizeVideo', { width: newValue });
        },
      });

      resizer.on('updateSize', () => {
        if (!widgetView.hasClass(RESIZED_VIDEO_CLASS)) {
          editingView.change((writer) => {
            writer.addClass(RESIZED_VIDEO_CLASS, widgetView);
          });
        }
      });

      resizer.bind('isEnabled').to(this);
    });
  }
}
