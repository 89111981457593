import { GroupsEndpoints } from 'ant/endpoints/groups';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { DictPaginated } from 'ant/types/api';
import { GroupDivisionModel, GroupInfoModel } from 'ant/types/models/groups.model';

enum SearchFields {
  ManagementDepartment = 'management_department',
  Organization = 'organization',
  Name = 'name',
  Search = 'search',
}

type SearchParamsStore = Partial<Record<SearchFields, string>>;

type UserGroupsListStorageParams = {
  userId: string;
};

type OrgStructureSubdivisionList = {
  pageSize?: number;
  pageNumber?: number;
  ordering?: string;
  organizationId?: string;
} & Pick<SearchParamsStore, SearchFields.ManagementDepartment | SearchFields.Organization>;

export const getUserGroupsListStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<GroupInfoModel>,
    GroupInfoModel[],
    GroupInfoModel[],
    UserGroupsListStorageParams
  >({
    endpointBuilder: ({ userId }) => GroupsEndpoints.membersUserIdGroups(userId),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getOrgStructureSubdivisionsStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<GroupDivisionModel>,
    GroupDivisionModel[],
    GroupDivisionModel[],
    OrgStructureSubdivisionList
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(GroupsEndpoints.department(), params),
    dataMapper: (data) => data.items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
    defaultValue: [],
  });

  return { storage };
};
