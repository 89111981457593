type ImageDimensions = {
  width: number;
  height: number;
};

export const getImageDimensions = async (url: string): Promise<ImageDimensions> => {
  return new Promise((resolved) => {
    const i = new window.Image();

    i.onload = () => {
      resolved({ width: i.naturalWidth, height: i.naturalHeight });
    };

    i.src = url;
  });
};
