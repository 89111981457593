import { AttachmentEntryId } from 'ant/types/models/attachment.model';
import {
  FileStorageCategoryId,
  FileStorageEntryId,
  FileStorageEntryRevision,
} from 'ant/types/models/file.model';

const getFileStorageUrl = (url: string) => `/filestorage/api/${url}`;
const getFileStorageUrlV2 = (url: string) => `/api/filestorage/v2/${url}`;
const getFileStorageUrlV3 = (url: string) => `/api/filestorage/v3/${url}`;

const optional = (id?: number) => (id ? `${id}/` : '');

// TODO перейти везде на вторую версию ручки
export class FileStorageEndpoints {
  static fileUpload = () => getFileStorageUrl('attachments/');

  static fileUploadV2 = () => getFileStorageUrlV2('file/upload/');

  static fileUploadV3 = () => getFileStorageUrlV3('file/upload/');

  static fileShareV3 = () => getFileStorageUrlV3('file/share/');

  static getFileV2 = (id: AttachmentEntryId) => getFileStorageUrlV2(`file/${id}/`);

  static getFileV3 = (id: FileStorageEntryId) => getFileStorageUrlV3(`file/${id}/`);

  static getFilesV2 = () => getFileStorageUrlV2(`get_files/`);

  static fileContent = (imageId: number) => `/api/filestorage/v2/file/${imageId}/content/`;

  static sharedFolder = () => `${getFileStorageUrlV3('folder/shared_folder/')}`;

  static fileCropImage = (imageId: FileStorageEntryId) => getFileStorageUrlV3(`file/${imageId}/crop-image/`);

  static folderEntryObjects = (entryId: FileStorageEntryId) =>
    getFileStorageUrlV3(`folder/${entryId}/objects/`);

  static folderFilesSearch = (entryId: FileStorageEntryId) =>
    getFileStorageUrlV3(`folder/${entryId}/files/search/`);

  static folderNew = () => getFileStorageUrlV3(`folder/new/`);

  static folder = (entryId: FileStorageEntryId) => getFileStorageUrlV3(`folder/${entryId}/`);

  static folderAllowedCategories = (entryId: FileStorageEntryId) =>
    getFileStorageUrlV3(`folder/${entryId}/allowed-categories/`);

  static categories = () => getFileStorageUrlV3(`categories/`);

  static categoriesCategoryId = (categoryId: FileStorageCategoryId) =>
    getFileStorageUrlV3(`categories/${categoryId}/`);

  static object = () => getFileStorageUrlV3(`object/`);

  static objectReactions = (entryId: FileStorageEntryId) =>
    getFileStorageUrlV3(`object/${entryId}/reactions/`);

  static objectTransfer = () => getFileStorageUrlV3(`object/transfer/`);

  static objectEntry = (entryId: FileStorageEntryId) => getFileStorageUrlV3(`object/${entryId}/`);

  static objectHierarchy = (entryId: FileStorageEntryId) =>
    getFileStorageUrlV3(`object/${entryId}/hierarchy/`);

  static file = (entryId: FileStorageEntryId, revision?: FileStorageEntryRevision) =>
    getFileStorageUrlV3(`file/${entryId}/${optional(revision)}`);

  static fileVersions = (entryId: FileStorageEntryId, revision?: FileStorageEntryRevision) =>
    getFileStorageUrlV3(`file/${entryId}/versions/${optional(revision)}`);

  static fileVersionsUndelete = (entryId: FileStorageEntryId, revision?: FileStorageEntryRevision) =>
    getFileStorageUrlV3(`file/${entryId}/versions/${revision}/undelete/`);

  static fileVersionsRestore = (entryId: FileStorageEntryId, revision?: FileStorageEntryRevision) =>
    getFileStorageUrlV3(`file/${entryId}/versions/${revision}/restore/`);

  static filesSearch = () => getFileStorageUrlV3('files/search/');

  static objectHistory = (entryId: FileStorageEntryId) => getFileStorageUrlV3(`object/${entryId}/history/`);

  static objectFavorite = (entryId: FileStorageEntryId) => getFileStorageUrlV3(`object/${entryId}/favorite/`);

  static folderUserRoles = (entryId: FileStorageEntryId) =>
    getFileStorageUrlV3(`folder/${entryId}/user-roles/`);

  static folderSetRole = (entryId: FileStorageEntryId) => getFileStorageUrlV3(`folder/${entryId}/set-role/`);

  static folderExtensions = (entryId: FileStorageEntryId) =>
    getFileStorageUrlV3(`folder/${entryId}/extensions/`);
}
