import { getDictsStorage } from 'ant/store/dictionaries';
import { Dictionaries } from 'ant/store/dictionaries/dictionaries';
import { DictDataParams } from 'ant/types/api';
import { ContactLinkAttributes, RecordResponse } from 'ant/types/dictionary';

export const getContactsDictsStorage = () => {
  const messengerDictsStorage = getDictsStorage<RecordResponse<ContactLinkAttributes>, DictDataParams>({
    dictionaryName: Dictionaries.Names.ContactTypes,
    dataBuilder: ({ kind: { type, value } }) => ({ kind: { type, value } }),
  });

  const messengerDictsOptionsStore = messengerDictsStorage.storage.store.map(({ data }) =>
    data.map((messenger) => ({
      value: messenger.id,
      icon: messenger.attributes.icon,
      label: messenger.attributes.name,
    })),
  );

  const externalLinkDictsStorage = getDictsStorage<RecordResponse<ContactLinkAttributes>, DictDataParams>({
    dictionaryName: Dictionaries.Names.ContactTypes,
    dataBuilder: ({ kind: { type, value } }) => ({ kind: { type, value } }),
  });

  const externalLinkDictsOptionsStore = externalLinkDictsStorage.storage.store.map(({ data }) =>
    data.map((link) => ({
      recordId: link.id,
      icon: link.attributes.icon,
      name: link.attributes.name,
      prefix: link.attributes.prefix,
      placeholder: link.attributes.placeholder,
    })),
  );

  return {
    messengerDictsStorage,
    messengerDictsOptionsStore,
    externalLinkDictsStorage,
    externalLinkDictsOptionsStore,
  };
};

export type GetContactsDictsStorage = ReturnType<typeof getContactsDictsStorage>;
