import React, { FC } from 'react';

import { UiImage } from 'ant/components/ui/image';
import { UiSkeleton } from 'ant/components/ui/skeleton';

import { UiActivityListItemSize, UiActivityListItemImageSize } from '../UiActivityListItem';
import styles from '../UiActivityListItem.scss';

export type Props = {
  size: UiActivityListItemSize;
};

const UiActivityListItemSkeleton: FC<Props> = ({ size }) => {
  return (
    <div className={styles.activityListItem}>
      <UiImage
        className={styles.activityListItem__image}
        placeholderSize={80}
        {...(size === UiActivityListItemSize.Small
          ? UiActivityListItemImageSize.small
          : UiActivityListItemImageSize.medium)}
      />
      <div className={styles.activityListItem__main}>
        <UiSkeleton loading width="50%" height={24} />
        <UiSkeleton className={styles.activityListItem__element} loading width="30%" height={20} />
        <UiSkeleton className={styles.activityListItem__element} loading width="40%" height={20} />
      </div>
    </div>
  );
};

export { UiActivityListItemSkeleton };
