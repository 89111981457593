import type { SizeType } from 'antd/es/config-provider/SizeContext';
import SizeContext from 'antd/es/config-provider/SizeContext';
import { ConfigProviderProps } from 'antd/lib/config-provider';
import React from 'react';

import { UiEmpty } from 'ant/components/ui/empty';

export { ConfigContext as UiConfigContext } from 'antd/lib/config-provider';
export { ConfigProvider as UiConfigProvider } from 'antd';
export const UiConfigProviderSizeContext = SizeContext;
export type UiConfigProviderSizeType = SizeType;
export type UiConfigProviderProps = ConfigProviderProps;

export type SizeClsPostfix = 'lg' | 'sm' | undefined;
export type SizeGutterPostfix = 'lg' | 'sm' | 'md';

export const defaultUiConfig: UiConfigProviderProps = {
  renderEmpty: () => <UiEmpty />,
};

export const sizeClassNameMap: Record<Exclude<UiConfigProviderSizeType, undefined>, SizeClsPostfix> = {
  large: 'lg',
  small: 'sm',
  middle: undefined,
};
export const sizeGutterNameMap: Record<Exclude<UiConfigProviderSizeType, undefined>, SizeGutterPostfix> = {
  large: 'lg',
  small: 'sm',
  middle: 'md',
};

export const getSizeClsBySizeFullName = (
  sizeFullname: Exclude<UiConfigProviderSizeType, undefined>,
): string => {
  if (!sizeFullname) {
    return '';
  }

  return sizeClassNameMap[sizeFullname] || '';
};

export const getSizeGutterBySizeFullName = (sizeFullname: UiConfigProviderSizeType): SizeGutterPostfix => {
  if (!sizeFullname || !sizeGutterNameMap[sizeFullname]) {
    return 'md';
  }

  return sizeGutterNameMap[sizeFullname];
};
