import React from 'react';

import { UiCarousel } from 'ant/components/ui/carousel/UiCarousel';

import styles from './UiImageShowcase.scss';

export type UiImageShowcaseProps = {
  images: string[];
};

const dotStyle: React.CSSProperties = { height: 78 };

const UiImageShowcase: React.FC<UiImageShowcaseProps> = ({ images }) => {
  const appendDots = (children: React.ReactNode): JSX.Element => (
    <span className={styles.uiImageShowcase__slickItem}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          return (
            <img
              alt=""
              key={child.key}
              style={dotStyle}
              src={images[index]}
              role="presentation"
              className={child.props.className}
              onClick={child.props.children.props.onClick}
            />
          );
        }

        return null;
      })}
    </span>
  );

  return (
    <UiCarousel effect="fade" dotPosition="bottom" appendDots={appendDots} className={styles.uiImageShowcase}>
      {images.map((imgUrl) => (
        <img key={imgUrl} src={imgUrl} alt="" />
      ))}
    </UiCarousel>
  );
};

export { UiImageShowcase };
