export type PermissionsId = number;
export type PermissionModel<T> = {
  description: string;
  name: T;
};
export interface PermissionsModel extends PermissionModel<string> {
  id: PermissionsId;
}

export enum SurveyAllowedPermission {
  CreateSurveys = 'create_surveys',
  ManageCategoriesSurveys = 'manage_categories_surveys',
  ManageAdminsSurveys = 'manage_admins_surveys',
  CreateSendingSurveys = 'create_sending_surveys',
}

export enum NewsAllowedPermission {
  CreateNews = 'create_news',
  ManageAllNews = 'manage_all_news',
  ManageAdmins = 'manage_admins',
  ManageCategories = 'manage_categories',
}

export enum EventsAllowedPermission {
  CreateEvents = 'create_events',
  ManageAdminsEvents = 'manage_admins_events',
  ManageAllEvents = 'manage_all_events',
}

export enum GroupsAllowedPermission {
  ManageGroups = 'manage_groups',
  ManageAdminsGroups = 'manage_admins_groups',
}

export enum CmsAllowedPermission {
  ManageCmsAll = 'manage_cms_all',
  ManageCmsGroup = 'manage_cms_group',
  ManageCmsGroupContent = 'manage_cms_group_content',
}

export enum CmsPersonalPagesAllowedPermission {
  ManageCmsPersonalPage = 'manage_cms_personal_page',
}

export type AllowedPermission =
  | EventsAllowedPermission
  | NewsAllowedPermission
  | CmsAllowedPermission
  | CmsPersonalPagesAllowedPermission;

export type AllAllowedPermissions = AllowedPermission | SurveyAllowedPermission;
