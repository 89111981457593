import { Progress } from 'antd';
import { ProgressProps } from 'antd/es/progress';
import classNames from 'classnames';
import React, { useMemo } from 'react';

import { themePalette } from 'ant/theme';

import styles from './UiProgress.scss';

export interface UiProgressProps extends ProgressProps {
  withChart?: boolean;
  className?: string;
}

const defaultFormatPercent = (percent: UiProgressProps['percent']) => `${percent}%`;

export const generateStrokeColor = (percent: UiProgressProps['percent'] = 0, colors: string[]) => {
  const step = 100 / colors.length;
  const percentList = colors.map((_, index) => step * (index + 1));
  const colorIndex = percentList.findIndex((value) => percent <= value);

  return colors[colorIndex];
};

const DEFAULT_PROGRESS_COLOR = [
  themePalette.colorNegative,
  themePalette.colorWarning,
  themePalette.colorSuccess,
];

export const UiProgress: React.FC<UiProgressProps> = (props) => {
  const { percent = 0, withChart = true, ...reset } = props;
  const strokeColor = useMemo(() => generateStrokeColor(percent, DEFAULT_PROGRESS_COLOR), [percent]);

  return (
    <Progress
      className={classNames(styles.uiProgress, { [styles.uiProgress_hideChart]: !withChart })}
      percent={percent}
      strokeColor={strokeColor}
      strokeLinecap="square"
      format={defaultFormatPercent}
      {...reset}
    />
  );
};
