import { Observer } from '@ckeditor/ckeditor5-engine';

export type VideoLoadedEvent = {
  name: 'videoLoaded';
  args: [Event];
};

export class VideoLoadObserver extends Observer {
  public observe(domRoot: HTMLElement): void {
    this.listenTo(
      domRoot,
      'load',
      (_, domEvent) => {
        const domElement = domEvent.target as HTMLElement;

        if (this.checkShouldIgnoreEventFromTarget(domElement)) {
          return;
        }

        // eslint-disable-next-line eqeqeq
        if (domElement.tagName == 'video') {
          this.fireEvents(domEvent);
        }
      },
      { useCapture: true },
    );
  }

  public override stopObserving(domRoot: HTMLElement): void {
    this.stopListening(domRoot);
  }

  private fireEvents(domEvent: Event): void {
    if (this.isEnabled) {
      this.document.fire('layoutChanged');
      this.document.fire<VideoLoadedEvent>('videoLoaded', domEvent);
    }
  }
}
