import React from 'react';

import { UiTypography } from 'ant/components/ui/typography';

import styles from '../CropImageArea.scss';

type CropImageAreaTitleProps = {
  title: string;
};

const CropImageAreaTitle: React.FC<CropImageAreaTitleProps> = ({ title }) => {
  return <UiTypography.Text className={styles.reactCropArea__title}>{title}</UiTypography.Text>;
};

export { CropImageAreaTitle };
