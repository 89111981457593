import React, { FC, PropsWithChildren } from 'react';

import 'react-image-crop/dist/ReactCrop.css';
import styles from '../CropImageArea.scss';

const CropImageAreaWrapper: FC<PropsWithChildren> = ({ children }) => {
  return <div className={styles.reactCropAreaWrapper}>{children}</div>;
};

export { CropImageAreaWrapper };
