import React, { FC, useMemo } from 'react';

import { UiEmpty, UiEmptyMessageType } from 'ant/components/ui/empty';

type LayoutNoAccessProps = UiEmptyMessageType;

export const LayoutNoAccess: FC<LayoutNoAccessProps> = (props) => {
  const { header = 'Нет доступа', description = 'Вы не можете просматривать этот раздел' } = props;
  const emptyMessage = useMemo(() => ({ header, description }), [header, description]);

  return <UiEmpty.Feed emptyMessage={emptyMessage} />;
};
