import { BinaryHeap } from './binary-heap';
/* eslint-disable */

export const dijkstra = {
  single_source_shortest_paths(graph, s, d) {
    const predecessors = {};

    // Веса самых коротких путей от 's' до всех встеченных узлов
    // node ID => cost
    const costs = {};
    costs[s] = 0;

    /* 
      Веса самых коротких путей от 's' до всех встеченных узлов
      Отличается от costs тем, что позволяет легко получить ноду, у которой мы знаем
      на текущий момент самый короткий путь от s
    */
    const open = new BinaryHeap((x) => {
      return x.cost;
    });

    open.push({ value: s, cost: 0 });

    let closest = null;
    let u = null;
    let cost_of_s_to_u = null;
    let adjacent_nodes = null;
    let cost_of_e = null;
    let cost_of_s_to_u_plus_cost_of_e = null;
    let cost_of_s_to_v = null;
    let first_visit = null;

    while (open.size()) {
      /* 
        В нодах, которые остаются в графе, у которых известен вес от s,
        Найти узел u, который имеет самый которкий путь от s
      */
      closest = open.pop();
      u = closest.value;
      cost_of_s_to_u = closest.cost;

      // Узлы прилегающие к u
      adjacent_nodes = graph(u) || {};

      /*
        пройтись по граням которые соединяют u к их узлам,
        обновляя веса самых которотких путей, если необходимо.
        v - это узел через текущую грань от u
       */
      for (let v in adjacent_nodes) {
        // Получить вес грани от u к v
        cost_of_e = adjacent_nodes[v];

        /*
          Вес веса узла s к узлу u веса узла u к узлу v через узлу e. :-)
          Вес от узла s к узлу v может быть меньше чем текущий вес к узлу v
        */
        cost_of_s_to_u_plus_cost_of_e = cost_of_s_to_u + cost_of_e;

        /*
          Если мы его не дошли до узла v или если текущий известный вес
          от узла s к узлу v больше чем новый вес, 
          который мы только что нашли (вес s + вес u + вес между u и v через узел e)
          обновить вес узла v в списке весов и и его предшественника
          в списке предшественних теперь u
        */

        cost_of_s_to_v = costs[v];
        first_visit = typeof costs[v] === 'undefined';
        if (first_visit || cost_of_s_to_v > cost_of_s_to_u_plus_cost_of_e) {
          costs[v] = cost_of_s_to_u_plus_cost_of_e;
          open.push({ value: v, cost: cost_of_s_to_u_plus_cost_of_e });
          predecessors[v] = u;
        }
      }
    }

    if (typeof costs[d] === 'undefined') {
      throw new Error(`Не смогли найти путь ${s} к ${d}.`);
    }

    return predecessors;
  },

  extract_shortest_path_from_predecessor_list(predecessors, d) {
    const nodes = [];
    let u = d;

    while (u) {
      nodes.push(u);
      u = predecessors[u];
    }
    nodes.reverse();
    return nodes;
  },

  find_path(graph, s, d) {
    const predecessors = dijkstra.single_source_shortest_paths(graph, s, d);
    return dijkstra.extract_shortest_path_from_predecessor_list(predecessors, d);
  },
};
/* eslint-enable */
