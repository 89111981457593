import { createEffect } from 'effector';

export enum FileSizeUnit {
  KB,
  MB,
  GB,
}

export type ErrorMessage = string;

export type BaseFileSizeUploadParams = {
  maxSize?: number;
  unitSize?: FileSizeUnit;
  errorSizeMessage?: ErrorMessage;
};

interface GetFileSizeUploadErrorParams extends BaseFileSizeUploadParams {
  file: File;
}

interface GetFileSizeUploadErrorsParams extends BaseFileSizeUploadParams {
  files: File[];
}

export const FILE_SIZE_ERROR_MESSAGE: Record<FileSizeUnit, ErrorMessage> = {
  [FileSizeUnit.KB]: 'килобайт',
  [FileSizeUnit.MB]: 'мегабайт',
  [FileSizeUnit.GB]: 'гигабайт',
};

export const UNIT_FILE_SIZES: Record<FileSizeUnit, number> = {
  [FileSizeUnit.KB]: 1024,
  [FileSizeUnit.MB]: 1024 ** 2,
  [FileSizeUnit.GB]: 1024 ** 3,
};

const EMPTY_FILE_ERROR_MSG: ErrorMessage = 'Файл не был загружен';

export const getFileSizeUploadError = (params: GetFileSizeUploadErrorParams): ErrorMessage | null => {
  const { file, maxSize = 10, unitSize = FileSizeUnit.MB, errorSizeMessage } = params;
  const MAX_FILES_SIZE = maxSize * UNIT_FILE_SIZES[unitSize];
  const MAX_FILES_SIZE_ERROR_MSG = `Максимальный вес файла должен быть ${maxSize} ${FILE_SIZE_ERROR_MESSAGE[unitSize]}.`;

  if (!file) {
    return EMPTY_FILE_ERROR_MSG;
  }

  if (file && file.size > MAX_FILES_SIZE) {
    return errorSizeMessage || MAX_FILES_SIZE_ERROR_MSG;
  }

  return null;
};

export const getFileSizeUploadErrors = (params: GetFileSizeUploadErrorsParams): ErrorMessage[] => {
  const { files, ...restParams } = params;

  return files.reduce((errorArr: Array<ErrorMessage>, file) => {
    const error = getFileSizeUploadError({
      file,
      ...restParams,
    });

    return error ? [...errorArr, error] : errorArr;
  }, []);
};

export const checkFileSizeUploadEffect = createEffect<
  GetFileSizeUploadErrorParams,
  { file: File },
  ErrorMessage
>((params) => {
  const { file } = params;
  const error = getFileSizeUploadError(params);

  if (error) {
    return Promise.reject(error);
  }

  return Promise.resolve({ file });
});
