import React, { FC } from 'react';

import { UiCard } from 'ant/components/ui/card';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { GANTT_PREVIEW_CONTAINER, BASE_NAVIGATION_WIDTH } from 'ant/components/widgets/Gantt/GanttCompact';
import { GanttCompactLoading } from 'ant/components/widgets/Gantt/loading/GanttCompactLoading';

export const GanttPreviewLoading: FC = () => {
  return (
    <UiCard size="default" emptyPadding style={{ marginBottom: 20 }}>
      <UiRow wrap={false}>
        <UiRow
          padding={[32, 20]}
          gutter={[24, 24]}
          style={{ width: BASE_NAVIGATION_WIDTH, alignContent: 'flex-start' }}
        >
          <UiCol span={24} style={{ textAlign: 'center' }}>
            <UiSkeleton loading width={110} height={20} />
          </UiCol>
          <UiCol span={24}>
            <UiRow justify="space-between">
              <UiSkeleton loading width={20} height={20} />
              <UiSkeleton loading width={56} height={20} />
              <UiSkeleton loading width={20} height={20} />
            </UiRow>
          </UiCol>
          <UiCol span={24} style={{ textAlign: 'center' }}>
            <UiSkeleton loading width={130} height={32} />
          </UiCol>
        </UiRow>
        <UiRow style={GANTT_PREVIEW_CONTAINER}>
          <GanttCompactLoading />
        </UiRow>
      </UiRow>
    </UiCard>
  );
};
