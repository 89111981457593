import React from 'react';

import CommentSvg from 'ant/components/svg/comment.svg';
import { UiIcon } from 'ant/components/ui/icon';

type Props = { count: number };

const ReactionsComments: React.FC<Props> = ({ count }) => (
  <UiIcon.Label component={CommentSvg} label={String(count)} />
);

export { ReactionsComments };
