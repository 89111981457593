// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".f499ac7e_empty-feed-text{display:flex;flex-direction:column;align-items:center;padding:24px}.f499ac7e_empty-feed-text__typography{max-width:430px;text-align:center}.f499ac7e_empty-feed-text__header{margin:20px 0 8px}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/empty/feed/message/UiEmptyFeedMessage.scss","webpack://./../ant/src/assets/scss/variables/content.scss"],"names":[],"mappings":"AACA,0BACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,YCJY,CDMZ,sCACE,eAAA,CACA,iBAAA,CAGF,kCACE,iBAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.empty-feed-text {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: $baseSpacing;\n\n  &__typography {\n    max-width: 430px;\n    text-align: center;\n  }\n\n  &__header {\n    margin: 20px 0 8px;\n  }\n}\n","$borderRadiusBase: get('themeSizes.borderRadiusBase');\n$baseSpacing: 24px;\n$spacerHeight: 32px;\n$widgetMargin: get('themeOffsets.widgetMargin');\n\n$borderRadius: 8px; // TODO: вынести в тему\n$borderRadiusHalf: 4px; // TODO: вынести в тему\n$borderRadiusQuarter: 2px; // TODO: вынести в тему\n$blockquoteBorderRadius: get('themeLongRead.blockquoteBorderRadius');\n\n$modalOffset: 72px;\n$spacerBorder: 1px solid $colorStroke;\n$dashedBorder: 1px dashed $colorStrokeDark;\n\n$spaceBase: 4px;\n\n$space2XS: $spaceBase;\n$spaceXS: $spaceBase * 2;\n$spaceS: $spaceBase * 3;\n$spaceM: $spaceBase * 4;\n$spaceL: $spaceBase * 5;\n$spaceXL: $spaceBase * 6;\n$space2XL: $spaceBase * 8;\n$space3XL: $spaceBase * 9;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty-feed-text": "f499ac7e_empty-feed-text",
	"emptyFeedText": "f499ac7e_empty-feed-text",
	"empty-feed-text__typography": "f499ac7e_empty-feed-text__typography",
	"emptyFeedText__typography": "f499ac7e_empty-feed-text__typography",
	"empty-feed-text__header": "f499ac7e_empty-feed-text__header",
	"emptyFeedText__header": "f499ac7e_empty-feed-text__header"
};
export default ___CSS_LOADER_EXPORT___;
