import classNames from 'classnames';
import React, { ChangeEventHandler, FC, MouseEventHandler, useEffect, useState, useRef } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import useClipboard from 'react-use-clipboard';

import ExitFullscreenSvg from 'ant/components/svg/exit-fullscreen.svg';
import FullscreenSvg from 'ant/components/svg/fullscreen.svg';
import MuteSvg from 'ant/components/svg/mute.svg';
import PauseSvg from 'ant/components/svg/pause.svg';
import PlaySvg from 'ant/components/svg/play.svg';
import ShareSvg from 'ant/components/svg/share.svg';
import VolumeSvg from 'ant/components/svg/volume.svg';
import { UiButton } from 'ant/components/ui/button';
import {
  UiButtonDecorator,
  UiButtonDecoratorProps,
} from 'ant/components/ui/button/decorator/UiButtonDecorator';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { message } from 'ant/components/ui/message';
import { UiOverlay } from 'ant/components/ui/overlay';
import { UiTypography, UiTypographyTextTypes } from 'ant/components/ui/typography';
import { useFullscreen } from 'ant/helpers/hooks/use-fullscreen';

import styles from './VideoPlayer.scss';

interface PlayerPropsAdditional extends Pick<UiButtonDecoratorProps, 'onClick'> {
  containerWidth?: number;
  containerHeight?: number;
  isShareable?: boolean;
  isThumbnail?: boolean;
}

type PlayerStateAdditional = {
  mutedVolume: number;
};

export type VideoPlayerProps = ReactPlayerProps & PlayerPropsAdditional;
type VideoPlayerState = ReactPlayerProps & PlayerStateAdditional;

const DEFAULT_PLAYER_STATE: VideoPlayerState = {
  played: 0,
  loaded: 0,
  pip: false,
  loop: false,
  duration: 0,
  volume: 0.8,
  light: false,
  muted: false,
  seeking: false,
  playing: false,
  controls: false,
  mutedVolume: 0.8,
  playbackRate: 1.0,
};

const getDuration = (seconds: number) => {
  const pad = (value: number) => {
    return `0${value}`.slice(-2);
  };

  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());

  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }

  return `${mm}:${ss}`;
};

const playerContainerSelector = '.react-player-container';

export const VideoPlayer: FC<VideoPlayerProps> = (props) => {
  const reactPlayerRef = useRef<ReactPlayer>(null);
  const [playerState, setPlayerState] = useState<VideoPlayerState>(DEFAULT_PLAYER_STATE);
  const { isFullscreen, onToggleFullscreen } = useFullscreen({ querySelector: playerContainerSelector });
  const { duration, played, playing, volume, muted, seeking } = playerState;
  const { url, isThumbnail, containerWidth, containerHeight, isShareable, onClick } = props;

  const [isCopied, setCopied] = useClipboard(window.location.href, { successDuration: 300 });

  useEffect(() => {
    if (isCopied) {
      message.success('Ссылка скопирована');
    }
  }, [isCopied]);

  const onDuration = (value: number) => {
    setPlayerState((prevState) => ({ ...prevState, duration: value }));
  };

  const onSeekChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setPlayerState((prevState) => ({ ...prevState, played: Number(value) }));
  };

  const onSeekMouseDown = () => {
    setPlayerState((prevState) => ({ ...prevState, seeking: true }));
  };

  const onSeekMouseUp: MouseEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.currentTarget;

    reactPlayerRef?.current?.seekTo(Number(value));
    setPlayerState((prevState) => ({ ...prevState, seeking: false }));
  };

  const onProgress: ReactPlayerProps['onProgress'] = (progress) => {
    if (!seeking) {
      const { played: currentPlayed, loaded: currentLoaded = 0 } = progress;

      setPlayerState((prevState) => ({
        ...prevState,
        played: currentPlayed,
        loaded: currentLoaded,
      }));
    }
  };

  const onTogglePlay = () => {
    setPlayerState((prevState) => ({ ...prevState, playing: !prevState.playing }));
  };

  const onChangeVolume: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    const numberValue = Number(value);

    setPlayerState((prevState) => ({
      ...prevState,
      volume: numberValue,
      muted: numberValue === 0,
      mutedVolume: numberValue,
    }));
  };

  const onToggleMute = () => {
    setPlayerState((prevState) => ({
      ...prevState,
      muted: !prevState.muted,
      volume: !prevState.muted ? 0 : prevState.mutedVolume || DEFAULT_PLAYER_STATE.mutedVolume,
      mutedVolume: prevState.mutedVolume,
    }));
  };

  return (
    <UiButtonDecorator
      onClick={onClick}
      style={{
        width: isFullscreen ? window.innerWidth : containerWidth,
        height: isFullscreen ? window.innerHeight : containerHeight,
      }}
      className={classNames(styles.videoPlayer, [{ 'react-player-container': !isThumbnail }])}
    >
      <UiOverlay
        overlayStyles={
          !playing
            ? {
                background: 'rgba(0,0,0,.5)',
              }
            : { background: 'transparent' }
        }
        content={
          <>
            {!playing && (
              <UiButton
                type="primary"
                style={{ pointerEvents: isThumbnail ? 'none' : 'all' }}
                disabledFocus
                icon={<UiIcon component={PlaySvg} width={30} height={30} />}
                className={styles.videoPlayer__playButton}
                onClick={onTogglePlay}
              />
            )}
            {!isThumbnail && (
              <UiRow wrap={false} align="middle" className={styles.videoPlayer__controls}>
                <UiCol flex="1 1 50%">
                  <div className={styles.videoPlayer__playback}>
                    <UiButton
                      disabledFocus
                      type="link"
                      size="small"
                      onClick={onTogglePlay}
                      icon={
                        <UiIcon
                          color="white"
                          component={playing ? PauseSvg : PlaySvg}
                          width={30}
                          height={30}
                        />
                      }
                    />
                    <input
                      type="range"
                      className={styles.videoPlayer__slider}
                      step="any"
                      value={played}
                      min={0}
                      max={0.999999}
                      onMouseDown={onSeekMouseDown}
                      onMouseUp={onSeekMouseUp}
                      onChange={onSeekChange}
                      style={{
                        backgroundSize: `${played * 100}% 100%`,
                      }}
                    />
                    <UiTypography.Text
                      className={styles.videoPlayer__duration}
                      type={UiTypographyTextTypes.Invert}
                    >
                      {getDuration(duration * (1 - played))}
                    </UiTypography.Text>
                  </div>
                </UiCol>
                <UiCol flex="1 1 10%">
                  <div className={styles.videoPlayer__volume}>
                    <UiButton
                      disabledFocus
                      type="link"
                      onClick={onToggleMute}
                      icon={
                        <UiIcon
                          color="white"
                          component={muted ? MuteSvg : VolumeSvg}
                          width={30}
                          height={30}
                        />
                      }
                    />
                    <input
                      type="range"
                      step="any"
                      min={0}
                      max={1}
                      onChange={onChangeVolume}
                      value={volume}
                      className={classNames(styles.videoPlayer__slider)}
                      style={{
                        backgroundSize: `${(volume || 0) * 100}% 100%`,
                      }}
                    />
                  </div>
                </UiCol>
                <UiCol flex="0 0 auto">
                  <div className={styles.videoPlayer__actions}>
                    {isShareable && (
                      <UiButton
                        disabledFocus
                        type="link"
                        size="small"
                        onClick={setCopied}
                        icon={<UiIcon color="white" component={ShareSvg} width={30} height={30} />}
                      />
                    )}
                    <UiButton
                      disabledFocus
                      type="link"
                      size="small"
                      onClick={onToggleFullscreen}
                      icon={
                        <UiIcon
                          color="white"
                          component={isFullscreen ? ExitFullscreenSvg : FullscreenSvg}
                          width={30}
                          height={30}
                        />
                      }
                    />
                  </div>
                </UiCol>
              </UiRow>
            )}
          </>
        }
      >
        <ReactPlayer
          ref={reactPlayerRef}
          playing={playing}
          played={played}
          volume={volume}
          muted={muted}
          url={url}
          width={isFullscreen ? '100%' : containerWidth}
          height={isFullscreen ? '100%' : containerHeight}
          onDuration={onDuration}
          onProgress={onProgress}
          onError={(e) => console.warn('onError', e)}
        />
      </UiOverlay>
    </UiButtonDecorator>
  );
};
