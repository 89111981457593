import type { EditorConfig } from '@ckeditor/ckeditor5-core';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import mergeWith from 'lodash/mergeWith';
import React, { FC } from 'react';

import { EntityContentType } from 'ant/types/models/entity-text';

import { editorConfiguration } from './config/editor-config';
import { EditorViewer } from './viewer/EditorViewer';

import './Editor.scss';

export interface EditorValue {
  data: string;
  version: EntityContentType;
}

export interface EditorProps {
  value?: EditorValue;
  onChange?: (value: EditorValue) => void;
  config?: EditorConfig;
}

type EditorComposition = {
  Viewer: typeof EditorViewer;
};

type EditorComponent = FC<EditorProps> & EditorComposition;

const editorArrayMergeCustomizer = (objValue: unknown, srcValue: unknown) => {
  return Array.isArray(objValue) ? objValue.concat(srcValue) : undefined;
};

export const Editor: EditorComponent = (props) => {
  const { value, onChange, config } = props;

  const mergedConfig = mergeWith({}, editorConfiguration, config, editorArrayMergeCustomizer);

  return (
    <CKEditor
      editor={ClassicEditor}
      config={mergedConfig}
      data={value?.data}
      onChange={(_, editor) => {
        const data: EditorValue = {
          data: editor.getData(),
          version: EntityContentType.CKEditor5,
        };

        onChange?.(data);
      }}
    />
  );
};

Editor.Viewer = EditorViewer;
