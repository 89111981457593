import { ProjectId, ProjectsStageId, ProjectsTaskId } from 'ant/types/models/projects';

const getUrl = (url: string) => `/api/tasks/${url}`;
const getUrlHistory = (url: string) => `/api/tasks/history/${url}`;
const getUrlProjects = (url: string) => `/api/tasks/projects/${url}`;
const getUrlReferences = (url: string) => `/api/tasks/references/${url}`;
const getUrlStages = (url: string) => `/api/tasks/stages/${url}`;
const getUrlTasks = (url: string) => `/api/tasks/tasks/${url}`;

class TasksEndpoints {
  static history = () => getUrlHistory('');
  static project = (id: ProjectId) => getUrlProjects(`${id}/`);
  static projectParticipants = (projectId: ProjectId) => getUrlProjects(`${projectId}/participants/`);
  static projects = () => getUrlProjects('');
  static referencesPriorities = () => getUrlReferences('priorities/');
  static referencesStatuses = () => getUrlReferences('statuses/');
  static stage = (id: ProjectsStageId) => getUrlStages(`${id}/`);
  static stages = () => getUrlStages('');
  static task = (taskId: ProjectsTaskId) => getUrlTasks(`${taskId}/`);
  static taskNew = (projectId: ProjectId) => getUrlTasks(`${projectId}/new/`);
  static tasksBrowse = () => getUrl('browse/');
}

export { TasksEndpoints };
