import { UiUserInfo, UiUserInfoProps as InfoProps } from 'ant/components/ui/user/info/UiUserInfo';

type UserComposition = {
  Info: typeof UiUserInfo;
};

const UiUser: UserComposition = {
  Info: UiUserInfo,
};

export type UiUserInfoProps = InfoProps;
export { UiUser };
