import { Plugin } from '@ckeditor/ckeditor5-core';
import type { DocumentSelection, Element, Selection, ViewElement, Match } from '@ckeditor/ckeditor5-engine';

import { VideoUtils } from '../videoutils';

export class VideoCaptionUtils extends Plugin {
  public static get pluginName() {
    return 'VideoCaptionUtils' as const;
  }

  public static get requires() {
    return [VideoUtils] as const;
  }

  // eslint-disable-next-line class-methods-use-this
  public getCaptionFromVideoModelElement(videoModelElement: Element): Element | null {
    for (const node of videoModelElement.getChildren()) {
      if (!!node && node.is('element', 'caption')) {
        return node;
      }
    }

    return null;
  }

  public getCaptionFromModelSelection(selection: Selection | DocumentSelection): Element | null {
    const videoUtils = this.editor.plugins.get('VideoUtils') as VideoUtils;
    const captionElement = selection.getFirstPosition()!.findAncestor('caption');

    if (!captionElement) {
      return null;
    }

    if (videoUtils.isVideo(captionElement.parent as Element)) {
      return captionElement;
    }

    return null;
  }

  public matchVideoCaptionViewElement(element: ViewElement): Match | null {
    const videoUtils = this.editor.plugins.get('VideoUtils') as VideoUtils;

    // eslint-disable-next-line eqeqeq
    if (element.name == 'figcaption' && videoUtils.isBlockVideoView(element.parent as ViewElement)) {
      return { name: true };
    }

    return null;
  }
}
