import { Input } from 'antd';
import { InputProps, InputRef, SearchProps } from 'antd/es/input';
import { PasswordProps } from 'antd/lib/input';
import classNames from 'classnames';
import React, { FC } from 'react';

import ClearSvgIcon from 'ant/components/svg/clear.svg';
import HideSvg from 'ant/components/svg/hide.svg';
import SearchIcon from 'ant/components/svg/search.svg';
import ViewSvg from 'ant/components/svg/view.svg';
import { UiIcon } from 'ant/components/ui/icon';

import styles from './UiInput.scss';

const ccn = classNames.bind(styles);

export type UiInputRef = InputRef;

export interface UiInputProps extends InputProps {
  onClear?: () => void;
  clearIcon?: React.ReactNode;
  noStyle?: boolean;
  prefixNoStyle?: boolean;
}

const { Search } = Input;

type UiInputComponent = React.ForwardRefExoticComponent<React.RefAttributes<InputRef> & UiInputProps>;

const UiInputBase: UiInputComponent = React.forwardRef((props, ref) => {
  const { allowClear, value, className, clearIcon, onClear, suffix, noStyle, prefixNoStyle, ...restProps } =
    props;
  const ClearIcon = clearIcon || ClearSvgIcon;
  const showClear = value && allowClear && onClear;

  return (
    <Input
      {...restProps}
      value={value}
      allowClear={false} // Отключил стандартный, нельзя переопределить
      className={ccn(styles.uiInput, className, {
        [styles.uiInput_noStyle]: noStyle,
        [styles.uiInput__prefix_noStyle]: prefixNoStyle,
      })}
      suffix={showClear ? <ClearIcon className={styles.uiInput__searchClear} onClick={onClear} /> : suffix}
      ref={ref}
    />
  );
});

export interface UiInputComposition {
  Group: typeof Input.Group;
  Search: FC<UiInputProps>;
  SearchBox: FC<SearchProps>;
  Password: FC<PasswordProps>;
  TextArea: typeof Input.TextArea;
}

export const UiInput = UiInputBase as UiInputComponent & UiInputComposition;

export const UiInputSearch: FC<UiInputProps> = (props) => {
  return <UiInput {...props} prefix={<SearchIcon className={styles.uiInput__searchPrefix} />} />;
};

export const SearchBox: FC<SearchProps> = (props) => {
  return <Search {...props} prefix={<SearchIcon className={styles.uiInput__searchPrefix} />} />;
};

export const UiInputPassword: FC<PasswordProps> = (props) => {
  return (
    <Input.Password
      iconRender={(visible) => <UiIcon component={visible ? ViewSvg : HideSvg} width={20} height={20} />}
      {...props}
    />
  );
};

UiInput.Group = Input.Group;
UiInput.Search = UiInputSearch;
UiInput.SearchBox = SearchBox;
UiInput.Password = UiInputPassword;
UiInput.TextArea = Input.TextArea;
