import { BlogAlbumImageModel, BlogFullModel, BlogId, BlogModel } from 'ant/types/models/blog.model';
import { CalendarEventModel } from 'ant/types/models/calendar';
import { CMSPageModel } from 'ant/types/models/cms.model';
import { CommentDetailListModel } from 'ant/types/models/comment.model';
import { EventModel } from 'ant/types/models/event.model';
import { FileStorageEntryVersionModel, FileStorageListEntryModel } from 'ant/types/models/file.model';
import { GamificationBadges } from 'ant/types/models/gamification.model';
import { NewsArticle } from 'ant/types/models/news.model';
import { PostBlogModel, MicropostModel, PostNewsModel } from 'ant/types/models/post';
import { SkillType } from 'ant/types/models/profile.model';
import { UserInitiatorModel } from 'ant/types/models/user.model';

export enum NotificationVersions {
  V1 = 1,
  V2 = 2,
}

export enum NotificationFetchStatuses {
  Unread = 'unread',
  Read = 'read',
  All = 'all',
}

export enum NotificationTypes {
  SkillAssignToUser = 'profile_skill_assign_to_user',
  SkillApproved = 'profile_skill_approved',
  CompetenceAssignToUser = 'profile_competence_assign_to_user',
  CompetenceApproved = 'profile_competence_approved',
  BlogEntryCreated = 'blog_blog_entry_created',
  BlogEntryUpdated = 'blog_blog_entry_update',
  BlogUserInvite = 'blog_blog_user_invite',
  BlogUserInviteExpert = 'blog_blog_user_invite_expert',
  TimelinesSignerMicropostCreated = 'timelines_signer_micropost_created',
  BlogUserInviteRequestClosedBlog = 'blog_blog_user_invite_request_closed_blog',
  BlogUserApproveRequestClosedBlog = 'blog_blog_user_approve_request_closed_blog',
  BlogUserRejectRequestClosedBlog = 'blog_blog_user_reject_request_closed_blog',
  BlogUserInviteModerator = 'blog_blog_user_invite_moderator',
  BlogEntryApproved = 'blog_entry_approved',
  BlogEntryDeclined = 'blog_entry_declined',
  BlogEntryRequireModeration = 'blog_entry_require_moderation',
  BlogGroupFileCreated = 'blog_group_file_created',
  BlogGroupAdministratorIsBlocked = 'blog_group_administrator_is_blocked',
  GamificationNewThanks = 'gamification_new_thanks',
  SurveyPublished = 'survey_survey_published',
  EventsInvite = 'events_events_invite',
  EventsDelete = 'events_events_delete',
  GamificationEventsManualGiveBadges = 'gamification_events_manual_give_badges',
  CalendarGroupEventAttendeeApproveDecision = 'calendar_group_event_attendee_approve_decision',
  CalendarGroupEventAttendeeRejectDecision = 'calendar_group_event_attendee_reject_decision',
  CalendarGroupEventAttendeeMaybeDecision = 'calendar_group_event_attendee_maybe_decision',
  CalendarGroupEventInvite = 'calendar_group_event_invite',
  CalendarGroupEventUpdated = 'calendar_group_event_updated',
  CalendarGroupEventDestroyed = 'calendar_group_event_destroyed',
  CalendarGroupEventComingSoon = 'calendar_ group_event_coming_soon',
  CalendarGroupEventDeleteAttendee = 'calendar_group_event_delete_attendee',
  CmsPageUserMentioned = 'cms_cms_page_user_mentioned',
  NewsUserMentioned = 'news_news_user_mentioned',
  FileStorageFileShared = 'filestorage_file_shared',

  // comments_comment_created
  CmsPageCommentCreated = 'comments_comment_created_cms',
  FileVersionCommentCreated = 'comments_comment_created_file_version',
  NewsCommentCreated = 'comments_comment_created_news',
  BlogEntryCommentCreated = 'comments_comment_created_entry',
  MicropostCommentCreated = 'comments_comment_created_micropost',
  AlbumImageCommentCreated = 'comments_comment_created_album_image',

  // comments_comment_reply
  CmsPageCommentReply = 'comments_comment_reply_cms',
  FileVersionCommentReply = 'comments_comment_reply_file_version',
  NewsCommentReply = 'comments_comment_reply_news',
  BlogEntryCommentReply = 'comments_comment_reply_entry',
  MicropostCommentReply = 'comments_comment_reply_micropost',
  AlbumImageCommentReply = 'comments_comment_reply_album_image',

  // comments_comment_user_mentioned
  CmsPageCommentUserMentioned = 'comments_comment_user_mentioned_cms',
  FileVersionCommentUserMentioned = 'comments_comment_user_mentioned_file_version',
  NewsCommentUserMentioned = 'comments_comment_user_mentioned_news',
  BlogEntryCommentUserMentioned = 'comments_comment_user_mentioned_entry',
  MicropostCommentUserMentioned = 'comments_comment_user_mentioned_micropost',
  AlbumImageCommentUserMentioned = 'comments_comment_user_mentioned_album_image',

  // comments_comment_user_mentioned
  CmsPageCommentReaction = 'comments_comment_reaction_cms',
  FileVersionCommentReaction = 'comments_comment_reaction_file_version',
  NewsCommentReaction = 'comments_comment_reaction_news',
  BlogEntryCommentReaction = 'comments_comment_reaction_entry',
  MicropostCommentReaction = 'comments_comment_reaction_micropost',
  AlbumImageCommentReaction = 'comments_comment_reaction_album_image',
}

interface BaseContentModel {
  title: string;
  description: string;
}

export interface ContentSkillModel extends BaseContentModel {
  id: string;
  name: string;
  type: SkillType;
  approversCount: number;
  proveThreshold: number;
}

export interface ContentSurveyModel extends BaseContentModel {
  link: string;
  surveyType: string;
  questionsCount: string;
}

export interface LinkContentModel extends BaseContentModel {
  link: string;
}

export interface BlogInviteModel extends BaseContentModel {
  id: BlogId;
  name: string;
  isSubscribed: boolean;
  isUserInvite: boolean;
}

type BaseCommentNotificationContentModel = {
  comment: CommentDetailListModel;
};

type BaseCommentReplyNotificationContentModel = {
  parent?: CommentDetailListModel;
};

export type GroupFileCreatedContentModel = {
  file: FileStorageListEntryModel;
  blog: BlogModel;
};

export interface NotificationFileVersionCommentModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  fileVersion: FileStorageEntryVersionModel;
  file: FileStorageListEntryModel;
}

export interface NotificationCmsPageCommentModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  cms: CMSPageModel;
}

export interface NotificationNewsCommentModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  news: NewsArticle;
}

export interface NotificationBlogEntryCommentModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  entry: PostBlogModel;
}

export interface NotificationMicropostCommentModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  micropost: MicropostModel;
}

export interface NotificationAlbumImageCommentModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  albumImage: BlogAlbumImageModel;
  blog: BlogModel;
}

export type NotificationMicropostEntryModel = {
  micropost: MicropostModel;
};

type NotificationCommentContentModel =
  | NotificationCmsPageCommentModel
  | NotificationFileVersionCommentModel
  | NotificationBlogEntryCommentModel
  | NotificationAlbumImageCommentModel
  | NotificationNewsCommentModel
  | NotificationMicropostCommentModel;

export type NotificationContentV1 = ContentSkillModel | LinkContentModel | ContentSurveyModel;
export type NotificationContentV2 =
  | EventModel
  | GamificationBadges
  | PostBlogModel
  | CalendarEventModel
  | BlogInviteModel
  | BlogFullModel
  | CMSPageModel
  | PostNewsModel
  | GroupFileCreatedContentModel
  | NotificationCommentContentModel
  | NotificationMicropostEntryModel
  | FileStorageListEntryModel;

export type NotificationContent = NotificationContentV1 | NotificationContentV2;

export type NotificationId = number;
export type NotificationModel<T extends NotificationContent = NotificationContent> = {
  id: NotificationId;
  initiator: UserInitiatorModel;
  image: string;
  status: number;
  content: T;
  routeUri: string;
  displayAvatar: boolean;
  createdAt: string;
  confirmStatus: number;
  notificationType: NotificationTypes;
  service?: string;
  version: NotificationVersions;
};

export enum NotificationSortAttributes {
  Date = 'createdAt',
  UnreadFirst = 'status',
}

export type NotificationFilters = string[];

export type FetchNotificationParams = {
  notificationType?: string;
  pageSize?: number;
  status?: NotificationFetchStatuses;
};

export enum NotificationStatuses {
  Unread = 0,
  Read = 1,
}
