import { Route } from 'antd/es/breadcrumb/Breadcrumb';
import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';

interface UiBreadcrumbItemProps {
  route: Route;
}

export const UiBreadcrumbItem: FC<UiBreadcrumbItemProps> = (props) => {
  const { route } = props;
  const { pathname } = useLocation();

  return route.path === pathname || route.path === '' ? (
    <UiTruncateMarkup truncate lines={1}>
      {route.breadcrumbName}
    </UiTruncateMarkup>
  ) : (
    <Link to={route.path}>{route.breadcrumbName}</Link>
  );
};
