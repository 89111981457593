import { AxiosError } from 'axios';

type ErrorResponseMessageDetail = {
  msg: string;
};

export type ErrorResponseMessage = {
  detail: Array<ErrorResponseMessageDetail> | string;
  non_field_errors: string;
  message: string;
  error: string;
};

export type AxiosErrorResponseMessage = AxiosError<Partial<ErrorResponseMessage>>;

export const getErrorResponseMessage = (e: AxiosErrorResponseMessage, defaultMessage = 'Ошибка'): string => {
  if (typeof e?.response?.data?.detail === 'string') {
    return e.response.data.detail;
  }

  if (e?.response?.data?.detail?.[0]) {
    return (e.response.data.detail[0] satisfies ErrorResponseMessageDetail).msg;
  }

  return (
    e?.response?.data?.non_field_errors ||
    e?.response?.data?.message ||
    e?.response?.data?.error ||
    defaultMessage
  );
};
