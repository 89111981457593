import classNames from 'classnames';
import React from 'react';

import { UiUser, UiUserInfoProps } from 'ant/components/ui/user';

import styles from './UiLinkListItem.scss';
import { UiLinkListItemSkeleton } from './skeleton/UiLinkListItemSkeleton';

export const DEFAULT_LINK_LIST_ITEM = {
  size: 40,
} as const;

export interface UiLinkListItemProps extends Omit<UiUserInfoProps, 'size'> {
  size?: number;
  suffixedChildren?: React.ReactNode;
}

export interface UiLinkListItemInterface extends React.FC<UiLinkListItemProps> {
  Skeleton: typeof UiLinkListItemSkeleton;
}

export const UiLinkListItem: UiLinkListItemInterface = (props) => {
  const {
    className,
    avatar,
    size = DEFAULT_LINK_LIST_ITEM.size,
    suffixedChildren,
    isActive,
    ...restProps
  } = props;

  return (
    <div className={classNames(styles.uiLinkListItem, className)}>
      <UiUser.Info
        avatar={{ ...avatar, size }}
        isActive={isActive}
        className={styles.uiLinkListItem__info}
        {...restProps}
      />
      <span className={styles.uiLinkListItem__actions}>{suffixedChildren}</span>
    </div>
  );
};

UiLinkListItem.Skeleton = UiLinkListItemSkeleton;
