import { Editor } from '@ckeditor/ckeditor5-core';

export function MentionPlugin(editor: Editor) {
  // The upcast converter will convert view <a class="mention" href="" data-user-id="">
  // elements to the model 'mention' text attribute.
  editor.conversion.for('upcast').elementToAttribute({
    view: {
      name: 'a',
      key: 'data-mention',
      classes: 'mention',
      attributes: {
        href: true,
        'data-user-id': true,
      },
    },
    model: {
      key: 'mention',
      // @ts-expect-error: TODO: исправить ошибки типизаци
      value: (viewItem) => {
        // The mention feature expects that the mention attribute value
        // in the model is a plain object with a set of additional attributes.
        // In order to create a proper object use the toMentionAttribute() helper method:
        return editor.plugins.get('Mention').toMentionAttribute(viewItem, {
          // Add any other properties that you need.
          // @ts-expect-error: TODO: исправить ошибки типизаци
          link: viewItem.getAttribute('href'),
          userId: viewItem.getAttribute('data-user-id'),
        });
      },
    },
    converterPriority: 'high',
  });

  // Downcast the model 'mention' text attribute to a view <a> element.
  editor.conversion.for('downcast').attributeToElement({
    model: 'mention',
    view: (modelAttributeValue, { writer }) => {
      // Do not convert empty attributes (lack of value means no mention).
      if (!modelAttributeValue) {
        return;
      }

      // TODO: исправить ошибки типизаци
      // eslint-disable-next-line consistent-return
      return writer.createAttributeElement(
        'a',
        {
          class: 'mention',
          'data-mention': modelAttributeValue.id,
          'data-user-id': modelAttributeValue.userId,
          href: modelAttributeValue.link,
        },
        {
          // Make mention attribute to be wrapped by other attribute elements.
          priority: 20,
          // Prevent merging mentions together.
          id: modelAttributeValue.uid,
        },
      );
    },
    converterPriority: 'high',
  });
}
