import React, { useCallback, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
  UiAutoComplete,
  UiAutoCompleteProps,
  UiOptionData,
} from 'ant/components/ui/auto-complete/UiAutoComplete';
import { UiFormItemProps } from 'ant/components/ui/form';

export interface SearchOptionItem<OptionType = unknown> extends Partial<UiOptionData> {
  /** @deprecated необходимо в приоритете использовать поле value,
   * от id будет отказ в пользу стандартной модели для полей формы antd - { value, name }
   */
  id: string;
  name: string;
  option?: OptionType;
}

export type SearchOptionCompleteType = UiFormItemProps<SearchOptionItem | undefined> &
  Omit<UiAutoCompleteProps, 'value'>;

export interface SearchOptionCompleteProps<T = undefined>
  extends UiFormItemProps<T>,
    Omit<UiAutoCompleteProps, 'value' | 'onChange'> {
  options: UiOptionData[];
  searchQuery: string;
  updateOptionListHandler: (searchQuery: string) => void;
}

// TODO: value === { id, name } -> UiOptionData, initValue -> defaultValue
const SearchOptionComplete: React.FC<SearchOptionCompleteProps<SearchOptionItem | undefined>> = (props) => {
  const {
    onChange,
    value,
    updateOptionListHandler,
    onSearch,
    searchQuery,
    maxLength = 50,
    allowClear = true,
    defaultActiveFirstOption = true,
    ...restProps
  } = props;

  const onSearchDebounced = useDebouncedCallback(updateOptionListHandler, 500);

  const onSearchAutoComplete = useCallback(
    (newSearchQuery: string) => {
      if (onSearch) {
        onSearch(newSearchQuery);
      }

      onSearchDebounced(newSearchQuery);
    },
    [onSearchDebounced, onSearch],
  );

  const onSelect = (_: UiOptionData, option: UiOptionData) => {
    const name = String(option.name || option.label);

    onSearch?.(name);
    onChange?.({ id: option.value, value: option.value, name, option });
  };

  const onChangeSearch = useCallback(() => {
    if (value && onChange) {
      onChange(undefined);
    }
  }, [value, onChange]);

  const onClear = useCallback(() => {
    if (onSearch) {
      onSearch('');
    }

    onChangeSearch();
  }, [onChangeSearch, onSearch]);

  useEffect(() => {
    if (value && onSearch) {
      onSearch(value.name);
    }
  }, [value?.name]);

  const onBlur = () => {
    if (!value) {
      onClear();
    }
  };

  return (
    <UiAutoComplete
      size="large"
      allowClear={allowClear}
      defaultActiveFirstOption={defaultActiveFirstOption}
      value={searchQuery}
      onSearch={onSearchAutoComplete}
      onSelect={onSelect}
      onClear={onClear}
      onBlur={onBlur}
      onChange={onChangeSearch}
      maxLength={maxLength}
      {...restProps}
    />
  );
};

export { SearchOptionComplete };
