// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6bb9a136_reactions-icon{cursor:pointer;transition:.2s ease transform;margin-left:-4px;height:20px}._6bb9a136_reactions-icon:hover{transform:scale(1.1)}", "",{"version":3,"sources":["webpack://./../ant/src/components/widgets/Reactions/icon/ReactionsIcon.scss"],"names":[],"mappings":"AACA,0BACE,cAAA,CACA,6BAAA,CACA,gBAAA,CACA,WAAA,CAEA,gCACE,oBAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.reactions-icon {\n  cursor: pointer;\n  transition: 0.2s ease transform;\n  margin-left: -4px;\n  height: 20px;\n\n  &:hover {\n    transform: scale(1.1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reactions-icon": "_6bb9a136_reactions-icon",
	"reactionsIcon": "_6bb9a136_reactions-icon"
};
export default ___CSS_LOADER_EXPORT___;
