import { UiMentionProps } from './UiMentions';
import uiMentionsInputStyles from './input/UiMentionsInput.scss';
import { renderSuggestion } from './suggestion/renderSuggestion';

export * from './suggestion/renderSuggestion';
export * from './input/UiMentionsInput';
export * from './UiMentions';

export const defaultUserMentionsProps: UiMentionProps = {
  data: [],
  trigger: '@',
  markup: '[__display__](@/user/__id__)', // TODO: тут @ -- символ нужен для распознования что это mention в UiMarkdown!
  displayTransform: (_, display) => `${display}`,
  className: uiMentionsInputStyles.uiMentionsInput__mention,
  renderSuggestion,
};
