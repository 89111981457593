// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e07d8098_ui-table-cell-dash{display:flex;justify-content:center}", "",{"version":3,"sources":["webpack://./../ant/src/components/ui/table/cell-dash/UiTableCellDash.scss"],"names":[],"mappings":"AACA,6BACE,YAAA,CACA,sBAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.ui-table-cell-dash {\n  display: flex;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-table-cell-dash": "e07d8098_ui-table-cell-dash",
	"uiTableCellDash": "e07d8098_ui-table-cell-dash"
};
export default ___CSS_LOADER_EXPORT___;
